// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  IconButton,
  withStyles,
  Box,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  Input,
  FormControl,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { ContractsStyleWeb } from "./ContractsStyle.web";
import {
  CubeIcon,
  TenantName,
  BuildingName,
  DurationIcon,
  CalenderIcon,
  RentAmountIcon,
  CurrencyIcon,
  ComplexIcon,
} from "./assets";
import LeaseFormController, { Props } from "./LeaseFormController.web";
import { Formik, Form } from "formik";
import { withTranslation } from "react-i18next";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";

class LeaseForm extends LeaseFormController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const contract = JSON.parse(window.sessionStorage.getItem("contractForm") as any);
    const isBuildingAvail = window.sessionStorage.getItem("isBuildingAvailable");
    const template_id: any = this.props.navigation.getParam("templateId");
    window.sessionStorage.removeItem("buildingId");
    window.sessionStorage.removeItem("unitId");
    window.sessionStorage.removeItem("complexId");
    this.setState(
      {
        templateId: template_id,
        isBuildingAvailable: isBuildingAvail === "true",
        leaseForm: {
          tenantName: contract.tenantName,
          landlordName: contract.landlordName,
          buildingName: contract.buildingName,
          unitName: contract.unitName,
          complexId: contract.complexId,
          buildingId: contract.buildingId,
          unitId: contract.unitId,
          duration: contract.duration,
          startDate: contract.startDate,
          endDate: contract.endDate,
          monthlyRent: contract.monthlyRent,
          currency: contract.currency,
          complexName: contract.complexName,
          address: contract.address,
          endDateInputType: 'text',
        },
      },
      () => {
        this.getCurrencyList();
        this.getFormComplexList();
        const { complexId, buildingId, unitId } = this.state.leaseForm;
        if (complexId && buildingId && unitId) {
          this.getBuilding(complexId);
          this.getUnits(complexId, buildingId);
          this.getUnitDetails(complexId, unitId);
          this.handleCheckContractExist(complexId, unitId);
        }
      }
    );
  }

  handleErrorMessage = (errors: any, touched: any, t: any) => {
    if (errors && touched) {
      return <p className="error">{t(errors)}</p>;
    }
  };

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    const isEditTemplate = window.sessionStorage.getItem("isEditFlow");
    const page = window.sessionStorage.getItem("page");
    const currentFormattedDate = `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`;

    return (
      <>
        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.selectTemplate}>
          <Grid container className={languageCondition(language, "arabic-grid", "")}>
            <Grid item xs={12} md={7}>
              <Box>
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <IconButton data-test-id="back-button" className="lease-form-back-btn" onClick={() => this.gotoSelectTemplatePage()}>
                      <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                    </IconButton>
                    <span className="bold-text">{t("Issue a Lease")}</span>
                  </div>
                </Box>
                <Container className="page-container">
                  <Box className="issue-lease-content form" marginBottom="80px">
                    <h4 className="bold-text" style={{ marginTop: "18px" }}>
                      {t("Residential Rental Lease Agreement")}
                    </h4>
                    <Formik
                      data-test-id="formik-form"
                      enableReinitialize={true}
                      initialValues={this.state.leaseForm}
                      validationSchema={this.ContractFormValidation}
                      onSubmit={(values: any, { resetForm }) => {
                        window.sessionStorage.setItem("contractForm", JSON.stringify(values));
                        this.props.navigation.navigate("ChangedSelectedTemplate", {
                          templateId: this.state.templateId,
                        });
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                        return (
                          <Form onSubmit={handleSubmit} translate="yes">
                            <Box className="select-input-box">
                              <FormControl fullWidth>
                                <Input
                                  data-test-id="input-tenant"
                                  value={values.tenantName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="tenantName"
                                  className="select-input input"
                                  placeholder={t("Tenant Name")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={TenantName} alt="" />
                                    </InputAdornment>
                                  }
                                  disabled
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  data-test-id="input-landlord-name"
                                  value={values.landlordName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="landlordName"
                                  className="select-input input"
                                  placeholder="Landlord Name"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={TenantName} alt="" />
                                    </InputAdornment>
                                  }
                                  disabled
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    data-test-id="select-complex-id"
                                    displayEmpty
                                    value={values.complexId}
                                    onBlur={handleBlur}
                                    name="complexId"
                                    variant="filled"
                                    className="select-input lease-form-select-complex"
                                    input={<OutlinedInput />}
                                    readOnly={
                                      isEditTemplate === "false" &&
                                      page === "IssueContract" &&
                                      this.state.leaseForm.complexId
                                    }
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("Complex Name")}
                                    </MenuItem>
                                    {this.state.complexList.map((complex: any) => {
                                      return (
                                        <MenuItem
                                          className={languageCondition(language, "select-arabic-menu", "")}
                                          value={complex.id}
                                          key={complex.id}
                                          onClick={() => this.handleIssueFormComplex(complex, setFieldValue)}
                                        >
                                          {complex.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={ComplexIcon} alt="" />
                                </Box>
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  data-test-id="input-address"
                                  value={values.address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="address"
                                  className="select-input input"
                                  placeholder={t("Complex Address")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={ComplexIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  disabled
                                />
                              </FormControl>
                              {this.state.isBuildingAvailable && (
                                <FormControl fullWidth>
                                  <Box className="select-box">
                                    <Select
                                      data-test-id="select-building-id"
                                      displayEmpty
                                      value={values.buildingId}
                                      onChange={(e: any) => {
                                        const value = e.target.value;
                                        setFieldValue("buildingId", value);
                                        this.getUnits(values.complexId, value);
                                      }}
                                      onBlur={handleBlur}
                                      name="buildingId"
                                      variant="filled"
                                      className="select-input lease-form-select-building"
                                      input={<OutlinedInput />}
                                      readOnly={
                                        isEditTemplate === "false" &&
                                        page === "IssueContract" &&
                                        this.state.leaseForm.buildingId
                                      }
                                    >
                                      <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("Building Name")}
                                      </MenuItem>
                                      {this.state.buildingList.map((building: any) => {
                                        return (
                                          <MenuItem
                                            value={building.id}
                                            className={languageCondition(language, "select-arabic-menu", "")}
                                            key={building.id}
                                            onClick={() => setFieldValue("buildingName", building.name)}
                                          >
                                            {building.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <img src={BuildingName} alt="" />
                                  </Box>
                                  {this.handleErrorMessage(errors.buildingId, touched.buildingId, t)}
                                </FormControl>
                              )}
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    data-test-id="select-unit-id"
                                    displayEmpty
                                    value={values.unitId}
                                    onChange={(e: any) => {
                                      const value = e.target.value;
                                      setFieldValue("unitId", value);
                                      this.handleCheckContractExist(values.complexId, value);
                                      this.getUnitDetails(values.complexId, value);
                                    }}
                                    onBlur={handleBlur}
                                    name="unitId"
                                    variant="filled"
                                    className="select-input lease-form-select-unit"
                                    input={<OutlinedInput />}
                                    readOnly={
                                      isEditTemplate === "false" &&
                                      page === "IssueContract" &&
                                      this.state.leaseForm.unitId
                                    }
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("Unit Name")}
                                    </MenuItem>
                                    {this.state.unitList.map((unit: any) => {
                                      return (
                                        <MenuItem
                                          className={languageCondition(language, "select-arabic-menu", "")}
                                          value={unit.id}
                                          key={unit.id}
                                          onClick={() =>
                                            setFieldValue(
                                              "unitName",
                                              unit.apartment_name
                                            )
                                          }
                                        >
                                          {unit.apartment_name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={CubeIcon} alt="" />
                                </Box>
                                {this.handleErrorMessage(errors.unitId, touched.unitId, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  data-test-id="input-duration"
                                  type="text"
                                  value={values.duration}
                                  onChange={(e)=> this.handleDurationChange(e, handleChange, values, setFieldValue)}
                                  onBlur={handleBlur}
                                  name="duration"
                                  inputProps={{
                                    pattern: "^\\d*$",
                                    title: "Please enter a valid number",
                                  }}
                                  className="select-input input"
                                  placeholder={t("Enter Agreement Duration (in years)")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={DurationIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {this.handleErrorMessage(errors.duration, touched.duration, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  data-test-id="input-start-date"
                                  value={values.startDate}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleContractStartDate(e, values, handleChange, setFieldValue)}
                                  onBlur={handleBlur}
                                  name="startDate"
                                  className="select-input input"
                                  placeholder={t("Start Contract Date")}
                                  type="text"
                                  onFocus={(e: any) => (e.target.type = "date")}
                                  inputProps={{ min: currentFormattedDate }}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={CalenderIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {this.handleErrorMessage(errors.startDate, touched.startDate, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  disabled
                                  data-test-id="input-end-date"
                                  value={values.endDate}
                                  name="endDate"
                                  className="select-input input"
                                  placeholder={t("End Contract Date")}
                                  type={values.endDateInputType}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={CalenderIcon} alt="" />
                                    </InputAdornment>
                                  }
                                  inputProps={{ min: values.startDate }}
                                />
                                {this.handleErrorMessage(errors.endDate, touched.endDate, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  data-test-id="input-monthly-rent"
                                  value={values.monthlyRent}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="monthlyRent"
                                  className="select-input input"
                                  placeholder={t("Enter Monthly Rent Amount")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={RentAmountIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {this.handleErrorMessage(errors.monthlyRent, touched.monthlyRent, t)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    data-test-id="select-currency"
                                    displayEmpty
                                    value={values.currency}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="currency"
                                    variant="filled"
                                    className="select-input"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("Select Currency")}
                                    </MenuItem>
                                    {this.state.currencyList.map(
                                      (currency: any) => {
                                        return (
                                          <MenuItem
                                            className={languageCondition(language, "select-arabic-menu", "")}
                                            value={currency.attributes.currency}
                                            key={currency.id}
                                            onClick={() =>
                                              sessionStorage.setItem(
                                                "currency",
                                                currency.attributes.currency
                                              )
                                            }
                                          >
                                            {LanguageAttributeHandler(language, currency.attributes.currency, currency.attributes.currency_ar)}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                  <img src={CurrencyIcon} alt="" />
                                </Box>
                                {this.handleErrorMessage(errors.currency, touched.currency, t)}
                              </FormControl>
                            </Box>
                            <div className="next-button">
                              <Button type="submit">{t("Next")}</Button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withTranslation()(withStyles(ContractsStyleWeb)(LeaseForm));
// Customizable Area End
