// Customizable Area Start
import React from "react";
import { Box, InputLabel, MenuItem, OutlinedInput, Select, FormControl, styled } from "@material-ui/core";
import { languageCondition } from "../ConditionManager.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const NormalSelect = ({ t, language, label, icon, option, value, required = false, ...props }: any) => {
  const getLabel = (newLabel: string, isLabel: boolean) => {
    if (newLabel === "Employee Name DashboardGranted") {
      return isLabel ? "Employee Name" : "Name";
    }

    if (newLabel === "Employee Name DashboardGranted new") {
      return isLabel ? "Select Permissions" : "Permissions";
    }

    return newLabel;
  };

  return (
    <Box className={`select_icon__form_box ${icon ? "" : "select__form_box"} ${languageCondition(language, "arabic_select_box", "")}`}>
      {label && (
        <InputLabel>
          {t(getLabel(label, true))} {required && <span style={{ color: "red" }}>*</span>}
        </InputLabel>
      )}
      <Box className="select__select_box">
        {icon && <img src={icon} alt="icon" />}
        <SelectContainer>
          <FormControl className="form-control" variant="filled">
            <Select
              displayEmpty
              fullWidth
              variant="filled"
              input={<OutlinedInput />}
              className="sortBySearch"
              value={value}
              style={value !== "" ? { color: "black" } : { color: "#adadad" }}
              IconComponent={(props) => <ExpandMoreIcon className="select-icon" {...props} />}
              {...props}
              MenuProps={{
                style: {
                  marginTop: "50px",
                },
              }}
            >
              <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "")} disabled>
                {t("Select")} {t(getLabel(label, false))}
              </MenuItem>
              {option &&
                option.map((item: any) => {
                  return (
                    <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value={item.value}>
                      {t(item.label)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </SelectContainer>
      </Box>
    </Box>
  );
};

export default NormalSelect;

const SelectContainer = styled(Box)({
  "& .form-control": {
    width: "100%",
  },
  "& .sortBySearch": {
    backgroundColor: "#f9f9f9",
    height: "53px",
    borderRadius: "8px",
    border: "1px solid #f0f0f0",
    outline: "none",
  },
  "& .select-icon": {
    marginRight: "10px",
    color: "darkgray",
    fontWeight: 100,
  },
});
// Customizable Area End
