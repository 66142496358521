//@ts-ignore
//@ts-nocheck
import * as React from "react";
// Customizable Area Start
// custom components
import {Box, Button, Card, Grid} from "@material-ui/core";
import ClassifiedController, {Props} from "./ClassifiedController.web";
import {withRouter} from 'react-router';
import Loader from "../../../components/src/Loader.web";
import {Building1, BuyIcon, cartBlue, GenericBlue, GenericIcon, SellerBlue, SellerIcon} from "../src/assets";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class ClassifiedType extends ClassifiedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // @ts-ignore
    const {t,i18n} = this.props;
    const language = i18n.language;
    return (

      <>
        <Grid container className="login-wrapper incident-wrapper" dir={languageCondition(language,"rtl","ltr")}>
          <Grid container spacing={2} className="auth-container" style={{backgroundColor:"#f8f9fe"}}>
            <Grid item xs={12} md={7} className="auth-cols" style={{ justifyContent: 'unset' }}>
              <Box className="content-block">
                <Box className="content-header" style={{paddingTop:"7px",paddingBottom:"15px"}}>
                  <Box className="left-block blocks">
                    <ArrowBackIcon data-test-id="iconArrowBack" onClick={() => window.history.back()} style={{fontSize:"20px"}} />
                    <h4 className='bold-text' style={{display: "flex",fontSize:"18px"}}>{t("Classifieds")}</h4>
                  </Box>
                </Box>
                <Box className="classifiedtype-block content-block-wrapper common-incident-block" style={{paddingLeft:"20px",paddingRight:"20px"}}>
                  <Box className="incident-content-wrapper" style={{ 
                      maxHeight: '481px',
                      overflowY: 'auto', 
                      paddingLeft: '10px',
                    }}>
                    <p className="text-left" style={{fontSize:"17px",color:"#181D25",marginTop:"10px",marginBottom:"20px"}}>
                      {t("This page will allow the residents to publish classifieds to the building's classified page.You can publish an advertisement to sell things, buy things, or ask for services like a baby setter for your child.")}
                    </p>
                    <Card className="card classified-type-card" style={{height:'65px'}}>
                      <Box className="middle-section" style={{display:'flex',alignItems:'center'}}>
                        <img src={this.state.userType == 'seller' ? SellerBlue :SellerIcon} className="icons" style={languageCondition(language,{marginLeft:"10px"},{})} />
                        <input data-test-id="radioInput"  type="radio" id="radCreateMode1" name="classifiedRadio" className="radioInput" value='seller' 
                        onChange={(e) => this.changeType(e.target.value)} 
                        />
                        <label for="radCreateMode1" className={languageCondition(language,"radioCheckmark_ar","radioCheckmark")}></label>
                        <label for="radCreateMode1" className={"radioTitle" + (this.state.userType == 'seller' ? ' active-type' : '')} style={{fontSize:"16px"}}>
                          {t("I want to sell something")}
                        </label>
                      </Box>
                    </Card>
                    <Card className="card classified-type-card" style={{height:'65px'}}>
                      <Box className="middle-section">
                        <img src={this.state.userType == 'buyer'? cartBlue :BuyIcon} className="icons" style={languageCondition(language,{marginLeft:"10px"},{})} />
                        {/* <img src={BuyIconSelected} className="icons" /> */}
                        <input data-test-id="radioIcon" type="radio" id="radCreateMode2" name="classifiedRadio" value='buyer' onChange={(e) => this.changeType(e.target.value)} />
                        <label for="radCreateMode2" className={languageCondition(language,"radioCheckmark_ar","radioCheckmark")}></label>
                        <label for="radCreateMode2" className={"radioTitle" + (this.state.userType == 'buyer' ? ' active-type' : '')}>
                          {t("I want to buy something")}
                        </label>
                      </Box>
                    </Card>
                    <Card className="card classified-type-card" style={{height:'65px'}}>
                      <Box className="middle-section">
                        <img src={this.state.userType == 'generic' ? GenericBlue:GenericIcon} className="icons" style={languageCondition(language,{marginLeft:"10px"},{})} />
                        {/* <img src={GenericIconSelected} className="icons" /> */}
                        <input data-test-id="generic" type="radio" id="radCreateMode3" name="classifiedRadio" value='generic' 
                        onChange={(e) => this.changeType(e.target.value)} 
                        />
                        <label for="radCreateMode3" className={languageCondition(language,"radioCheckmark_ar","radioCheckmark")}></label>
                        <label for="radCreateMode3" className={"radioTitle" + (this.state.userType == 'generic' ? ' active-type' : '')}>
                          {t("I have a generic request")}
                        </label>
                      </Box>
                    </Card>
                  </Box>
                  <Box className="CreateClassifedFormButton">
                    <Button
                      fullWidth={true}
                      className={'SelectTypeClassifedBottomButton'}
                      variant="contained"
                      type="submit"
                      style={{
                        backgroundColor: "#2B6FEC",
                        borderRadius: 25,
                        height: 54,
                        marginBottom: 14,
                        boxShadow: "none",
                        color: "#F7F7FC",
                        fontWeight: 600,
                        fontSize: 16,
                        marginTop: 30
                      }}
                      data-test-id="nextBtn"
                      onClick={() => this.nextBtn(this.state.userType)}
                    >
                      {t("continue")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Loader loading={this.state.loading} />
      </>
    )
  }
}
export default withTranslation()(withRouter(ClassifiedType));
export {ClassifiedType}
// Customizable Area End