// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  IconButton,
  withStyles,
  Box,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  Input,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Drawer,
  Link,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  EarthIcon,
  CubeIcon,
  CalenderIcon,
  RentAmountIcon,
  BuildingIcon,
  RegionIcon,
  CityIcon,
  ComplexIcon,
  FloorIcon,
  SizeIcon,
  ConfigIcon,
  PriceIcon,
  ValuationIcon,
  DeleteRentIcon,
  RentAmountHistoryIcon,
  ReceivedIcon,
  TenantIcon,
  UploadIcon,
} from "./assets";
import RegisterUnitController, { Props } from "./RegisterUnitController.web";
import moment from "moment";
import { Formik, Form } from "formik";
import { withTranslation } from "react-i18next";
import { MyUnitStyle } from "./MyUnitStyle.web";
import Loader from "../../../components/src/Loader.web";
import { del_image } from "../../LeadManagement/src/assets";
import { ErrorHandler, LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";

class RegisterMyUnit extends RegisterUnitController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    this.getCountryList();
    this.getConfigurationList();
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.registerUnit}>
          <Grid container className="register-unit-container">
            <Grid item xs={12} md={7}>
              <Box className={languageCondition(language, "arabic-grid", "")}>
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <Link href="/MyUnitList">
                      <IconButton>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                    </Link>
                    <span className="bold-text">{t("Add Another Unit")}</span>
                  </div>
                </Box>
                <Container className="page-container">
                  <Box className="issue-lease-content form" style={{ marginBottom: "120px" }}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={this.state.unitRegisterForm}
                      validationSchema={this.validationRegisterUnitFormSchema}
                      onSubmit={(values, { resetForm }) => {
                        this.setState({ loading: true }, () => {
                          this.registerUnitToOwner(values);
                          if (this.state.newRentIds.length > 0) {
                            this.publishRentHistory();
                          }
                          if (this.state.deleteRentIds.length > 0) {
                            this.deleteRentHistoriesOnSave();
                          }
                          resetForm();
                        });
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }: any) => {
                        return (
                          <Form onSubmit={handleSubmit} translate="yes">
                            <Box className="select-input-box">
                              <h4 style={{ marginTop: "18px", fontSize: "18px" }} className="bold-text">
                                {t("Location Details")}
                              </h4>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    displayEmpty
                                    value={values.country}
                                    onChange={(e: any) => {
                                      setFieldValue("country", e.target.value);
                                      this.getRegionList(e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    className="select-input register-unit-country"
                                    variant="filled"
                                    name="country"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("Country")}
                                    </MenuItem>
                                    {this.state.countryList.map((country: any) => {
                                      return (
                                        <MenuItem value={country} key={country} className={languageCondition(language, "select-arabic-menu", "")}>
                                          {country}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={EarthIcon} alt="" />
                                </Box>
                                {ErrorHandler(t, touched.country, errors.country)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    displayEmpty
                                    value={values.region}
                                    onChange={(e: any) => {
                                      const value = e.target.value;
                                      setFieldValue("region", value);
                                      this.getCityList(value);
                                    }}
                                    onBlur={handleBlur}
                                    name="region"
                                    variant="filled"
                                    className="select-input register-unit-region"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("Region")}
                                    </MenuItem>
                                    {this.state.regionList.map((region: any) => {
                                      return (
                                        <MenuItem value={region} key={region} className={languageCondition(language, "select-arabic-menu", "")}>
                                          {region}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={RegionIcon} alt="" />
                                </Box>
                                {ErrorHandler(t, touched.region, errors.region)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    displayEmpty
                                    value={values.city}
                                    onChange={(e: any) => {
                                      const value = e.target.value;
                                      setFieldValue("city", value);
                                      this.getComplexList(value);
                                    }}
                                    onBlur={handleBlur}
                                    name="city"
                                    variant="filled"
                                    className="select-input register-unit-city"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("City")}
                                    </MenuItem>
                                    {this.state.cityList.map((city: any) => {
                                      return (
                                        <MenuItem value={city} key={city} className={languageCondition(language, "select-arabic-menu", "")}>
                                          {city}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={CityIcon} alt="" />
                                </Box>
                                {ErrorHandler(t, touched.city, errors.city)}
                              </FormControl>
                              <Box className="map-span">
                                <Link
                                  href={`https://maps.google.com/?q=${this.state.lat},${this.state.long}`}
                                  target="_blank"
                                >
                                  <span className="bold-text">{t("See building on map")}</span>
                                </Link>
                              </Box>
                              <h4 style={{ marginTop: "18px", fontSize: "18px" }} className="bold-text">
                                {t("Unit Details")}
                              </h4>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    displayEmpty
                                    value={values.complex}
                                    onBlur={handleBlur}
                                    name="complex"
                                    variant="filled"
                                    className="select-input"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("Complex Name")}
                                    </MenuItem>
                                    {this.state.complexList.map((complex: any) => {
                                      return (
                                        <MenuItem
                                          className={languageCondition(language, "select-arabic-menu", "")}
                                          value={complex.id}
                                          key={complex.id}
                                          onClick={() => this.handleRegisterComplex(complex, setFieldValue)}
                                        >
                                          {complex.attributes.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={ComplexIcon} alt="" />
                                </Box>
                                {ErrorHandler(t, touched.complex, errors.complex)}
                              </FormControl>
                              {this.state.isBuilding && (
                                <FormControl fullWidth>
                                  <Box className="select-box">
                                    <Select
                                      displayEmpty
                                      value={values.buildingId}
                                      onChange={(e: any) => {
                                        const value = e.target.value;
                                        setFieldValue("buildingId", value);
                                        this.getFloorList(value);
                                      }}
                                      onBlur={handleBlur}
                                      name="buildingId"
                                      variant="filled"
                                      className="select-input register-unit-building"
                                      input={<OutlinedInput />}
                                    >
                                      <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("Building Name")}
                                      </MenuItem>
                                      {this.state.buildingList.map((building: any) => {
                                        return (
                                          <MenuItem value={building.id} key={building.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                            {building.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <img src={BuildingIcon} alt="" />
                                  </Box>
                                  {ErrorHandler(t, touched.buildingId, errors.buildingId)}
                                </FormControl>
                              )}
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    displayEmpty
                                    value={values.floorId}
                                    onChange={(e: any) => {
                                      const floor = e.target.value;
                                      setFieldValue("floorId", floor);
                                      this.getUnitList(values.buildingId, floor);
                                    }}
                                    onBlur={handleBlur}
                                    name="floorId"
                                    className="select-input register-unit-floor"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("Floor Number")}
                                    </MenuItem>
                                    {this.state.floorList.map((floor: any) => {
                                      return (
                                        <MenuItem value={floor} key={floor} className={languageCondition(language, "select-arabic-menu", "")}>
                                          {floor}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={FloorIcon} alt="" />
                                </Box>
                                {ErrorHandler(t, touched.floorId, errors.floorId)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    displayEmpty
                                    value={values.unitId}
                                    onChange={(e: any) => {
                                      const value = e.target.value;
                                      setFieldValue("unitId", value);
                                      this.getRentHistory(value);
                                      this.handleSaveData()
                                    }}
                                    onBlur={handleBlur}
                                    name="unitId"
                                    variant="filled"
                                    className="select-input register-unit-unit"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                      {t("Unit Number")}
                                    </MenuItem>
                                    {this.state.unitList.map((unit: any) => {
                                      return (
                                        <MenuItem value={unit.id} key={unit.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                          {unit.apartment_name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={CubeIcon} alt="" />
                                </Box>
                                {ErrorHandler(t, touched.unitId, errors.unitId)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="unit-box-currency">
                                  <Input
                                    value={this.state.sizes}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="size"
                                    className="select-input input"
                                    placeholder={t("Size")}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={SizeIcon} alt="" />
                                      </InputAdornment>
                                    }
                                  />
                                  <Box className="unit-box-value">{t(this.state.measurement)}</Box>
                                </Box>
                                {ErrorHandler(t, touched.size, errors.size)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    value={this.state.configuration ? this.state.configuration.title : ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="config"
                                    variant="filled"
                                    className="select-input"
                                    input={<OutlinedInput />}
                                    disabled={true} 
                                  >
                                    <MenuItem
                                      value=""
                                      disabled
                                      className={languageCondition(language, "select-arabic-menu", "")}
                                    >
                                      {t("Configuration")}
                                    </MenuItem>
                                    {this.state.configList.map((config: any) => (
                                      <MenuItem
                                        value={config.title}
                                        key={config.title}
                                        className={languageCondition(language, "select-arabic-menu", "")}
                                      >
                                        {LanguageAttributeHandler(language, config.title, config.title_ar)}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  <img src={ConfigIcon} alt="" />
                                </Box>
                              </FormControl>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={7}>
                                  <FormControl fullWidth>
                                    <Box className="unit-box-currency">
                                      <Input
                                        value={this.state.purchasePrice}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="price"
                                        className="select-input input"
                                        placeholder={t("Purchase Price")}
                                        startAdornment={
                                          <InputAdornment position="start">
                                            <img src={PriceIcon} alt="" />
                                          </InputAdornment>
                                        }
                                      />
                                      <Box className="unit-box-value">{t(this.state.currency)}</Box>
                                    </Box>
                                    {ErrorHandler(t, touched.price, errors.price)}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                  <FormControl fullWidth>
                                    <Input
                                      value={this.state.date}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name="date"
                                      className={
                                        !values.date && is_mobile
                                          ? "select-input input placeHolderForDate"
                                          : "select-input input"
                                      }
                                      placeholder={t("Purchase Date")}
                                      type="date"
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <img src={CalenderIcon} alt="" />
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <h4 style={{ marginTop: "18px" }} className="bold-text">
                                {t("Unit Status")}
                              </h4>
                              <FormControl fullWidth>
                                <RadioGroup
                                  name="type"
                                  value={values.type}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="type-radio-select"
                                >
                                  <FormControlLabel
                                    value="Rented"
                                    control={<Radio />}
                                    label={t("Rented")}
                                    className="rented"
                                  />
                                  <FormControlLabel
                                    value="Non-Rented"
                                    control={<Radio />}
                                    label={t("Vacant")}
                                    className="non-rented"
                                  />
                                </RadioGroup>
                              </FormControl>
                              {values.type === "Rented" && (
                                <>
                                  <h4 style={{ marginTop: "18px", fontSize: "18px" }} className="bold-text">
                                    {t("Rent History")}
                                  </h4>
                                  {this.state.rentHistoryList.map((rentHistory: any) => {
                                    return (
                                      <Box className="rent-history-box" key={rentHistory.id}>
                                        <Box className="heading">
                                          <h4 className="bold-text">
                                            {moment(rentHistory.attributes.start_date, "YYYY-MM-DD").format("MMM YYYY")}{" "}
                                            to{" "}
                                            {moment(rentHistory.attributes.end_date, "YYYY-MM-DD").format("MMM YYYY")}
                                          </h4>
                                          <img
                                            onClick={() => {
                                              this.setState({ loading: true, unitId: values.unitId }, () => {
                                                this.deleteRentHistories(rentHistory.id);
                                              });
                                            }}
                                            src={DeleteRentIcon}
                                            alt="delete-rent"
                                          />
                                        </Box>
                                        <p className="tenant-name register-tenant-name">{rentHistory.attributes.tenant_name}</p>
                                        <Divider />
                                        <Box className="info-box">
                                          <Box className="info">
                                            <p className="unit-rent-amount">{t("Rent Amount (Monthly)")}</p>
                                            <span>
                                              {this.state.currency +
                                                " " +
                                                Number(rentHistory.attributes.rent_amount).toLocaleString()}
                                            </span>
                                          </Box>
                                          <Box className="info rent-receive-amount-box">
                                            <p className="unit-receive-amount">{t("Received Amount")}</p>
                                            <span>
                                              {this.state.currency +
                                                " " +
                                                Number(rentHistory.attributes.received_amount).toLocaleString()}
                                            </span>
                                          </Box>
                                        </Box>
                                      </Box>
                                    );
                                  })}
                                  <Button
                                    className="add-rent-history-btn"
                                    onClick={() => this.handleOpenRentHistoryModal(values.unitId)}
                                    disabled={!values.unitId}
                                  >
                                    {this.state.rentHistoryList.length == 0
                                      ? `${t("+ Add Rent History")}`
                                      : `${t("+ Add Another Rent History")}`}
                                  </Button>
                                  <FormControl fullWidth>
                                    <Box className="unit-box-currency">
                                      <Input
                                        value={values.income}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="income"
                                        className="select-input input"
                                        placeholder={t("Monthly Renting Income")}
                                        startAdornment={
                                          <InputAdornment position="start">
                                            <img src={RentAmountIcon} alt="" />
                                          </InputAdornment>
                                        }
                                      />
                                      <Box className="unit-box-value">{t(this.state.currency)}</Box>
                                    </Box>
                                    {ErrorHandler(t, touched.income, errors.income)}
                                  </FormControl>
                                </>
                              )}
                              <FormControl fullWidth>
                                <Box className="unit-box-currency">
                                  <Input
                                    value={values.valuation}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="valuation"
                                    className="select-input input"
                                    placeholder={t("Current Valuation")}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={ValuationIcon} alt="" />
                                      </InputAdornment>
                                    }
                                  />
                                  <Box className="unit-box-value">{t(this.state.currency)}</Box>
                                </Box>
                                {ErrorHandler(t, touched.valuation, errors.valuation)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="upload-box" onClick={() => this.uploadUnitImages.click()}>
                                  <img src={UploadIcon} alt="" />
                                  <p>{t("Upload Images")}</p>
                                </Box>
                                <input
                                  accept="image/png, image/gif, image/jpeg"
                                  type="file"
                                  onChange={(e: any) => {
                                    for (let i = 0; i < e.target.files.length; i++) {
                                      const file = e.target.files[i];
                                      let reader = new FileReader();
                                      reader.onloadend = () => {
                                        values.images = [...values.images, reader.result];
                                        setFieldValue("images", values.images);
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }}
                                  multiple
                                  onBlur={handleBlur}
                                  style={{ display: "none" }}
                                  name="images"
                                  ref={(ref: any) => (this.uploadUnitImages = ref)}
                                  className="unit-upload-image-input"
                                />
                                <Grid container spacing={4} style={{ marginTop: "0" }}>
                                  {values.images.map((image: any, index: number) => {
                                    return (
                                      <Grid item md={3} sm={4} xs={6} key={index}>
                                        <Box className="building-image register-unit-images">
                                          <img
                                            src={del_image}
                                            className="delete-image"
                                            onClick={() => {
                                              const remainImage = values.images.filter(
                                                (img: any, idx: number) => idx !== index
                                              );
                                              setFieldValue("images", remainImage);
                                            }}
                                          />
                                          <img src={image} alt="unit-image" />
                                        </Box>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                                {ErrorHandler(t, touched.images, errors.images)}
                              </FormControl>
                            </Box>
                            <div className="next-button register-unit-btn">
                              <Button type="submit">{t("Submit")}</Button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        <Drawer
          className="condition-modal penalty-dialog rent-history-dialog"
          anchor="bottom"
          dir={languageCondition(language, "rtl", "ltr")}
          onClose={() => this.handleCloseRentHistoryModal()}
          open={this.state.isRentHistoryModalOpen}
        >
          <Formik
            validationSchema={this.validationRentHistoryFormSchema}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true }, () => {
                this.handleCloseRentHistoryModal();
                this.addRentHistory(values);
                resetForm();
              });
            }}
            initialValues={this.state.rentHistoryForm}
            enableReinitialize
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes">
                  <Box className={languageCondition(language, "arabic-grid", "")}>
                    <h4 className="bold-text">{t("Rent History")}</h4>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Box className="custom-input-box register-rent-start-box">
                            <Input
                              onChange={handleChange}
                              value={values.startDate}
                              name="startDate"
                              onBlur={handleBlur}
                              className={
                                !values.startDate && is_mobile
                                  ? "select-input input placeHolderForDate"
                                  : "select-input input"
                              }
                              type="date"
                              placeholder={t("Start Date")}
                              inputProps={{
                                max: moment().format("YYYY-MM-DD"),
                              }}
                            />
                            <img src={CalenderIcon} alt="" />
                          </Box>
                          {ErrorHandler(t, touched.startDate, errors.startDate)}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Box className="custom-input-box register-rent-end-box">
                            <Input
                              inputProps={{
                                min: values.startDate,
                                max: moment().format("YYYY-MM-DD"),
                              }}
                              name="endDate"
                              onChange={handleChange}
                              value={values.endDate}
                              onBlur={handleBlur}
                              className={
                                !values.endDate && is_mobile
                                  ? "select-input input placeHolderForDate"
                                  : "select-input input"
                              }
                              type="date"
                              placeholder={t("End Date")}
                            />
                            <img src={CalenderIcon} alt="calender" />
                          </Box>
                          {ErrorHandler(t, touched.endDate, errors.endDate)}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <FormControl fullWidth>
                      <Box className="unit-box-currency">
                        <Input
                          placeholder={t("Rent Amount (Monthly)")}
                          type="text"
                          name="rentAmount"
                          value={values.rentAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="select-input input"
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={RentAmountHistoryIcon} alt="amount-icon" />
                            </InputAdornment>
                          }
                        />
                        <Box className="unit-box-value">{this.state.currency}</Box>
                      </Box>
                      {ErrorHandler(t, touched.rentAmount, errors.rentAmount)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Box className="unit-box-currency register-rent-amount-box">
                        <Input
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={ReceivedIcon} alt="" />
                            </InputAdornment>
                          }
                          className="select-input input"
                          onChange={handleChange}
                          type="text"
                          onBlur={handleBlur}
                          name="receivedAmount"
                          value={values.receivedAmount}
                          placeholder={t("Received Amount")}
                        />
                        <Box className="unit-box-value">{this.state.currency}</Box>
                      </Box>
                      {ErrorHandler(t, touched.receivedAmount, errors.receivedAmount)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Input
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={TenantIcon} alt="" />
                          </InputAdornment>
                        }
                        value={values.tenantName}
                        className="select-input input"
                        onBlur={handleBlur}
                        name="tenantName"
                        placeholder={t("Tenant Name")}
                        onChange={handleChange}
                        type="text"
                      />
                      {ErrorHandler(t, touched.tenantName, errors.tenantName)}
                    </FormControl>
                  </Box>
                  <Box className="button-group register-rent-add-btn">
                    <Button className="add-button" type="submit">
                      {t("Add")}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Drawer>
      </>
    );
  }
}

export default withTranslation()(withStyles(MyUnitStyle)(RegisterMyUnit));
// Customizable Area End
