// Customizable Area Start
import React from "react";
import { Avatar, Box, Button, Card, Dialog, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";
import CompEmpComplexBlockStep7Controller, { Props } from "./CompEmpComplexBlockStep7Controller.web";
import { Formik, Form } from "formik";
import "../../assets/commonForm.css";
import Loader from "../../../../components/src/Loader.web";
import { CheckIcon, profile } from "../assets";
import CloseIcon from "@material-ui/icons/Close";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import AlertError from "../../../../components/src/AlertError.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";

export default class CompEmpComplexBlockStep7 extends CompEmpComplexBlockStep7Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;
    return (
      <>
        <Loader loading={this.state.loading} />

        <Box className="topBarFormik">
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="Core bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
              {t("Core Members")}
            </Typography>
            <Typography className="Corestep" style={{ fontSize: "16px" }}>
              {t("Step")} {this.props.step + 1} {t("of")} 7
            </Typography>
          </Box>

          <Box style={{ marginTop: "30px" }}>
            <Grid container spacing={2} alignItems="stretch">
              {this.state.coreMembersList.map((value: any, index: number) => (
                <Grid item sm={6} md={4} lg={3} key={index} style={{ display: "flex" }}>
                  <Box style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <Card
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flexGrow: 1,
                        ...webStyle.coreMemberCard,
                      }}
                    >
                      <Box style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
                        <CloseIcon
                          data-test-id="core-member-delete"
                          onClick={() => this.handleDeleteCoreMember(value.id)}
                        />
                      </Box>
                      <Box
                        className="complexBuildingBlock"
                        sx={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          flexGrow: 1,
                        }}
                      >
                        <Avatar src={value.attributes.user_profile.data.attributes.profile_pic || profile} />
                        <Box className="bold-text" style={webStyle.coreMemberP}>
                          {value.attributes.role}
                        </Box>
                        <Box style={webStyle.coreMemberP}>{value.attributes.name}</Box>
                      </Box>
                      <Box style={webStyle.coreMemberTag}>
                        {value.attributes.role_list.map((role: string, index: number) => {
                          return (
                            <span key={index} className="bold-text" style={webStyle.roleTag}>
                              {role}
                            </span>
                          );
                        })}
                      </Box>
                    </Card>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box style={{ justifyContent: "space-between", flexWrap: "wrap", display: "flex", width: "100%" }}>
            <Box style={{ marginTop: "20px", borderRadius: "8px", border: "1px solid #2B6FED" }}>
              <Box style={webStyle.addMemberBtn} className="bold-text" onClick={this.handleAddMemberDialog}>
                {t("+ ADD NEW MEMBER")}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="step7BtnBox" style={{ display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "20px" }}>
          <Grid className="step7Grid" style={{ marginTop: "30px" }}>
            <Button className="step7Prev" style={webStyle.prevButton} onClick={this.props.handleBack}>
              <span className="bold-text">{t("PREV")}</span>
            </Button>
          </Grid>
          <Grid className="step7Grid" style={{ marginTop: "30px" }}>
            <Button onClick={this.handleComplexComplexDialog} data-test-id="saveData" style={webStyle.saveButton}>
              <span className="bold-text">{t("Save")}</span>
            </Button>
          </Grid>
        </Box>

        <AlertError
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
          data-test-id="core-alert"
        />

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          scroll="paper"
          open={this.state.isAddCoreMembersOpen}
          fullWidth
          maxWidth="md"
        >
          <DialogHeader t={t} title={t("Add New Member")} onClose={this.handleAddCoreMemberDialog} />
          <Formik
            initialValues={this.state.coreFormValues}
            validationSchema={this.validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, isAddCoreMembersOpen: false }, () => {
                this.submitNewCoreMember(values);
              });
            }}
            data-test-id="add-member-form"
          >
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
              <Form translate="yes" onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <NormalSelect
                        value={values.account}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="account"
                        t={t}
                        language={language}
                        label={t("User")}
                        option={this.state.coreUserList}
                        disabled={this.state.coreUserList.length === 0}
                        required
                      />
                      {ErrorHandler(t, touched.account, errors.account)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        name="role"
                        t={t}
                        onChange={handleChange}
                        value={values.role}
                        onBlur={handleBlur}
                        language={language}
                        label={t("Role")}
                        option={this.state.coreRoleList}
                        required
                      />
                      {ErrorHandler(t, touched.role, errors.role)}
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        className="new-member-dialog"
                        style={webStyle.createNewMember}
                        onClick={this.handleCreateNewCoreMemberDialog}
                      >
                        {t("+ CREATE NEW MEMBER")}
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog__button_group">
                  <Button className="cancel-button" onClick={this.handleAddCoreMemberDialog}>
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button">
                    {t("SAVE")}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          scroll="paper"
          open={this.state.isCreateNewCoreMembersOpen}
          fullWidth
          maxWidth="md"
        >
          <DialogHeader t={t} title={t("Create New Member")} onClose={this.handleCreateNewCoreMemberDialog} />
          <Formik
            initialValues={this.state.memberFormValues}
            validationSchema={this.validationCreateSchema}
            enableReinitialize={true}
            data-testid="memberForm"
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, isCreateNewCoreMembersOpen: false }, () => {
                this.inviteCoreMemberForm(values);
                resetForm();
              });
            }}
            data-test-id="invite-member-form"
          >
            {({ values, touched, errors, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <NormalSelect
                          onBlur={handleBlur}
                          label={t("User Type")}
                          t={t}
                          onChange={handleChange}
                          value={values.type}
                          name="type"
                          language={language}
                          option={this.state.userTypeList}
                          required
                        />
                        {ErrorHandler(t, touched.type, errors.type)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.name}
                          label={t("Full Name")}
                          onChange={handleChange}
                          name="name"
                          t={t}
                          language={language}
                          onBlur={handleBlur}
                          isLabel
                          required
                        />
                        {ErrorHandler(t, touched.name, errors.name)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          language={language}
                          t={t}
                          label={t("Email Address")}
                          isLabel
                          required
                        />
                        {ErrorHandler(t, touched.email, errors.email)}
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="form__phone_number">
                          <PhoneInput
                            value={values.countryCode}
                            onChange={(value: any) => {
                              setFieldValue("countryCode", `+${value}`);
                            }}
                            country="us"
                            enableSearch={true}
                            data-test-id="complex-core-phone"
                          />
                          <NormalTextField
                            type="number"
                            required
                            t={t}
                            onBlur={handleBlur}
                            language={language}
                            name="phone"
                            value={values.phone}
                            isLabel
                            label="Phone Number"
                            onChange={handleChange}
                          />
                        </Box>
                        {ErrorHandler(t, touched.phone, errors.phone)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          onBlur={handleBlur}
                          language={language}
                          onChange={handleChange}
                          isLabel
                          required
                          value={values.country}
                          label="Country"
                          name="country"
                          disabled
                        />
                        {ErrorHandler(t, touched.country, errors.country)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          onBlur={handleBlur}
                          required
                          language={language}
                          onChange={handleChange}
                          isLabel
                          value={values.city}
                          label="City"
                          name="city"
                          disabled
                        />
                        {ErrorHandler(t, touched.city, errors.city)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          onBlur={handleBlur}
                          t={t}
                          required
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("isComplex", Number(event.target.value));
                            setFieldValue("complex", "");
                            this.handleIsCoreComplex(event.target.value);
                          }}
                          option={[
                            { label: "Complex", value: 1 },
                            { label: "Individual Building", value: 2 },
                          ]}
                          value={values.isComplex}
                          name="isComplex"
                          language={language}
                          label={t("Complex/Individual Building")}
                          data-test-id="complex-core-isComplex"
                        />
                        {ErrorHandler(t, touched.isComplex, errors.isComplex)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalSelect
                          label={values.isComplex === 1 ? t("Complex") : t("Individual Building")}
                          value={values.complex}
                          name="complex"
                          onBlur={handleBlur}
                          data-test-id="complex-core-complex"
                          required
                          t={t}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("building", "");
                            setFieldValue("complex", event.target.value);
                            if (values.isComplex === 1) {
                              this.getCoreBuildingList(event.target.value);
                            } else {
                              this.getCoreUnitList(event.target.value, "");
                            }
                          }}
                          language={language}
                          option={this.state.filterComplexList}
                        />
                        {ErrorHandler(t, touched.complex, errors.complex)}
                      </Grid>
                      {values.isComplex !== 2 && (
                        <Grid item xs={6}>
                          <NormalSelect
                            name="building"
                            required
                            label={t("Building")}
                            onBlur={handleBlur}
                            value={values.building}
                            t={t}
                            data-test-id="complex-core-building"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue("building", event.target.value);
                              setFieldValue("unit", "");
                              this.getCoreUnitList(values.complex, event.target.value);
                            }}
                            option={this.state.buildingList}
                            language={language}
                          />
                          {ErrorHandler(t, touched.building, errors.building)}
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <NormalSelect
                          onChange={handleChange}
                          label={t("Unit")}
                          name="unit"
                          required
                          onBlur={handleBlur}
                          value={values.unit}
                          t={t}
                          language={language}
                          option={this.state.unitList}
                        />
                        {ErrorHandler(t, touched.unit, errors.unit)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleCreateNewCoreMemberDialog}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("Send Invitation")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>

        <AlertDialog
          IsOpen={this.state.isComplexComplexOpen}
          Image={CheckIcon}
          CloseDialog={this.handleComplexComplexDialog}
          Header={this.props.isEditComplex ? t("Complex Edited") : t("New Complex Created")}
          Content={`<p>${t(this.handleMessage())}</p>`}
          DeclineText={t("Okay")}
          DeclineFun={this.handleSubmitComplexComplexDialog}
          language={language}
        />
      </>
    );
  }
}

const webStyle: any = {
  prevButton: {
    color: "#2B6FED",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "capitalize",
    width: "177px",
    height: "56px",
    border: "1px solid #2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  saveButton: {
    width: "177px",
    height: "56px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "capitalize",
  },
  coreMemberCard: {
    padding: "20px 10px",
    border: "1px solid #e9e9e9",
    boxShadow: "none",
    borderRadius: "8px",
    // height: "100%",
  },
  coreMemberP: { margin: "11px 0px", color: "black", fontSize: "16px" },
  coreMemberTag: { justifyContent: "center", display: "flex", gap: "10px", flexFlow: "wrap" },
  roleTag: {
    color: "rgb(43, 111, 237)",
    fontSize: "14px",
    width: "max-content",
    background: "rgba(43, 111, 237, 0.08)",
    padding: "6px 22px",
    display: "flex",
    lineHeight: "14px",
    alignItems: "center",
    borderRadius: "14px",
    justifyContent: "center",
  },
  addMemberBtn: {
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: 400,
    color: "#2B6FED",
    padding: "10px 25px",
  },
  createNewMember: { color: "#FC8434", padding: "15px 0", fontWeight: "400", fontSize: "15px", cursor: "pointer" },
};

// Customizable Area End
