//@ts-ignore
//@ts-nocheck
import React from "react";
// Customizable Area Start

import { Box, Button, Dialog, Grid, TextareaAutosize, Typography, IconButton, DialogContent, Paper, MenuItem, FormControl, Select, CardActions } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Fullscreen, Close } from '@material-ui/icons';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import IncidentController, { IApartmentDetails, CommonArea, IncidentDetails, IncidentRelated, Media, MediaFile, MyApartment, Props } from "./IncidentController.web";
import { Upload_Icon, Clipboard_Icon, Warning_Icon, House_Icon, Box_Icon, Building1, VideoIcon, } from "../src/assets";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { del_image } from "../../LeadManagement/src/assets";
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withTranslation } from "react-i18next";
import { ArrowBackIcon } from "../../../components/src/ConditionManager.web";
import CreateIncidentContainer from "./components/CreateIncidentContainer.web";

class CreateIncident extends IncidentController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    this.getMyApartmentList();
    this.getIncidentRelated();
  }

  incidentUploadFileValid = (e: React.ChangeEvent<HTMLInputElement>, existFile: Media[]): boolean => {
    const existFiles = existFile.map((media) => media.file);
    const totalFile = [...existFiles, ...(e.target.files ? Array.from(e.target.files) : [])];
    if (totalFile.length > 6) {
      this.setState({ showError: true, error: "Only six files supported!" });
      if (e.target) e.target.value = '';
      return false;
    }

    let size = 0;
    totalFile.forEach((file) => {
      size += file.size;
    });
    size = size / (1024 * 1024);

    if (size > 30) {
      this.setState({ showError: true, error: "More than 30MB of files size not allowed!" });
      if (e.target) e.target.value = '';
      return false;
    }

    return true;
  };

  handleImageVideo = (file: File) => {
    if (file.type === "video/mp4" || file.type === "video/x-m4v" || file.type === "video/mkv" || file.type === "video/quicktime") {
      return true;
    } else {
      return false;
    }
  };

  renderAdormentIcon(icon: string, alt: string) {
    return (<img src={icon} alt={alt} className="input-icon" />)
  }

  renderPreview() {
    const { t, i18n } = this.props;
    const language = i18n.language;
    let incidentDetails: IncidentDetails = {
      commonArea: {
        id: "",
        name: ""
      },
      description: "",
      incidentRelated: {
        id: "",
        name: ""
      },
      incidentTitle: "",
      media: this.state.incidentDetails.media || [],
      myApartment: {
        id: "",
        attributes: {
          apartment_name: "",
          building_management: {
            name: ""
          },
          society_management: {
            id: 0
          }
        }
      }
    };
    const incidentData = localStorage.getItem("incidentPreview");
    if (incidentData) {
      incidentDetails = JSON.parse(incidentData);
    } else {

      window.history.back();
      localStorage.removeItem("incidentPreview");
    }

    return (
      <Grid item xs={12} md={7} className="incident-preview-content">
        <Box className="content-header">
          <Typography variant="h5" className="content-title">{t("Incident Details")}</Typography>
        </Box>
        <Box className="incident-preview-form__wrapper">
          <Paper className="form-content">
            <Box className="details-group">
              <Typography className="detail-title">{t("Affected Area")}:</Typography>
              <Typography className="detail-value">{incidentDetails.commonArea.name}</Typography>
            </Box>
            <Box className="details-group">
              <Typography className="detail-title">{t("Incident is related to")}:</Typography>
              <Typography className="detail-value">{incidentDetails.incidentRelated.name}</Typography>
            </Box>
            <Box className="details-group">
              <Typography className="detail-title">{t("Incident Title")}:</Typography>
              <Typography className="detail-value">{incidentDetails.incidentTitle}</Typography>
            </Box>
            <Box className="details-group">
              <Typography className="detail-title">{t("Building")}:</Typography>
              <Typography className="detail-value">{incidentDetails.myApartment.attributes.building_management.name}</Typography>
            </Box>
            <Box className="details-group">
              <Typography className="detail-title">{t("Unit")}:</Typography>
              <Typography className="detail-value">{incidentDetails.myApartment?.attributes?.apartment_name}</Typography>
            </Box>
            <Box className="details-group">
              <Typography className="detail-title">{t("Description")}:</Typography>
              <Typography className="detail-value">{incidentDetails.description}</Typography>
            </Box>
            {(incidentDetails.media.length > 0) && (
              <Box className="details-group">
                <Typography className="detail-title">{t("Photos")}:</Typography>
                <CardActions className="incident-attachements">
                  {incidentDetails.media.map((media: MediaFile, index: number) => (
                    (media.file.type === "video/mp4" || media.file.type === "video/x-m4v")
                      ? (
                        <Box className="attachment-wrapper" key={index} onClick={() => { this.setState({ showDialog: true, file: { url: media.url, type: media.file.type, name: media.file.name } }); }}>
                          <Box className="attachement-overlay"></Box>
                          <video className="incident-video" poster="">
                            <source src={media.url} type={media.file.type} />
                          </video>
                          <Fullscreen className="incident-icon" />
                        </Box>
                      )
                      : (
                        <Box className="attachment-wrapper" key={index} data-test-id="video" onClick={() => { this.setState({ showDialog: true, file: { url: media.url, type: media.file.type, name: media.file?.name } }); }}>
                          <Box className="attachement-overlay"></Box>
                          <img src={media.url} className="incident-image" alt="incident-attachment" />
                          <Fullscreen className="incident-icon" />
                        </Box>
                      )
                  ))}
                </CardActions>
                <Typography className="detail-value"></Typography>
              </Box>
            )}

          </Paper>
          <Box className="form-footer">
            <Button data-test-id="create-incident" className="form-submit" variant="contained" type="button" onClick={this.handleIncidentCreation}>{t("Submit")}</Button>
          </Box>
        </Box>
      </Grid>
    )
  }

  renderCreateView() {
    const { t, i18n } = this.props;
    const language = i18n.language;
    let initialValues = {commonArea: '', incidentRelated: "", incidentTitle: "", description: "", media: this.state.incidentDetails.media || [], myApartment: "" };
    const incidentData = localStorage.getItem("incidentPreview");
    if(incidentData) {
      const incidentDetails = JSON.parse(incidentData);
      initialValues = {
        commonArea: incidentDetails.commonArea.id,
        incidentRelated: incidentDetails.incidentRelated.id,
        incidentTitle: incidentDetails.incidentTitle,
        description: incidentDetails.description,
        media: this.state.incidentDetails.media || [],
        myApartment: incidentDetails.myApartment.id,
      }
      console.log(initialValues);
      localStorage.removeItem("incidentPreview");
    }
    return (
      <Grid item xs={12} md={7} className="create-incident-content">
        <Box className="content-header">
          <Typography variant="h5" className="content-title">{t("Incident Details")}</Typography>
        </Box>
        <Box className="incident-creation-form__wrapper">
          <Formik data-test-id="formik-component" initialValues={initialValues} validationSchema={this.createIncidentSchema()} validateOnMount={true} onSubmit={this.handleFormikSubmit} >
            {({ values, setFieldValue }) => {
              return (
                <Form translate="yes" className="incident-creation-form">
                  <Box className="form-content">
                    <Box className="form-input-group">
                      <FormControl className="form-control" variant="outlined" fullWidth>
                        <Select data-test-id="select-unit" className="form-select" fullWidth margin="dense" variant="outlined" startAdornment={this.renderAdormentIcon(Box_Icon, 'Unit Box Icon')} displayEmpty
                          renderValue={(selected) => {
                            if (!selected) {
                              return <Typography>{t("Select unit")}</Typography>;
                            }
                            const apartment = this.state.myApartmentList.find((apt: MyApartment) => apt.id == selected);
                            return apartment
                              ? <Typography>{`${apartment.attributes.building_management.name} ${apartment.attributes.apartment_name}`}</Typography>
                              : <Typography>{t("Select unit")}</Typography>;
                          }}
                          onChange={(e) => {
                            setFieldValue("myApartment", e.target.value);
                            this.handleUnitChange(e.target.value as string);
                          }}
                          value={values.myApartment || ''}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            PaperProps: {
                              style: {
                                maxHeight: 200, 
                                marginTop: 8,
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            <Typography>{t("Select unit")}</Typography>
                          </MenuItem>
                          {this.state.myApartmentList.length > 0 && this.state.myApartmentList.map((apartment: MyApartment) => (
                            <MenuItem key={apartment.id} value={apartment.id}>
                              <Typography>{`${apartment.attributes.building_management.name} ${apartment.attributes.apartment_name}`}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <ErrorMessage className="text-error" component={Typography} name="myApartment" />
                    </Box>
                    <Box className="form-input-group">
                      <FormControl className="form-control square" variant="outlined" fullWidth>
                        <Select data-test-id="select-commonarea" className="form-select" fullWidth margin="dense" variant="outlined" startAdornment={this.renderAdormentIcon(House_Icon, 'House Icon')} displayEmpty
                          renderValue={(selected) => {
                            if (!selected) {
                              return <Typography>{t("Location of the Incident")}</Typography>;
                            }
  
                            const commonArea = this.state.commonAreaData.find((commonArea: CommonArea) => commonArea.id == selected);
                            return commonArea
                              ? <Typography>{`${commonArea.name}`}</Typography>
                              : <Typography>{t("Location of the Incident")}</Typography>;
                          }}
                          onChange={(e) => {
                            setFieldValue("commonArea", e.target.value);
                          }}
                          value={values.commonArea || ''}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            PaperProps: {
                              style: {
                                maxHeight: 200, 
                                marginTop: 8,
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            <Typography>{t("Location of the Incident")}</Typography>
                          </MenuItem>
                          {this.state.commonAreaData.length > 0 && this.state.commonAreaData.map((commonArea: CommonArea) => (
                            <MenuItem key={commonArea.id} value={commonArea.id}>
                              <Typography>{`${commonArea.name}`}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <ErrorMessage className="text-error" component={Typography} name="commonArea" />
                    </Box>
                    <Box className="form-input-group">
                      <FormControl className="form-control" variant="outlined" fullWidth>
                        <Select data-test-id="select-incident-releated" className="form-select" fullWidth margin="dense" variant="outlined" startAdornment={this.renderAdormentIcon(Warning_Icon, 'Warning Icon')} displayEmpty
                          renderValue={(selected) => {
                            if (!selected) {
                              return <Typography>{t("Incident is related to")}</Typography>;
                            }
  
                            const incidentRelated = this.state.incidentRelatedData.find((incidentRelated: IncidentRelated) => incidentRelated.id == selected);
                            return incidentRelated
                              ? <Typography>{`${incidentRelated.name}`}</Typography>
                              : <Typography>{t("Incident is related to")}</Typography>;
                          }}
                          onChange={(e) => {
                            setFieldValue("incidentRelated", e.target.value);
                          }}
                          value={values.incidentRelated || ''}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            PaperProps: {
                              style: {
                                maxHeight: 200, 
                                marginTop: 8,
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            <Typography>{t("Incident is related to")}</Typography>
                          </MenuItem>
                          {this.state.incidentRelatedData.length > 0 && this.state.incidentRelatedData.map((incidentRelated: IncidentRelated) => (
                            <MenuItem key={incidentRelated.id} value={`${incidentRelated.id}`}>
                              <Typography>{`${incidentRelated.name}`}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <ErrorMessage className="text-error" component={Typography} name="incidentRelated" />
                    </Box>
                    <Box className="form-input-group">
                      <Box className="form-input">
                        {this.renderAdormentIcon(Warning_Icon, 'Warning icon')}
                        <Field data-test-id="input-incident-title" name="incidentTitle" type="text" placeholder={t("Incident Title")} className="input-field" value={values.incidentTitle || ''}/>
                      </Box>
                      <ErrorMessage className="text-error" component={Typography} name="incidentTitle"/>
                    </Box>
                    <Box className="form-input-group">
                      <Box className="form-textarea-wrapper">
                        {this.renderAdormentIcon(Clipboard_Icon, 'Clipboard icon')}
                        <TextareaAutosize data-test-id="textarea-incident-description" minRows={5} className="form-textarea" value={values.description} placeholder={t("Add Description")} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => { setFieldValue("description", e.target.value); }} />
                      </Box>
                      <ErrorMessage className="text-error" component={Typography} name="description" />
                    </Box>
                    <Box className="form-input-group">
                      <Button className="form-input file" variant="contained" component="label">
                        <img src={Upload_Icon} alt='Upload icon' className="input-file-icon" />
                        <Typography className="form-label">{t("Add Image / Video")}</Typography>
                        <input data-test-id="input-file" name='media' type="file" hidden multiple accept="image/jpg ,image/jpeg,image/gif,image/png,video/mp4,video/x-m4v,.HEVC,.mov" className="form-input-file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.incidentUploadFileValid(e, values.media)) { this.handleSelectMedia(e, values.media, setFieldValue); } }} />
                      </Button>
                      {this.state.upload &&
                        <>
                          <Box style={{ marginTop: "20px", overflow: "hidden" }}>
                            <Grid spacing={2} container>
                              {(values.media.length > 0) && values.media.map((val: Media, index: number) => (
                                <Grid data-test-id="uploaded-media" key={index} xs={4} sm={4} item onClick={() => { this.setState({ showDialog: true, file: { url: val.url, type: val?.file.type, name: val?.file?.name } }); }}>
                                  <Box className="incident-preview-document">
                                    {this.handleImageVideo(val?.file) || val?.file?.name.includes(".hevc") ?
                                      <video poster={VideoIcon.default}>
                                        <source src={val.url} type={val.file.type} />
                                      </video> :
                                      <img src={val.url} alt="image" />
                                    }
                                    <Fullscreen className="play-icon" />
                                    <img data-test-id="uploaded-image" src={del_image} className="delete-image" onClick={(e: React.MouseEvent<HTMLImageElement>) => {
                                      e.stopPropagation();
                                      const remainMedia = values.media.filter((img: File, idx: number) => idx !== index);
                                      setFieldValue("media", remainMedia);
                                    }}
                                    />
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </>
                      }
                    </Box>
                  </Box>
                  <Box className="form-footer">
                    <Button data-test-id="preview-button" className="form-submit" variant="contained" type="submit">{t("preview")}</Button>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </Box>
      </Grid>
    )
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;
    const { createMode } = this.state;

    return (
      <CreateIncidentContainer container className="incident-container">
        <Grid item xs={12} className="header-wrapper">
          <Box className="header">
            <ArrowBackIcon data-test-id="header-back-btn" onClick={this.handleBack} />
            <Typography variant="h4" className="header-text">{createMode ? t("Add New Incident") : t("Incident Preview")}</Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} className="incident-content">
          {createMode ? (this.renderCreateView()) : (this.renderPreview())}
          <Grid item xs={12} md={5} className="image-container">
            <Box className="image-wrapper">
              <img src={Building1.default} className="right-image" alt="Tall building with black windows" />
            </Box>
          </Grid>
        </Grid>
        <AlertErrorWeb data-test-id="error-box" show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
        <Loader loading={this.state.loading} />
        <Dialog data-test-id="media-modal" className="delete-document personal" fullWidth onClose={() => this.setState({ showDialog: false })} open={this.state.showDialog}>
          <MuiDialogTitle disableTypography className="dialog-heading">
            <Typography variant="h6" className="bold-text">
              Image / Video
            </Typography>
            <IconButton data-test-id="dialog-close-btn" onClick={() => this.setState({ showDialog: false })}>
              <Close />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent>
            {(this.handleImageVideo(this.state.file) || this.state.file?.name?.includes(".hevc"))
              ? (
                <Player playsInline poster={this.state.file.url} src={this.state.file?.url}>
                  <BigPlayButton position="center" />
                </Player>
              ) : (
                <img src={this.state.file?.url} className="incident-dialog-photo" alt="card-img" />
              )
            }
          </DialogContent>
        </Dialog>
      </CreateIncidentContainer>
    )
  }
}

export default withTranslation()(withRouter(CreateIncident));
// Customizable Area End