//@ts-ignore
//@ts-nocheck
import React from "react";
// Customizable Area Start
//components
import {
    Box,
    Button,
    Link,
    Typography,
    IconButton,
    Grid, DialogContent, Dialog, Divider
} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {
    Tenant_Logo, Building_Logo, Email_Icon, User_Icon, Lock_User_Icon, Building1, globalIcon
} from "../src/assets";
import {withRouter} from 'react-router';
import {Formik, Form, Field} from "formik";
import {Close, Visibility, VisibilityOff} from "@material-ui/icons";
import ChairmanAccountLoginController, {
    Props
} from "./ChairmanAccountLoginController.web";
import Loader from "../../../components/src/Loader.web";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {withTranslation} from 'react-i18next';
import '../../../web/src/i18n.js';
import i18next from 'i18next';
import {Menu} from "@szhsin/react-menu";
import MenuItem from '@material-ui/core/MenuItem';
import "@szhsin/react-menu/dist/core.css";
import AlertErrorWeb from "../../../components/src/AlertError.web"
import {buildingImg} from "../../customform/src/assets";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class ChairmanLogin extends ChairmanAccountLoginController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.getUserType();
    }

    handleEngLngChange = () => {
        localStorage.setItem("language", "en");
        i18next.changeLanguage("en");
    };

    handleAreLngChange = () => {
        localStorage.setItem("language", "ar");
        i18next.changeLanguage("ar");
    };


    render() {
        const {t,i18n} = this.props;
        const language = i18n.language;
        return (
            <>
                <Box className="login-wrapper ManagementLogin" style={{backgroundColor: "white", height: "auto"}} dir={languageCondition(language,"rtl","ltr")}>
                    <Grid container spacing={2} className="auth-container ManagementLogin">
                        <Grid item xs={12} md={7} className="auth-cols ManagementLogin">
                            <Box className="content-block ManagementLogin">
                                <Box className="logo-block common-top-padding common-logo-block ManagementLogin"
                                     display={{xs: 'none', md: 'flex'}}>
                                    <Link className="ManagementLogin" href="/ManagementLogin">
                                        <img src={Building_Logo.default} className="head-logo ManagementLogin" alt=""/>
                                        <h4>{t("Building Name")}</h4>
                                    </Link>
                                    <Box>
                                    <Menu
                                        className="chairman-lang-menu chairman-menu menu-button ManagementLogin"
                                        arrow={true}
                                        align="center"
                                        menuButton={
                                            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                                <img src={globalIcon} alt="Global Icon" style={{ marginRight: "15px", marginLeft: "7px" }} />
                                                <span>
                                                    {localStorage.getItem("language") === "ar" ? "AR" : "ENG"}
                                                </span>
                                            </div>
                                        }
                                        >
                                        <MenuItem
                                            className={localStorage.getItem("language") === "en" ? "active ManagementLogin" : "ManagementLogin"}
                                            onClick={() => {
                                                localStorage.setItem("language", "en");
                                                window.location.reload(); // Reload to reflect changes, or use a state update
                                            }}
                                            id="item1"
                                        >
                                            English
                                        </MenuItem>
                                        <MenuItem
                                            className={localStorage.getItem("language") === "ar" ? "active ManagementLogin" : "ManagementLogin"}
                                            onClick={() => {
                                                localStorage.setItem("language", "ar");
                                                window.location.reload(); // Reload to reflect changes, or use a state update
                                            }}
                                            id="item2"
                                        >
                                            Arabic
                                        </MenuItem>
                                        </Menu>
                                  </Box>
                                </Box>
                                <Box className="main-content-block desktop-ui inputPlaceholderRegistration ManagementLogin">
                                    <Box className="header-block ManagementLogin">
                                        <Box display={{xs: 'flex', md: 'none'}} className="ManagementLogin">
                                            <img src={Tenant_Logo.default} className="tenant-logo ManagementLogin"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => window.open("https://www.TenantInt.com", '_blank',"noopener,noreferrer")}
                                                 alt=""/>
                                        </Box>
                                        <h1 className="login-h1 bold-text">{t("Welcome Back")}</h1>
                                        <p>{t("Login with your account credentials")}</p>
                                    </Box>
                                    <Formik
                                        initialValues={{
                                            email: "",
                                            password: "",
                                            showPassword: false,
                                            stayIn: false,
                                            userType: " "
                                        }}
                                        validationSchema={this.LoginSchema()}
                                        validateOnMount={true}
                                        onSubmit={(values) => {this.doLogIn(values);}}
                                    >
                                        {({values, touched, errors, setFieldValue, handleChange}) => (
                                            <Form translate="yes" className="commonForm ManagementLogin">
                                                <Box  className={languageCondition(language, "arabic-grid formGroup customSelect ManagementLogin", "formGroup customSelect ManagementLogin")}>
                                                    <FormControl variant="outlined" className="ManagementLogin">
                                                        <Select
                                                            name="userType"
                                                            labelId="demo-simple-select-outlined-label"
                                                            className="userTypeSelect userTypeManagementLogin"
                                                            id="demo-simple-select-outlined"
                                                            MenuProps={{
                                                                getContentAnchorEl: null,
                                                                anchorOrigin: {
                                                                  vertical: 'bottom',
                                                                  horizontal: 'left',
                                                                },
                                                                transformOrigin: {
                                                                  vertical: 'top',
                                                                  horizontal: 'left',
                                                                },
                                                                PaperProps: {
                                                                  style: {
                                                                    maxHeight: 200, 
                                                                    marginTop: 8,
                                                                  },
                                                                },
                                                              }}
                                                            startAdornment={
                                                                <img src={User_Icon} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"5px",marginRight:"20px"},{marginLeft:"5px",marginRight:"7px"})}/>
                                                            }
                                                            // label="Select User Type"
                                                            onChange={(e) => {
                                                                (e.target.value != " ") && setFieldValue("userType", e.target.value)
                                                            }}
                                                            value={values.userType}
                                                        >
                                                            <MenuItem disabled value=" " className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectComplex")}>
                                                              {t("Select User Type")}
                                                            </MenuItem>
                                                            {
                                                                this.state?.userTypeData?.map((val, index) => (
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={val?.attributes?.name}
                                                                        className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectComplex")}
                                                                    >
                                                                        {languageCondition(language,val?.attributes?.name_ar,val?.attributes?.name)}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    {
                                                        errors.userType && touched.userType ?
                                                            (
                                                                <Typography className="text-error ManagementLogin" style={{fontWeight: 600,marginLeft: "15px"}}>{t(errors.userType)} </Typography>
                                                            ) : null
                                                    }
                                                </Box>
                                                <Box style={{marginBottom: "25px"}}>
                                                    <Box className="formGroup">
                                                        <Field name="email" type="text" placeholder={t("Email ID")}
                                                            className={languageCondition(language, "formInput_ar", "formInput enterUserName")}
                                                            style={{
                                                            borderRadius: "10px",
                                                            border: "0.1px solid #d1d1d1"
                                                        }}/>
                                                        <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons enterUserName")}>
                                                          <img src={Email_Icon} className="frm-icons" alt="Email Icon" style={languageCondition(language,{marginTop: "6px"},{marginTop: "2px"})}/>
                                                        </span>
                                                    </Box>
                                                    <Box style={{marginTop: "-10px", marginBottom: "-10px"}}>
                                                        {
                                                            errors.email && touched.email ?
                                                                (
                                                                    <Typography className="text-error ManagementLogin" style={{
                                                                        fontWeight: 600,
                                                                        marginLeft: "15px"
                                                                    }}
                                                                    >{t(errors.email)} </Typography>

                                                                ) : null
                                                        }
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box className="formGroup ManagementLogin">
                                                        <Field name="password"
                                                               type={values.showPassword ? "text" : "password"}
                                                               placeholder={t("Password")} className={languageCondition(language, "formInput_ar", "formInput ManagementLogin")} style={{borderRadius: "10px",border: "0.1px solid #d1d1d1"}}/>
                                                        <span  className={languageCondition(language, "frmRightIcons", "frmLeftIcons ManagementLogin")}>
                                                            <img src={Lock_User_Icon} className="frm-icons ManagementLogin" alt="Email Icon"
                                                                 style={languageCondition(language,{marginTop: "4px"},{marginTop: "-2px"})}/>
                                                        </span>
                                                        <span className={languageCondition(language, "frmLeftIcons", "frmRightIcons ManagementLogin")}>
                                                          {values.showPassword ? (
                                                              <IconButton
                                                                  className="ManagementLogin"
                                                                  onClick={() => setFieldValue("showPassword", false)}
                                                                  style={{padding: 0, backgroundColor: "transparent"}}
                                                                  disableRipple={true}
                                                              >
                                                                  <Visibility style={languageCondition(language,{width: 24, height: 24,marginRight: 0,marginTop: -4,color: "#000000",opacity: 0.54},{width: 24, height: 24,marginRight: 0,marginTop: 4,color: "#000000",opacity: 0.54})}/>
                                                              </IconButton>
                                                          ) : (
                                                              <IconButton
                                                                  className="ManagementLoginButton2"
                                                                  onClick={() => setFieldValue("showPassword", true)}
                                                                  style={{padding: 0, backgroundColor: "transparent"}}
                                                                  disableRipple={true}
                                                              >
                                                                  <VisibilityOff className="ManagementLogin" style={languageCondition(language,{width: 24, height: 24,marginRight: 0,marginTop: -4,color: "#000000",opacity: 0.54},{width: 24, height: 24,marginRight: 0,marginTop: 4,color: "#000000",opacity: 0.54})}/>
                                                              </IconButton>
                                                          )}
                                                        </span>
                                                    </Box>
                                                    <Box style={{marginTop: "-10px"}}>
                                                        {
                                                            errors.password && touched.password ?
                                                                (
                                                                    <Typography className="text-error" style={{
                                                                        fontWeight: 600,
                                                                        marginLeft: "15px"
                                                                    }}>{t(errors.password)} </Typography>
                                                                ) : null
                                                        }
                                                    </Box>
                                                </Box>
                                                <Box className="formGroup formCheckbox">
                                                    <div>
                                                        <Checkbox name="stayIn" onChange={handleChange}
                                                                  value={values.stayIn} icon={<CircleUnchecked/>}
                                                                  checkedIcon={<CircleCheckedFilled/>}
                                                                  id="loginCheckbox"
                                                        />
                                                        <label htmlFor="loginCheckbox" className="checkboxLabel">{t("Stay logged in")}</label>
                                                    </div>
                                                    <Link href="/ChairmanForgotPassword" className="link bold-text">{t("Forgot Password?")}</Link>
                                                </Box>
                                                <Box className="customButton" style={{paddingTop: "4px"}}>
                                                    <Button variant="contained" type="submit">{t("login")}</Button>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                                {/* desktop footer block */}
                                <Box className="footer-main-block bottomBlock"
                                     style={{backgroundColor: "white", marginTop: '70px'}}>
                                    <h6 className="bottom-text">{t("POWERED BY")}</h6>
                                    <img src={Tenant_Logo.default} className="tenant-logo" style={{cursor: 'pointer'}}
                                         onClick={() => window.open("https://www.TenantInt.com", '_blank',"noopener,noreferrer")}
                                         alt=""/>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5} className="auth-cols">
                            <Box className="right-block" display={{xs: 'none', md: 'flex'}}>
                                <img src={Building1.default} className="building-logo" alt=""/>
                            </Box>
                        </Grid>
                    </Grid>
                    <Dialog
                        onClose={() => this.setState({complexModal: false})}
                        open={this.state.complexModal}
                        className="cancel-meeting-dialog selectComplexModal" style={{borderRadius:"50px"}}
                    >
                        <DialogContent style={{ margin: "0px 0"}}>
                            <Box>
                                <Box style={{position:"absolute",right:"0px",top:"8px"}}>
                                    <IconButton className="closeComplexModel" onClick={() => this.setState({complexModal: false})}>
                                        <Close />
                                    </IconButton>
                                </Box>
                                <Box style={{width:"100%"}}>
                                    <Typography className="bold-text" variant="h6" style={{fontSize:"22px",marginBottom:"15px"}}>{t("Select Building / Complex")}</Typography>
                                    <Divider/>
                                    <Typography variant="body1" style={{ marginBottom: "0px",color:"#181d25",fontSize:"16px",textAlign:"center",marginTop:"10px" }}>
                                        {t("Please Select Building / Complex you Want to manage.")}
                                    </Typography>
                                </Box>
                                <Box style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"15px",marginBottom:"20px"}}>
                                    {
                                        this.state.complexList.map((val) => {
                                            return(
                                                <Box key={val.id} className="selectedComplexClick" onClick={() => this.handleComplexNavigation(val)}
                                                     style={{width:"440px",height:"40px",border:"1px solid rgb(217 217 217)",display:"flex",alignItems:'center',padding:"10px",borderRadius:"90px",cursor:"pointer",marginBottom:"10px"}}>
                                                    <img src={buildingImg} style={{width:"30px",height:"30px",marginRight:"10px",marginBottom:"0px",marginLeft:"15px"}} />
                                                    <Typography variant="body1" className="bold-text" style={{marginBottom:"0px",fontSize:"16px"}}>{val.name}</Typography>
                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    <Loader loading={this.state.loading}/>
                    <AlertErrorWeb show={this.state.showError}
                                   handleClose={() => this.setState({showError: false, error: null})}
                                   message={this.state.error}/>
                </Box>
            </>
        );
    }
}

//@ts-ignore
export default withTranslation()(withRouter(ChairmanLogin));

// Customizable Area End
