// Customizable Area Start
import * as React from "react";
// custom components
import {Box, Button, Grid, Typography,} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import RentPaymentController, {Props} from "./RentPaymentController";
import {withTranslation} from "react-i18next";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Loader from "../../../components/src/Loader.web";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class RentPayments extends RentPaymentController{
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {t,i18n} = this.props
    const language = i18n.language;
    return (
        <>
            <Grid item xs={12} md={12} style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 20 }} className="auth-cols Rentpayments" dir={languageCondition(language,"rtl","ltr")}>
                <Grid container style={{ padding: '1rem' }}>
                  <Grid item xs={12} style={{ display:"flex", alignItems:"center", gap:"1rem",justifyContent:"space-between"}} >
                      <Box style={{ display:"flex", alignItems:"center", gap:"1rem"}}>
                          <ArrowBackIcon className="rentPaymentsBackBtn" onClick={() => this.props.history.push("/")} />
                          <p className="bold-text RentPaymentMain" style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                              {t("Rent Payments")}
                          </p>
                      </Box>
                  </Grid>
                </Grid>
                <Box style={{ padding: '0 1rem', flex: '1', background: "#F7F9FE",maxHeight:"95%", overflowY: 'scroll', paddingBottom: 20, display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"space-between"}} >
                    <Grid container>
                        {
                            this.state.BuildingListing.length 
                            ? this.state.BuildingListing.map((item:any,key:any)=> {
                                return(
                                    <Grid key={key} item xs={12} style={{position:"relative"}}>
                                        <Box
                                            className="unitListBox"
                                            display="flex"
                                            justifyContent='space-between'
                                            alignItems="center"
                                            borderRadius="15px"
                                            bgcolor="white"
                                            marginTop='1.5rem'
                                            padding='1.5rem'
                                            style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
                                            onClick={()=> {
                                                this.props.history.push(`/RentUnitList/${item.id}`);
                                                localStorage.setItem("buildingName",item.attributes.name)
                                            }}
                                        >
                                            <Box style={{minWidth:"100%",height:"70px"}}>
                                                <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                                    <Box>
                                                        <Typography variant={"body1"} className="bold-text RentPaymentMainName" style={{fontWeight:"bold",marginTop:"5px",padding:"0px"}}>
                                                            {item.attributes.name}
                                                        </Typography>
                                                        <Typography variant={"body2"} style={{marginTop:"5px"}}>
                                                            {t(item.attributes.city || "NA")}
                                                        </Typography>
                                                    </Box>
                                                    {
                                                        languageCondition(language, <ArrowBackIosIcon fontSize="small" style={{color:"#BFBFBF",fontSize:"25px"}}/>, <ArrowForwardIosIcon fontSize="small" style={{color:"#BFBFBF",fontSize:"25px"}}/>)
                                                    }

                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="rentPaymentBuildingMapBtn" style={languageCondition(language,{position:"absolute",bottom:"20px",right:"35px"},{position:"absolute",bottom:"20px",left:"30px"})} onClick={()=> window.open(`https://maps.google.com?q=${item.attributes?.lat},${item.attributes?.long}`, '_blank')}>
                                            <Typography variant={"subtitle1"} style={{fontWeight:"bold",color:"#FC8434",marginTop:"5px"}}>
                                                {t("See building on map")}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )
                            })
                            : (
                                <Grid item xs={12} style={{ padding: '1rem' }}>
                                    <Typography className="bold-text">{t("No rent payments.")}</Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
                <Loader loading={this.state.loading} />
            </Grid>
        </>
    );
  }
}
export default withTranslation()(withRouter(RentPayments))

const CloseButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        fontWeight:"bold",
        borderRadius:"100px",
        height:"55px",
        '&:hover': {
            backgroundColor: "#2b6fef",
        },
    },
}))(Button);

// Customizable Area End
