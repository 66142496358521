// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  MenuItem,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Avatar,
  Tabs,
  Tab,
  Link,
  withStyles,
} from "@material-ui/core";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import CETeamMemberTableController, { PendingTaskData, Props } from "./CETeamMemberTableController.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withRouter } from "react-router-dom";
import { chat, email, telephone } from "../assets";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CEReassignBuild from "./CEReassignBuild.web";
import CEChangeRP from "./CEChangeRP.web";
import CEDeleteRevokeMember from "./CEDeleteRevokeMember.web";
import CEAssignObject from "./CEAssignObject.web";
import CEAddNewTeamMember from "./CEAddNewTeamMember.web";
import { withTranslation } from "react-i18next";
import RoomIcon from "@material-ui/icons/Room";
import ListIcon from "@material-ui/icons/List";
import { ComplexMapComponent } from "../../../../components/src/MapComponent";
import CENotDelete from "./CENotDelete.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { MyTeamStyle } from "./CEMyTeamTable.web";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import { ControlledMenu, Menu } from "@szhsin/react-menu";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";

class CETeamMemberTable extends CETeamMemberTableController {
  constructor(props: Props) {
    super(props);
  }

  renderTopBar = (t: any) => {
    const { role } = this.state;
    return (
      <>
        <Button className="more-option-btn" ref={this.ref} onClick={() => this.toggleMenu()}>
          <Typography>{t("MORE OPTIONS")}</Typography>
          {this.state.menuopen2 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </Button>
        <ControlledMenu state={this.state.menuopen2 ? "open" : "closed"} anchorRef={this.ref} onClose={() => this.toggleMenu()}>
          {role !== "sales_manager" && (
            <MenuItem onClick={() => this.setState({ ChangeRPopen: true, menuopen2: false })}>{t("Change Reporting Person")}</MenuItem>
          )}
          <MenuItem onClick={this.handleAssignModal}>{t(`Assign ${role != "sales_manager" ? "Object" : ""}`)}</MenuItem>
          <MenuItem onClick={() => this.setState({ Deleteopen: true, menuopen2: false, dataId: this.state.userId })}>{t(`${
            role != "sales_manager" ? "Delete Profile" : "Suspend"
          }`)}</MenuItem>
        </ControlledMenu>
        {this.state.ChangeRPopen && (
          // @ts-ignore
          <CEChangeRP
            dataFromComponent1={this.state.ChangeRPopen}
            data={this.state.data}
            handleModalClose={this.handleModalChangeRP}
            handleRPChangeSuccess={this.getUserData}
          />
        )}
        {this.state.Deleteopen && (
          <>
            {this.checkOjbesIsAssigned() ? (
              // @ts-ignore
              <CEDeleteRevokeMember
                name={this.state.data ? this.state.data.attributes.employee_name : ""}
                navigation={this.props.navigation}
                openModal={this.state.Deleteopen}
                handleModalClose={this.handleDelete}
                modalType="delete"
                dataId={this.state.dataId}
              />
            ) : (
              // @ts-ignore
              <CENotDelete dataFromComponent1={this.state.Deleteopen} handleModalClose={this.handleDelete} />
            )}
          </>
        )}
        {this.state.assigneeOpen && (
          // @ts-ignore
          <CEAssignObject
            dataFromComponent1={this.state.assigneeOpen}
            isEdit={true}
            handleModalClose={this.handleAssignModal}
            empId={this.state.data.id}
            empName={this.state.data.attributes?.employee_name}
          />
        )}
        {this.state.editTeamMemberOpen && (
          // @ts-ignore
          <CEAddNewTeamMember
            openModal={this.state.editTeamMemberOpen}
            isEdit={true}
            closeModal={this.handleEditTeamdetialModal}
            id={this.state.employeeId}
          />
        )}
      </>
    );
  };

  renderProfileGeneralDetailsCard = (t: any) => {
    const { role } = this.state;
    return (
      <Box className="team-member-detail">
        <Typography className="team-content-heading bold-text">{t("General Details")}</Typography>
        <Box className="team-member-detail-card">
          {this.state.data && (
            <Box className="member-detail-card-left">
              <Avatar
                src={
                  this.state.data.attributes && this.state.data.attributes.profile_photo
                    ? this.state.data.attributes.profile_photo.url
                    : ""
                }
              />
              <Typography className="bold-text" style={{ fontSize: "18px" }}>
                {this.state.data.attributes ? this.state.data.attributes.employee_name : t("Person name")}
              </Typography>
              <Box className="role-chip">
                <span>{this.state.data.attributes ? this.showingRoleList[this.state.data.attributes.role] : t("NA")}</span>
              </Box>
              <Box className="contact-image-box">
                <Box>
                  <img src={chat} />
                </Box>
                <Link href={`tel:${this.state.data.attributes && this.state.data.attributes.phone_number}`}>
                  <Box>
                    <img src={telephone} />
                  </Box>
                </Link>
                <Link href={`tel:${this.state.data.attributes && this.state.data.attributes.email}`}>
                  <Box>
                    <img src={email} />
                  </Box>
                </Link>
              </Box>
            </Box>
          )}
          <Box className="member-detail-card-right">
            <Grid container spacing={2}>
              <Grid xs={4} item>
                <Box className="detail-info-box">
                  <Typography className="info-title user-report-person">{t("Reporting Person")}</Typography>
                  <Typography className="info-value">
                    {this.handleProfileName(this.generateProfileData("reporting_person"))}
                  </Typography>
                </Box>
                <Box className="detail-info-box">
                  <Typography className="info-title">{t("Country")}</Typography>
                  <Typography className="info-value user-country-value">{this.generateProfileData("country")}</Typography>
                </Box>
                <Box className="detail-info-box user-complex-detail-box">
                  <Typography className="info-title user-assign-complex">{t("Assigned Complexes")}</Typography>
                  <Typography className="info-value">{this.generateProfileData("society_management")}</Typography>
                </Box>
                {role === "sales_manager" && (
                  <Box className="detail-info-box">
                    <Typography className="info-title">{t("Assigned Owners")}</Typography>
                    <Typography className="user-assign-owner-value info-value">{this.generateProfileData("total_units")}</Typography>
                  </Box>
                )}
              </Grid>
              <Grid xs={4} item>
                <Box className="detail-info-box user-detail-email-box">
                  <Typography className="info-title user-email">{t("Email Address")}</Typography>
                  <Typography className="info-value">{this.generateProfileData("email")}</Typography>
                </Box>
                <Box className="detail-info-box">
                  <Typography className="info-title">{t("City")}</Typography>
                  <Typography className="user-city-value info-value">{this.generateProfileData("city")}</Typography>
                </Box>
                <Box className="detail-info-box">
                  <Typography className="info-title">{t("Assigned Individual Buildings")}</Typography>
                  <Typography className="info-value user-building-value">{this.generateProfileData("building_management")}</Typography>
                </Box>
                {role == "sales_manager" && (
                  <Box className="detail-info-box">
                    <Typography className="info-title user-property-dev">{t("Assigned Property Managers")}</Typography>
                    <Typography className="info-value">{this.generateProfileData("total_units")}</Typography>
                  </Box>
                )}
              </Grid>
              <Grid xs={4} item>
                <Box className="detail-info-box">
                  <Typography className="info-title user-phone">{t("Phone Number")}</Typography>
                  <Typography className="info-value">{this.generateProfileData("full_phone_number")}</Typography>
                </Box>
                <Box className="detail-info-box">
                  <Typography className="info-title">{t("Assigned Companies")}</Typography>
                  <Typography className="info-value user-company-value">{this.generateProfileData("real_estate_company")}</Typography>
                </Box>
                <Box className="detail-info-box user-detail-unit-box">
                  <Typography className="info-title">{t("Total Units")}</Typography>
                  <Typography className="info-value">{this.generateProfileData("total_units")}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  renderPendingTaskItem = (taskDetail: PendingTaskData) => {
    return (
      <Grid xs={12} item>
        <Grid container style={{ alignItems: "end" }}>
          <Grid container item xs={12}>
            {this.pendingTaskContent(taskDetail).map((item, index: number) => {
              return (
                <Grid xs={4} key={index} item style={{ marginBottom: "20px" }}>
                  <Grid container>
                    <Grid item xs={9} className="pendingTaskContent">
                      <Box>
                        <img src={item.img} alt={item.name} />
                      </Box>
                      <Box>
                        <Typography className="login-h1" style={{ color: "#fc8434", fontSize: "14px" }}>
                          {item.name}
                        </Typography>
                        <Typography className="login-h1 bold-text">{this.handleTaskValue(item)}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderPendingTask = (taskDetail: PendingTaskData) => {
    return (
      <Box className="dashboard_card" p={3} mb={5}>
        <Box style={{ marginBottom: "10px" }}>
          <Typography className="login-h1 bold-text"> {taskDetail.title}</Typography>
        </Box>
        <Box style={{ marginBottom: "15px" }}>
          <Typography>{taskDetail.description}</Typography>
        </Box>
        <Grid container spacing={2}>
          {this.renderPendingTaskItem(taskDetail)}
        </Grid>
      </Box>
    );
  };

  renderSearchBar = (t: any, language: any, placeholder: string, isAssignedCompanies?: boolean) => {
    return <SearchInput t={t} language={language} label={placeholder} onChange={(event: any) => this.handleSearch(event, isAssignedCompanies)} />;
  };

  renderAssignCompanies = (t: any, language: any) => {
    return (
      <Box className="dashboard_card">
        <Box className="team-complex-list-header">
          <Typography className="login-h1 bold-text" style={{ fontSize: "22px" }}>
            {t("Assigned Companies")}
          </Typography>
          {this.renderSearchBar(t, language, "Search by company name", true)}
        </Box>
        <Divider />
        <Box className="team-complex-list-content">
          <Grid container spacing={1}>
            {this.state.assignedCompanies.map((complexData: any) => {
              const complex = complexData.id;
              return (
                <Grid xs={4} item>
                  <Box className="complex-btn-box">
                    <Button
                      className="complex-btn"
                      endIcon={
                        <Menu menuButton={<MoreVertIcon />}>
                          <MenuItem onClick={() => this.props.navigation.navigate("RealEstateCompanyDetail", { id: complex })}>{t("View Details")}</MenuItem>
                        </Menu>
                      }
                    >
                      <span className="ce-team-complex-btn" title={complexData.company_name}>
                        {complexData.company_name}
                      </span>
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    );
  };

  renderMap = () => {
    return (
      <Box className="complex_table_switch_map_switch">
        <ComplexMapComponent
          defaultCenter={{
            lat: 12.1111,
            lng: 12.1111,
          }}
          defaultZoom={1}
          isData={true}
          mapPoints={this.state.mapPoints}
          manageClick={this.handleMapPointClick}
          currentPoint={this.state.mapPointClick}
        />
      </Box>
    );
  };

  renderMapSwitch = () => (
    <Box className="ce-buttonBorderBox">
      <Box data-test-id="tableview-button" onClick={() => this.handleView(false)}>
        <ListIcon className={this.state.mapSwitch ? "ce-inactiveIcon" : "ce-activeIcon"} />
      </Box>
      <Box data-test-id="mapview-button" onClick={() => this.handleView(true)}>
        <RoomIcon className={this.state.mapSwitch ? "ce-activeIcon" : "ce-inactiveIcon"} />
      </Box>
    </Box>
  );

  renderTab = (t: any) => {
    const { role } = this.state;
    return (
      <Box>
        <Tabs
          TabIndicatorProps={{ style: { display: "none" } }}
          value={this.state.value}
          onChange={(_e, value) => {
            this.setState({ value: value, searchText: "" });
          }}
          className="complex-tab bold-text"
          data-test-id="building-tab"
        >
          <Tab
            textColor="textDark"
            className={this.state.value === 0 ? "activeMenubar menubarButton" : "leftInactiveMenubar menubarButton"}
            label={<span className="login-h1 bold-text CEtab-label">{t("Complex")}</span>}
          />
          <Tab
            textColor="textDark"
            className={this.state.value === 1 ? "activeMenubar menubarButton" : "rightInactiveMenubar menubarButton"}
            label={<span className="login-h1 bold-text CEtab-label">{t("Individual Buildings")}</span>}
          />
          {role === "sales_manager" && (
            <Tab
              textColor="textDark"
              className={this.state.value === 2 ? "activeMenubar menubarButton" : "rightInactiveMenubar menubarButton"}
              label={<span className="login-h1 bold-text CEtab-label">{t("Paying Owners")}</span>}
            />
          )}
          {role === "sales_manager" && (
            <Tab
              textColor="textDark"
              className={this.state.value === 3 ? "activeMenubar menubarButton" : "rightInactiveMenubar menubarButton"}
              label={<span className="login-h1 bold-text CEtab-label">{t("Paying Property Managers")}</span>}
            />
          )}
        </Tabs>
      </Box>
    );
  };

  handleTableHeading = (heading: string) => heading === "complex" ? "Complex" : "Individual Building";

  renderTabHeader = (t: any, language: any, hName: string) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box className="groupButtonContainer">
          {this.renderMapSwitch()}
          <Typography className="login-h1 bold-text" style={{ fontSize: "22px" }}>
            {t(this.handleTableHeading(hName))}
          </Typography>
        </Box>
        <SearchInput t={t} language={language} label={`Search by ${hName} name`} onChange={this.handleComplexSearch} />
      </Box>
    );
  };

  isManager = (flag: boolean) => (flag ? "Yes" : "No");

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <CEHeaderComponentWeb>
        <Box className={classes.MyTeamTable}>
          <Box className="navigation action-header-box">
            <Typography variant="body1">
              <Link href="/CompanyEmployee/CEMyTeam">{t("Team Members")}</Link> /{" "}
              <Box component="span" style={{ color: "blue" }}>
                {this.generateProfileData("employee_name")}
              </Box>
            </Typography>
            <Box className="sub-heading-box">
              <Typography variant="h5" className="bold-text">
                {this.generateProfileData("employee_name")}
              </Typography>
              <Box className="team-btn-box">
                {this.renderTopBar(t)}
                <CommonButton
                  isVariant={false}
                  data-test-id="add-team-member-btn"
                  t={t}
                  color="blue"
                  label="Edit Details"
                  onClick={this.handleEditTeamdetialModal}
                />
              </Box>
            </Box>
          </Box>

          <Box>{this.renderProfileGeneralDetailsCard(t)}</Box>

          <Box>{this.renderAssignCompanies(t, language)}</Box>

          <Box style={{ marginBottom: "30px" }}>
            {this.renderTab(t)}
            {this.state.value === 0 && (
              <div>
                <Box className="dashboard_card" p={3}>
                  {this.renderTabHeader(t, language, "complex")}
                  <br />
                  {this.state.mapSwitch ? (
                    this.renderMap()
                  ) : (
                    <>
                      <TableContainer>
                        <Table className="table-box">
                          <TableHead>
                            <TableRow>
                              <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                {t("#")}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                {t("Complex ID")}
                              </TableCell>
                              <TableCell
                                align={languageCondition(language, "right", "left")}
                                className="ce-team-complex-table-header bold-text"
                              >
                                {t("Complex Name")}
                              </TableCell>
                              <TableCell
                                align={languageCondition(language, "right", "left")}
                                className="ce-team-complex-table-header bold-text"
                              >
                                {t("Is Manager")}
                              </TableCell>
                              <TableCell
                                align={languageCondition(language, "right", "left")}
                                className="ce-team-complex-table-header bold-text"
                              >
                                {t("Company Name")}
                              </TableCell>
                              <TableCell
                                align={languageCondition(language, "right", "left")}
                                className="bold-text ce-team-complex-table-header"
                              >
                                {t("Chairman")}
                              </TableCell>
                              <TableCell
                                align={languageCondition(language, "right", "left")}
                                className="bold-text ce-team-complex-table-header"
                              >
                                {t("Total Unit")}
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.empData.length === 0 && (
                              <TableRow className="ce-team-no-complex-row">
                                <TableCell colSpan={8}>{t("No Complex Available")}</TableCell>
                              </TableRow>
                            )}
                            {this.state.empData.map((cItem: any, cIndex: any) => {
                              return (
                                <TableRow key={cItem.id}>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {cIndex + 1}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>{cItem.id}</TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {cItem.complex_name}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {this.isManager(cItem.is_manager)}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {cItem.company_name}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {cItem.chairman}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {cItem.total_units}
                                  </TableCell>
                                  <TableCell>
                                    <Menu menuButton={<MoreVertIcon data-test-id="ce-team-complex-menu" />}>
                                      <MenuItem
                                        className="ce-team-permission-menu"
                                        onClick={() => {
                                          this.setState({ Revokeopen: true, menuopen: false, dataId: cItem.id, userData: {...this.state.userData, complexBuildingName: cItem.complex_name} });
                                        }}
                                      >
                                        {t("Revoke Permission")}
                                      </MenuItem>
                                      <MenuItem
                                        className="ce-team-assign-menu"
                                        onClick={() => {
                                          this.setState({ menuopen: false, dialogopen: true, dataId: cItem.id });
                                        }}
                                      >
                                        {t("Assign to another manager")}
                                      </MenuItem>
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box className="tablePagination ce-team-complex-pagination">
                        <PaginationModule
                          handlePagination={this.handleChangePage}
                          page={this.state.page}
                          pagination={this.state.pagination}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </div>
            )}
            {this.state.value === 1 && (
              <div>
                <Box className="dashboard_card" p={3}>
                  {this.renderTabHeader(t, language, "building")}
                  <br />
                  {this.state.mapSwitch ? (
                    this.renderMap()
                  ) : (
                    <>
                      <TableContainer>
                        <Table className="table-box c1">
                          <TableHead>
                            <TableRow className="login-h1 bold-text brow">
                              <TableCell align={languageCondition(language, "right", "left")} 
                                className="bold-text team-building-table-header"
                              >
                                {t("#")}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                {t("Building ID")}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")} 
                                className="team-building-table-header bold-text"
                              >
                                {t("Building Name")}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                {t("Is Manager")}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")} 
                                className="bold-text team-building-table-header"
                              >
                                {t("Company Name")}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                {t("Chairman")}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}
                                className="team-building-table-header bold-text"
                              >
                                {t("Total Unit")}
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.empData.length === 0 && (
                              <TableRow>
                                <TableCell colSpan={8}>{t("No Individual Buildings Available")}</TableCell>
                              </TableRow>
                            )}
                            {this.state.empData.map((bItem: any, bIndex: any) => {
                              return (
                                <TableRow key={bIndex}>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {bIndex + 1}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>{bItem.id}</TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {bItem.building_name}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {this.isManager(bItem.is_manager)}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {bItem.company_name}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {bItem.chairman}
                                  </TableCell>
                                  <TableCell align={languageCondition(language, "right", "left")}>
                                    {bItem.total_units}
                                  </TableCell>
                                  <TableCell>
                                    <Menu menuButton={<MoreVertIcon />}>
                                      <MenuItem
                                        onClick={() =>
                                          this.setState({ Revokeopen: true, menuopen: false, dataId: bItem?.id, userData: {...this.state.userData, complexBuildingName: bItem.building_name} })
                                        }
                                      >
                                        {t("Revoke Permission")}
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          this.setState({ menuopen: false, dialogopen: true, dataId: bItem?.id })
                                        }
                                      >
                                        {t("Assign to another manager")}
                                      </MenuItem>
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box className="tablePagination">
                        <PaginationModule
                          pagination={this.state.pagination}
                          handlePagination={this.handleChangePage}
                          page={this.state.page}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </div>
            )}
            <Typography
              className="login-h1 bold-text"
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                fontSize: "22px",
              }}
            >
              {t("Pending Task")}
            </Typography>
            {this.state.pendingTaskData.length > 0 ? (
              this.state.pendingTaskData.map((pendingTaskDetail) => this.renderPendingTask(pendingTaskDetail))
            ) : (
              <Box>
                <Typography>{t("No Task Available")}</Typography>
              </Box>
            )}
          </Box>
        </Box>
        {this.state.dialogopen && (
          // @ts-ignore
          <CEReassignBuild
            isBuilding={this.state.value === 1}
            dataFromComponent1={this.state.dialogopen}
            handleModalClose={this.handleModalClose}
            dataId={this.state.dataId}
          />
        )}
        {this.state.Revokeopen && (
          // @ts-ignore
          <CEDeleteRevokeMember
            isBuilding={this.state.value === 1}
            openModal={this.state.Revokeopen}
            handleModalClose={this.handleRevoke}
            modalType="revoke"
            dataId={this.state.dataId}
            name={this.state.userData.name}
            buildingComplexName={this.state.userData.complexBuildingName}
            userId={this.state.userId}
            getComplexData={this.getComplexData}
            getIndividualBuildingData={this.getIndividualBuildingData}
            getUserData={this.getUserData}
          />
        )}
      </CEHeaderComponentWeb>
    );
  }
}

export default withTranslation()(withStyles(MyTeamStyle)(withRouter(CETeamMemberTable)));
// Customizable Area End
