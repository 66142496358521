// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import { Share } from "./assets";
import SpentDetailsController, { Props } from "./SpentDetailsController";
import "./style.css";
import { withTranslation } from "react-i18next";
import { CloseButton } from "./CloseButton";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
// @ts-ignore
import { baseURL }  from "../../../framework/src/config";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";

class Visitors extends SpentDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;
    const { getMySpentList, selectedSpentId } = this.state;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols">
          <Grid container className="fee-payment-top-bar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} onClick={() => this.props.history.push(`/BudgetSpending/ComplexList/${this.state.complexId}`)} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text">
                  {getMySpentList?.attributes?.expence_category}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              <Grid item xs={12} style={{}}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px " }}>
                  <Typography variant={"body1"} className="bold-text">
                    {t("Budget Spending Details")}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="8px"
                  bgcolor="white"
                  padding="15px"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                >
                  <Box style={{ minWidth: "100%" }}>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Building Name")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {getMySpentList?.attributes?.building_name}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Paid Amount")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, getMySpentList?.attributes?.currency?.currency, getMySpentList?.attributes?.currency?.currency_ar)} {Number(getMySpentList?.attributes?.paid_amount).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Paid Date")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {getMySpentList?.attributes?.paid_date}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Paid For")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, getMySpentList?.attributes?.currency?.currency, getMySpentList?.attributes?.currency?.currency_ar)} {Number(getMySpentList?.attributes?.paid_amount).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Paid By")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {getMySpentList?.attributes?.paid_by}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Expense Approved By")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {getMySpentList?.attributes?.expence_approved_by}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Description")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {getMySpentList?.attributes?.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} className="spend-details-download-share-btn download-share-bar">
                <CloseButton
                  onClick={() => this.buildingBudgetDownload(selectedSpentId)}
                  fullWidth
                  className="download-receipt-btn spend-details-download-btn download-btn-receipt"
                >
                  {t("Download Invoice")}
                </CloseButton>
                <Box
                  className="spend-details-share-btn shareIcon"
                  style={{ marginRight: "5px", padding: "0 16px", width: "unset" }}
                  onClick={this.handleSpentShareModal}
                >
                  <img src={Share} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <ShareDocumentModal
          isOpen={this.state.isShareModalOpen}
          heading={t("Share")}
          language={language}
          documentURL={`${baseURL}/society_managements/${this.state.complexId}/bx_block_fees_payment/building_budgets/${this.state.selectedSpentId}/download_report.pdf`}
          handleClose={this.handleSpentShareModal}
        />
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));

// Customizable Area End
