// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import OwnerNotificationController, { Props } from "./OwnerNotificationController.web";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Typography,
  withStyles,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { DeleteImage, DeleteRentIcon, FilterIcon } from "./assets";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import { NotificationStyle } from "./NotificationStyle.web";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";
import { toast } from "react-hot-toast";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";
import LaunchIcon from "@material-ui/icons/Launch";

class OwnerNotification extends OwnerNotificationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n?.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box
          dir={languageCondition(language, "rtl", "ltr")}
          className={classes.ownerNotification}
          style={{ background: "#F7F9FE", height: "100vh", overflowY: "hidden" }}
        >
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className="faq-step notification-top-bar">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar notification-top-content">
                  <div className="left-icon notification-back-icon">
                    <IconButton className="owner-notification-back-btn" onClick={() => this.handleNavigationBack()}>
                      <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                    </IconButton>
                    <span>{t("Notification")}</span>
                  </div>
                  <div className="right-icon notification-right-content-box">
                    {this.state.isDeleteOpen ? (
                      <>
                        <p
                          onClick={() => {
                            const idList = this.state.notificationList.map((notification: any) => notification.id);
                            this.setState({ selectedNotification: idList });
                          }}
                          className="select-text notification-select-all"
                        >
                          {t("Select All")}
                        </p>
                        <img
                          alt="delete"
                          className="delete-icon-notification"
                          onClick={() => {
                            if (this.state.selectedNotification.length > 0) {
                              this.handleDeleteNotificationModal();
                            } else {
                              toast.error("Please Select Notification");
                            }
                          }}
                          src={DeleteRentIcon}
                        />
                      </>
                    ) : (
                      <>
                        <Box>
                          <Menu menuButton={<img src={FilterIcon} alt="notification-menu" />}>
                            <MenuItem onClick={() => this.setState({ filterType: "" })}>{t("All")}</MenuItem>
                            {this.state.notificationTypeList.map((type: any) => {
                              return (
                                <MenuItem onClick={() => this.setState({ filterType: type })} key={type}>
                                  {type}
                                </MenuItem>
                              );
                            })}
                          </Menu>
                        </Box>
                        <img
                          src={DeleteRentIcon}
                          alt="notification-delete"
                          onClick={() => this.setState({ isDeleteOpen: true })}
                        />
                      </>
                    )}
                  </div>
                </Box>
                <Box className="tenant-detail-box notification-list-box">
                  <Container>
                    <Box className="rent-history-box notification-list-content">
                      {this.state.notificationList.length === 0 && (
                        <Card>
                          <Box className="rent-history notification-empty-box">{t("No notification found")}</Box>
                        </Card>
                      )}
                      {this.state.notificationList.map((notification: any) => {
                        return (
                            <Card key={notification.id} className="notification-card">
                              <Box className="rent-history notification-content-box">
                                <Box className="header notification-header">
                                  <Box className="left-side notification-status-bar">
                                    {notification.attributes.building_name && (
                                      <span className="building" style={{ fontSize: "12px" }}>
                                        {notification.attributes.building_name}
                                      </span>
                                    )}
                                    {notification.attributes.notification_type && (
                                      <span className="type" style={{ fontSize: "12px" }}>
                                        {notification.attributes.notification_type}
                                      </span>
                                    )}
                                  </Box>
                                  {this.state.isDeleteOpen && (
                                    <Checkbox
                                      onChange={(e: any) => {
                                        e.stopPropagation();
                                        if (!e.target.checked) {
                                          const newList = this.state.selectedNotification.filter(
                                            (id: any) => id != notification.id
                                          );
                                          this.setState({
                                            selectedNotification: newList,
                                          });
                                        } else {
                                          this.setState({
                                            selectedNotification: [...this.state.selectedNotification, notification.id],
                                          });
                                        }
                                      }}
                                      checkedIcon={<CircleCheckedFilled />}
                                      icon={<CircleUnchecked />}
                                      checked={this.state.selectedNotification.includes(notification.id)}
                                    />
                                  )}
                                  {!this.state.isDeleteOpen && notification.attributes.app_url && <LaunchIcon />}
                                </Box>
                                <h4 className="heading bold-text notification-heading" style={{ fontSize: "15px" }}>
                                  {languageCondition(
                                    language,
                                    notification.attributes.title_ar,
                                    notification.attributes.title
                                  )}
                                </h4>
                                <p className="notification-desc">
                                  {languageCondition(
                                    language,
                                    notification.attributes.description_ar,
                                    notification.attributes.description
                                  )}
                                </p>
                                <p className="time notification-time">
                                  {moment(notification.attributes.created_at).fromNow()}
                                </p>
                              </Box>
                            </Card>
                         );
                      })}
                    </Box>
                  </Container>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={this.state.isDeleteNotificationModalOpen}
          onClose={() => this.handleDeleteNotificationModal()}
          dir={languageCondition(language, "rtl", "ltr")}
          className="delete-document personal delete-notification-owner-modal delete-notification-modal"
          fullWidth
        >
          <DialogContent>
            <Box textAlign="center" className="notification-dialog-content">
              <img src={DeleteImage} alt="notification-dialog-delete-icon" />
              <Typography variant="h6" className="notification-dialog-heading">
                {t("Delete")} {this.state.selectedNotification.length} {t("Selected Notification")}
              </Typography>
              <Typography variant="body1" className="notification-dialog-content">
                {t("Are you sure want to delete")} {this.state.selectedNotification.length}{" "}
                {t("selected notification? Once deleted you won't be able to view deleted notification again.")}
              </Typography>
              <DialogActions className="dialog-button-group notification-dialog-action-button">
                <Button
                  onClick={() => {
                    this.setState({ loading: true, isDeleteNotificationModalOpen: false, isDeleteOpen: false }, () => {
                      this.deleteNotification();
                    });
                  }}
                  className="notification-dialog-delete-button"
                >
                  {t("Yes Delete")}
                </Button>
                <Button
                  onClick={() => this.handleDeleteNotificationModal()}
                  className="notification-dialog-cancel-button"
                >
                  {t("No, Don't Delete")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
export default withTranslation()(withStyles(NotificationStyle)(OwnerNotification));
// Customizable Area End
