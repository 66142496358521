// Customizable Area Start
import { Box, styled, } from "@material-ui/core";
const DrawerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  '& .header-wrapper': {
    paddingTop: 20,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .drawer-button__close': {
      minWidth: 25,
      width: 25,
      height: 25,
    }
  },
  '& .drawer-content': {
    maxHeight: '45vh',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  '& .button-group': {
    padding: 10,
    '& .button-copy__checked': {
      padding: 10,
      minWidth: '100%',
      borderRadius: '25px',
      fontWeight: 600,
      borderColor: 'inherit',
      background: '#FFFFFF',
      color: '#2b6fed',
      fontSize: '14px',
      '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.12)',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
    },
    '& .button-add__condition': {
      padding: 10,
      minWidth: '100%',
      background: '#2b6fed',
      color: '#FFFFFF',
      borderRadius: '25px',
      fontSize: '14px',
      '&:disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
        color: '#FFFFFF',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
    }
  },

}));

export default DrawerBox;
// Customizable Area End