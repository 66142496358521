// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import "../../assets/commonForm.css";
import CompEmpComplexBlockController, { Props } from "./CompEmpComplexBlockController.web";
import CompEmpComplexBlockStep1 from "./CompEmpComplexBlockStep1.web";
import CompEmpComplexBlockStep2 from "./CompEmpComplexBlockStep2.web";
import CompEmpComplexBlockStep3 from "./CompEmpComplexBlockStep3.web";
import CompEmpComplexBlockStep4 from "./CompEmpComplexBlockStep4.web";
import CompEmpComplexBlockStep5 from "./CompEmpComplexBlockStep5.web";
import CompEmpComplexBlockStep6 from "./CompEmpComplexBlockStep6.web";
import CompEmpComplexBlockStep7 from "./CompEmpComplexBlockStep7.web";

const complexTabs = [
  "Jurisdiction",
  "Basic Details",
  "Documents",
  "Location",
  "Buildings",
  "Shared Facilities",
  "Core Members",
];

class CompEmpComplexBlock extends CompEmpComplexBlockController {
  constructor(props: Props) {
    super(props);
  }

  getHeading = () => {
    if (this.state.isEditComplex) {
      return "Edit Complex";
    } else {
      return "Create New Complex";
    }
  };

  getMainBreadcrumb = () => (this.state.realEstateId ? "Real Estate Companies" : "Complex");

  getMainSubBreadcrumb = () => (this.state.realEstateId ? "Company Details" : "Complex Details");

  render() {
    const { t } = this.props;

    return (
      <>
        <Box style={{ padding: "0 40px 40px", backgroundColor: "rgb(244, 247, 255)" }}>
          <Box style={{ display: "flex" }}>
            <Typography style={{ fontSize: "16px", cursor: "pointer" }} onClick={this.handleCompanyListNavigation}>
              {t(this.getMainBreadcrumb())} /
            </Typography>
            <Typography style={{ fontSize: "16px", cursor: "pointer" }} onClick={this.handleCompanyDetailNavigation}>
              {t(this.getMainSubBreadcrumb())} /
            </Typography>
            <Typography style={webStyle.headColor}>{t(this.getHeading())}</Typography>
          </Box>
          <Typography style={{ fontSize: "32px", marginTop: "6px" }} className="bold-text">
            {t(this.getHeading())}
          </Typography>
          <Box style={{ marginTop: "25px", display: "flex", overflowX: "auto", gap: "8px", padding: "4px 0" }}>
            {complexTabs.map((val: any, index: number) => {
              return (
                <Box
                  key={index}
                  data-test-id="tab-container"
                  className="bold-text"
                  style={{
                    ...webStyle.tabContainer,
                    backgroundColor: this.state.stepComplex == index ? "#2B6FED" : "#C8C8C81F",
                  }}
                  onClick={() => this.handleStepClick(index)}
                >
                  {this.state.stepComplex === index ? (
                    <Button className="bold-text higlight-selected" style={{ color: "#FFFFFF", ...webStyle.tabBtn }}>
                      {t(val)}
                    </Button>
                  ) : (
                    <Button
                      className="higlight-selected"
                      style={{ ...webStyle.tabBtn, color: this.state.stepComplex < index ? "grey" : "#2B6FED" }}
                    >
                      {t(val)}
                    </Button>
                  )}
                </Box>
              );
            })}
          </Box>
          <Grid>
            {this.state.stepComplex === 0 && (
              <CompEmpComplexBlockStep1
                step={this.state.stepComplex}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                complexID={this.state.newComplexId}
                jurisdictionSelected={this.state.jurisdictionSelected}
                handleChangeJurisdiction={this.handleChangeJurisdiction}
                handleMeasurement={this.handleMeasurement}
                t={this.props.t}
                i18n={this.props.i18n}
              />
            )}
            {this.state.stepComplex === 1 && (
              <CompEmpComplexBlockStep2
                step={this.state.stepComplex}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                complexID={this.state.newComplexId}
                jurisdiction={this.state.jurisdictionSelected}
                updateComplexId={this.handleUpdateComplexId}
                currency={this.state.currency}
                handleChangeJurisdiction={this.handleChangeJurisdiction}
                measurement={this.state.measurement}
                t={this.props.t}
                i18n={this.props.i18n}
              />
            )}
            {this.state.stepComplex === 2 && (
              <CompEmpComplexBlockStep3
                step={this.state.stepComplex}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                jurisdiction={this.state.jurisdictionSelected}
                complexID={this.state.newComplexId}
                t={this.props.t}
                i18n={this.props.i18n}
              />
            )}
            {this.state.stepComplex === 3 && (
              <CompEmpComplexBlockStep4
                step={this.state.stepComplex}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                jurisdiction={this.state.jurisdictionSelected}
                complexID={this.state.newComplexId}
                t={this.props.t}
                i18n={this.props.i18n}
                handleCountryCity={this.handleCountryCity}
              />
            )}
            {this.state.stepComplex === 4 && (
              <CompEmpComplexBlockStep5
                step={this.state.stepComplex}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                jurisdiction={this.state.jurisdictionSelected}
                handleChangeCurrency={this.handleChangeCurrency}
                complexID={this.state.newComplexId}
                measurement={this.state.measurement}
                t={this.props.t}
                i18n={this.props.i18n}
                navigation={this.props.navigation}
                country={this.state.country}
              />
            )}
            {this.state.stepComplex === 5 && (
              <CompEmpComplexBlockStep6
                step={this.state.stepComplex}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                jurisdiction={this.state.jurisdictionSelected}
                complexID={this.state.newComplexId}
                currency={this.state.currency}
                measurement={this.state.measurement}
                t={this.props.t}
                i18n={this.props.i18n}
              />
            )}
            {this.state.stepComplex === 6 && (
              <CompEmpComplexBlockStep7
                step={this.state.stepComplex}
                handleBack={this.handleBack}
                jurisdiction={this.state.jurisdictionSelected}
                complexID={this.state.newComplexId}
                isEditComplex={this.state.isEditComplex}
                t={this.props.t}
                i18n={this.props.i18n}
                navigation={this.props.navigation}
                country={this.state.country}
                city={this.state.city}
              />
            )}
          </Grid>
        </Box>
      </>
    );
  }
}

const webStyle: any = {
  headColor: {
    color: "rgb(43, 111, 237)",
    fontSize: "16px",
  },
  tabContainer: {
    height: "43px",
    marginRight: "8px",
    borderRadius: "25px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  tabBtn: {
    fontWeight: 800,
    padding: "0px 20px",
    textTransform: "capitalize",
    borderRadius: "25px",
    width: "max-content",
  },
};

export default withTranslation()(CompEmpComplexBlock);
// Customizable Area End
