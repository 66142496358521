//@ts-ignore
//@ts-nocheck
// Customizable Area Start
import React from "react";

//components
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    IconButton,
    InputBase,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Select from '@material-ui/core/Select';
import {withRouter} from 'react-router-dom';
import {Menu} from "@szhsin/react-menu";
// @ts-ignore
import OverDuePaymentController, {Props} from "./OverDuePaymentController";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import ChairmanSidebar from "../../dashboard/src/ChairmanSidebar.web";
import {withTranslation} from 'react-i18next';
import {SearchIconImage} from "../../user-profile-basic/src/assets"
import {SuggestionStyleWeb} from "../../user-profile-basic/src/SuggestionStyle.web";
import SearchIcon from "@material-ui/icons/Search";
import 'web/src/i18n.js';
import {dashBoardActions} from "./chairmanUIStyles"
import '../../dashboard/src/style.css'
import AlertError from "../../../components/src/AlertError.web";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class CharmainInvoices extends OverDuePaymentController {
constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
        dataSearch: "",
        anchorEl: null
    }
    // Customizable Area End
}

render() {
    const {t,i18n} = this.props
    const { classes } = this.props;
    const {language} = i18n
    return (
      // Customizable Area Start
      <>
        <GeneralHeader>
                    <Container  className={classes.announcements}>
                        <Box style={dashBoardActions.navigation}>
                            <Box>
                                <Typography variant="body1" >
                                {t("My Dashboards")} / <Box component="span" style={{color: "blue"}}>{t("Overdue Payments")}</Box>
                                </Typography>
                                <Typography variant="h5" className="bold-text chairmanInvoices" style={{marginLeft:"0px",marginBottom:"15px",marginTop:"15px",fontSize:"32px"}}>{t("Overdue Payments")}</Typography>
                            </Box>
                        </Box>
                        <Box className="top-bar">
                            <Box className="filter">
                                <Select displayEmpty value={this.state.filterBuilding || ""} className="select-input selectBuildingForOverDuePayments" onChange={this.selectBuilding} 
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200, 
                                        marginTop: 8,
                                      },
                                    },
                                  }}>
                                    <MenuItem value="">
                                        {t("Select Building")}
                                    </MenuItem>
                                    {
                                        this.state.buildingList?.map((item:any,key:any)=> {
                                            return(
                                                <MenuItem key={key} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <Select displayEmpty  value={this.state.filterFloor || ""} className="select-input selectFloorChairmanOverDuePayments" onChange={this.selectFloor} 
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200, 
                                        marginTop: 8,
                                      },
                                    },
                                  }}>
                                    <MenuItem value="">
                                        {t("Select Floor")}
                                    </MenuItem>
                                    {
                                        this.state.floorList?.map((item:any,key:any)=> {
                                            return(
                                                <MenuItem key={key} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <Select displayEmpty value={this.state.filterUnit || ""} className="select-input selectUnitChairmanOverDuePayments" onChange={(e:any) => this.setState({filterUnit:e.target.value})}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                        transformOrigin: {
                                          vertical: 'top',
                                          horizontal: 'left',
                                        },
                                        PaperProps: {
                                          style: {
                                            maxHeight: 200, 
                                            marginTop: 8,
                                          },
                                        },
                                      }}>
                                    <MenuItem value="">
                                        {t("Select Unit")}
                                    </MenuItem>
                                    {
                                        this.state.unitList?.map((item:any,key:any)=> {
                                            return(
                                                <MenuItem key={key} value={item.id}>
                                                    {item.apartment_name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <Button className="handleSearchFilterForOverDuePayments" onClick={this.handleFilterBy} startIcon={<img src={SearchIconImage} style={{marginLeft:"10px"}} />}>{t("Search")}</Button>
                            </Box>
                        </Box>
                        <Box className="meeting-table">
                            <Grid item sm={12} md={12} xs={12}>
                                <Box className="table-top">
                                    <h3 className="bold-text" style={{fontSize:"22px"}}>{t("Overdue Payments")}</h3>
                                    <Box className="filter">
                                        <Box className="search-box">
                                            <SearchIcon />
                                            <InputBase placeholder={t("Search by name")} className="search handleSearchByNameForOverDuePayments" onChange={this.handleSearch}/>
                                        </Box>
                                    </Box>
                                </Box>
                                <Divider />
                                <Table className="table-box" style={{paddingBottom:"25px"}}  aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>#</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Name")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Building")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Unit No.")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Title")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Amount")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")} >{t("Type")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Status")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.invoicesList?.length > 0 ?
                                            this.state.invoicesList?.map((row:any,key:any) => (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row" align={languageCondition(language,"right","left")}>{key + 1}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{row.attributes.name}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{row.attributes.building_name}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{row.attributes.unit_number}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{row.attributes.title}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{languageCondition(language,row.attributes.currency.currency_ar,row.attributes.currency.currency)} {row.attributes.amount.toLocaleString()}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{t(row.attributes.payment_type.split("_").join(" "))}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{t(row.attributes.status.split("_").join(" "))}</TableCell>
                                                <TableCell className="invoice-receipts-menu" align={languageCondition(language,"right","left")}>
                                                    <Menu
                                                        menuButton={
                                                            <IconButton>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <MenuItem onClick={() => this.manageDownload(row.id)}>{t("Download")}</MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                            )):
                                            <TableRow>
                                                <TableCell colSpan={6} align={languageCondition(language,"right","left")}>{t("No Overdue Payment Data Available")}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                                <Divider />
                                <Box style={{width:"95%",height:"70px",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                    <PaginationModule handlePagination={this.handleInvoicesPagination} pagination={this.state.pagination} page={this.state.page ||1}/>
                                </Box>
                            </Grid>
                        </Box>
                    </Container>
        </GeneralHeader>
          <AlertError show={this.state.showError} handleClose={()=> this.setState({showError:false})} message={this.state.error} />
          <AlertSuccess show={this.state.showSuccess} handleClose={()=> this.setState({showSuccess:false})} message={this.state.successMessage} />
      </>
      // Customizable Area End
    );
  }
}

export default withTranslation()(withStyles(SuggestionStyleWeb)(withRouter(CharmainInvoices)));

// Customizable Area End