import React from "react";

// Customizable Area Start
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Modal,
  styled,
  Switch,
  Typography,
  Container,
  DialogContent,
} from "@material-ui/core";
import { withRouter } from "react-router";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { NoProfile_Img } from "../../user-profile-basic/src/assets";
import { DoubleTick, info, NoChat, Send } from "./assets";
import InboxController, { Props } from "./inboxController.web";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import "../assets/css/style.scss";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import DashboardHeaderWeb from "../../dashboard/src/DashboardHeader.web";
import GeneralSideBarWeb from "../../dashboard/src/GeneralSideBar.web";

// @ts-ignore
const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    inputProps={{ "aria-label": "controlled" }}
    onChange={props.diableChat}
    checked={!props.enable}
    {...props}
  />
))(({ theme }) => ({
  switchBase: {
    color: "#B0BEC5",
    "&$checked": {
      color: "#FC8434",
    },
    "&$checked + $track": {
      backgroundColor: "#FC8434",
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "white          ",
    },
  },
  checked: {},
  track: {},
  color: "#B0BEC5",
  "&$checked": {
    color: "#FC8434",
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#FC8434",
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#FC8434",
  },
}));

const ChatRoomSection = (props: any) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        <span style={{ fontWeight: "bold", display: "flex", gap: "0.5rem", marginTop: ".8rem", alignItems: "center" }}>
          {props.item?.attributes?.chat_with_account?.id != localStorage.getItem("userId")
            ? (
              <img
                src={props.item?.attributes?.chat_with_account?.attributes?.profile_pic?.url || NoProfile_Img}
                width="50"
                height="50"
                style={{ marginLeft: "10px", borderRadius: "50px" }}
              />
            ) || (
              <img src={NoProfile_Img} width="50" height="50" style={{ marginLeft: "10px", borderRadius: "50px" }} />
            )
            : (
              <img
                src={props.item?.attributes?.chatable?.attributes?.profile_pic?.url || NoProfile_Img}
                width="50"
                height="50"
                style={{ marginLeft: "10px", borderRadius: "50px" }}
              />
            ) || (
              <img src={NoProfile_Img} width="50" height="50" style={{ marginLeft: "10px", borderRadius: "50px" }} />
            )}
          {props.item?.attributes?.chat_with_account?.id != localStorage.getItem("userId")
            ? props.item?.attributes?.chat_with_account?.attributes?.full_name || "N/A"
            : props.item?.attributes?.chatable?.attributes?.full_name || "N/A"}
        </span>
      </Box>
    </>
  );
};

const MessageSection = (props: any) => {
  return (
    <>
      <Grid
        item
        xs={12}
        // @ts-ignore
        style={
          props.message.message.account_id == props.currentAccountId
            ? {
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "0.5rem",
            }
            : { display: "flex", justifyContent: "start", alignItems: "center", gap: "0.5rem" }
        }
      >
        {props.message.message.account_id != props.currentAccountId ? (
          <img
            src={props.message.message.profile_pic.url || NoProfile_Img}
            alt="profile-pic"
            width="50"
            height="50"
            style={{ borderRadius: "50%", marginRight: 5 }}
          />
        ) : null}
        {props.message.message.account_id == props.currentAccountId && (
          <div style={{ position: "relative", marginBottom: "22px" }}>
            <img src={DoubleTick} />
            <img src={DoubleTick} style={{ position: "absolute", left: "-4px" }} />
          </div>
        )}
        <Box>
          <Box style={{ background: "#f6f6f6", borderRadius: "6px", padding: "0.5rem", borderTopRightRadius: 0 }}>
            <Typography
              style={{
                marginLeft: 5,
                fontFamily: "Poppins",
                color: "#081F32",
                fontWeight: 500,
                fontSize: 14,
              }}
              align={props.message.message.account_id == props.currentAccountId ? "right" : "left"}
            ></Typography>
            {props.message.message.message.length > 45 ? (
              <>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#081F32",
                    wordBreak: "break-all",
                  }}
                  align="left"
                >
                  {props.message.message.message}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  style={{
                    fontWeight: 500,
                    display: "flex",
                    fontSize: 14,
                    wordBreak: "break-all",
                    color: "#081F32",
                    alignItems: "center",
                  }}
                >
                  {props.message.message.message}
                </Typography>
              </>
            )}
            {props.message?.message?.images.length != 0 ? (
              <Grid item xs={12}>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={props.handleClick}
                  src={props.message.message.images[0].url}
                  width="75"
                  height="75"
                />
              </Grid>
            ) : null}
          </Box>
          <ListItemText
            style={
              props.message.message.account_id == props.currentAccountId
                ? { textAlign: "right", fontSize: "11px" }
                : { textAlign: "left", fontSize: "11px" }
            }
            secondary={props.displaytime2(props.message.message.created_at)}
          />
        </Box>
      </Grid>
    </>
  );
};
// Customizable Area End

class AdminChat extends InboxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.inputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  messageList: any;
  inputRef: any;
  interval: any;

  scrollToBottom = () => {
    const scrollHeightBox = this.messageList?.scrollHeight;
    if (scrollHeightBox) {
      const screenHeight = this.messageList.clientHeight;
      const maxScrollTop = scrollHeightBox - screenHeight;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  async componentDidMount() {
    this.getProfile();
    this.getInbox();
    this.markUnread();

    this.interval = setInterval(() => {
      this.getSingleInbox();
    }, 10000);

    if (window.history?.state?.state?.data) {
      this.updateChatRoom();
    }
  }

  async componentWillUnmount() {
    clearInterval(this.interval);
  }

  displaytime(obj: any) {
    let valueTime: any = obj[Object.keys(obj)[Object.keys(obj).length - 1]];
    if (valueTime) {
      return this.dateToFromNowDaily(valueTime[valueTime.length - 1].message.created_at);
    } else {
      return "";
    }
  }

  displaytime2(time: any) {
    let date = new Date(time || Date.now());
    let minute = date.getMinutes();
    let hour = date.getHours();
    let formattedM = minute < 10 ? `0${minute}` : minute;
    return `${hour}:${formattedM}`;
  }

  dateToFromNowDaily(myDate: any) {
    let fromNow = moment.utc(myDate).fromNow();
    return moment(myDate).calendar(null, {
      lastWeek: "[Last] dddd",
      lastDay: "[Yesterday]",
      sameDay: "HH:mm A",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  }

  getLastMessage = (obj: any) => {
    let valueMessage = obj[Object.keys(obj)[Object.keys(obj).length - 1]];
    if (valueMessage) {
      return valueMessage[valueMessage.length - 1].message.message || "he";
    } else {
      return "";
    }
  };

  checkNosocialMedia(profileData: any) {
    if (!profileData?.attributes?.website[0].twitter_link) {
      if (!profileData?.attributes?.website[1].instagram_link) {
        if (!profileData?.attributes?.website[2].fb_link) {
          if (!profileData?.attributes?.website[3].snapchat_link) {
            return "No social media handle";
          }
        }
      }
    }
  }

  checkPrime = () => {
    return this.state.allInbox[0]?.attributes?.chat_with_account?.id == localStorage.getItem("userId") ? this.check2() : this.check3();
  };

  check2 = () => {
    return this.state.allInbox[0]?.attributes?.chat_with_account?.attributes?.disable_chat
      ? "Enable Chat"
      : "Disable Chat";
  };

  check3 = () => {
    return this.state.allInbox[0]?.attributes?.chatable?.attributes?.disable_chat
      ? "Enable Chat"
      : "Disable Chat";
  };

  SendMessage = (t: any, language: any) => {
    return (
      <>
        {this.state?.selectedChatRoom?.attributes?.chatable?.attributes?.disable_chat || this.state?.selectedChatRoom?.attributes?.chat_with_account?.attributes?.disable_chat ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.5rem",
                background: "#E7E1E1",
                borderRadius: "6px",
                boxShadow: "0px 4px 14px #f4f6fb",
                padding: "0.75rem",
              }}
            >
              <img src={info} width="20" height="20" />
              <p>
                {this.state?.selectedChatRoom?.attributes?.chatable?.attributes?.disable_chat ? this.state?.selectedChatRoom?.attributes?.chatable?.attributes?.full_name : this.state?.selectedChatRoom?.attributes?.chat_with_account?.attributes?.full_name}
                {t("has disabled the chat. You won’t be able to send the message until he/she enables it")}.
              </p>
            </div>
          </>
        ) : (
          <Grid
            container
            style={{ padding: "20px", display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                gap: "10px",
              }}
            >
              <input
                disabled={!this.state.selectedChatRoom}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.createMessages();
                  }
                }}
                onChange={(e) => this.CreateNewMessage(e)}
                type=""
                style={{
                  border: "1px solid #F0F0F0",
                  color: "#726363",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "25px",
                  padding: "10px 20px",
                  height: "50px",
                  width: "100%",
                  outline: "none",
                }}
                className="admin-chat-box-input"
                placeholder={t("Type your message")}
                value={this.state.newMessage}
              />
              {
                // @ts-ignore
                // @ts-nocheck
                <AttachFileIcon
                  onClick={() => {
                    this.inputRef.current.click();
                  }}
                  for="BtnBrowseHidden"
                  style={{ cursor: "pointer" }}
                  className="admin-chat-upload-icon"
                />
              }
              <input
                disabled={!this.state.selectedChatRoom}
                ref={this.inputRef}
                id="BtnBrowseHidden"
                type="file"
                onChange={(e: any) => this.handleFile2(e.target.files[0])}
                style={{
                  position: "absolute",
                  height: "10px",
                  width: "10px",
                  zIndex: 2,
                  cursor: "pointer",
                  opacity: 0,
                }}
                className="admin-chat-upload-input"
                accept="image/png, image/jpeg, image/jpg,.pdf"
              />
              <Box
                style={{
                  cursor: "pointer",
                  borderRadius: "50px",
                  padding: "1rem",
                  background: "#2B6FED",
                  width: "20px",
                  height: "20px",
                }}
                className="admin-chat-send-chat"
                onClick={() => {
                  this.createMessages();
                  this.scrollToBottom();
                }}
              >
                <img src={Send} className={languageCondition(language, "KeyboardForwardIconAR", "")} />
              </Box>
            </Box>
          </Grid>
        )}
      </>
    );
  };

  InBoxCard = (item: any,) => {
    return (
      <Box padding="0.25rem" width="100%">
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <p style={{ fontSize: "16px" }} className="bold-text">
            {item?.attributes?.chat_with_account?.id != localStorage.getItem("userId") ? item?.attributes?.chat_with_account?.attributes?.full_name || "N/A" : item?.attributes?.chatable?.attributes?.full_name || "N/A"}
          </p>
          <p style={{ fontSize: "12px", color: "#8D8D8D" }}>{this.displaytime(item.attributes.messages)}</p>
        </Box>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="textwrapChat" style={{ fontSize: "14px", color: "#8D8D8D" }}>
            {Object.keys(item.attributes.messages).length != 0 &&
              this.getLastMessage(item.attributes.messages)}
          </p>
          {item?.attributes?.is_mark_unread === 0 ? (
            <div style={{ position: "relative", marginRight: "0.25rem" }}>
              <img src={DoubleTick} />
              <img src={DoubleTick} style={{ position: "absolute", left: "-4px" }} />
            </div>
          ) : (
            <p
              className="bold-text"
              style={{
                background: "#FC8434",
                color: "white",
                borderRadius: "50%",
                width: "12px",
                height: "12px",
                fontSize: "12px",
                padding: "4px 6px 8px 6px",
                textAlign: "center",
              }}
            >
              {item?.attributes?.is_mark_unread}
            </p>
          )}
        </Box>
      </Box>
    );
  };

  handleDialogHeader = () => {
    if (this.state.selectedMedia?.mimetype !== "application/pdf") {
      return (
        <Box data-test-id="image-modal" style={{ width: "300px", maxHeight: "26rem", margin: '0 auto', display: 'flex', alignItems:'center', justifyContent:'center' }} >
          <img src={this.state.selectedMedia?.url} alt="conversation-image" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        </Box>
      )
    } else {
      return (
        <iframe
          style={{ width: "300px", height: "26rem" }}
          src={this.state.selectedMedia?.url}
        />
      )
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    let item: any = this.state.selectedChatRoom;
    const currentAccountId: any = localStorage.getItem("userId");

    const { t, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={{ background: "#F4F7FF" }} className="admin-chat-box">
          <DashboardHeaderWeb />
          <Box style={{ display: "flex" }}>
            <Grid item xs={3} md={3} sm={3} className="SideBar">
              <GeneralSideBarWeb />
            </Grid>
            <Grid item xs={9} md={9} sm={9}>
              <Container className="admin-chat-container">
                <Grid container>
                  <Grid
                    item
                    justifyContent="space-between"
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <p className="bold-text" style={{ fontWeight: 600, fontSize: "26px" }}>
                      {t("Chat")}
                    </p>
                    <Box display={"flex"} alignItems="center" gridGap={"1rem"}>
                      <p className="bold-text" style={{ fontSize: "16px" }}>
                        {t("Enable Chat")}
                      </p>
                      <FormControlLabel
                        style={{ marginRight: "0px" }}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            enable={this.state.switchVaule}
                            value="start"
                            labelPlacement="start"
                            diableChat={() =>
                              this.setState({
                                showSuccessModal: !this.state.showSuccessModal,
                                switchVaule: !this.state.switchVaule,
                              })
                            }
                          />
                        }
                        label=""
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container style={{ height: "75vh" }}>
                  <Grid item xs={4} md={4} sm={4} style={{ boxShadow: "rgb(0 0 0 / 15%) 9px -3px 8px 0px" }}>
                    <>
                      <Box className="login-wrapper reg-wrapper" style={{ margin: 0, background: "#FFFFFF" }}>
                        <Grid container style={{ padding: "0 1rem" }}>
                          <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box
                              display="flex"
                              alignItems="center"
                              width={this.state.isSearch ? "7%" : "100%"}
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <input
                                autoFocus
                                className="inputbox"
                                placeholder={t("search")}
                                onChange={(e) => this.getInboxBySearch(e.target.value)}
                                style={{
                                  border: "1px solid #F1F1F1",
                                  borderRadius: "10px",
                                  fontSize: "1rem",
                                  height: "50px",
                                  padding: "0.75rem",
                                  marginTop: "1rem",
                                  width: "100%",
                                  outline: "none",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} md={12} style={{ justifyContent: "normal" }}>
                            {this.state.allInbox.length != 0 ? (
                              this.state.allInbox.map((item) => (
                                <>
                                  <Box
                                    key={item}
                                    display="flex"
                                    style={{
                                      gap: "1rem",
                                      maxHeight: "5rem",
                                      padding: "14px 1rem",
                                      cursor: "pointer",
                                      borderBottom: "1px solid #f2f2f2",
                                    }}
                                    className="admin-chat-inbox-box"
                                    onClick={() => {
                                      this.scrollToBottom();
                                      this.openChat2(item);
                                    }}
                                  >
                                    {item?.attributes?.chat_with_account?.id != localStorage.getItem("userId")
                                      ? (
                                        <img
                                          src={
                                            item?.attributes?.chat_with_account?.attributes?.profile_pic?.url ||
                                            NoProfile_Img
                                          }
                                          width="68"
                                          height="50"
                                          style={{ marginLeft: "10px", borderRadius: "50px" }}
                                        />
                                      ) || (
                                        <img
                                          src={NoProfile_Img}
                                          width="68"
                                          height="50"
                                          style={{ marginLeft: "10px", borderRadius: "50px" }}
                                        />
                                      )
                                      : (
                                        <img
                                          src={
                                            item?.attributes?.chatable?.attributes?.profile_pic?.url || NoProfile_Img
                                          }
                                          width="68"
                                          height="50"
                                          style={{ marginLeft: "10px", borderRadius: "50px" }}
                                        />
                                      ) || (
                                        <img
                                          src={NoProfile_Img}
                                          width="68"
                                          height="50"
                                          style={{ marginLeft: "10px", borderRadius: "50px" }}
                                        />
                                      )}
                                    {this.InBoxCard(item)}
                                  </Box>
                                </>
                              ))
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "Center",
                                }}
                              >
                                <Typography className="bold-text" color="textSecondary">
                                  {"No chat"}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </Box>

                      <Loader loading={this.state.loading} />
                    </>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    md={8}
                    sm={8}
                    style={{
                      borderLeft: "1px solid #EFEFEF",
                      boxShadow: "rgb(0 0 0 / 15%) 9px -3px 8px 0px",
                    }}
                  >
                    <Grid item xs={12} md={12} className="auth-cols">
                      <Box display={{ xs: "none", md: "flex" }}>
                        <div
                          style={{
                            padding: "0.3rem",
                            backgroundColor: "#ffff",
                            paddingLeft: "0.3rem",
                            minWidth: "99%",
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: ".8rem",
                                borderBottom: "1px solid #F0F0F0",
                              }}
                            >
                              <ChatRoomSection item={item} />
                            </Grid>

                            <Grid xs={12}>
                              <List
                                ref={(div: any) => {
                                  this.messageList = div;
                                }}
                                style={{
                                  overflowY: "auto",
                                  maxHeight: "60vh",
                                  minHeight: "60vh",
                                  overflowX: "hidden",
                                }}
                              >
                                {this.state.allInboxKey?.length != 0 &&
                                  this.state.allInboxKey?.map((date, i) => (
                                    <>
                                      <Box key={i} display="flex" justifyContent="center" position="relative">
                                        <p
                                          className="oval-shape bold-text"
                                          style={{ fontSize: "11px", padding: "5px 12px" }}
                                        >
                                          {moment.utc(date).format("DD MMM YYYY")}
                                        </p>
                                      </Box>
                                      {this.state.singleChatRoom[date]?.map((message: any, i: any) => (
                                        <>
                                          <ListItem key={i}>
                                            <Grid container>
                                              <MessageSection
                                                handleClick={() => {
                                                  //@ts-ignore
                                                  //@ts-nocheck
                                                  this.setState({ selectedMedia: message.message.images[0] });
                                                }}
                                                message={message}
                                                displaytime2={this.displaytime2}
                                                currentAccountId={currentAccountId}
                                              />
                                            </Grid>
                                          </ListItem>
                                        </>
                                      ))}
                                    </>
                                  ))}
                              </List>
                              {this.SendMessage(t, language)}
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Box>

          <Modal
            className="admin-chat-box-modal"
            open={this.state.selectedMedia}
            style={{
              flexDirection: "column-reverse",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            onClose={() => this.setState({ selectedMedia: null, accept: false })}
          >
            <div>
              {this.handleDialogHeader()}
              {this.state.accept && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    onClick={() =>
                      this.setState({
                        accept: false,
                        selectedMedia: null,
                      })
                    }
                    className="admin-chat-cancel-btn"
                    style={{
                      backgroundColor: "rgb(241, 78, 36)",
                      fontFamily: "Poppins",
                      boxShadow: "none",
                      color: " rgb(247, 247, 252)",
                      border: "1px solid rgb(217, 219, 233)",
                      borderRadius: "16px",
                      height: 35,
                      marginTop: 10,
                      fontSize: 13,
                      marginRight: 10,
                      width: 150,
                    }}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    onClick={() =>
                      this.setState(
                        {
                          accept: false,
                          selectedMedia: null,
                        },
                        () => this.handleSelectFile(this.state.file)
                      )
                    }
                    className="admin-chat-send-btn"
                    style={{
                      backgroundColor: "#ffff",
                      border: "1px solid red",
                      fontFamily: "Poppins",
                      height: 35,
                      boxShadow: "none",
                      color: " red",
                      fontSize: 13,
                      borderRadius: "16px",
                      marginTop: 10,
                      marginRight: 10,
                      width: 150,
                    }}
                  >
                    {t("Send")}
                  </button>
                </div>
              )}
            </div>
          </Modal>

          <Dialog
            className="delete-document delete-document-dialog admin-toggle-chat-modal"
            fullWidth
            maxWidth="sm"
            onClose={() => this.setState({ showSuccessModal: false })}
            open={this.state.showSuccessModal}
          >
            <DialogContent>
              <Box textAlign="center">
                <img src={NoChat} alt="delete" />
                <Typography variant="h6" className="bold-text">
                  {t(this.checkPrime())} {t("Functionality")}?
                </Typography>
                <Typography variant="body1">
                  {t("Are you sure want to")} {t(this.checkPrime())}{" "}
                  {t("functionality? No one will be able to send you any messages while it is disabled.")}
                </Typography>
                <DialogActions className="dialog-button-group">
                  <Button className="cancel-button admin-cancel-toggle-btn" onClick={() => this.setState({ showSuccessModal: false })} style={{ width: "200px" }}>
                    {t("No, Don’t")} {t(this.checkPrime())}
                  </Button>
                  <Button className="add-button admin-add-toggle-btn" onClick={() => this.disablechat()}>
                    {t("Yes")} {t(this.checkPrime())}
                  </Button>
                </DialogActions>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

export default withTranslation()(withRouter(AdminChat));
