// @ts-ignore
// @ts-nocheck

import * as React from "react";
// Customizable Area Start
// custom components
import {Box, Button, Grid} from "@material-ui/core";
import "../assets/css/style.scss";
import EmailAccountRegistrationController, {Props} from "./EmailAccountRegistrationController.web.tsx";
import {withRouter} from 'react-router';
import OtpInput from 'react-otp-input';
import {Back_btn, Building1} from "./assets";
import AlertErrorWeb from "../../../components/src/AlertError.web";
// Customizable Area End

class VerofyOTP extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
// Customizable Area Start
    maskCodeEmail = (email: any) => {
        let str: any = email
        str = str?.split('');
        let finalArr: any = [];
        let len = str?.indexOf('@');
        str?.forEach((item: any, pos: any) => {
            (pos >= 3 && pos <= len - 2) ? finalArr.push('*') : finalArr.push(str[pos]);
        })
        return finalArr.join('');
    }

    render() {
        const user_email = localStorage.getItem('user_email')
        return (

            <>
                <Grid container spacing={2} className="auth-container">
                    <Grid item xs={12} md={7} className="auth-cols"
                          style={{justifyContent: 'unset', paddingLeft: '1rem', paddingRight: '1rem'}}>

                        <Grid container className="main-content-block">
                            <Grid xs={12}>
                                <img src={Back_btn} onClick={() => window.history.back()}
                                     style={{marginTop: '1rem', marginLeft: '0rem'}}/>
                            </Grid>
                        </Grid>


                        <Grid container className="main-content-block" style={{marginLeft: '0.5rem'}}>
                            <Grid xs={12}>
                                <p className="text-left" style={{
                                    fontSize: '1.5rem',
                                    fontWeight: 700,
                                    marginTop: '2rem',
                                    marginBottom: '0.5rem'
                                }}>
                                    Enter OTP Code

                                </p>
                            </Grid>
                        </Grid>
                        <Grid container className="main-content-block" style={{marginLeft: '0.5rem'}}>
                            <Grid xs={12}>
                                <p className="text-left" style={{marginBottom: '1.5rem', marginBottom: '9rem'}}>
                                    Please enter the code sent to the email address
                                    <span className="text">
                                           {"  "} {this.maskCodeEmail(user_email)}
                                      </span>
                                </p>
                            </Grid>
                        </Grid>
                        <Grid container className="main-content-block" style={{marginLeft: '0.5rem'}}>
                            <Grid xs={12} style={{display: 'flex'}} justifyContent="center">
                                <Box className="commonForm" style={{marginTop: '1rem',width:"100%"}}>
                                    <Box className="formGroup otpBlock">
                                        <OtpInput
                                            className="formOutlineInput"
                                            data-test-id='keydown'
                                            value={this.state.otp}
                                            onChange={this.handleChangeOTP}
                                            isInputNum={true}
                                            numInputs={4}
                                            renderInput={(props, index) => (
                                                <input
                                                    {...props}
                                                    onKeyDown={(e) => this.handleKeyDown(e, index)}
                                                    style={{ ...props.style, direction: "ltr" }}
                                                />
                                            )}
                                        />
                                    </Box>

                                    <Box className="passwordRow">
                                        {/* <Link href="#" className="link"> <span>RESEND OTP</span></Link> */}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className="main-content-block" style={{marginTop: 'auto'}}>
                            <Grid xs={12} style={{display: 'flex'}} justifyContent="center">
                                <Box className="customButton row-btn" style={{width: '100%'}}>
                                    <Button variant="contained" onClick={() => this.verifyOtp()}>NEXT</Button>
                                    <Button variant="text" disabled={this.state.isTimer} onClick={() => {
                                        this.resendTimer();
                                        this.resendOTP();
                                    }} style={this.state.isTimer ? {marginTop:"10px",color:"gray"} : {marginTop:"10px",color:"#FC8434"}} fullWidth >Resend OTP {this.state.isTimer &&` in 00:${this.state.OTPTimer}`}</Button>
                                </Box>
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid item xs={12} md={5} className="auth-cols">
                        <Box className="right-block" display={{xs: 'none', md: 'flex'}}>
                            <img src={Building1.default} className="building-logo" alt=""/>
                        </Box>
                    </Grid>
                </Grid>
                <AlertErrorWeb show={this.state.showError}
                               handleClose={() => this.setState({showError: false, error: null})}
                               message={this.state.error}/>


            </>

        )

    }

}

export default withRouter(VerofyOTP)
// Customizable Area End