// Customizable Area Start
import React from "react";
import { StyleSheet, Platform } from "react-native";
import {
  Grid,
  Box,
  Typography,
  Button,
  ListItem,
  Avatar,
  ListItemText,
  List,
  Modal,
  Link,
} from "@material-ui/core";
import { withRouter } from "react-router";
import moment from "moment";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InboxController, { Props } from "./inboxController.web";
import '../assets/css/style.scss';
import { NoProfile_Img } from "../../user-profile-basic/src/assets";
import { Sendbutton } from "./assets";
import GetAppIcon from '@material-ui/icons/GetApp';
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class IncidentChatBox extends InboxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleClick1 = this.handleClick1.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start

  handleClick1(e: any) {
    console.log(e);
    // @ts-ignore
    // @ts-nocheck
    this.refs.fileUploader.click();
  }

  handleFile2(file: any) {
    this.setState({ selectedMedia: { url: URL.createObjectURL(file), mimetype: file.type }, accept: true, file: file },);
  }



  displaytime(time: any) {
    let date = new Date(time);
    let d = date.getHours();
    let m = date.getMinutes();
    let formattedM = m < 10 ? `0${m}` : m;
    return `${d}:${formattedM}`;
  }

  _handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      this.createMessages();

    }
  }

  async componentDidMount() {
    this.getAllChat();
    //@ts-ignore
    //@ts-nocheck
    this.interval = setInterval(() => {
      this.getSingleInbox();
    }, 3000);

  }

  messageList: any;

  scrollToBottom = () => {
    const scrollHeight = this.messageList?.scrollHeight;
    if (scrollHeight) {
      const height = this.messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  handleModalContent = (media: any) => {
    if (media?.mimetype !== "application/pdf") {
      return <Avatar src={media?.url} style={{ width: '300px', height: '26rem', borderRadius: 0 }} />;
    } else {
      return <iframe src={media?.url} style={{ width: '300px', height: '26rem' }} />;
    }
  };

  handleDate = (idx: any, date: any) => {
    if (idx > 1) {
      return moment.utc(date).fromNow();
    } else {
      return moment.utc(date).format('MMM-DD-YYYY');
    }
  };

  handleUserImage = (message: any, currentAccountId: any, item: any) => {
    if (message.message.account_id != currentAccountId) {
      return <img src={item?.attributes?.chat_with_account?.id != localStorage.getItem('userId') ? item?.attributes?.chat_with_account?.attributes?.profile_pic?.url || NoProfile_Img : item?.attributes?.chatable?.attributes?.profile_pic?.url || NoProfile_Img} alt='profile-pic' width='50' height='50' style={{ borderRadius: 20, marginRight: 5 }} />;
    }
  };

  handleAlignment = (left: any, right: any) => {
    return left == right ? "right" : "left";
  };

  handleChatImage = (image: any) => {
    if (image.length != 0) {
      const url = image[0].url;
      if (url.includes(".pdf")) {
        return <Box style={{ display: "flex", alignItems: "center", gap: "20px" }}><p>PDF</p> <Link href={url} target="_blank"><GetAppIcon /></Link></Box>;
      } else {
        return (<Grid item xs={12}>
          <img style={{ 'cursor': 'pointer' }} onClick={() => this.setState({ selectedMedia: image[0] })} src={image[0].url} width="75" height="75" />
        </Grid>);
      }
    }
  };

  handleMessage = (message: any) => {
    if (message.length > 45) {
      return <Typography
        style={{
          color: "#081F32",
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: 14,
          wordBreak: 'break-all'
        }}
        align='left'
      >
        {message}
      </Typography>;
    } else {
      return <Typography
        style={{
          color: "#081F32",
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: 14,
          wordBreak: 'break-all'
        }}
      >
        {message}
      </Typography>;
    }
  };

  render() {
    // @ts-nocheck
    // @ts-ignore
    const item: any = JSON.parse(localStorage.getItem('selectedChat'));
    const currentAccountId: any = localStorage.getItem('userId');
    const { t }: any = this.props;

    return (
      <div style={{ padding: "0.3rem", backgroundColor: "#ffff", paddingLeft: '0.3rem', marginTop: '1rem', overflowY: "hidden" }}>
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box display='flex' alignItems='center' onClick={() => this.redirectToList()}>
              <KeyboardBackspaceIcon />
              <span style={{ fontWeight: 'bold', marginLeft: '1rem' }}>
                {t("Ticket")}
              </span>
            </Box>
          </Grid>
          <Box marginTop={2} display='flex' justifyContent='space-between' width='100%' alignItems="center" style={{ height: "60px", borderTop: "1px solid #f4f4f4", borderBottom: "1px solid #f4f4f4", padding: "1rem" }}>
            <Box>
              <p className={"bold-text"} style={{ fontWeight: 'bold', fontSize: '16px', }}>{item?.attributes?.chatable?.attributes?.incident_title}</p>
              <p style={{ fontWeight: 'bold', fontSize: '12px', color: 'rgba(24,29,37,0.5)' }}>
                {t("Ticket ID")}: {item?.attributes?.chatable?.id}
              </p>
            </Box>
            <Box>
              <Button variant="contained" className="contain danger" style={{ textTransform: 'capitalize' }} >{item?.attributes?.chatable?.attributes?.incident_status}</Button>
            </Box>
          </Box>
          <Grid xs={12}>
            <List style={{ overflowY: "auto", maxHeight: "62vh", minHeight: "62vh", overflowX: 'hidden' }} ref={(div: any) => { this.messageList = div; }}>
              {this.state.allInboxKey?.length != 0 && this.state.allInboxKey?.map((date: any, i: any) => (
                <>
                  <Box key={i} display='flex' justifyContent='center'>
                    <p className="bold-text" style={{ padding: "6px 12px", background: "#f9f9f9", borderRadius: "25px", fontSize: "13px" }}>
                      {this.handleDate(i, date)}
                    </p>
                  </Box>
                  {
                    this.state.singleChatRoom[date]?.map((message: any, i: any) => <>
                      <ListItem key={i}>
                        <Grid container>
                          <Grid item xs={12}
                            style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem' }}
                            //@ts-ignore
                            //@ts-nocheck
                            style={message.message.account_id == currentAccountId ? { 'display': 'flex', 'justifyContent': 'end', alignItems: 'center' } : { 'display': 'flex', 'justifyContent': 'start', alignItems: 'center' }}
                          >
                            {this.handleUserImage(message, currentAccountId, item)}
                            <Box>
                              <Box style={{ background: '#f6f6f6', borderRadius: '6px', padding: '0.5rem', borderTopRightRadius: 0 }}>
                                <Typography
                                  style={{
                                    color: "#081F32",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    marginLeft: 5
                                  }}
                                  align={this.handleAlignment(message.message.account_id, currentAccountId)}
                                >
                                </Typography>
                                {this.handleMessage(message.message.message)}
                                {this.handleChatImage(message?.message?.images)}
                              </Box>
                              <p
                                style={{ textAlign: this.handleAlignment(currentAccountId, message.message.account_id), fontSize: "12px" }}
                              >{this.displaytime(message.message.created_at)}</p>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </>)
                  }
                </>
              ))}
            </List>
            <Grid container className="incident-chat-box-chairman" style={{ padding: "20px", display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: "white" }}>
              <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      this.createMessages();
                    }
                  }}
                  onChange={(e) => this.CreateNewMessage(e)} type="" style={{ border: '1px solid #EDEDED', color: '#726363', borderRadius: 15, padding: 10, width: '100%' }} placeholder="Type your messgae" value={this.state.newMessage} />
                {// @ts-ignore
                  // @ts-nocheck
                  <AttachFileIcon onClick={this.handleClick1} for="BtnBrowseHidden" style={{ cursor: 'pointer' }} />}
                <input
                  id="BtnBrowseHidden"
                  type="file"
                  onChange={(e: any) =>
                    this.handleFile2(
                      e.target.files[0]
                    )
                  }
                  style={{
                    position: "absolute",
                    outline: "none",
                    height: "10px",
                    width: "10px",
                    zIndex: 2,
                    cursor: "pointer",
                    opacity: 0
                  }}
                  ref="fileUploader"
                  accept="image/png, image/jpeg, image/jpg,.pdf"
                />
              </Grid>

              <img src={Sendbutton} style={{ cursor: 'pointer', width: '2.5rem' }} onClick={() => { this.createMessages(); this.scrollToBottom(); }} />
            </Grid>
          </Grid>
        </Grid>

        <Modal
          open={this.state.selectedMedia}
          style={{ display: 'flex', alignItems: 'center', flexDirection: 'column-reverse', justifyContent: 'center' }}
          onClose={() => this.setState({ selectedMedia: null, accept: false })}
          aria-labelledby="alert-Modal-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            {this.handleModalContent(this.state.selectedMedia)}
            {
              this.state.accept &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={() => this.setState({ selectedMedia: null, accept: false })} style={{
                  backgroundColor: 'rgb(241, 78, 36)',
                  border: '1px solid rgb(217, 219, 233)',
                  borderRadius: '16px',
                  height: 35,
                  boxShadow: 'none',
                  color: ' rgb(247, 247, 252)',
                  fontFamily: 'Poppins',
                  fontSize: 13,
                  marginTop: 10,
                  marginRight: 10,
                  width: 150
                }}>
                  {t("Cancel")}
                </button>
                <button onClick={() => this.setState({ selectedMedia: null, accept: false }, () => this.handleSelectFile(this.state.file))} style={{
                  backgroundColor: '#ffff',
                  border: '1px solid red',
                  borderRadius: '16px',
                  height: 35,
                  boxShadow: 'none',
                  color: ' red',
                  fontFamily: 'Poppins',
                  fontSize: 13,
                  marginTop: 10,
                  marginRight: 10,
                  width: 150
                }}>
                  {t("Send")}
                </button>
              </div>
            }
          </div>
        </Modal>
      </div>
    );
  }
  // Customizable Area End

}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  title: {
    marginBottom: 2,
    fontSize: 14,
    textAlign: "left",
    marginTop: 16
  },

  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgDummyPassword: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    opacity: 0.4,
    fontWeight: "bold",
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 0,
    padding: 12,
    marginTop: 0
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    marginTop: 0,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  helperText: { marginTop: 10 }
});
export default withTranslation()(withRouter(IncidentChatBox));
// Customizable Area End
