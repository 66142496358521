import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    t: (value: string) => string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectIncident: string;
    cooOpenTicketData: Array<CooOpenTicketItem>;
    userRole: string | null;
    complex: string;
    complexName: string;
    building: string;
    category: string;
    factor: string;
    year: string;
    month: string;
    sortBy: string;
    searchText: string;
    openBuildingMap:boolean;
    token:string;
    latitude:number,
    longitude:number,
    complexList:Array<Society>
    buildingList:Array<Society>
    categoryList:Array<Category>
    yearList:Array<number>
    monthList:Array<number>
    sortList:Array<string>
    loader: boolean;
    paginationData: PaginationData | null;
    // Customizable Area End
}
// Customizable Area Start

export interface Category {
  id: number,
  name: string,
  created_at:string ,
  updated_at:string ,
  name_ar: string
}
export interface CooOpenTicketItem {
    id: string;
    type: string;
    attributes: {
        name: string;
        building_name: string;
        total_open_tickets: number;
        tickets_open_7_days: number;
        tickets_open_30_days: number;
        top_3_category: Category[];
        latitude: number;
        longitude: number;
    };
}

export interface Society {
    id: number;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
    complex_area: string;
    maintenance_per_square_feet: number | null;
    measurement_unit: string;
    ticket_days_configuration: number;
    contigency: number | null;
    zone_multiplier: number;
    late_charge_percentage: number | null;
    latitude: number | null;
    longitude: number | null;
    value_added_tax: string;
    is_building: boolean;
    real_estate_company_id: number | null;
    total_floor: number;
    total_unit: number;
    currency_id: number;
    account_manager_id: number | null;
    language: string | null;
    status: string;
}

export interface PaginationData {
  current_page: null | number;
  next_page: null | number;
  prev_page: null | number;
  total_pages: null | number;
  total_count: null | number;
}

export interface CooOpenTicketItemResponse {
  complex: { data: Array<CooOpenTicketItem> };
  meta: {
      pagination: PaginationData | null
  }
}



export type TFunction = (value: string) => string
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class OpenTicketsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    totalOpenincidentsAPiId: string = "";
    totalComplexAPiId:string="";
    totalBuildingId:string="";
    totalCategoryAPiId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.NavigationMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            selectIncident: "",
            complex: "",
            building: "",
            category: "",
            factor: "",
            year: "",
            month: "",
            sortBy: "",
            token:"",
            searchText: "",
            openBuildingMap:false,
            latitude:0,
            longitude:0,
            cooOpenTicketData:[],
            userRole: null,
            complexList:[],
            buildingList:[],
            categoryList:[],
            yearList:[],
            monthList:[1,2,3,4,5,6,7,8,9,10,11,12],
            sortList:["7_days","30_days"],
            complexName: "",
            loader: false,
            paginationData: {
              current_page: 0,
              next_page: 0,
              prev_page: 0,
              total_pages: 0,
              total_count: 0,
            },
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
      
            runEngine.debugLog("API Message Recived", message);
      
            this.responseOpenincidentsData(apiRequestCallId,responseJson)
            this.responseComplexData(apiRequestCallId,responseJson)
             this.responseBuildingData(apiRequestCallId,responseJson)
             this.responsecategoryData(apiRequestCallId,responseJson)
         
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getUserType();
        const token= await getStorageData("loginSuccessToken")
        this.setState({
            token:token
        })
        this.handleTotalincidents(token, "1")
        this.handleTotalComplex(token)
        this.handleTotalCategory(token)
        this.generateYearArray()

    };

    responseOpenincidentsData=(apiRequestCallId:string,responseJson:  CooOpenTicketItemResponse )=>{
      if (apiRequestCallId === this.totalOpenincidentsAPiId) {
        if(responseJson.complex.data){
            this.setState({
                cooOpenTicketData:responseJson.complex.data,
                loader: false,
                paginationData: responseJson.meta.pagination
            })
        }
        else{
            toast.error("Someting went wrong !!!")
        }
    }
    }

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
      this.handleTotalincidents(this.state.token, String(value));
  };

    responseComplexData=(apiRequestCallId:string,responseJson: { societies: Array<Society>; })=>{
      if (apiRequestCallId === this.totalComplexAPiId) {
        if(responseJson.societies){
           this.setState({
            complexList:responseJson.societies
           })
        }
        else{
            toast.error("Someting went wrong !!!")
        }
    }
    }

    responseBuildingData=(apiRequestCallId:string,responseJson: { buildings: Array<Society>; })=>{
      if (apiRequestCallId === this.totalBuildingId) {
        if(responseJson.buildings){
           this.setState({
            buildingList:responseJson.buildings
           })
        }
        else{
            toast.error("Someting went wrong !!!")
        }
    }
    }

    responsecategoryData=(apiRequestCallId:string,responseJson: { data: { incident_relateds: Array<Category>; }; })=>{
      if (apiRequestCallId === this.totalCategoryAPiId) {
        if(responseJson){
           this.setState({
            categoryList:responseJson.data.incident_relateds
           })
        }
        else{
            toast.error("Someting went wrong !!!")
        }
    }
    }

    generateYearArray = () => {
      const currentYear = new Date().getFullYear();
      let years = Array.from({ length: currentYear - 2000 + 1 }, () => currentYear);
      years = years.map((year, index) => year - index);
      this.setState({ yearList: years });
  }; 

    getUserType = async () => {
        const storedRole = await localStorage.getItem('role');
        this.setState({ userRole: storedRole });
    };

    handleComplexChange = (event: React.ChangeEvent<{ value: Society|unknown }>) => {
        this.setState({ complex: event.target.value as string, 
          complexName: this.state.complexList.find(each => each.id === event.target.value)?.name || "" },()=>{
            this.handleTotalBuliding(this.state.complex,this.state.token)
        });
    };

    handleBuildingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ building: event.target.value as string });
    };

    handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ category: event.target.value as string })
    };

    handleFactorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ factor: event.target.value as string });
    };

    handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ year: event.target.value as string });
    };

    handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ month: event.target.value as string });
    };

    handleSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ sortBy: event.target.value as string });
    };

    handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value },()=>{
            this.handleTotalincidents(this.state.token, "1")
        });
    };

    handleSearchApi=()=>{
      this.handleTotalincidents(this.state.token, "1")
    }

    handleTotalincidents = (token: string, page: string) => {
      this.setState({loader:true});
        const header = {
          "Content-Type": "application/json",
          "Token": token
        };
       const object=Object.create({})
       object.search=this.state.searchText
       object.complex=this.state.complex
       object.building=this.state.building
       object.category=this.state.category
       object.year=this.state.year
       object.month=this.state.month
       object.sort_type=this.state.sortBy

       const url=new URLSearchParams(object)

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.totalOpenincidentsAPiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.totalOpenincidentsEndPoint+`page=${page}&per_page=10&${url}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      handleNavigationdashboard = () => {
        const msgData: Message = new Message(
          getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "DashboardCompanyEmployee");
        this.send(msgData);
      };
      handleBuildingMap = (cooOpenTicketItem:CooOpenTicketItem) => {
        this.setState({
          openBuildingMap: !this.state.openBuildingMap,
          latitude:cooOpenTicketItem&&cooOpenTicketItem.attributes&&cooOpenTicketItem.attributes.latitude,
          longitude:cooOpenTicketItem&&cooOpenTicketItem.attributes&&cooOpenTicketItem.attributes.longitude
        });
      };
    
      handleCloseBuildingMap = () => {
        this.setState({ openBuildingMap: !this.state.openBuildingMap });
      };
      handleTotalComplex = (token: string) => {
        const header = {
          "Content-Type": "application/json",
          "Token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.totalComplexAPiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.complexList
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      handleTotalBuliding = (id:string,token: string) => {
        const header = {
          "Content-Type": "application/json",
          "Token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.totalBuildingId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.bulidingList +`${id}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      handleTotalCategory = (token: string) => {
        const header = {
          "Content-Type": "application/json",
          "Token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.totalCategoryAPiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.categoryListAPi
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      handleClearFiltersClick = async () => {
        this.setState({complex:"", building:"", category:"", factor:"", month:"", year:"", sortBy:""}, () => {
          this.handleTotalincidents(this.state.token, "1");
        })
      }
    // Customizable Area End
}
