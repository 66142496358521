//Customizable Area Start
import React, { CSSProperties, ChangeEvent } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Modal,
  TextField,
  Fade,
  Backdrop,
  styled,
  Grid,
  Select,
  MenuItem,
  TextareaAutosize
} from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { withTranslation } from "react-i18next";
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from "moment";
import COOSubscriptionDetailsController from "./COOSubscriptionDetailsController";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import {crossImg, imgDeactivateImg, imgDeactivateImgwait} from "./assets"
export const configJSON = require("./config");

class COOSubscriptionDetails extends COOSubscriptionDetailsController {

  extendPaidRaio = (condation:string,status:string,firstLabel:string,secondLabel:string) => {
    return condation === status? <TypographyBold style={{ ...webStyle.radionLabel }}>{firstLabel}</TypographyBold> : <Typography style={{ ...webStyle.radionLabel }}>{secondLabel}</Typography>
  }
   showStyleBtn = (isDisabled:any) => {
    return isDisabled ? webStyle.disableMainBtn : webStyle.mainBtn
  }
  titleHead=(data:any)=>{
     switch(data){
      case "Real Estate Company":{
        return "Property Developer"
      }
      case "Owner":{
        return "Owner Name"
      }
      case "General Assembly":{
        return "General Assembly"
      }
      case "Property Manager":{
        return "Property Manager"
      }
      default:
        break
     }
  }
  freezeReason = (name: string, t: any) => {
    return (
      <>
        {
          (name === "deactivated" || name === "frozen") && (
            <Card className="card" style={{ margin: "20px 0 20px", padding: "20px" }}>
              <h4 className="bold-text" style={{ color: "red" }}>
                {name === "deactivated" ? t("Deactivation Reason") : t("Reason for freeze")}
              </h4>
              <p style={{ marginTop: "10px" }}>
                {this.state.subscriptionDetailsData &&
                  this.state.subscriptionDetailsData.attributes &&
                  this.state.subscriptionDetailsData.attributes.reason
                  ? this.state.subscriptionDetailsData.attributes.reason
                  : "-"}
              </p>
            </Card>
          )
        }
      </>
    )
  }

  rejectReason = (t: any) => {
    return (
      <>
        {
           this.state.subscriptionDetailsData &&
           this.state.subscriptionDetailsData.attributes &&
           this.state.subscriptionDetailsData.attributes.reject_reason&&	
            (
            <Card className="card" style={{ margin: "20px 0 20px", padding: "20px" }}>
              <h4 className="bold-text" style={{ color: "red" }}>
                {t("Rejected Reason") }
              </h4>
              <p style={{ marginTop: "10px" }}>
                {this.state.subscriptionDetailsData &&
                  this.state.subscriptionDetailsData.attributes &&
                  this.state.subscriptionDetailsData.attributes.reject_reason	
                  ? this.state.subscriptionDetailsData.attributes.reject_reason	
                  : "-"}
              </p>
            </Card>
          )
        }
      </>
    )
  }
  handleExtendPlanModal=(t:any)=>{
    return(
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={this.state.extendPlanOpenModel}
      onClose={this.extendPlanModelClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade
       in={this.state.extendPlanOpenModel}
      >
        <Box sx={{ ...webStyle.configureModel, ...webStyle.extandModelBox }}>
          <Box sx={{...webStyle.configureBody}}>
          <Box>
          <TypographyBold >Extend Subscription Duration</TypographyBold>
          {this.state.accountType!=="coo"&&<Typography style={webStyle.extendSubtitle}>The request will be submitted to the COO for his review and approval</Typography>}
              <Grid container style={webStyle.extendgrid}>
                <Grid item sm={4} >
                   <Typography style={{ ...webStyle.extendssubtitles }}>Subscription Expiration Date:</Typography>
                  <TypographyBold sx={{ ...webStyle.extandBoxDiscription }}>{this.formateDate(this.state.subscriptionDetailsData?.attributes?.expiry_date)}</TypographyBold>
                </Grid>
                <Grid item sm={5}>
                  <Typography style={{ ...webStyle.extendssubtitles }}>Subscription Status:</Typography>
                  <TypographyBold sx={{ ...webStyle.extandBoxDiscription }}>{this.state.subscriptionDetailsData?.attributes?.plan?.data?.attributes?.title} version <span style={webStyle.companyTag} className="bold-text">{this.state.subscriptionDetailsData?.attributes?.status}</span></TypographyBold>
                </Grid>
                <Grid item sm={3}>
                   <Typography style={{ ...webStyle.extendssubtitles }}>Days to expiration:</Typography>
                  <TypographyBold sx={{ ...webStyle.extandBoxDiscription }}>{this.state.subscriptionDetailsData?.attributes?.ends_in_days==="Expired"?"Expired":<>{this.state.subscriptionDetailsData?.attributes?.ends_in_days}&nbsp;Days</>}</TypographyBold>
                </Grid>
              </Grid>
              <Box>
              <Typography style={{ ...webStyle.extendssubFieldtitles }}>Extend Duration to</Typography>
                <Grid container >
                  <TextField
                    label="Select Date"
                    variant="outlined"
                    placeholder="Select Date"
                    style={{...webStyle.extendValue}}
                    type={this.state.startDateType}
                    name="startDate"
                    fullWidth
                    id="nominationDate"
                    data-testid="deactiveDate"
                    className="startDate-value"
                    onFocus={this.handleStartDate}
                    value={this.state.selectedDate}
                    onChange={(e) => this.setFieldValue( e.target.value)}
                    inputProps={{
                      min: moment().add(this.state.subscriptionDetailsData?.attributes?.ends_in_days,"days").format("YYYY-MM-DD"),
                      max: "5000-05-31",
                    }}
                  />
                  <Box style={webStyle.errorMsg}>{this.state.extendError}</Box>
                </Grid>
                <Box>
                  <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  
                    <RadioController value="paid" checked={this.state.extendRadio==='paid'}  data-testid="extendCheckPaid" onChange={()=>{
                      this.changeExtendDuration('paid')
                    }}  control={<Radio color="primary"  icon={<RadioButtonUncheckedIcon style={{ ...webStyle.radioUncheckIcon }} />}
                    checkedIcon={<CheckCircleRoundedIcon style={{  ...webStyle.radionCheckIcon  }} />} />} label={this.extendPaidRaio(this.state.extendRadio,'paid','Paid Extension','Paid Extension')} />
                  
                    <RadioController value="free" data-testid="extendCheckPaidfree" checked={this.state.extendRadio==='free'}style={this.state.extendRadio === 'free' ? webStyle.extendssubRadiotitlesCheck : webStyle.extendssubRadiotitles} onChange={()=>{
                      this.changeExtendDuration('free')
                    }}control={<Radio color="primary"  icon={<RadioButtonUncheckedIcon style={{ ...webStyle.radioUncheckIcon }} />}
                    checkedIcon={<CheckCircleRoundedIcon style={{ ...webStyle.radionCheckIcon }} />}/>}label={this.extendPaidRaio(this.state.extendRadio,'free','Free Extension','Free Extension')}/>
                  
                  </RadioGroup>
                </Box>
                {this.state.extendRadio==='paid'&&
                <>
                <Typography style={{ ...webStyle.extendssubFieldtitles }}>Payment Reference Number</Typography>
                <TextField
                  label="Payment Reference Number"
                  variant="outlined"
                   onChange={(e) => {
                    this.changePRNExtendData(e.target.value);
                  }}
                  value={this.state.extendPRNValue}
                  style={{
                    ...webStyle.configureInputStyle,
                  }}
                  data-testid='Paymentchangeconfigurevalue'
                />
                  <Box style={webStyle.errorMsg}>{this.state.extendPRNError}</Box>
                  </>
                  }
              </Box>
          </Box>
          <Box sx={{ ...webStyle.extendsModelButton }}>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={webStyle.mainBtn}
                onClick={this.extendPlanModelClose}
              >
                {t("CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={webStyle.mainBtnActive}
                onClick={this.extendPlanConform}
                data-testid="extendPlanePlan"
              >
                {t("SUBMIT")}
              </Button>
            </div>
          </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
    )
  }
  handleDeactiveModal=(t:any)=>{
    return(
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modalStyle"
      open={this.state.deactivatePlan}
      onClose={this.deactivatePlanModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={this.state.deactivatePlan}>
          <Box sx={{ ...webStyle.configureModeldeactive, ...webStyle.modelFixWidth, minWidth: "350px" }}>
          <Box sx={{...webStyle.configureBody}}>
            <Box>
              <Typography style={webStyle.modelChekIcon}>{this.state.accountType==="coo"?<img src={imgDeactivateImg}/>:<img src={imgDeactivateImgwait} />}</Typography>
              <TypographyBold style={webStyle.modelTitle}>{this.state.accountType==="coo"?this.deactivateModelTitleCoo():this.deactivateModelTitle()}</TypographyBold>
              <Typography style={webStyle.configureSubtitle}>{this.state.accountType==="coo"?this.deactivateModelDiscriptionCoo():this.deactivateModelDiscription()}</Typography>
            </Box>
            <Box sx={{ ...webStyle.configureModelButton,justifyContent:"center"}}>
            {this.state.confirmDeactivatePlan?
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnActivedeactiveok}
                  onClick={this.deactivatePlanModel}
                >
                  {t("OKAY")}
                </Button>
             :  <>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnupgrade}
                  onClick={this.deactivatePlanModel}
                >
                  {t("CLOSE")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnActiveupgarde}
                onClick={this.deactiveReasonModal}
                data-testid="confirmDeactivatePlan"
                >
                  {t("CONFIRM")}
                </Button>
              </>
              }
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
    )
  }
  handledeActivejustify=(t:any)=>{
    return(
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modalStyle"
      open={this.state.deactiveReasonModal}
      onClose={this.deactiveReasonModalclose}
      data-testid="deactiveReasonModalclose"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      
    >
      <Fade
       in={this.state.deactiveReasonModal}
      >
        <Box sx={{...webStyle.configuredecativeModelsubmit,maxWidth: {sm:'50%',md:'50%'}}}>
          <Box sx={{...webStyle.justifydeactiveconfigureBody}}>
            <Box sx={{...webStyle.freezeSubmitheadingdeactive}}>
              <TypographyBold style={webStyle.modelTitle}>{configJSON.JustifyDeactiveText}</TypographyBold>
              <Typography style={webStyle.modelChekIcon} onClick={this.freezCloseSubmitmodel}><img src={crossImg} /></Typography>
            </Box>
            <Box sx={{...webStyle.horizontalLine}}></Box>
            <Box sx={{...webStyle.freezeSubmitheading}}>
              <TextareaAutosize onChange={(event:ChangeEvent<HTMLTextAreaElement>)=>{this.addReasondeactive(event)}} style={{...webStyle.freezeSubmitextarea}} aria-label="minimum height" minRows={5} placeholder={configJSON.AddNotes} />
            </Box>
            <Box sx={{ ...webStyle.configureModelsubmitButtondeactive}}>
           <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtn}
                  onClick={this.deactiveReasonModalclose}
                >
                  {t(configJSON.Cancel)}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnActive}
                onClick={this.confirmDeactivatePlan}
                data-testid="confirmDeactivatePlan"
                >
                  {t(configJSON.Submit)}
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
    )

  }
  handleRealEstate=(t:any)=>{
    if(this.state.userRoleVal === 'Real Estate Company'){
    return (
    <div>
      
    <Box style={webStyle.boxStyle}>
      <Typography style={webStyle.labelTxt}>{("Total Complexes")}: </Typography>
      <h4 className="bold-text">{this.state.subscriptionDetailsData?.attributes?.total_complexes || 0}</h4>
    </Box>
    <Box style={webStyle.boxStyle}>
      <Typography style={webStyle.labelTxt}>{("Total Buildings")}: </Typography>
      <h4 className="bold-text">{this.state.subscriptionDetailsData?.attributes?.total_buildings || 0}</h4>
    </Box>
    </div>
    )}}
  handleComplexBuilding=(t:any)=>{
    if(this.state.userRoleVal === 'General Assembly') {
    return (
     <div>
        <Box style={webStyle.boxStyle}>
          <Typography style={webStyle.labelTxt}>{t("Complex")}: </Typography>
            <h4 className="bold-text">{this.state.subscriptionDetailsData?.attributes?.complex_name?.data?.attributes?.name || "NA"}</h4>
        </Box>
        <Box style={webStyle.boxStyle}>
           <Typography style={webStyle.labelTxt}>{t("Building")}: </Typography>
            <h4 className="bold-text">{this.state.subscriptionDetailsData?.attributes?.total_buildings || "NA"}</h4>
        </Box>
      </div>
    
  )}}
  handleConformextendModal=(t:any)=>{
    return(
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modalStyle"
      open={this.state.conformExtendplan}
      onClose={this.extendPlanConformClose}
      data-testid="conformExtendplan"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade
       in={this.state.conformExtendplan}
      >
        <Box sx={{...webStyle.configureModelextend,maxWidth: {sm:'50%',md:'50%'}}}>
          <Box sx={{...webStyle.configureBody}}>
            <Box>
              <Typography style={webStyle.modelCheckIcon}><img src={imgDeactivateImg} /></Typography>
              <TypographyBold style={webStyle.modelTitleextend}>{configJSON.extendhead}</TypographyBold>
              <Typography style={webStyle.configureSubtitleextend}>{configJSON.extendtext}</Typography>
            </Box>
            <Box sx={{ ...webStyle.configureModelButtonextend}}>
           <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnextend}
                  onClick={this.extendPlanConformClose}
                >
                  {t("CLOSE")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnActiveextend}
                onClick={this.extendPlaneSubmit}
                data-testid="extendPlaneSubmit"
                >
                  {t("CONFIRM")}
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
    )
  }
  handleConformupgradeModal=(t:any)=>{
    return(
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modalStyle"
      open={this.state.conformupgardeplan}
      onClose={this.upgradePlanConformClose}
      data-testid="conformExtendplan"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade
       in={this.state.conformupgardeplan}
      >
        <Box sx={{...webStyle.configureModelupgrade,maxWidth: {sm:'50%',md:'50%'}}}>
          <Box sx={{...webStyle.configureBody}}>
            <Box>
              <Typography style={webStyle.modelChekIcon}><img src={imgDeactivateImg} /></Typography>
              <TypographyBold style={webStyle.modelTitle}>{configJSON.upgardehead}</TypographyBold>
              <Typography style={webStyle.configureSubtitle}>{configJSON.upgardetext}</Typography>
            </Box>
            <Box sx={{ ...webStyle.configureModelButton}}>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnupgrade}
                  onClick={this.upgradePlanConformClose}
                >
                  {t("CLOSE")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnActiveupgarde}
                onClick={this.upgradePlaneSubmitModel}
                data-testid="upgradePlaneSubmitModel"
                >
                  {t("CONFIRM")}
                </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
    )
  }
  handleSucessfullextendModal=(t:any)=>{
    return(
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modalStyle"
      open={this.state.extendSucessfullState}
      onClose={this.extendPlanModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={this.state.extendSucessfullState}>
        <Box sx={{...webStyle.configureModelsucess,...webStyle.modelFixWidth}}>
          <Box sx={{...webStyle.configureBodyextend}}>
            <Box>
              <Typography style={webStyle.modelChekIcon}>{this.state.accountType==="coo"?<img src={imgDeactivateImg}/>:<img src={imgDeactivateImgwait} />}</Typography>
              <TypographyBold style={webStyle.modelTitle}>{this.state.accountType==="coo"?configJSON.planExtendedModelCoo:configJSON.planExtendedModel}</TypographyBold>
              <Typography style={webStyle.configureSubtitle}>{this.state.accountType==="coo"?configJSON.planExtendedDiscCoo:configJSON.planExtendedDisc}</Typography>
            </Box>
            <Box sx={{ ...webStyle.configureModelButton,justifyContent:"center"}}>
            <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnActiveok}
                  onClick={this.extendPlanModel}
                >
                  {t("OKAY")}
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
    )
  }

  handleSucessfullupgradeModal=(t:any)=>{
    return(
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modalStyle"
      open={this.state.upgradesucessfullState}
      onClose={this.upgradePlanModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={this.state.upgradesucessfullState}>
        <Box sx={{...webStyle.configureModelsucess,...webStyle.modelFixWidth}}>
          <Box sx={{...webStyle.configureBody}}>
            <Box>
              <Typography style={webStyle.modelChekIcon}>{this.state.accountType==="coo"?<img src={imgDeactivateImg}/>:<img src={imgDeactivateImgwait} />}</Typography>
              <TypographyBold style={webStyle.modelTitle}>{this.state.accountType==="coo"?configJSON.planUpgradeDiscCoo:configJSON.planUpgradeDisc}</TypographyBold>
              <Typography style={webStyle.configureSubtitle}>{this.state.accountType==="coo"?configJSON.planUpgradeModelCoo:configJSON.planUpgradeModel}</Typography>
            </Box>
            <Box sx={{ ...webStyle.configureModelButton,justifyContent:"center"}}>
            <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.mainBtnActiveok}
                  onClick={this.upgradePlanModel}
                >
                  {t("OKAY")}
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
    )
  }
  roleChangefun=(t:any)=>{
    return(
      <>
      {this.state.accountType==="coo" &&
      <Box style={webStyle.boxStyle}>
        <Typography style={webStyle.labelTxt}>{t("Sales Manager")}: </Typography>
        <h4 className="bold-text">{this.state.subscriptionDetailsData && this.state.subscriptionDetailsData.attributes.sales_manager?this.state.subscriptionDetailsData.attributes.sales_manager:"NA"}</h4>
      </Box>
       }
       {this.state.accountType==="coo" && 
      <Box style={webStyle.boxStyle}>
        <Typography style={webStyle.labelTxt}>{t("Account Manager")}: </Typography>
        <h4 className="bold-text">{this.state.subscriptionDetailsData && this.state.subscriptionDetailsData.attributes.account_manager?this.state.subscriptionDetailsData.attributes.account_manager:"NA"	}</h4>
      </Box>
        }
         {this.state.accountType!=="coo" && 
      <Box style={webStyle.boxStyle}>
        <Typography style={webStyle.labelTxt}  data-test-id="titlePlane">{this.titleHead(this.state.userRoleVal)}: </Typography>
        <h4 className="bold-text">{this.handletitleHead()}</h4>
      </Box>
        }
        </>
    )
  }
  render() {
    const { t }: any = this.props;
    const statusMap:any = {
      deactive: "deactivated",
      freeze: "frozen",
      upgrade_request: "Upgrade Request",
      freeze_request: "Freeze Request",
      extend_request: "Extend Request",
      deactive_request: "Deactive Request",
    };
    
    const status = this.state.subscriptionDetailsData?.attributes?.status;
    const name = statusMap[status] || status;
   
  
    return (
      <>
        <MainScreen>
          <Box style={{ justifyContent: 'space-between' }}>
            <Box maxWidth='xl' style={webStyle.detailsBox} >
              <Box>
                <Typography variant="body1">
                  <Link
                    to={"/CompanyEmployee/SubsciptionListing"}
                    color="inherit"
                  >
                    {t("Manage Subscription")}
                  </Link>{" "}
                  /
                    <Box component="span" style={{ color: "#2c6fed" }}>
                      {t("Subscription Details")}
                    </Box>
                </Typography>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
                <div style={{display:"flex"}}>
                <Typography
                  variant="h5"
                  className="bold-text"
                  style={webStyle.subHeading}
                >
                 {`${this.titleHead(this.state.userRoleVal)} : ${this.handletitleHead()}`} 
                 
                </Typography>
                </div>
                {
                  this.state.accountType === "account_manager" ?
                    <Box style={webStyle.btnBoxStyle}>
                      <Button style={webStyle.registerBtn} ><span className="bold-text"  data-testid="register_btn">{t("Usage Statistical Report")}</span></Button>
                    </Box> :
                    <Box style={webStyle.btnBoxStyle}>
                      <Button style={webStyle.configBtn} ><span className="bold-text" onClick={() => this.handleGoToStatisticalReport()}  data-testid="register_btn">{t("Usage Statistical Report")}</span></Button>
                      <Button style={webStyle.registerBtn} ><span className="bold-text" data-testid="subscription_btn" onClick={this.handleSubscriptionreport}>{t("Subscription Report")}</span></Button>
                    </Box>
                }
              </Box>
              <Box >
                <Card className="incident-detail-card card">
                  <Box className="card-header" style={{ display: "flex", }}>
                    <Box style={{ display: "flex", alignItems: 'center' }}>

                      <Typography component="h3" className="bold-text" style={{ fontSize: "22px" }}>
                        {this.state.subscriptionDetailsData.attributes?.plan.data?.attributes?.title}
                      </Typography>
                      <Button style={webStyle.configPlan} data-testid="plan-model" onClick={()=>{this.managepropertyDeveloperModel()}} ><span className="bold-text" data-testid="register_btn">{t("View Plan")}</span></Button>

                    </Box>
                   
                    {
                      
                      <span style={webStyle[this.state.subscriptionDetailsData?.attributes?.status]} className="bold-text">{name}</span>
                    }
                  </Box>
                  <CardContent className="card-content">
                    <Box className="row-block">
                      {this.roleChangefun(t)}
                      {this.handleRealEstate(t)}
                      {this.handleComplexBuilding(t)}
                      <Box style={webStyle.boxStyle}>
                        <Typography style={webStyle.labelTxt}>{t("Total Units")}: </Typography>
                        <h4 className="bold-text">{this.state.subscriptionDetailsData.attributes?.total_units + " Units"}</h4>
                      </Box>
                      <Box style={webStyle.boxStyle}>
                        <Typography style={webStyle.labelTxt}>{t("Ends In")}: </Typography>
                        <h4 className="bold-text">{this.state.subscriptionDetailsData?.attributes?.ends_in_days==="Expired"?"Expired":<>{this.state.subscriptionDetailsData?.attributes?.ends_in_days}&nbsp;Days</>}</h4>
                        
                      </Box>
                      <Box style={webStyle.boxStyle}>
                        <Typography style={webStyle.labelTxt}>{t("Ends On")}: </Typography>
                        <h4 className="bold-text">{this.state.subscriptionDetailsData.attributes?.expiry_date ? this.formatDate(this.state.subscriptionDetailsData.attributes.expiry_date) : ""}</h4>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              {this.freezeReason(name,t)}
              {this.rejectReason(t)}
            </Box>
            {
            name !== "deactivated" &&
            
            <Box style={webStyle.groupsbtn}>
              <Button
                variant="contained"
                color="primary"
               
                style={this.showStyleBtn(this.isRequestStatus(status))}
                onClick={()=>{this.freezUnFreezModel()}}
                disabled={this.isRequestStatus(status)}
                data-testid="freezeUnfreez"
              >
                {this.state.freezSubscription?t("Unfreeze Subscription"):t('FREEZE SUBSCRIPTION')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={this.showStyleBtn(this.isRequestStatus(status))}
                onClick={()=>{this.deactivatePlanModel()}}
                disabled={this.isRequestStatus(status)}
                data-testid="deactiveBtn"
              >
                {t("Deactivate Plan")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={this.showStyleBtn(this.isRequestStatus(status))}
                disabled={this.isRequestStatus(status)}
                onClick={()=>{this.extendPlanModelOpen()}}
                data-testid="extendBtn"
              >
                {t("Extend Plan")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={this.showStyleBtn(this.isRequestStatus(this.state.subscriptionDetailsData?.attributes?.status))}
                disabled={this.isRequestStatus(this.state.subscriptionDetailsData?.attributes?.status)||this.state.subscriptionPlans.length ===0}
                onClick={()=>{this.upgradePlanModelOpen()}}
                data-testid="upgradeBtn"
              >
                {t("Upgrade Plan")}
              </Button>
            </Box>
            }
          </Box>
         
          {this.handleDeactiveModal(t)}
          {this.handledeActivejustify(t)}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalStyle"
            open={this.state.openFreezUnfreezModel}
            onClose={this.freezUnFreezModel}
            data-testid="freezeUnfreezNew"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade
             in={this.state.openFreezUnfreezModel}
            >
              <Box sx={{...webStyle.configureModelfreeze,maxWidth: {sm:'30%',md:'30%'}}}>
                <Box sx={{...webStyle.configureBody}}>
                  <Box>
                    <Typography style={webStyle.modelChekIcon}><img src={imgDeactivateImg} /></Typography>
                    <TypographyBold style={webStyle.modelTitle}>{this.freezUnfreezTitle()}</TypographyBold>
                    <Typography style={webStyle.configureSubtitle}>{this.freezUnfreezDiscription()}</Typography>
                  </Box>
                  <Box sx={{ ...webStyle.configureModelButton}}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={webStyle.mainBtnupgrade}
                        onClick={this.freezUnFreezModel}
                      >
                        {t("CLOSE")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={webStyle.mainBtnActiveupgarde}
                      onClick={this.freezUnfreezPlanModel}
                      data-testid="freezUnfreezPlanModel"
                      >
                        {t("CONFIRM")}
                      </Button>
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Modal>
        {this.handleExtendPlanModal(t)}
          {this.handleConformextendModal(t)}
          {this.handleSucessfullextendModal(t)}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal"
            open={this.state.upgradePlanOpenModel}
            onClose={this.upgradePlanModelClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade
             in={this.state.upgradePlanOpenModel}
            >
              <Box sx={{ ...webStyle.configureModel, ...webStyle.extandModelBox }}>
                <Box sx={{...webStyle.configureBody}}>
                <Box>
                <TypographyBold >Upgrade Plan</TypographyBold>
               {this.state.accountType!=="coo"&&<Typography style={webStyle.extendSubtitle}>The request will be submitted to the COO for his review and approval</Typography>
                }
                   <Grid container style={webStyle.extendgrid}>
                      <Grid item sm={5}>
                        <Typography style={{ ...webStyle.extendssubtitles }} data-testid="subscriptiondate">Subscription Expiration Date:</Typography>
                        <TypographyBold sx={{ ...webStyle.extandBoxDiscription }}>{this.formateDate(this.state.subscriptionDetailsData?.attributes?.expiry_date)}</TypographyBold>
                      </Grid>
                      <Grid item sm={4} data-testid="subscriptionstatue">
                        <Typography style={{ ...webStyle.extendssubtitles }}>Subscription Status:</Typography>
                        <TypographyBold sx={{ ...webStyle.extandBoxDiscription }}>{this.state.subscriptionDetailsData?.attributes?.plan?.data?.attributes?.title} version <span style={webStyle.companyTag} className="bold-text">{this.state.subscriptionDetailsData?.attributes?.status}</span></TypographyBold>
                      </Grid>
                      <Grid item sm={3}>
                        <Typography style={{ ...webStyle.extendssubtitles }} data-testid="subscriptiondexpire">Days to expiration:</Typography>
                        <TypographyBold sx={{ ...webStyle.extandBoxDiscription }}>{this.state.subscriptionDetailsData?.attributes?.ends_in_days==="Expired"?"Expired":<>{this.state.subscriptionDetailsData?.attributes?.ends_in_days}&nbsp;Days</>}</TypographyBold>
                      </Grid>
                    </Grid>
                    <Box>
                    <Typography style={{ ...webStyle.extendssubFieldtitles }}>Upgrade Plan to</Typography>
                      <Grid container style={{width:"100%"}}>
                        <Box style={{ width: '100%' }}>
                          <Select
                           id="demo-simple-select"
                            disableUnderline
                            IconComponent={ExpandMoreRoundedIcon}
                            displayEmpty
                            data-test-id="upgradeSelectPlane"
                            style={{...webStyle.selectFormSortByBig,...webStyle.selectModel}}
                            value={this.state.upgradeSelectPlaneValue}
                            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                              this.upgradeSelectPlane(e.target.value  as string)}}
                          >
                            <MenuItem value={'0'}>Select Plan</MenuItem>
                            {
                             this.state.subscriptionPlans.length>0 &&  this.state.subscriptionPlans?.map((plan: any, i: number) => {
                                return <MenuItem value={plan.id} className="text_capital" key={i}>{plan.attributes.title}</MenuItem>
                              })
                            }
                          </Select>
                          <Box style={webStyle.errorMsg}>{this.state.selecePlaneError}</Box>
                        </Box>
                      </Grid>
                      <Box>
                        {this.state.upgradeSelectPlaneValue !== "2" &&
                          <RadioGroup row aria-label="position" name="position" defaultValue="top">
                            <RadioController value="paid" data-testid="changeUpgradeDuration" checked={this.state.upgradeRadio === 'paid'} onChange={() => {
                              this.changeUpgradeDuration('paid')
                            }} control={<Radio color="primary" icon={<RadioButtonUncheckedIcon style={{ ...webStyle.radioUncheckIcon }} />}
                              checkedIcon={<CheckCircleRoundedIcon style={{ ...webStyle.radionCheckIcon }} />} />} label={this.extendPaidRaio(this.state.upgradeRadio, 'paid', 'Paid', 'Paid')} />

                            <RadioController value="free" data-testid="changeUpgradeDurationfree" checked={this.state.upgradeRadio === 'free'} style={this.extendStyle(webStyle.extendssubRadiotitlesCheck, webStyle.extendssubRadiotitles)} onChange={() => {
                              this.changeUpgradeDuration('free')
                            }} control={<Radio color="primary" icon={<RadioButtonUncheckedIcon style={{ ...webStyle.radioUncheckIcon }} />}
                              checkedIcon={<CheckCircleRoundedIcon style={{ ...webStyle.radionCheckIcon }} />} />} label={this.extendPaidRaio(this.state.upgradeRadio, 'free', 'Free', 'Free')} />
                          </RadioGroup>
                        }
                      </Box>
                      {this.state.upgradeRadio !== "free" && 
                        <>

                          <Typography style={{ ...webStyle.extendssubFieldtitles, ...webStyle.extendAddStyle }}>Payment Reference Number</Typography>
                          <TextField
                            label={this.state.upgradeRadio === 'free'?"-":"Payment Reference Number"}
                            variant="outlined"
                            value={this.state.upgradePRNValue}
                            style={{
                              ...webStyle.configureInputStyle, ...webStyle.configuredata
                            }}
                            data-testid='changeupgradevalue'
                            onChange={(e) => {
                              this.changePRNData(e.target.value);
                            }}
                            disabled={this.state.upgradeRadio === 'free'}
                          />
                          <Box style={webStyle.errorMsg}>{this.state.upgradePRNError}</Box>
                        </>
                      }
                    </Box>
                </Box>
                <Box sx={{ ...webStyle.extendsModelButton }}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={webStyle.mainBtn}
                      onClick={this.upgradePlanModelClose}
                    >
                      {t("CANCEL")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={webStyle.mainBtnActive}
                      onClick={this.upgradePlanConform}
                      data-testid="upgradelanModel"
                    >
                      {t("SUBMIT")}
                    </Button>
                  </div>
                </Box>
                </Box>
              </Box>
            </Fade>
          </Modal>
          {this.handleConformupgradeModal(t)}
          {this.handleSucessfullupgradeModal(t)}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalStyle"
            open={this.state.propertyDeveloperModel}
            onClose={this.managepropertyDeveloperModel}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={this.state.propertyDeveloperModel}>
              <Box style={webStyle.configureModelOne}>
                <Box style={{ ...webStyle.contentModalPro, ...webStyle.configureHeader }}>
                  <Typography className="bold-text">{this.state?.planDetails?.attributes?.title}</Typography>
                  <CloseIcon style={webStyle.configureCloseIcon} data-testid="close_register" onClick={this.managepropertyDeveloperModel} />
                </Box>
                <Box sx={{...webStyle.configureBody}}>
                    <div dangerouslySetInnerHTML={{ __html: this.state?.planDetails?.attributes?.description }} />
                </Box>
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalStyle"
            open={this.state.openFreezSubmitmodel}
            onClose={this.freezCloseSubmitmodel}
            data-testid="freezSubmitmodel"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            
          >
            <Fade
             in={this.state.openFreezSubmitmodel}
            >
              <Box sx={{...webStyle.configureModelsubmit,maxWidth: {sm:'50%',md:'50%'}}}>
                <Box sx={{...webStyle.justifyconfigureBody}}>
                  <Box sx={{...webStyle.freezeSubmitheading}}>
                    <TypographyBold style={webStyle.modelTitle}>Justify Freeze Subscription</TypographyBold>
                    <Typography style={webStyle.modelChekIcon} onClick={this.freezCloseSubmitmodel}><img src={crossImg} /></Typography>
                  </Box>
                  <Box sx={{...webStyle.horizontalLine}}></Box>
                  <Box sx={{...webStyle.freezeSubmitheading}}>
                    <TextareaAutosize onChange={(event:ChangeEvent<HTMLTextAreaElement>)=>{this.addReason(event)}} style={{...webStyle.freezeSubmitextarea}} aria-label="minimum height" minRows={5} placeholder="Add Notes" />
                  </Box>
                  <Box sx={{ ...webStyle.configureModelsubmitButton}}>
                 <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={webStyle.mainBtn}
                        onClick={this.freezCloseSubmitmodel}
                      >
                        {t("CANCEL")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={webStyle.mainBtnActive}
                      onClick={this.submitFreezereason}
                      data-testid="freezUnfreezPlanModel"
                      >
                        {t("SUBMIT")}
                      </Button>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </MainScreen>
      </>
    );
  }
}

const webStyle: any = {
  subHeading: {
    marginTop: 15,
    fontSize: "26px"
  },
  btnBoxStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  registerBtn: {
    background: '#FC8434',
    color: '#fff',
    height: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '8px'
  },
  deactive_request:{
    background: 'rgb(252, 132, 52, 0.2)',
    color: 'rgb(252, 132, 52)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  pending: {
    background: 'rgb(252, 132, 52, 0.2)',
    color: 'rgb(252, 132, 52)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  active: {
    background: 'rgb(30,198,91,0.2)',
    color: 'rgb(30 198 91)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  suspend: {
    background: "rgba(236, 135, 152, 0.12)",
    color: "red",
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  deactive: {
    background: "#D8D8D8",
    color: "#45474B",
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  freeze:{
    background: "rgba(236, 135, 152, 0.12)",
    color: "red",
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  freeze_request: {
    background: 'rgb(252, 132, 52, 0.2)',
    color: 'rgb(252, 132, 52)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  extend_request: {
    background: 'rgb(252, 132, 52, 0.2)',
    color: 'rgb(252, 132, 52)',
    padding:"5px 15px",
    borderRadius:"20px",
    textTransform: "capitalize"
  },
  upgrade_request: {
  background: 'rgb(252, 132, 52, 0.2)',
  color: 'rgb(252, 132, 52)',
  padding:"5px 15px",
  borderRadius:"20px",
  textTransform: "capitalize"
},

suspended: {
  background: "rgba(236, 135, 152, 0.12)",
  color: "red",
  padding:"5px 15px",
  borderRadius:"20px",
  textTransform: "capitalize",
},
rejected: {
  background: "rgba(236, 135, 152, 0.12)",
  color: "red",
  borderRadius:"20px",
  textTransform: "capitalize",
  padding:"5px 15px",
},
deactive_rejected:{
  color: "red",
  background: "rgba(236, 135, 152, 0.12)",
  textTransform: "capitalize" ,
  padding:"5px 15px",
  borderRadius:"20px",
},
extend_rejected:{
  color: "red",
  borderRadius:"20px",
  background: "rgba(236, 135, 152, 0.12)",
  padding:"5px 15px",
  textTransform: "capitalize",
},
unfreeze_rejected:{
  color: "red",
  padding:"5px 15px",
  borderRadius:"20px",
  textTransform: "capitalize",
  background: "rgba(236, 135, 152, 0.12)",
},
freeze_rejected :{
  color: "red",
  padding:"5px 15px",
  background: "rgba(236, 135, 152, 0.12)",
  borderRadius:"20px",
  textTransform: "capitalize",
},
unfreeze_request:{
  background: 'rgb(252, 132, 52, 0.2)',
  padding:"5px 15px",
  borderRadius:"20px",
  color: 'rgb(252, 132, 52)',
  textTransform: "capitalize"
},
upgrade_rejected:{
  color: "red",
  borderRadius:"20px",
  background: "rgba(236, 135, 152, 0.12)",
  textTransform: "capitalize" ,
  padding:"5px 15px",
},
  configBtn: {
    background: '#Ffffff',
    color: '#FC8434',
    height: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '8px',
    border: '1px solid #FC8434',
    marginRight: "8px",
    borderColor: '#FC8434'
  },
  configPlan: {
    background: '#Ffffff',
    color: '#FC8434',
    height: '32px',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: '8px',
    border: '1px solid #FC8434',
    marginRight: "8px",
    borderColor: '#FC8434'
  },
  detailsBox: {
    padding: '30px'
  },
  companyTag: {
    background: 'rgb(30,198,91,0.2)',
    color: 'rgb(30 198 91)',
    textAlign: 'center' as CSSProperties['textAlign'],
    padding: '5px 20px',
    borderRadius: '25px',
    fontSize: '0.875rem',
    whiteSpace: 'nowrap'
  },
  boxStyle: {
    display: 'flex',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '1px solid #F7F9FE'
  },
  dayStyle:{
paddingLeft:"5px",
fontSize: '17px',
    fontWeight: '400' as CSSProperties['fontWeight'],
    fontFamily: "none !important"
  },
  labelTxt: {
    fontSize: '15px',
    fontWeight: '400' as CSSProperties['fontWeight']

  },
  valueTxt: {
    fontSize: '15px',
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    marginLeft: '5px',
    textTransform: 'capitalize'
  },
  groupsbtn: {
    display: 'flex',
    justifyContent: 'end',
    padding: '30px'
  },
  mainBtnupgrade: {
    minWidth: "100px",
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    color: "rgb(43, 111, 237)",
    border: '1px solid rgb(43, 111, 237)',
    padding: '10px 57px',
    marginLeft: "10px",
    borderRadius: "8px",
    fontSize: "15px",
    backgroundColor: "#ffffff",
  },
  mainBtn: {
    minWidth: "100px",
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    padding: '10px 30px',
    marginLeft: "10px",
    borderRadius: "8px",
    fontSize: "15px",
    backgroundColor: "#ffffff",
    color: "rgb(43, 111, 237)",
    border: '1px solid rgb(43, 111, 237)'
  },
  mainBtnextend:{
    fontSize: "15px",
    backgroundColor: "#ffffff",
    color: "rgb(43, 111, 237)",
    border: '1px solid rgb(43, 111, 237)',
    minWidth: "100px",
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    padding: '10px 52px',
    marginLeft: "10px",
    borderRadius: "8px",
    
  },
  disableMainBtn: {
    opacity:'0.5',
    minWidth: "100px",
    padding: '12px 30px',
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    marginLeft: "10px",
    borderRadius: "8px",
    fontSize: "15px",
    backgroundColor: "#ffffff",
    color: "rgb(43, 111, 237)",
    border: '1px solid rgb(43, 111, 237)'
  },
  mainBtnActive: {
    padding: '12px 30px',
    minWidth: "100px",
    backgroundColor: "rgb(43, 111, 237)",
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    color: "#ffffff",
    marginLeft: "10px",
    borderRadius: "8px",
    fontSize: "15px",
  },
  mainBtnActiveok: {
    padding: '12px 70px',
    minWidth: "100px",
    backgroundColor: "rgb(43, 111, 237)",
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    color: "#ffffff",
    marginLeft: "10px",
    borderRadius: "8px",
    fontSize: "15px",
  },
  mainBtnActivedeactiveok:{
    color: "#ffffff",
    borderRadius: "8px",
    fontSize: "15px",
    padding: '12px 70px',
    minWidth: "100px",
    backgroundColor: "rgb(43, 111, 237)",
    fontWeight: 'bold' as CSSProperties['fontWeight'],
  },

  mainBtnActiveupgarde:{
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    color: "#ffffff",
    marginLeft: "10px",
    borderRadius: "8px",
    fontSize: "15px",
    padding: '12px 50px',
    minWidth: "100px",
    backgroundColor: "rgb(43, 111, 237)",
  },
  mainBtnActiveextend:{
    padding: '12px 50px',
    minWidth: "100px",
    color: "#ffffff",
    marginLeft: "10px",
    borderRadius: "8px",
    fontSize: "15px",
    backgroundColor: "rgb(43, 111, 237)",
    fontWeight: 'bold' as CSSProperties['fontWeight'],
  },
  disableMainBtnActive: {
    opacity:'0.5',
    padding: '12px 30px',
    minWidth: "100px",
    backgroundColor: "rgb(43, 111, 237)",
    fontWeight: 'bold' as CSSProperties['fontWeight'],
    color: "#ffffff",
    marginLeft: "10px",
    borderRadius: "8px",
    fontSize: "15px",
  },
  
  reasonStyle: {
    padding: "16px"
  },
  configureModelOne:{
    width: '40%',
    opacity: '1',
    marginTop: '15px',
    backgroundColor: 'white',
    padding: '20px 0px',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  configureModel:{
    maxWidth: {sm:'50%',md:'50%'},
    opacity: '1',
    backgroundColor: 'white',
    padding: '20px 0px',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom:'0px',
  },
  configureModelfreeze:{
    maxWidth: {sm:'50%',md:'50%'},
    padding: '25px 60px',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom:'0px',
    opacity: '1',
    backgroundColor: 'white',
  
  },
  configureModelupgrade:{
    maxWidth: {sm:'50%',md:'50%'},
    opacity: '1',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom:'0px',
    backgroundColor: 'white',
    padding: '20px 60px',
  },
  configureModelsucess:{
    maxWidth: {sm:'50%',md:'50%'},
    opacity: '1',
    backgroundColor: 'white',
    padding: '35px 60px',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom:'0px',
  },
    configureModeldeactive:{
    maxWidth: {sm:'50%',md:'50%'},
    opacity: '1',
    padding: '50px 65px',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom:'0px',
    backgroundColor: 'white',
  },
  configureModelsubmit:{
    maxWidth: {sm:'50%',md:'50%'},
    width:"100%",
    opacity: '1',
    backgroundColor: 'white',
    padding: '20px 0px',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom:'0px',
  },
  configureModelextend:{
    padding: '40px 50px',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom:'0px',
    maxWidth: {sm:'50%',md:'50%'},
    opacity: '1',
    backgroundColor: 'white'
  },
  configuredecativeModelsubmit:{
    maxWidth: {sm:'50%',md:'50%'},
    width:"100%",
    opacity: '1',
    backgroundColor: 'white',
    padding: '20px 0px',
    borderRadius: '7px',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom:'0px',
  },
  freezeSubmitheading:{
 display:"flex",
 justifyContent:"space-between",
 margin:"3%"
  },
  freezeSubmitheadingdeactive:{
    display:"flex",
    justifyContent:"space-between",
    margin:"3%"
     },
  freezeSubmitextarea:{
    width:"100%",
    height:"150px",
    border:"1px solid lightgray",
    borderRadius:"10px",
    padding:"2%"
  },
  horizontalLine:{
    width:"100%",
    height:"0px",
    borderBottom:"1px solid lightgray",
    marginBottom:"5%"
  },
  justifyconfigureBody:{padding:'10px 0px'},
  justifydeactiveconfigureBody:{padding:'10px 0px'},


  extandModelBox:{ maxWidth: {sm:'50%',md:'60%'},padding: '25px 0 40px',
  width: '50%',
  backgroundColor: 'white',
  outline: 'none',
  borderRadius: '8px',
  boxShadow: '4px 0px 14px 0px rgba(0, 0, 0, 0.1)'},
  configureBody:{padding:'10px 17px'},
  configureBodyextend:{padding:'10px 17px'},
  configureSubtitle:{fontSize: '0.8rem', paddingBottom:'10px', textAlign: 'center' },
  configureSubtitleProperty:{fontSize: '0.8rem', paddingBottom:'10px' },
  extendSubtitle:{fontSize: '0.8rem', paddingBottom:'10px', color:'rgb(252, 132, 52)',paddingTop:'10px'},
  extendgrid:{
    marginTop:"2%"
  },
  configureModelButton:{textAlign: 'center',pt:2, display:"flex", alignItems:"center", gap:"10px"},
  configureModelsubmitButton:{
   display:"flex",
   justifyContent:"flex-end",
   margin:"5%"
  },
  configureSubtitleextend:{fontSize: '0.8rem', paddingBottom:'10px', textAlign: 'center' },
  configureModelsubmitButtondeactive:{
    display:"flex",
    justifyContent:"flex-end",
    margin:"5%"
   },
  configureModelButtonextend:{textAlign: 'center',pt:2},

  extendsModelButton:{textAlign: 'end',pt:2},
  modelTitle: {
    paddingBottom:'10px',
    fontWeight: 900,
    lineHeight: '1',
    letterSpacing: '0.00938em', textAlign: 'center'
  },
  modelExtendTitle: {
    fontFamily: "Gotham_Bold !important",
    fontWeight: 900,
    lineHeight: '1',
    letterSpacing: '0.00938em', textAlign: 'center'
  },
  modelTitleextend: {
    paddingBottom:'10px',
    letterSpacing: '0.00938em', textAlign: 'center',
    fontWeight: 900,
    lineHeight: '1',
  },
  configureHeader:{padding:'0px 20px',paddingBottom: '10px',display:'flex',justifyContent: 'space-between',flexDirection: 'row'},
modelChekIcon:{textAlign: 'center'},
disablebutton:{opacity: '0.5'},
modelCheckIcon:{textAlign: 'center'},
extendssubtitles:{fontSize: '0.8rem',opacity: '0.5'},
extendAddStyle:{padding:'6px 0px'},
extendssubFieldtitles:{fontSize: '0.8rem',padding:'6px 0px'},
extendssubRadiotitles:{fontSize: '0.8rem !importent',color: '#rgb(0, 0, 0)',},
extendssubRadiotitlesCheck:{fontSize: '1.5rem',},
extandBoxDiscription:{fontSize: '15px!important',marginTop: '5px'},
modelFixWidth:{maxWidth: '30%'},
configuredata:{border: '1px solid rgba(0, 0, 0, 0.07)',backgroundColor: 'rgba(0, 0, 0, 0.03)',color: 'rgba(0, 0, 0, 0.25)'},
configureInputStyle: {
  border: '1px solid rgba(0, 0, 0, 0.07)',
  backgroundColor: 'rgba(0, 0, 0, 0.03)',
  borderRadius: '8px',
  height: '50px',
  padding: '0px',
  color: 'rgba(0, 0, 0, 0.25)',
  display: 'flex',
  justifyContent: 'center'
},
extendValue:{border: "1px solid #00000012", borderRadius: "10px", marginRight: "10px" ,backgroundColor: 'rgba(0, 0, 0, 0.03)'},
selectDate:{ border: "1px solid #00000012", borderRadius: "10px", backgroundColor: "#f9f9f9", marginRight: "10px" },
radioUncheckIcon:{ color: '#8080803b'},
radionCheckIcon:{color: 'rgb(252, 132, 52)'},
radionLabel:{fontSize: '0.8rem'},
selectModel:{  minWidth: '-moz-available',
backgroundColor: 'rgba(0, 0, 0, 0.03)',
height: '30px',
border: "1px solid #00000012",
padding: "25px 10px",
borderRadius: "5px",},
contentModalPro: {
  display: "flex",
  flexDirection: "column",
  lignItems: "center",
  position: 'relative',
  justifyContent: 'center'
},
modalRegisterHeading:{fontSize: '0.7rem'},
configureCloseIcon:{cursor:'pointer'},
selectFormSortByBig: {
  width: '100%',
  fontWeight: 'bold',
  border: '1px solid #00000012',
  borderRadius: "8px",
  color: 'rgba(0, 0, 0, 0.25)',
  boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } 
},
errorMsg:{
  color:'red',
  fontSize: '12px',
  marginTop:'5px'
},
};
const TypographyBold = styled(Box)({
  fontFamily: "Gotham_Bold !important",
  fontWeight: 900,
  lineHeight: '1',
  letterSpacing: '0.00938em',
  fontSize: '20px',
})
const RadioController = styled(FormControlLabel)({
  fontFamily: "Gotham_Bold !important",
})
export default withTranslation()(withRouter(COOSubscriptionDetails));

//Customizable Area End
