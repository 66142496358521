import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
import { debounce } from "../../../components/src/helperFunctions.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
  t: (value: string) => string;
  i18n: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
  individualBuildingList: Array<any>;
  userRole: string | null;
  country: string;
  city: string;
  building: string;
  subscriptionEndsIn: string;
  salesManager: string;
  cooPageNumber: number;
  salesPageNumber: number;
  accountPageNumber: number;
  searchBuildingText: string;
  searchCompanyText: string;
  countryList: Array<string>;
  cityList: Array<string>;
  companyList: Array<any>;
  salesManagerList: Array<any>;
  accountManagerList: Array<any>;
  showError: boolean;
  errorMessage: string;
  showSuccess: boolean;
  showSuccessMessage: string;
  latitude: null | number | string;
  longitude: null | number | string;
  openBuildingModal: boolean;
  companyId: string;
  complexId: string;
  salesManagerId: string;
  accountManagerId: string;
  buildingId: string;
  loader: boolean;
  paginationData: any | null;
  complexList: Array<any>;
  buildingList: Array<any>;
  openReassignModal: boolean;
  employeeAccountId: number | null;
  newAccountManagerList: Array<any>;
  newSalesManagerList: Array<any>;

  reassignForm: {
    reassignBuildingId: string;
    reassignBuildingName: string;
    reassignSalesManager: string;
    reassignAccountManager: string;
    reassignAccountManagerId: string;
    reassignNewSalesManager: string;
    reassignNewAccountManager: string;
    reassignNote: string;
  };
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class IndivisualBuildingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  individualBuildingApiCallId: string = "";
  companyListApiCallId: string = "";
  countryApiCallId: string = "";
  cityApiCallId: string = "";
  buildingApiCallId: string = "";
  complexListApiCallId: string = "";
  salesManagerApiCallId: string = "";
  accountManagerApiCallId: string = "";
  reassignAnotherManagerApiCallId: string = "";

  [key: string]: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
      individualBuildingList: [],
      userRole: null,
      country: "",
      city: "",
      building: "",
      subscriptionEndsIn: "",
      salesManager: "",
      cooPageNumber: 1,
      salesPageNumber: 1,
      accountPageNumber: 1,
      searchBuildingText: "",
      searchCompanyText: "",
      countryList: [],
      cityList: [],
      companyList: [],
      salesManagerList: [],
      accountManagerList: [],
      showError: false,
      errorMessage: "",
      showSuccess: false,
      showSuccessMessage: "",
      latitude: null,
      longitude: null,
      openBuildingModal: false,
      companyId: "",
      complexId: "",
      salesManagerId: "",
      accountManagerId: "",
      buildingId: "",
      loader: false,
      paginationData: {
        current_page: 0,
        next_page: 0,
        prev_page: 0,
        total_pages: 0,
        total_count: 0,
      },
      complexList: [],
      buildingList: [],
      openReassignModal: false,
      employeeAccountId: null,
      newAccountManagerList: [],
      newSalesManagerList: [],

      reassignForm: {
        reassignBuildingId: "",
        reassignBuildingName: "",
        reassignSalesManager: "",
        reassignAccountManager: "",
        reassignAccountManagerId: "",
        reassignNewSalesManager: "",
        reassignNewAccountManager: "",
        reassignNote: "",
      },
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
    this.debouncedHandleSearchSubmit = debounce(this.handleFilterSubmit.bind(this), 300);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if ((responseJson && (responseJson.errors !== null && !responseJson.errors))) {
        this.totalIndividualBuildingApiResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson) {
        this.totalIndividualBuildingApiResponseFailureCall(apiRequestCallId, responseJson);
      }
    }
        // Customizable Area End
    }
    // Customizable Area Start
  async componentDidMount() {
    const storedRole = localStorage.getItem("role");
    this.setState({ userRole: storedRole }, () => {
      this.getBuildingListing();
      this.getCountryList();
      this.getCompanyListing();
      this.getSalesManagerListing();
      this.getAccountManagerListing();
      this.getIndividualBuildingListing("1");
    });
  }

  totalIndividualBuildingApiCall = async (data: any) => {
    let { method, endPoint, body, type = "", contentType } = data;
    let token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": contentType,
    };
    let individualBuildingsrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    individualBuildingsrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    individualBuildingsrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    individualBuildingsrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    individualBuildingsrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type !== "formData"
      ? individualBuildingsrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : individualBuildingsrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(individualBuildingsrequestMessage.id, individualBuildingsrequestMessage);
    return individualBuildingsrequestMessage.messageId;
  };

  totalIndividualBuildingApiResponseSucessCell = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.complexListApiCallId) {
      this.complexListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.individualBuildingApiCallId) {
      this.individualBuildingListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingApiCallId) {
      this.buildingListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.countryApiCallId) {
      this.countryListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityApiCallId) {
      this.cityListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.companyListApiCallId) {
      this.companyListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.salesManagerApiCallId) {
      this.salesManagerSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.accountManagerApiCallId) {
      this.accountManagerSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
      this.reassignToAnotherAccountManagerSucessCallBack(responseJson);
    }
  };

  totalIndividualBuildingApiResponseFailureCall = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.complexListApiCallId) {
      this.complexListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.individualBuildingApiCallId) {
      this.individualBuildingListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingApiCallId) {
      this.buildingListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.countryApiCallId) {
      this.countryListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityApiCallId) {
      this.cityListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.companyListApiCallId) {
      this.companyListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.salesManagerApiCallId) {
      this.salesManagerListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.accountManagerApiCallId) {
      this.accountManagerListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
      this.reassignToAnotherAccountManagerFailureCallBack(responseJson);
    }
  };

  handleAllFilter = (value: any) => (value === "all" ? "" : value);

  getIndividualBuildingListing = async (page: string) => {
    this.setState({ loader: true });
    let endPoint = `${configJSON.individualBuilidingListEndpoint}page=${page}`;
    let {
      country,
      city,
      companyId,
      complexId,
      salesManagerId,
      subscriptionEndsIn,
      searchBuildingText,
      accountManagerId,
      buildingId,
      searchCompanyText,
    } = this.state;
    if (country) {
      endPoint += `&country=${this.handleAllFilter(country)}`;
    }
    if (city) {
      endPoint += `&city=${this.handleAllFilter(city)}`;
    }
    if (companyId) {
      endPoint += `&company=${this.handleAllFilter(companyId)}`;
    }
    if (complexId) {
      endPoint += `&complex=${this.handleAllFilter(complexId)}`;
    }
    if (buildingId) {
      endPoint += `&complex=${this.handleAllFilter(buildingId)}`;
    }
    if (salesManagerId) {
      endPoint += `&sales_manager=${this.handleAllFilter(salesManagerId)}`;
    }
    if (accountManagerId) {
      endPoint += `&account_manager=${this.handleAllFilter(accountManagerId)}`;
    }
    if (subscriptionEndsIn) {
      endPoint += `&subscription_ends_in=${this.handleAllFilter(subscriptionEndsIn)}`;
    }
    if (searchBuildingText) {
      endPoint += `&search_term=${searchBuildingText}`;
    }
    if (searchCompanyText) {
      endPoint += `&search_term=${searchCompanyText}`;
    }
    this.individualBuildingApiCallId = await this.totalIndividualBuildingApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: endPoint,
    });
  };

  individualBuildingListSucessCallBack = (response: any) => {
    this.setState({ individualBuildingList: response.data, paginationData: response.meta.pagination, loader: false });
  };

  individualBuildingListFailureCallBack = (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract });
  };

  getComplexListing = async (companyId: any) => {
    this.complexListApiCallId = await this.totalIndividualBuildingApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: `bx_block_dashboard/assign_action_team/complex_list?real_estate_company_id=${companyId}&is_building=true`,
    });
  };

  complexListSucessCallBack = (response: any) => {
    if (response && response.complexes) {
      this.setState({ complexList: response.complexes });
    }
  };

  complexListFailureCallBack = (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract });
  };

  getBuildingListing = async () => {
    this.buildingApiCallId = await this.totalIndividualBuildingApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: `${configJSON.buildingListEndpoint}`,
    });
  };

  buildingListSucessCallBack = (response: any) => {
    this.setState({ buildingList: response.data.building_list });
  };

  buildingListFailureCallBack = (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract });
  };

  getCompanyListing = async () => {
    this.companyListApiCallId = await this.totalIndividualBuildingApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: `bx_block_dashboard/assign_action_team/real_estate_company_list`,
    });
  };

  companyListSucessCallBack = (response: any) => {
    if (response && response.compneys) {
      this.setState({ companyList: response.compneys });
    }
  };

  companyListFailureCallBack = (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract });
  };

  getCountryList = async () => {
    this.countryApiCallId = await this.totalIndividualBuildingApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: "bx_block_address/addresses",
    });
  };

  handleMapModal = (item: any) => {
    this.setState({
      openBuildingModal: true,
      latitude: item.attributes.lat,
      longitude: item.attributes.long,
    });
  };

  countryListSucessCallBack = (response: any) => {
    this.setState({ countryList: response.data });
  };

  countryListFailureCallBack = (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract });
  };

  getCityList = async () => {
    this.cityApiCallId = await this.totalIndividualBuildingApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: `${configJSON.cityEndpoint}${this.state.country}`,
    });
  };

  cityListSucessCallBack = (response: any) => {
    this.setState({ cityList: response.data.cities });
  };

  cityListFailureCallBack = (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract });
  };

  getSalesManagerListing = async () => {
    this.salesManagerApiCallId = await this.totalIndividualBuildingApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.salesManagerEndPoint,
    });
  };

  salesManagerSuccessCallBack = (response: any) => {
    this.setState({ salesManagerList: response.sales_manager_listing });
  };

  salesManagerListFailureCallBack = (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract });
  };

  getAccountManagerListing = async () => {
    this.accountManagerApiCallId = await this.totalIndividualBuildingApiCall({
      method: configJSON.dashboarApiMethodType,
      endPoint: `bx_block_my_team/company_employee/employee_team_members/account_manager_list_for_reassign`,
    });
  };

  accountManagerSuccessCallBack = (response: any) => {
    if (response && response.data) {
      this.setState({ accountManagerList: response.data });
    }
  };

  accountManagerListFailureCallBack = (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors[0].error });
  };

  reassignToAnotherAccountManager = (values: any) => {
    const body = {
      id: Number(values.reassignBuildingId),
      account_employee_team_id: values.reassignAccountManagerId,
      new_employee_account_team_id: values.reassignNewAccountManager,
      note: values.reassignNote,
    };

    this.setState({ loader: true }, async () => {
      this.reassignAnotherManagerApiCallId = await this.totalIndividualBuildingApiCall({
        method: configJSON.httpPut,
        endPoint: `bx_block_society_management/company_employee/complexes/reassign_complex`,
        body: body,
        contentType: "application/json",
      });
    });
  };

  reassignToAnotherAccountManagerSucessCallBack = (response: any) => {
    this.setState({
      showSuccessMessage: response.message,
      openReassignModal: false,
      showSuccess: true,
      loader: false,
    }, () => {
      this.getIndividualBuildingListing("1");
    });
  };

  reassignToAnotherAccountManagerFailureCallBack = async (response: any) => {
    this.setState({ showError: true, errorMessage: response.errors, loader: false });
  };

  handleSubscriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ subscriptionEndsIn: event.target.value as string });
  };

  handleCountryChange = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ country: event.target.value as string }, () => {
      this.getCityList();
    });
  };

  handleCityChange = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ city: event.target.value as string });
  };

  handleBuildingChange = (event: ChangeEvent<{ value: unknown }>) => {
    let building = this.state.buildingList.find((building) => {
      return building.name === event.target.value;
    });
    if (building) {
      this.setState({ building: event.target.value as string, buildingId: String(building.id) });
    }
  };

  handleAccountManagerChange = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ accountManagerId: event.target.value as string });
  };

  handleSearchBuildingChange = (event: any) => {
    this.setState({ searchBuildingText: event.target.value }, () => {
      this.getIndividualBuildingListing("1");
    });
  };

  handleUserType = (userType: string) => {
    if (this.state.userRole === userType) {
      return true;
    } else {
      return false;
    }
  };

  handleSearchCompanyChange = (event: any) => {
    this.setState({ searchCompanyText: event.target.value }, () => {
      this.debouncedHandleSearchSubmit();
    });
  };

  handleFilterSubmit = () => {
    this.getIndividualBuildingListing("1");
  };

  handleCloseViewBuilding = () => {
    this.setState({ openBuildingModal: false });
  };

  handleAlertError = () => {
    this.setState({ errorMessage: "", showError: false });
  };

  handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.getIndividualBuildingListing(String(value));
  };

  handleRedirection = (path: string) => {
    const msgData: Message = new Message(getName(MessageEnum.NavigationMessageSendData));
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
    this.send(msgData);
  };

  handleOpenReassignModal = (item: any) => {
    this.setState({
      openReassignModal: true,
      reassignForm: {
        reassignBuildingName: item.attributes.building_name,
        reassignBuildingId: item.id,
        reassignSalesManager:
        item.attributes.account_sales_manager?.sales_manager ? item.attributes.account_sales_manager.sales_manager.first_name +
          " " +
          item.attributes.account_sales_manager.sales_manager.last_name : "",
        reassignAccountManager:
        item.attributes.account_sales_manager?.account_manager ? item.attributes.account_sales_manager.account_manager.first_name +
          " " +
          item.attributes.account_sales_manager.account_manager.last_name : "",
          reassignNote: "",
          reassignNewSalesManager: "",
          reassignNewAccountManager: "",
          reassignAccountManagerId: item.attributes.account_sales_manager?.account_manager?.id,
      },
    });
  };

  handleCloseReassignModal = () => {
    this.setState({ openReassignModal: false });
  };

  handleSuccessAlert = () => {
    this.setState({ showSuccess: false });
  };

  handleCompanyChange = (event: any) => {
    this.setState({ companyId: event.target.value + "" }, () => {
      this.getComplexListing(event.target.value);
    });
  };

  handleComplexChange = (event: any) => {
    this.setState({ complexId: event.target.value as string });
  };
    // Customizable Area End
}
