import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import * as Yup from "yup";
import toast from "react-hot-toast";
import moment from "moment";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { Menu } from "@szhsin/react-menu";
import { BlueCheckIcon, CommentIcon, DateIcon, Dots, GreyCheckIcon, SearchIconImage, TimeIcon } from "./assets";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface MeetingForm {
  title: string;
  place: string;
  agenda: string;
  building: string;
  date: string;
  time: string;
  // momWriter: string | null;
  status: string;
  meetingType: string;
  attendeeIds: any[];
  meetingGroupIds: any[];
  role: any[];
}

interface Pagination {
  current_page: any | number;
  next_page: any | number;
  prev_page: any | number;
  total_count: any | number;
  total_pages: any | number;
}

interface Filter {
  place: string;
  status: string;
  date: string;
  title: string;
  page: number;
  building: string;
}

interface UserFilter {
  buildingName: string;
  floorId: string;
  userType: string;
}

interface S {
  // Customizable Area Start
  loading: boolean;

  isCreateMeetingModalOpen: boolean;
  isEditMeetingModalOpen: boolean;
  isCancelMeetingModalOpen: boolean;
  isCompleteMeetingModalOpen: boolean;
  isCreateAttendeeModalOpen: boolean;
  isEditAttendeeModalOpen: boolean;
  isSelectAllUser: boolean;

  scheduleMeetingList: any[];
  buildingsList: any[];
  managersList: any[];
  responseList: any[];
  userList: any[];
  groupList: any[];
  floorList: any[];

  pagination: any | Pagination;

  place: string;
  status: string;
  date: string;
  building: string;
  filter: Filter;

  scheduleMeetingId: string;
  scheduleMeetingStatus: string;
  scheduleMeetingDetails: any;

  meetingForm: MeetingForm;

  groupId: string;
  selectedUser: any[];
  groupName: string;
  selectedGroup: any[];
  isIdAddingToList: boolean;

  buildingName: string;
  floorId: string;
  userType: string;
  userFilter: UserFilter;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ScheduledMeetingController extends BlockComponent<Props, S, SS> {
  GetAllMeetingsCallId: any;
  GetScheduledMeetingDetailCallId: any;
  GetAllBuildingsCallId: any;
  GetAllManagersCallId: any;
  CreateMeetingCallId: any;
  EditMeetingCallId: any;
  UpdateStatusMeetingCallId: any;
  GetMeetingResponseCallId: any;
  GetUserListCallId: any;
  GetGroupListCallId: any;
  GetGroupDetailsCallId: any;
  CreateGroupCallId: any;
  DeleteGroupListCallId: any;
  UpdateGroupCallId: any;
  // GetGroupIdsCallId: any;
  GetOwnerIdsCallId: any;
  GetResidentIdsCallId: any;
  GetFloorListCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      isCreateMeetingModalOpen: false,
      isEditMeetingModalOpen: false,
      isCancelMeetingModalOpen: false,
      isCompleteMeetingModalOpen: false,
      isCreateAttendeeModalOpen: false,
      isEditAttendeeModalOpen: false,
      isSelectAllUser: false,

      scheduleMeetingList: [],
      buildingsList: [],
      managersList: [],
      responseList: [],
      userList: [],
      groupList: [],
      floorList: [],

      pagination: null,

      place: "",
      status: "select status",
      date: "",
      building: "select building",
      filter: {
        place: "",
        status: "",
        date: "",
        title: "",
        page: 1,
        building: "",
      },

      scheduleMeetingId: "",
      scheduleMeetingStatus: "",
      scheduleMeetingDetails: null,

      meetingForm: {
        title: "",
        place: "",
        agenda: "",
        building: "",
        date: "",
        time: "",
        // momWriter: "",
        status: "scheduled",
        meetingType: "",
        attendeeIds: [],
        meetingGroupIds: [],
        role: [],
      },

      groupId: "",
      selectedUser: [],
      groupName: "",
      selectedGroup: [],
      isIdAddingToList: false,

      buildingName: "select building",
      floorId: "",
      userType: "select type",
      userFilter: {
        buildingName: "",
        floorId: "",
        userType: "",
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetScheduledMeetingDetailCallId:
          this.GetScheduledMeetingDetailResponse(responseJson);
          break;
        case this.GetAllMeetingsCallId:
          this.GetAllMeetingsResponse(responseJson);
          break;
        case this.GetAllBuildingsCallId:
          this.GetAllBuildingsResponse(responseJson);
          break;
        case this.CreateMeetingCallId:
          this.CreateMeetingResponse(responseJson);
          break;
        case this.EditMeetingCallId:
          this.EditMeetingResponse(responseJson);
          break;
        case this.UpdateStatusMeetingCallId:
          this.UpdateStatusMeetingResponse(responseJson);
          break;
        case this.GetMeetingResponseCallId:
          this.GetMeetingResponseResponse(responseJson);
          break;
        case this.GetUserListCallId:
          this.GetUserListResponse(responseJson);
          break;
        case this.GetGroupListCallId:
          this.GetGroupListResponse(responseJson);
          break;
        case this.GetGroupDetailsCallId:
          this.GetGroupDetailsResponse(responseJson);
          break;
        case this.CreateGroupCallId:
          this.CreateGroupResponse(responseJson);
          break;
        case this.DeleteGroupListCallId:
          this.DeleteGroupListResponse(responseJson);
          break;
        case this.UpdateGroupCallId:
          this.UpdateGroupResponse(responseJson);
          break;
        case this.GetOwnerIdsCallId:
          this.GetOwnerIdsResponse(responseJson);
          break;
        case this.GetResidentIdsCallId:
          this.GetResidentIdsResponse(responseJson);
          break;
        case this.GetFloorListCallId:
          this.GetFloorListResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // Response API
  GetScheduledMeetingDetailResponse = (responseJson: any) => {
    if (responseJson.code === 200) {
      this.setState({
        scheduleMeetingDetails: responseJson.meeting.data,
        scheduleMeetingStatus: responseJson.meeting.data.attributes.status,
      });
    }
  };
  GetAllMeetingsResponse = (responseJson: any) => {
    if (responseJson && responseJson.meeting) {
      this.setState({
        ...this.state,
        scheduleMeetingList: responseJson.meeting.data,
        pagination: responseJson.meta.pagination,
      });
    }
  };
  GetAllBuildingsResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingsList: responseJson.buildings,
      });
    }
  };
  CreateMeetingResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.code === 200) {
        this.setState(
          {
            scheduleMeetingList: [ responseJson.meeting.data, ...this.state.scheduleMeetingList ],
            meetingForm: {
              title: "",
              place: "",
              agenda: "",
              building: "",
              date: "",
              time: "",
              status: "scheduled",
              meetingType: "",
              attendeeIds: [],
              meetingGroupIds: [],
              role: [],
            },
            selectedGroup: []
          },
          () => {
            toast.success(responseJson.message);
          }
        );
      }
    });
  };
  EditMeetingResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
    const newMeetingList = this.state.scheduleMeetingList.map((meeting: any) => {
      return meeting.id === this.state.scheduleMeetingId ? responseJson.meeting.data : meeting;
    });

    this.setState({
      scheduleMeetingList: newMeetingList, meetingForm: {
        title: "",
        place: "",
        agenda: "",
        building: "",
        date: "",
        time: "",
        status: "scheduled",
        meetingType: "",
        attendeeIds: [],
        meetingGroupIds: [],
        role: [],
      },
    }, () => {
      toast.success(responseJson.message);
      if (this.props.navigation.getParam("id")) {
        this.getScheduleMeetingDetail();
        this.getMeetingResponseList();
      }
    });
    });
  };
  UpdateStatusMeetingResponse = (responseJson: any) => {
    const newMeetingList = this.state.scheduleMeetingList.map((meeting: any) => {
      return meeting.id === this.state.scheduleMeetingId ? responseJson.meeting.data : meeting;
    });
    this.setState(
      {
        scheduleMeetingList: newMeetingList,
      },
      () => {
        if (this.state.isCancelMeetingModalOpen) {
          this.handleCancelMeetingModal();
        }
        if (this.state.isCompleteMeetingModalOpen) {
          this.handleCompleteMeetingModal();
        }
        if (this.props.navigation.getParam("id")) {
          this.getScheduleMeetingDetail();
          toast.success(responseJson.message);
        }
      }
    );
  };
  GetMeetingResponseResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ responseList: [], pagination: responseJson.meta.pagination }, () => {
        this.setState({ responseList: responseJson.data.data });
      });
    }
  };
  GetUserListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ userList: responseJson.data });
    }
  };
  GetGroupListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ groupList: responseJson.data });
    }
  };
  GetGroupDetailsResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const idList = responseJson.data.attributes.accounts.map((user: any) => user.id.toString());
      this.setState({
        groupName: responseJson.data.attributes.group_name,
        selectedUser: idList,
      });
    }
  };
  CreateGroupResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.handleCreateAttendeeModal();
      this.setState({ groupName: "", selectedUser: [] }, () => {
        this.getGroupList();
        toast.success("Group Created Successfully!!");
      });
    }
  };
  DeleteGroupListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const filterList = this.state.meetingForm.meetingGroupIds.filter((groupId: any) => groupId !== responseJson.data.id);
      this.setState({ meetingForm: { ...this.state.meetingForm, meetingGroupIds: filterList } }, () => {
        this.getGroupList();
        toast.success("Group Deleted Successfully!!");
      });
    }
  };
  UpdateGroupResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.handleCreateAttendeeModal();
      this.setState({ groupName: "", selectedUser: [], isEditAttendeeModalOpen: false }, () => {
        this.getGroupList();
        toast.success("Group Updated Successfully!!");
      });
    }
  };
  GetOwnerIdsResponse = (responseJson: any) => {
    if (responseJson.owner_account && this.state.isIdAddingToList) {
      const newIdList = this.state.meetingForm.attendeeIds.concat(
        responseJson.owner_account.map((resOwnerId: any) => resOwnerId.toString())
      );
      this.setState({ meetingForm: { ...this.state.meetingForm, attendeeIds: newIdList } });
    } else if (responseJson && responseJson.owner_account && !this.state.isIdAddingToList) {
      let ownerIds = responseJson.owner_account.map((resOwnerId: any) => resOwnerId.toString());
      let newIdList: any[] = [];
      for (let attendeeId of this.state.meetingForm.attendeeIds) {
        if (ownerIds.includes(attendeeId)) {
          ownerIds = ownerIds.filter((Id: any) => Id !== attendeeId);
        } else {
          newIdList = [...newIdList, attendeeId.toString()];
        }
      }
      this.setState({ meetingForm: { ...this.state.meetingForm, attendeeIds: newIdList } });
    }
  };
  GetResidentIdsResponse = (responseJson: any) => {
    if (responseJson.resident_account && this.state.isIdAddingToList) {
      const newIdList = this.state.meetingForm.attendeeIds.concat(
        responseJson.resident_account.map((resOwnerId: any) => resOwnerId.toString())
      );
      this.setState({ meetingForm: { ...this.state.meetingForm, attendeeIds: newIdList } });
    } else if (responseJson.resident_account && !this.state.isIdAddingToList) {
      let residentIds = responseJson.resident_account.map((resResidentId: any) => resResidentId.toString());

      let newIdList: any[] = [];
      for (let attendeeId of this.state.meetingForm.attendeeIds) {
        if (residentIds.includes(attendeeId)) {
          residentIds = residentIds.filter((Id: any) => Id !== attendeeId);
        } else {
          newIdList = [...newIdList, attendeeId.toString()];
        }
      }
      this.setState({ meetingForm: { ...this.state.meetingForm, attendeeIds: newIdList } });
    }
  };
  GetFloorListResponse = (responseJson: any) => {
    if (responseJson && responseJson.floors) {
      this.setState({ floorList: responseJson.floors });
    }
  };

  addMeetingValidation: any = Yup.object().shape({
    title: Yup.string()
      .required("Required")
      .max(100, "Maximum length of title should be 100 characters")
      .matches(/^[^\s]/, "Title should not start with a space"),
    place: Yup.string()
      .required("Required")
      .max(100, "Maximum length of title should be 100 character")
      .matches(/\S/, "Required"),
    agenda: Yup.string()
      .required("Required")
      .max(100, "Maximum length of title should be 100 character")
      .matches(/^[^\s]/, "Title should not start with a space"),
    building: Yup.string(),
    date: Yup.string()
      .required("Required")
      .matches(/\S/, "Required"),
    status: Yup.string()
      .required("Required")
      .matches(/\S/, "Required"),
    time: Yup.string()
      .required("Required")
      .matches(/\S/, "Required")
      .when(["status", "date"], (status: any, date: any, schema: any) => {
        const newDate = date && moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
        return schema.test({
          test: (time: any) => {
            if (status == "scheduled" && date && time) {
              const test = moment(newDate + ` ${time}`, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
              return moment(test).isAfter(new Date());
            }
            return true;
          },
          message: "You have entered past time",
        });
      }),
    meetingType: Yup.string()
      .required("Required")
      .matches(/\S/, "Required"),
    attendeeIds: Yup.array().when("meetingGroupIds", {
      is: (meetingGroupIds) => meetingGroupIds.length === 0,
      then: Yup.array().min(1, "Required"),
    }),
  });

  // Get All Meeting API
  getAllMeetings = async () => {
    const { place, status, date, title, page, building } = this.state.filter;
    const filterBuilding = building === "select building" ? "" : building;
    const filterStatus = status === "select status" ? "" : status;
    const society_id = localStorage.getItem("society_id");
    this.GetAllMeetingsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meetings?place=${place}&status=${filterStatus}&title=${title.trim()}&date=${date}&page=${page}&search_building=${filterBuilding}`,
    });
  };

  // Schedule Meeting Detail API
  getScheduleMeetingDetail = async () => {
    const society_id = localStorage.getItem("society_id");
    this.GetScheduledMeetingDetailCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meetings/${this.state.scheduleMeetingId}`,
    });
  };

  // Get All Building List API
  getBuildingsList = async () => {
    const society_id = localStorage.getItem("society_id");
    this.GetAllBuildingsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/find_building`,
    });
  };

  // Create Meeting API
  createMeeting = async (values: MeetingForm) => {
    const body = {
      meeting: {
        title: values.title,
        place: values.place,
        agenda: values.agenda,
        building_management_id: values.building,
        date: values.date,
        time: values.time,
        meeting_type: values.meetingType,
        joinee_ids: values.attendeeIds,
        meeting_group_ids: values.meetingGroupIds,
        meeting_attandees_type: values.role,
      },
    };
    const society_id = localStorage.getItem("society_id");
    this.CreateMeetingCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meetings`,
      body: JSON.stringify(body)
    });
  };

  // Edit Meeting API
  editMeeting = async (values: MeetingForm) => {
    const body = {
      meeting: {
        title: values.title,
        place: values.place,
        agenda: values.agenda,
        building_management_id: values.building,
        date: values.date,
        time: values.time,
        status: values.status,
        meeting_type: values.meetingType,
        joinee_ids: values.attendeeIds,
        meeting_group_ids: values.meetingGroupIds,
        meeting_attandees_type: values.role,
      },
    };
    const society_id = localStorage.getItem("society_id");
    this.EditMeetingCallId = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meetings/${this.state.scheduleMeetingId}`,
      body: JSON.stringify(body)
    });
  };

  // Update Status Meeting API
  updateStatusMeeting = async (newStatus: string) => {
    const body = {
      meeting: {
        status: newStatus,
      },
    };
    const society_id = localStorage.getItem("society_id");
    this.UpdateStatusMeetingCallId = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meetings/${this.state.scheduleMeetingId}`,
      body: JSON.stringify(body)
    });
  };

  // Get Meeting Response API
  getMeetingResponseList = async () => {
    const { page } = this.state.filter;
    const society_id = localStorage.getItem("society_id");
    this.GetMeetingResponseCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_responses?meeting_id=${this.state.scheduleMeetingId}&page=${page}`,
    });
  };

  // Get User List API
  getUserList = async () => {
    const { buildingName, floorId, userType } = this.state.userFilter;
    const filterBuilding = buildingName === "select building" ? "" : buildingName;
    const filterType = userType === "select type" ? "" : userType;
    const society_id = localStorage.getItem("society_id");
    this.GetUserListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups/add_group_member?search_building=${filterBuilding}&search_floor_number=${floorId}&user_type=${filterType}`,
    });
  };

  // Get Group List API
  getGroupList = async () => {
    const society_id = localStorage.getItem("society_id");
    this.GetGroupListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups?building_management_id=${society_id}`,
    });
  };

  // Get Group Details API
  getGroupDetails = async (id: any) => {
    const society_id = localStorage.getItem("society_id");
    this.GetGroupDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups/${id}?building_management_id=${society_id}`,
    });
  };

  // Create Group API
  createGroup = async () => {
    const body = {
      meeting_group: {
        group_name: this.state.groupName,
        account_ids: this.state.selectedUser,
      },
    };
    const society_id = localStorage.getItem("society_id");
    this.CreateGroupCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups?building_management_id=${society_id}`,
      body: JSON.stringify(body)
    });
  };

  // Delete Group API
  deleteGroup = async (id: any) => {
    const society_id = localStorage.getItem("society_id");
    this.DeleteGroupListCallId = await apiCall({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups/${id}?building_management_id=${society_id}`,
    });
  };

  // Update Group API
  updateGroup = async () => {
    const body = {
      meeting_group: {
        group_name: this.state.groupName,
        account_ids: this.state.selectedUser,
      },
    };
    const society_id = localStorage.getItem("society_id");
    this.UpdateGroupCallId = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups/${this.state.groupId}?building_management_id=${society_id}`,
      body: JSON.stringify(body)
    });
  };

  // Get Owner Ids List API
  getOwnerIdsList = async () => {
    const society_id = localStorage.getItem("society_id");
    this.GetOwnerIdsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups/owner?building_management_id=${society_id}`,
    });
  };

  // Get Resident Ids List API
  getResidentIdsList = async () => {
    const society_id = localStorage.getItem("society_id");
    this.GetResidentIdsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups/resident?building_management_id=${society_id}`,
    });
  };

  // Get Floor List API
  getFloorIdsList = async (buildingId: any) => {
    const society_id = localStorage.getItem("society_id");
    this.GetFloorListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups/floor_listing?building_id=${buildingId}`,
    });
  };

  // Handle State
  handleCreateMeetingModal = () => {
    this.setState({
      isCreateMeetingModalOpen: !this.state.isCreateMeetingModalOpen,
    });
  };

  handleEditMeetingModal = () => {
    this.setState({
      isEditMeetingModalOpen: !this.state.isEditMeetingModalOpen,
    });
  };

  handleCancelMeetingModal = () => {
    this.setState({
      isCancelMeetingModalOpen: !this.state.isCancelMeetingModalOpen,
    });
  };

  handleCompleteMeetingModal = () => {
    this.setState({
      isCompleteMeetingModalOpen: !this.state.isCompleteMeetingModalOpen,
    });
  };

  handleCreateAttendeeModal = () => {
    this.setState({
      isCreateAttendeeModalOpen: !this.state.isCreateAttendeeModalOpen,
    });
  };

  openCreateAttendeeModal = () => {
    this.setState({ selectedUser: [], groupName: "", isEditAttendeeModalOpen: false }, () => {
      this.handleCreateAttendeeModal();
    });
  };

  openEditMeetingModal = (meeting: any) => {
    const meetingGroupList = meeting.attributes.meeting_groups.meeting_group.map((group: any) => group.id.toString());

    let selectedGroupList: any[] = [...meetingGroupList];
    let meetingRole: any[] = [];
    if (meeting.attributes.meeting_groups.meeting_owner) {
      selectedGroupList = [...selectedGroupList, "owner"];
      meetingRole = [...meetingRole, "owner"];
      this.setState({ isIdAddingToList: true }, () => {
        this.getOwnerIdsList();
      });
    }
    if (meeting.attributes.meeting_groups.meeting_resident) {
      selectedGroupList = [...selectedGroupList, "resident"];
      meetingRole = [...meetingRole, "resident"];
      this.setState({ isIdAddingToList: true }, () => {
        this.getResidentIdsList();
      });
    }

    this.setState(
      {
        scheduleMeetingId: meeting.id,
        selectedGroup: selectedGroupList,
        meetingForm: {
          ...this.state.meetingForm,
          title: meeting.attributes.title,
          place: meeting.attributes.place,
          agenda: meeting.attributes.agenda,
          building: meeting?.attributes?.building?.id || "",
          date: meeting.attributes.meeting_date_time
            .split(" ")[0]
            .split("-")
            .reverse()
            .join("-"),
          time: meeting.attributes.meeting_date_time.split(" ")[1],
          // momWriter: meeting.attributes.meeting_mins_writer.id,
          status: meeting.attributes.status,
          meetingType: meeting.attributes.meeting_type,
          meetingGroupIds: meetingGroupList,
          role: [...meetingRole],
        },
      },
      () => {
        this.handleEditMeetingModal();
      }
    );
  };

  openCreateMeetingModal = () => {
    this.setState(
      {
        meetingForm: {
          title: "",
          place: "",
          agenda: "",
          building: "",
          date: "",
          time: "",
          status: "scheduled",
          meetingType: "",
          attendeeIds: [],
          meetingGroupIds: [],
          role: [],
        },
        selectedGroup: []
      },
      () => {
        this.handleCreateMeetingModal();
      }
    );
  };

  addUser = (Id: any) => {
    this.setState({
      selectedUser: [...this.state.selectedUser, Id],
    });
  };

  removeUser = (Id: string) => {
    const newUserList = this.state.selectedUser.filter((id: any) => {
      return id !== Id;
    });
    this.setState({
      selectedUser: newUserList,
    });
  };

  selectAllUser = () => {
    const idList = this.state.userList.map((user: any) => user.id);
    this.setState({
      selectedUser: idList,
      isSelectAllUser: true,
    });
  };

  removeAllUser = () => {
    this.setState({
      selectedUser: [],
      isSelectAllUser: false,
    });
  };

  handleSelectedGroupList = (Id: string) => {
    const ROLE = {
      Owner: "owner",
      Resident: "resident",
    };
    if (!this.state.selectedGroup.includes(Id)) {
      this.setState({ selectedGroup: [...this.state.selectedGroup, Id] }, () => {
        if (Id === ROLE.Owner || Id === ROLE.Resident) {
          this.setState({ isIdAddingToList: true }, () => {
            this.handleGetIdAPICall(Id, ROLE);
          });
        } else {
          this.setState({
            meetingForm: {
              ...this.state.meetingForm,
              meetingGroupIds: [...this.state.meetingForm.meetingGroupIds, Id],
            },
          });
        }
      });
    } else {
      const newSelectedList = this.state.selectedGroup.filter((id: any) => id !== Id);
      this.setState({ selectedGroup: newSelectedList }, () => {
        if (Id === ROLE.Owner || Id === ROLE.Resident) {
          this.setState({ isIdAddingToList: false }, () => {
            this.handleGetIdAPICall(Id, ROLE);
          });
        } else {
          const newGroupIdsList = this.state.meetingForm.meetingGroupIds.filter((id: any) => id !== Id);
          this.setState({
            meetingForm: {
              ...this.state.meetingForm,
              meetingGroupIds: newGroupIdsList,
            },
          });
        }
      });
    }

    if ((Id === ROLE.Owner || Id === ROLE.Resident) && this.state.meetingForm.role.includes(Id)) {
      const newRoleList = this.state.meetingForm.role.filter((id: any) => id !== Id);
      this.setState({
        meetingForm: {
          ...this.state.meetingForm,
          role: newRoleList,
        },
      });
    } else {
      this.setState({ meetingForm: { ...this.state.meetingForm, role: [...this.state.meetingForm.role, Id] } });
    }
  };

  handleGetIdAPICall = (Id: any, ROLE: any) => {
    if (Id === ROLE.Owner) {
      this.getOwnerIdsList();
    } else {
      this.getResidentIdsList();
    }
  }

  scheduleMeetingModal = (language: any, t: any) => {
    return (
      <Dialog dir={languageCondition(language, "rtl", "ltr")} fullWidth scroll="paper" open={this.state.isCreateMeetingModalOpen} className="add-meeting schedule-meeting-schedule-modal">
        <MuiDialogTitle disableTypography className="dialog-heading">
          <Typography variant="h6" className="bold-text">
            {t("Create New Meeting")}
          </Typography>
          <IconButton onClick={() => this.handleCreateMeetingModal()}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.meetingForm}
          validationSchema={this.addMeetingValidation}
          onSubmit={(values, { resetForm }) => {
            this.setState({ loading: true }, () => {
              resetForm();
              this.handleCreateMeetingModal();
              this.createMeeting(values);
            });
          }}
        >
          {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <FormControl fullWidth>
                    <Select
                      value={values.meetingType}
                      onBlur={handleBlur}
                      name="meetingType"
                      displayEmpty
                      onChange={(e: any) => {
                        setFieldValue("meetingType", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, meetingType: e.target.value } });
                        touched["attendeeIds"] = false;
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        PaperProps: {
                          style: {
                            maxHeight: 200, 
                            marginTop: 8,  
                          },
                        },
                      }}
                      className="dialog-select-input schedule-meeting-type"
                    >
                      <MenuItem value="" disabled>
                        {t("Select Meeting Type")}
                      </MenuItem>
                      <MenuItem value="ga_meeting">{t("GA Meeting")}</MenuItem>
                      <MenuItem value="regular_meeting">{t("Regular Meeting")}</MenuItem>
                    </Select>
                    {this.handleFormError(t, errors.meetingType, touched.meetingType)}
                  </FormControl>
                  <FormControl fullWidth>
                    <Input
                      value={values.title}
                      name="title"
                      onChange={(e: any) => {
                        setFieldValue("title", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, title: e.target.value } });
                        touched["attendeeIds"] = false;
                      }}
                      onBlur={handleBlur}
                      placeholder={t("Title")}
                      className="dialog-input schedule-meeting-title"
                    />
                    {this.handleFormError(t, errors.title, touched.title)}
                  </FormControl>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <FormControl fullWidth>
                        <div className="date-time">
                          <input
                            value={values.date}
                            onBlur={handleBlur}
                            name="date"
                            className="date schedule-meeting-date"
                            min={moment().format("YYYY-MM-DD")}
                            onChange={(e: any) => {
                              setFieldValue("date", e.target.value);
                              this.setState({ meetingForm: { ...this.state.meetingForm, date: e.target.value } });
                              touched["attendeeIds"] = false;
                            }}
                            type="text"
                            onFocus={(e: any) => (e.target.type = "date")}
                            placeholder={t("Date")}
                          />
                          <img src={DateIcon} alt="" />
                        </div>
                        {this.handleFormError(t, errors.date, touched.date)}
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl fullWidth>
                        <div className="date-time">
                          <Input
                            value={values.time}
                            className="time schedule-meeting-time"
                            onChange={(e: any) => {
                              setFieldValue("time", e.target.value);
                              this.setState({ meetingForm: { ...this.state.meetingForm, time: e.target.value } });
                              touched["attendeeIds"] = false;
                            }}
                            onBlur={handleBlur}
                            name="time"
                            onFocus={(e: any) => (e.target.type = "time")}
                            type="text"
                            placeholder={t("Time")}
                            fullWidth
                            />
                          <img src={TimeIcon} alt="" />
                        </div>
                        {this.handleFormError(t, errors.time, touched.time)}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl fullWidth>
                    <Select
                      value={values.building}
                      name="building"
                      className="dialog-select-input schedule-meeting-building"
                      MenuProps={{
                        getContentAnchorEl: null, 
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            marginTop: 8,
                          },
                        },
                      }}
                      onChange={(e: any) => {
                        setFieldValue("building", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, building: e.target.value } });
                        touched["attendeeIds"] = false;
                      }}
                      onBlur={handleBlur}
                      displayEmpty
                    >
                      <MenuItem value="" disabled className="disable-building-menu">
                        {t("Select Building")}
                      </MenuItem>
                      <MenuItem value="" className="all-building">{t("All")}</MenuItem>
                      {this.state.buildingsList.map((building: any) => {
                        return (
                          <MenuItem value={building.id} key={building.id}>
                            {building.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {this.handleFormError(t, errors.building, touched.building)}
                  </FormControl>
                  <FormControl fullWidth>
                    <Input
                      value={values.place}
                      onBlur={handleBlur}
                      onChange={(e: any) => {
                        setFieldValue("place", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, place: e.target.value } });
                        touched["attendeeIds"] = false;
                      }}
                      placeholder={t("Place")}
                      name="place"
                      className="dialog-input schedule-meeting-place"
                    />
                    {this.handleFormError(t, errors.place, touched.place)}
                  </FormControl>
                  <FormControl fullWidth>
                    <TextareaAutosize
                      value={values.agenda}
                      onBlur={handleBlur}
                      name="agenda"
                      onChange={(e: any) => {
                        setFieldValue("agenda", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, agenda: e.target.value } });
                        touched["attendeeIds"] = false;
                      }}
                      className="dialog-textarea schedule-meeting-agenda"
                      placeholder={t("Agenda")}
                    />
                    {this.handleFormError(t, errors.agenda, touched.agenda)}
                  </FormControl>
                  {/* Create Audience */}
                  <FormControl fullWidth>
                    <Box className="create-audience schedule-meeting-create-group">
                      <p>{t("Select Meeting Attendees")}</p>
                      <span onClick={() => this.openCreateAttendeeModal()}>{t("+ Create New Group")}</span>
                    </Box>
                    <Box className="attendee-box schedule-meeting-group">
                      <Box
                        className={`${this.state.selectedGroup.includes("owner") && "active"} attendee`}
                        onClick={() => {
                          this.handleSelectedGroupList("owner");
                          touched["attendeeIds"] = true;
                        }}
                      >
                        {this.state.selectedGroup.includes("owner") ? (
                          <img src={BlueCheckIcon} alt="" />
                        ) : (
                          <img src={GreyCheckIcon} alt="" />
                        )}
                        <span className="bold-text">{t("Owner")}</span>
                      </Box>
                      <Box
                        className={`${this.state.selectedGroup.includes("resident") && "active"} attendee resident-attendee`}
                        onClick={() => {
                          this.handleSelectedGroupList("resident");
                          touched["attendeeIds"] = true;
                        }}
                      >
                        {this.state.selectedGroup.includes("resident") ? (
                          <img src={BlueCheckIcon} alt="" />
                        ) : (
                          <img src={GreyCheckIcon} alt="" />
                        )}
                        <span className="bold-text">{t("Resident")}</span>
                      </Box>
                      {this.state.groupList.map((group: any) => {
                        return (
                          <Box
                            key={group.id}
                            className={`${this.state.selectedGroup.includes(group.id.toString()) && "active"} attendee`}
                          >
                            {this.state.selectedGroup.includes(group.id.toString()) ? (
                              <img className="blue-check" src={BlueCheckIcon} alt="" />
                            ) : (
                              <img className="grey-check" src={GreyCheckIcon} alt="" />
                            )}
                            <span
                              className="bold-text custom-group"
                              onClick={() => {
                                this.handleSelectedGroupList(group.id.toString());
                                touched["attendeeIds"] = true;
                              }}
                            >
                              {group.attributes.group_name}
                            </span>
                            <Box className="schedule-meeting-group-menu">
                              <Menu direction="top" align="end" menuButton={<img src={Dots} alt="|" />} className="attendee-menu">
                                <MenuItem
                                  className="schedule-meeting-edit-group"
                                  onClick={() => {
                                    this.setState({ isEditAttendeeModalOpen: true, groupId: group.id }, () => {
                                      this.getGroupDetails(group.id);
                                      this.handleCreateAttendeeModal();
                                    });
                                  }}
                                >
                                  {t("Edit")}
                                </MenuItem>
                                <MenuItem className="schedule-meeting-delete-group" onClick={() => this.deleteGroup(group.id)}>{t("Delete")}</MenuItem>
                              </Menu>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                    {this.handleFormError(t, errors.attendeeIds, touched.attendeeIds)}
                  </FormControl>
                </DialogContent>
                <DialogActions className="dialog-button-group" style={{ gap:"10px" }}>
                  <Button className="cancel-button" onClick={() => this.handleCreateMeetingModal()}>
                    {t("Cancel")}
                  </Button>
                  <Button className="add-button" type="submit" style={{ margin:"0px" }}>
                    {t("Create")}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    );
  };

  handleFormError = (t: any, errors: any, touched: any) => {
    if (errors && touched) {
      return <small className="error">{t(errors)}</small>;
    }
  };

  editMeetingModal = (language: any, t: any) => {
    return (
      <Dialog dir={languageCondition(language, "rtl", "ltr")} fullWidth scroll="paper" className="add-meeting schedule-meeting-edit-modal" open={this.state.isEditMeetingModalOpen}>
        <MuiDialogTitle disableTypography className="dialog-heading">
          <Typography variant="h6" className="bold-text">
            {t("Edit Meeting")}
          </Typography>
          <IconButton onClick={() => this.handleEditMeetingModal()}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.meetingForm}
          validationSchema={this.addMeetingValidation}
          onSubmit={(values, { resetForm }) => {
            this.setState({ loading: true }, () => {
              this.handleEditMeetingModal();
              this.editMeeting(values);
            });
          }}
        >
          {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <FormControl fullWidth>
                    <Select
                      value={values.meetingType}
                      onChange={(e: any) => {
                        setFieldValue("meetingType", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, meetingType: e.target.value } });
                      }}
                      onBlur={handleBlur}
                      name="meetingType"
                      displayEmpty
                      className="dialog-select-input edit-meeting-type"
                    >
                      <MenuItem value="" disabled>
                        {t("Select Meeting Type")}
                      </MenuItem>
                      <MenuItem value="ga_meeting">{t("GA Meeting")}</MenuItem>
                      <MenuItem value="regular_meeting">{t("Regular Meeting")}</MenuItem>
                    </Select>
                    {this.handleFormError(t, errors.meetingType, touched.meetingType)}
                  </FormControl>
                  <FormControl fullWidth>
                    <Input
                      value={values.title}
                      onChange={(e: any) => {
                        setFieldValue("title", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, title: e.target.value } });
                      }}
                      onBlur={handleBlur}
                      name="title"
                      placeholder={t("Title")}
                      className="dialog-input edit-meeting-title"
                    />
                    {this.handleFormError(t, errors.title, touched.title)}
                  </FormControl>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <FormControl fullWidth>
                        <div className="date-time">
                          <input
                            value={values.date}
                            onChange={(e: any) => {
                              setFieldValue("date", e.target.value);
                              this.setState({ meetingForm: { ...this.state.meetingForm, date: e.target.value } });
                            }}
                            onBlur={handleBlur}
                            name="date"
                            className="date edit-meeting-date"
                            type="date"
                          />
                          <img src={DateIcon} alt="" />
                        </div>
                        {this.handleFormError(t, errors.date, touched.date)}
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl fullWidth>
                        <div className="date-time">
                          <Input
                            className="time edit-meeting-time"
                            value={values.time}
                            onChange={(e: any) => {
                              setFieldValue("time", e.target.value);
                              this.setState({ meetingForm: { ...this.state.meetingForm, time: e.target.value } });
                            }}
                            onBlur={handleBlur}
                            name="time"
                            fullWidth
                            type="time"
                          />
                          <img src={TimeIcon} alt="" />
                        </div>
                        {this.handleFormError(t, errors.time, touched.time)}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl fullWidth>
                    <Select
                      value={values.building}
                      onChange={(e: any) => {
                        setFieldValue("building", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, building: e.target.value } });
                      }}
                      onBlur={handleBlur}
                      name="building"
                      displayEmpty
                      className="dialog-select-input edit-meeting-building"
                    >
                      <MenuItem value="" disabled>
                        {t("Select Building")}
                      </MenuItem>
                      <MenuItem value="">{t("All")}</MenuItem>
                      {this.state.buildingsList.map((building: any) => {
                        return (
                          <MenuItem value={building.id} key={building.id}>
                            {building.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {this.handleFormError(t, errors.building, touched.building)}
                  </FormControl>
                  <FormControl fullWidth>
                    <Input
                      value={values.place}
                      onChange={(e: any) => {
                        setFieldValue("place", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, place: e.target.value } });
                      }}
                      onBlur={handleBlur}
                      name="place"
                      placeholder={t("Place")}
                      className="dialog-input edit-meeting-place"
                    />
                    {this.handleFormError(t, errors.place, touched.place)}
                  </FormControl>
                  <FormControl fullWidth>
                    <TextareaAutosize
                      value={values.agenda}
                      onChange={(e: any) => {
                        setFieldValue("agenda", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, agenda: e.target.value } });
                      }}
                      onBlur={handleBlur}
                      name="agenda"
                      placeholder={t("Agenda")}
                      className="dialog-textarea edit-meeting-agenda"
                    />
                    {this.handleFormError(t, errors.agenda, touched.agenda)}
                  </FormControl>
                  <FormControl fullWidth>
                    <Select
                      value={values.status}
                      onChange={(e: any) => {
                        setFieldValue("status", e.target.value);
                        this.setState({ meetingForm: { ...this.state.meetingForm, status: e.target.value } });
                      }}
                      onBlur={handleBlur}
                      name="status"
                      displayEmpty
                      className="dialog-select-input edit-meeting-status"
                    >
                      <MenuItem value="" disabled>
                        {t("Select Status")}
                      </MenuItem>
                      <MenuItem value="scheduled">{t("Scheduled")}</MenuItem>
                      <MenuItem value="completed">{t("Completed")}</MenuItem>
                      <MenuItem value="cancelled">{t("Cancelled")}</MenuItem>
                    </Select>
                    {this.handleFormError(t, errors.status, touched.status)}
                  </FormControl>
                  <FormControl fullWidth>
                    <Box className="create-audience">
                      <p>{t("Select Meeting Attendees")}</p>
                      <span onClick={() => this.openCreateAttendeeModal()}>{t("+ Create New Group")}</span>
                    </Box>
                    <Box className="attendee-box">
                      <Box
                        className={`${this.state.selectedGroup.includes("owner") && "active"} attendee`}
                        onClick={() => {
                          this.handleSelectedGroupList("owner");
                          touched["attendeeIds"] = true;
                        }}
                      >
                        {this.state.selectedGroup.includes("owner") ? (
                          <img src={BlueCheckIcon} alt="" />
                        ) : (
                          <img src={GreyCheckIcon} alt="" />
                        )}
                        <span className="bold-text">{t("Owner")}</span>
                      </Box>
                      <Box
                        className={`${this.state.selectedGroup.includes("resident") && "active"} attendee resident-edit-attendee`}
                        onClick={() => {
                          this.handleSelectedGroupList("resident");
                          touched["attendeeIds"] = true;
                        }}
                      >
                        {this.state.selectedGroup.includes("resident") ? (
                          <img src={BlueCheckIcon} alt="" />
                        ) : (
                          <img src={GreyCheckIcon} alt="" />
                        )}
                        <span className="bold-text">{t("Resident")}</span>
                      </Box>
                      {this.state.groupList.map((group: any) => {
                        return (
                          <Box
                            key={group.id}
                            className={`${this.state.selectedGroup.includes(group.id.toString()) && "active"} attendee`}
                          >
                            {this.state.selectedGroup.includes(group.id.toString()) ? (
                              <img src={BlueCheckIcon} alt="" />
                            ) : (
                              <img src={GreyCheckIcon} alt="" />
                            )}
                            <span
                              className="bold-text custom-edit-group"
                              onClick={() => {
                                this.handleSelectedGroupList(group.id.toString());
                                touched["attendeeIds"] = true;
                              }}
                            >
                              {group.attributes.group_name}
                            </span>
                            <Box className="edit-meeting-group-menu">
                              <Menu direction="top" align="end" menuButton={<img src={Dots} alt="|" />} className="attendee-menu">
                                <MenuItem
                                  className="edit-group-menu"
                                  onClick={() => {
                                    this.setState({ isEditAttendeeModalOpen: true, groupId: group.id }, () => {
                                      this.getGroupDetails(group.id);
                                      this.handleCreateAttendeeModal();
                                    });
                                  }}
                                >
                                  {t("Edit")}
                                </MenuItem>
                                <MenuItem className="delete-group-menu" onClick={() => this.deleteGroup(group.id)}>{t("Delete")}</MenuItem>
                              </Menu>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                    {this.handleFormError(t, errors.attendeeIds, touched.attendeeIds)}
                  </FormControl>
                </DialogContent>
                <DialogActions className="dialog-button-group" style={{ gap:"10px"}}>
                  <Button className="cancel-button" onClick={() => this.handleEditMeetingModal()}>
                    {t("Cancel")}
                  </Button >
                  <Button className="add-button" type="submit" style={{ margin:"0"}}>
                    {t("Save")}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    );
  };

  cancelMeetingModal = (language: any, t: any) => {
    return (
      <Dialog
        fullWidth
        dir={languageCondition(language, "rtl", "ltr")} 
        onClose={() => this.handleCancelMeetingModal()}
        open={this.state.isCancelMeetingModalOpen}
        className="cancel-meeting-dialog"
      >
        <DialogContent style={{ margin: "15px 0" }}>
          <Box textAlign="center">
            <img className="comment-image" src={CommentIcon} alt="comment" />
            <Typography variant="h6" className="bold-text">
              {t("Cancel Meeting Confirmation")}
            </Typography>
            <Typography variant="body1" style={{ marginBottom: "0px" }}>
              {t("Are you sure want to cancel the meeting scheduled on")}{" "}
              {moment(
                this.state.scheduleMeetingDetails && this.state.scheduleMeetingDetails.attributes.meeting_date_time,
                "DD-MM-YYYY HH:mm",
                true
              ).format("MMMM DD, YYYY HH:mm")}{" "}
              {t("at")} {this.state.scheduleMeetingDetails && this.state.scheduleMeetingDetails.attributes.place}? {t("Once cancelled, attendees will receive a meeting cancelation notification.")}
            </Typography>
            <DialogActions className="dialog-button-group" style={{ gap:"10px" }}>
              <Button className="cancel-button" style={{ width: "200px" }} onClick={() => this.handleCancelMeetingModal()}>
                {t("No, Don't Cancel")}
              </Button>
              <Button style={{ width: "200px", margin:"0" }} className="add-button" onClick={() => this.updateStatusMeeting("cancelled")}>
                {t("Yes, Cancel")}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  handleGroupHeader = (t: any) => {
    if(this.state.isEditAttendeeModalOpen) {
      return (
        <Typography variant="h6" className="bold-text">
          {t("Edit Meeting Group")}
        </Typography>
      )
    } else {
      return (
        <Typography variant="h6" className="bold-text">
          {t("Create Meeting Group")}
        </Typography>
      )
    }
  }

  removeBuildingAllFilter = () => {
    this.setState({
      place: "",
      status: "select status",
      date: "",
    });
  }

  removeStatusAllFilter = () => {
    this.setState({
      date: "",
    });
  }

  removePlaceAllFilter = () => {
    this.setState({
      status: "select status",
      date: "",
    });
  }

  createMeetingGroupModal = (language: any, t: any) => {
    return (
      <Dialog
        scroll="paper"
        dir={languageCondition(language, "rtl", "ltr")}
        fullWidth
        maxWidth="md"
        open={this.state.isCreateAttendeeModalOpen}
        className="select-meeting scheduled-meeting meeting-group-dialog"
      >
        <MuiDialogTitle disableTypography className="dialog-heading">
          {this.handleGroupHeader(t)}
          <IconButton onClick={() => this.handleCreateAttendeeModal()}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent dividers className="filter">
          <Select
            value={this.state.buildingName}
            displayEmpty
            className="dialog-select-input group-building-filter"
            onChange={(e: any) => {
              const building: string = e.target.value + "";
              this.setState({ buildingName: building }, () => {
                this.setState({
                  userType: "select type",
                })
              });
            }}
          >
            <MenuItem value="select building">{t("Select Building")}</MenuItem>
            <MenuItem value="">{t("All")}</MenuItem>
            {this.state.buildingsList.map((building: any) => {
              return (
                <MenuItem key={building.id} value={building.id}>
                  {building.name}
                </MenuItem>
              );
            })}
          </Select>
          <Select
            value={this.state.userType}
            displayEmpty
            className="dialog-select-input group-type-filter"
            onChange={(e: any) => {
              this.setState({ userType: e.target.value });
            }}
          >
            <MenuItem value="select type">{t("User Type")}</MenuItem>
            <MenuItem value="">{t("All")}</MenuItem>
            <MenuItem value="Owner">{t("Owner")}</MenuItem>
            <MenuItem value="Owner Resident">{t("Owner Resident")}</MenuItem>
            <MenuItem value="Tenant">{t("Tenant")}</MenuItem>
            <MenuItem value="Manager">{t("Manager")}</MenuItem>
            <MenuItem value="Property Manager">{t("Property Manager")}</MenuItem>
          </Select>
          <Button
            className="filter-button"
            startIcon={<img src={SearchIconImage} />}
            onClick={() => {
              this.setState({
                userFilter: {
                  ...this.state.userFilter,
                  buildingName: this.state.buildingName,
                  floorId: this.state.floorId,
                  userType: this.state.userType,
                },
              });
            }}
          >
            {t("Search")}
          </Button>
        </DialogContent>
        <DialogContent dividers>
          <TableContainer style={{ height: "400px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={this.state.isSelectAllUser}
                      onChange={(e: any) => (e.target.checked ? this.selectAllUser() : this.removeAllUser())}
                    />
                  </TableCell>
                  <TableCell style={{ minWidth: "150px" }} className="bold-text">{t("Name")}</TableCell>
                  <TableCell style={{ minWidth: "150px" }} className="bold-text">{t("Building")}</TableCell>
                  <TableCell style={{ minWidth: "150px" }} className="bold-text">{t("Unit No.")}</TableCell>
                  <TableCell style={{ minWidth: "150px" }} className="bold-text">{t("Floor Number")}</TableCell>
                  <TableCell className="bold-text">{t("User Type")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.userList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6}>{t("No User Available!!")}</TableCell>
                  </TableRow>
                )}
                {this.state.userList.map((user: any) => {
                  return (
                    <TableRow key={user.id}>
                      <TableCell>
                        <Checkbox
                          checked={this.state.selectedUser.includes(user.id)}
                          onChange={(e: any) => (e.target.checked ? this.addUser(user.id) : this.removeUser(user.id))}
                        />
                      </TableCell>
                      <TableCell style={{ minWidth: "150px" }}>{user.attributes.full_name}</TableCell>
                      <TableCell style={{ minWidth: "150px" }}>
                        {user.attributes.building_management.map((building: any) => building.name).toString()}
                      </TableCell>
                      <TableCell style={{ minWidth: "150px" }}>{user.attributes.unit_number.toString()}</TableCell>
                      <TableCell style={{ minWidth: "150px" }}>{user.attributes.floor_number.toString()}</TableCell>
                      <TableCell className="group-user-type">{user.attributes.user_type.toString()}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className="dialog-button-group">
          <div className="selected-meeting">
            <h4 className="bold-text">
              <span>{this.state.selectedUser.length} </span>{t("user selected")}
            </h4>
          </div>
          <div className="button-group" style={{ gap:"10px" }}>
            <Input
              value={this.state.groupName}
              onChange={(e: any) => {
                this.setState({
                  groupName: e.target.value,
                });
              }}
              name="title"
              placeholder={t("Group Name")}
              className="dialog-input"
            />
            {this.state.isEditAttendeeModalOpen ? (
              <Button
                className="add-button"
                disabled={this.state.selectedUser.length === 0 || !this.state.groupName}
                onClick={() => this.updateGroup()}
                style={{ height: "46px", margin:"0" }}
              >
                {t("Edit Group")}
              </Button>
            ) : (
              <Button
                className="add-button"
                disabled={this.state.selectedUser.length === 0 || !this.state.groupName}
                onClick={() => this.createGroup()}
                style={{ height: "46px", margin:"0" }}
              >
                {t("Create Group")}
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    );
  };
  // Customizable Area End
}
