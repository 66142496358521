// Customizable Area Start
import { Grid, styled } from "@material-ui/core";
const CreateIncidentContainer = styled(Grid)(({ theme }) => ({
  '& .header-wrapper': {
    padding: '20px 10px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '2px solid #f2efef',
    maxHeight: '60px',
    '& .header': {
      display: 'flex',
      columnGap: 10,
      alignItems: 'center',
      '& .header-text': {
        fontSize: '14px',
        fontFamily: 'Gotham_Bold !important',
        fontWeight: 800,
        [theme.breakpoints.up("md")]: {
          fontSize: '18px',
        }
      }
    },
  },
  '& .incident-content': {
    display: 'flex',
    width: '100%',
    padding: 10,
    overflowY: 'scroll',
    height: 'calc(100vh - 120px)',
    maxHeight: 'calc(100vh - 120px)',
    background: 'rgb(247, 249, 254)',
    [theme.breakpoints.up("md")]: {
      height: 'calc(100vh - 60px)',
      maxHeight: 'calc(100vh - 60px)',
    },
    '& .create-incident-content': {
      width: '100%',
      padding: '0 10px',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      maxHeight: '100%',
      overflowY: 'scroll',
      '& .content-header': {
        display: 'flex',
        '& .content-title': {
          width: '100%',
          fontSize: '14px',
          fontFamily: 'Gotham_Bold !important',
          [theme.breakpoints.up("md")]: {
            fontSize: '18px',
          }
        }
      },
      '& .incident-creation-form__wrapper': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        [theme.breakpoints.up("md")]: {
          width: '50%',
          margin: '0 auto',
        },
        '& .incident-creation-form': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          '& .form-content': {
            width: '100%',
            maxHeight: '80%',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            paddingBottom: 20,
            '& .form-input-group': {
              width: '99%',
              display: 'flex',
              flexDirection: 'column',
              '& .form-control': {
                width: '100%',
                color: '#979797',
                border: '1px solid #d1d1d1',
                borderRadius: '25px',
                background: 'rgb(242, 242, 242) !important',
                '&.square': {
                  borderRadius: '10px',
                },
                '& .MuiSelect-outlined': {
                  color: '#7a7878 !important',
                },
                '& .input-icon': {
                  marginRight: 10,
                },
                '& .form-select': {

                }
              },
              '& .form-input': {
                display: 'flex',
                width: '100%',
                position: 'relative',
                border: '1px solid #d1d1d1',
                borderRadius: '25px',
                background: 'rgb(242, 242, 242) !important',
                "& .input-icon": {
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: '50%',
                  left: '15px',
                  transform: 'translateY(-50%)',
                },
                "& .input-field": {
                  background: 'rgb(242, 242, 242) !important',
                  borderRadius: '25px',
                  width: '100%',
                  padding: '18px 18px 18px 50px',
                  border: 'none',
                  outline: 'none',
                  fontSize: '16px',
                  [theme.breakpoints.up("md")]: {
                    fontSize: '18px',
                  },
                  '&::placeholder': {
                    color: 'rgb(24, 24, 24) !important'
                  }
                },
                '&.file': {
                  border: '2px dashed rgb(209, 209, 209)',
                  minHeight: 150,
                  '& .MuiButton-label': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10,
                    '& .form-label': {
                      color: '#979797',
                    }
                  }
                }
              },
              '& .form-textarea-wrapper': {
                border: '1px solid #d1d1d1',
                borderRadius: '25px',
                position: 'relative',
                background: 'rgb(242, 242, 242) !important',
                "& .input-icon": {
                  position: 'absolute',
                  top: '18px',
                  left: '15px',

                },
                '& .form-textarea': {
                  background: 'transparent',
                  padding: '18px 18px 18px 50px',
                  width: '100%',
                  borderRadius: '25px',
                  border: 'none',
                  outline: 0,
                  '&::placeholder': {
                    color: 'rgb(24, 24, 24) !important'
                  }
                }
              }
            }
          }
        }
      }
    },
    '& .incident-preview-content': {
      width: '100%',
      padding: '0 10px',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      maxHeight: '100%',
      overflowY: 'scroll',
      '& .content-header': {
        display: 'flex',
        '& .content-title': {
          width: '100%',
          fontSize: '14px',
          fontFamily: 'Gotham_Bold !important',
          [theme.breakpoints.up("md")]: {
            fontSize: '18px',
          }
        }
      },
      '& .incident-preview-form__wrapper': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        paddingBottom: 20,
        [theme.breakpoints.up("md")]: {
          width: '50%',
          margin: '0 auto',
        },
        '& .form-content': {
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          '& .details-group': {
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            width: '100%',
            '& .detail-title': {
              fontSize: '14px',
              fontWeight: 500,
              color: '#6f6e71',
            },
            '& .detail-value': {
              fontSize: '14px',
              fontWeight: 600,
              display: 'flex',
              width: '100%',
              wordBreak: 'break-all'
            },
            '& .incident-attachements': {
              display: 'flex',
              flexWrap: 'wrap',
              gap: 10,
              '& .attachment-wrapper': {
                width: 60,
                height: 60,
                border: '1px solid',
                borderRadius: '10px',
                margin: 0,
                position: 'relative',

                '& .attachement-overlay': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px'
                },
                '& .incident-video': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                },
                '& .incident-image': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                },
                '& .incident-icon': {
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'rgb(255, 255, 255)'
                }
              }
            }
          }
        }
      }
    },
    '& .form-footer': {
      width: "100%",
      height: '60px',
      maxHeight: '60px',
      position: 'absolute',
      left: 0,
      bottom: 0,
      [theme.breakpoints.up("md")]: {
        position: 'inherit',
      },
      '& .form-submit': {
        width: '100%',
        background: '#2c6fed',
        fontSize: '16px',
        [theme.breakpoints.up("md")]: {
          fontSize: '18px',
        },
        color: '#FFFFFF',
        textTransform: "uppercase",
        fontWeight: 600,
        padding: 15,
        borderRadius: 10,
      }
    },
    '& .image-container': {
      display: 'none',
      [theme.breakpoints.up("md")]: {
        display: 'block',
        [theme.breakpoints.up("md")]: {
          maxHeight: '100%',
          overflow: 'auto'
        },
      },
      '& .image-wrapper': {
        width: '100%',
        height: '100%',
        display: 'flex',
        '& .right-image': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '50px',
        }
      }
    }
  }
}));
export default CreateIncidentContainer;
// Customizable Area End