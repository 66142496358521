// Customizable Area Start
import React from "react";
import { Button, Dialog, DialogContent, DialogActions, Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
export const image_realEstateCompany = require("../../assets/pollsurvey.png");
import CEAssignObjectController, { Props } from "./CEAssignObjectController.web";
import { Formik, Form } from "formik";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";

class CEAssignObject extends CEAssignObjectController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n, dataFromComponent1, handleModalClose, isEdit }: any = this.props;
    const language = i18n.language;
    const { usersList, countryList, cityList, nameList } = this.state;

    return (
      <Dialog dir={languageCondition(language, "rtl", "ltr")} open={dataFromComponent1} scroll="paper" fullWidth maxWidth="md">
        <DialogHeader t={t} title="Assign Object" onClose={handleModalClose} />
        <Formik
          onSubmit={(values, { resetForm }) => {
            this.postAssigneeAPI(values);
            resetForm();
          }}
          initialValues={this.state.initialValues}
          enableReinitialize={true}
          validationSchema={this.assignedValidationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} translate="yes">
                <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      {isEdit ? (
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Employee Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.EmpName}
                          data-test-id="assignee-name"
                          name="EmpName"
                          isLabel
                          disabled={true}
                        />
                      ) : (
                        <NormalSelect
                          t={t}
                          language={language}
                          label="Employee Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.EmpName}
                          name="EmpName"
                          data-test-id="assignee-name"
                          option={usersList}
                        />
                      )}
                      {ErrorHandler(t, touched.EmpName, errors.EmpName)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="Type of Assignment"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setFieldValue("Assignment", value);
                          setFieldValue("country", "");
                          setFieldValue("city", "");
                        }}
                        onBlur={handleBlur}
                        value={values.Assignment}
                        name="Assignment"
                        data-test-id="assignee-assignment"
                        option={[
                          { label: "Company", value: "Company" },
                          { label: "Individual Building", value: "Individual Building" },
                          { label: "Complex", value: "Complex" },
                        ]}
                      />
                      {ErrorHandler(t, touched.Assignment, errors.Assignment)}
                    </Grid>
                    {values.Assignment !== "" && (
                      <>
                        <Grid item xs={6}>
                          <NormalSelect
                            t={t}
                            language={language}
                            label={this.dynamicContent[values["Assignment"]]?.country}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("country", value);
                              setFieldValue("city", "");
                              this.getAssigneeCityListAPI(value);
                            }}
                            onBlur={handleBlur}
                            value={values.country}
                            name="country"
                            data-test-id="assignee-country"
                            option={countryList}
                          />
                          {ErrorHandler(t, touched.country, errors.country)}
                        </Grid>
                        <Grid item xs={6}>
                          <NormalSelect
                            t={t}
                            language={language}
                            label={this.dynamicContent[values["Assignment"]]?.city}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("city", value);
                              this.getAssigneeNameList(values.Assignment, values.country, value);
                            }}
                            onBlur={handleBlur}
                            value={values.city}
                            name="city"
                            data-test-id="assignee-city"
                            option={cityList}
                          />
                          {ErrorHandler(t, touched.city, errors.city)}
                        </Grid>
                        <Grid item xs={6}>
                          <NormalSelect
                            t={t}
                            language={language}
                            label={this.dynamicContent[values["Assignment"]]?.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nameOfAssignment}
                            name="nameOfAssignment"
                            data-test-id="assignee-nameOfAssignment"
                            option={nameList}
                          />
                          {ErrorHandler(t, touched.nameOfAssignment, errors.nameOfAssignment)}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog__button_group">
                  <Button className="cancel-button" onClick={handleModalClose}>
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button">
                    {t("Assign")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    );
  }
}

export default withTranslation()(CEAssignObject);
// Customizable Area End
