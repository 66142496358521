// Customizable Area Start
import * as React from "react";
import { Grid, Box, Divider, Typography, MenuItem } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { shortIcon } from "../../BroadcastMessage/src/assets";
import { withRouter } from "react-router";
import { Calander } from "./assets";
import BudgetSpendingController, { Props } from "./BudgetSpendingController";
import "./style.css";
import Select from "@material-ui/core/Select";
import { withTranslation } from "react-i18next";
import { Menu, MenuItem as MenuItemMenu } from "@szhsin/react-menu";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class Visitors extends BudgetSpendingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols budget-spending-page">
          <Grid container className="fee-payment-top-bar budget-spending-top-bar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} onClick={() => this.props.history.push("/BudgetSpending/ComplexList")} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text budget-spending-navigation-bar">
                  {t("View Budget Spending")}
                </p>
              </Box>
              <Box style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                <Box className={languageCondition(language, "", "budget-spending-menu")}>
                  <Menu menuButton={<img src={shortIcon} alt="" />} >
                    <MenuItemMenu className="budget-asc-menu" onClick={() => this.setState({ toggleAscDesc: "asc" }, () => this.getBudgetSpendingList())}>
                      {t("Asc")}
                    </MenuItemMenu>
                    <MenuItemMenu className="budget-desc-menu" onClick={() => this.setState({ toggleAscDesc: "desc" }, () => this.getBudgetSpendingList())}>
                      {t("Desc")}
                    </MenuItemMenu>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list budget-spending-list">
            <Grid container spacing={2} style={{ width: "94%",maxHeight:"75vh", overflow:"auto", scrollbarWidth:"none" }}>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontWeight: "bold" }}>
                  <Typography variant="body1" className="bold-text">
                    {sessionStorage.getItem("complexName")}
                  </Typography>
                  <Select
                    value={this.state.budgetYear}
                    className="selectFYBox buildingBudget-select-box"
                    disableUnderline
                    onChange={this.handleChange}
                    style={{ backgroundColor: "white", borderRadius: "100px" }}
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <MenuItem value={new Date().getFullYear() - 2} className="spend-prev-year">
                      {t("FY")} {new Date().getFullYear() - 2}-{(new Date().getFullYear() - 1).toString().slice(-2)}
                    </MenuItem>
                    <MenuItem value={new Date().getFullYear() - 1} className="spend-curr-year">
                      {t("FY")} {new Date().getFullYear() - 1}-{new Date().getFullYear().toString().slice(-2)}
                    </MenuItem>
                    <MenuItem value={new Date().getFullYear()} className="spend-next-year">
                      {t("FY")} {new Date().getFullYear()}-{(new Date().getFullYear() + 1).toString().slice(-2)}
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>
              {this.state.buildingBudgetResponse.length === 0 && (
                <Box
                  style={{
                    borderRadius: "8px",
                    background: "white",
                    padding: "15px",
                    boxShadow: "4px 0px 14px #e9e9e9",
                    width: "90%",
                    margin:"10px 0"
                  }}
                >
                  {t("No data available")}
                </Box>
              )}
              {this.state.buildingBudgetResponse.map((data: any) => {
                return (
                  <Grid item xs={12} key={data.id}>
                    <Box
                      className="budget-spending-box"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderRadius="8px"
                      bgcolor="white"
                      padding="18px"
                      style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                      onClick={() => {
                        const {complexId} = this.state;
                        this.props.history.push({ pathname: `/BudgetSpending/ComplexList/${complexId}/Spent/${data.id}`, state: { selectedInvoice: data } });
                      }}
                    >
                      <Box style={{ minWidth: "100%" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Typography variant={"body1"} className="bold-text">
                            {data?.attributes?.title} - {this.state.budgetYear}
                          </Typography>
                        </Box>
                        <Grid container spacing={1}>
                          <Grid item xs={6} style={{ marginTop: "10px" }}>
                            <Typography variant={"subtitle2"} color="textSecondary">
                              {t("Payment Amount")}:
                            </Typography>
                            <Typography variant={"body1"} style={{ marginTop: "2px" }}>
                              {LanguageAttributeHandler(language, data?.attributes?.currency?.currency, data?.attributes?.currency?.currency_ar)} {Number(data?.attributes?.paid_amount).toLocaleString()}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ alignSelf: "flex-end", marginTop: "10px" }}>
                            <Typography variant={"subtitle2"} color="textSecondary">
                              {t("Payment Date")}:
                            </Typography>
                            <Typography variant={"body1"} style={{ marginTop: "2px" }}>
                              {data?.attributes?.paid_date}
                            </Typography>
                          </Grid>
                          <Grid xs={12} style={{ marginBottom: "20px", marginTop: "10px" }}>
                            <Divider style={{ backgroundColor: "#f2f2f2" }} />
                          </Grid>
                          <Grid xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box display="flex" alignItems="center" style={{ gap: "10px" }}>
                              <img src={Calander} />
                              <Typography variant="subtitle2">
                                {data?.attributes?.paid_date}
                              </Typography>
                            </Box>
                            <Typography variant="subtitle2" className="paymentStatusGreen">
                              {t("Paid")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
              <Box style={{width:"100%"}}>
              <Grid item 
                  style={{
                    position: "fixed",
                    width: "94%",
                    bottom: "20px",
                    boxShadow:"rgb(233, 233, 233) 4px 0px 14px",
                    borderRadius: "15px 15px 0px 0px",
                    border: "1px solid #e4e4e4",
                    background:"white",
                  }}
                >
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px 15px" }}>
                    <Typography>{t("Approved Budget Amount")}</Typography>
                    <Typography className="bold-text budget-currency-amount">
                      {LanguageAttributeHandler(language, this.state.total?.currency?.currency, this.state.total?.currency?.currency_ar)} {Number(this.state.total?.approve_budget_spending).toLocaleString()}
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px 15px" }}>
                    <Typography>{t("Spent Amount")}</Typography>
                    <Typography className="bold-text budget-spent-amount">
                      {LanguageAttributeHandler(language, this.state.total?.currency?.currency, this.state.total?.currency?.currency_ar)} {Number(this.state.total?.spent_amount).toLocaleString()}
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px 15px" }}>
                    <Typography>{t("Reserve Amount")}</Typography>
                    <Typography className="bold-text budget-reserve-amount">
                      {LanguageAttributeHandler(language, this.state.total?.currency?.currency, this.state.total?.currency?.currency_ar)} {Number(this.state.total?.reserve_amount).toLocaleString()}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));

// Customizable Area End
