
//Customizable area start

import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { configJSON } from "../DashboardHeaderController.web";
import { Message } from "../../../../framework/src/Message";
import { getStorageData } from "../../../../framework/src/Utilities";
export interface Props {
  navigation: any;
  id: string;


}
interface ProfileDetail {
  id: string;
  type: string;
  attributes: {
    id: number;
    full_name: { value: string };
    bio: { bio: string | null };
    full_phone_number: { full_phone_number: null | string };
    email: { email: string | null };
    gender: { gender: string | null };
    date_of_birth: { date_of_birth: string | null };
    hobbies: { hobbies: string[] };
    website: [
      { twitter_link: string | null },
      { instagram_link: string | null },
      { fb_link: string | null },
      { snapchat_link: string | null }
    ];
    profile_pic: {
      url: string;
    } | null;
  };
}
interface S {
  open: boolean,
  open2: boolean,
  field: any,
  drawer: boolean,
  profileData: ProfileDetail | null;
  isLogoutModalOpen: boolean,
  role: any
  actualrole: any
}interface SS {
  id: any;
}
export default class DashboardHeaderController extends CommonApiCallForBlockComponent<Props, S, SS> {
  ConfirmPassCallID: any
  apiGetprofileApiCallId:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];
    this.state = {
      open: false,
      open2: false,
      field: "",
      drawer: false,
      isLogoutModalOpen: false,
      role: '',
      actualrole: '',
      profileData: null,
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if(apiRequestCallId===this.apiGetprofileApiCallId){
        if(responseJson){
          this.setState({
            profileData: responseJson.data
          })
        }
       
      }
    }
  }

  async componentDidMount() {
    await super.componentDidMount()

    const storedRole = localStorage.getItem('role');

   this.formatBackendString(storedRole)
   const user_id = localStorage.getItem("user_id") || "";
   let token  = await getStorageData("loginSuccessToken");

  this.getProfiledata(user_id,token)

  }
   formatBackendString=(storedRole:string|null)=> {
    if (storedRole !== null) {
      if(storedRole == "coo"){
        this.setState({actualrole:"COO"})
      }else{
        const words = storedRole.split('_');
        const formattedWords = words.map(word => {
          const cleanedWord = word.replace(/"/g, ''); // Remove double quotes
          const capitalizedWord = cleanedWord.charAt(0).toUpperCase() + cleanedWord.slice(1).toLowerCase();
          return capitalizedWord;
        });
        const rolee = formattedWords.join(' ');
        
        this.setState({ actualrole: rolee });
      }
      
    }
  
  }
 
  handleDrawerOpen = () => {
    this.setState({
      drawer: true,
    });
  }
  handleDrawerClose = () => {
    this.setState({
      drawer: false,
    });
  }
  handleOpen = (e: any) => {
    const ExpandElement = document.querySelector("#myExpand");
    this.setState({
      open: true,
      field: ExpandElement

    });

  }
  handleLogoutModal = () => {
    this.setState({ isLogoutModalOpen: false });
    console.log("abc", this.props.navigation)
  };
  logout = () => {
    localStorage.clear();
    window.location.href = '/CompanyEmployee/Login'
  }

  getProfiledata = async (userId:string,token:string) => {
    const header = {
      token: token,
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetprofileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserprofileDataEndPoint+`${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };




}
// Customizable Area End

