// Customizable Area Start
import React from "react";
import { Box, Button, Typography, Grid, InputLabel } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import RealEstateCompanyFormController, { Props } from "./RealEstateCompanyFormController.web";
import { withTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { CrossIcon, TickIcon, Upload_Icon } from "../assets";
import Loader from "../../../../components/src/Loader.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import PhoneInput from "react-phone-input-2";
import AlertError from "../../../../components/src/AlertError.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";

class RealEstateCompanyForm extends RealEstateCompanyFormController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Box style={webStyle.registerContainer}>
          <Typography style={{ fontSize: "18px" }}>
            <span style={webStyle.realEstateCompanyTitle} onClick={this.handleCancel}>{t("Real Estate Companies")}</span> /{" "}
            <span style={webStyle.linkColor}>{t(this.handleTitle())}</span>
          </Typography>
          <Typography style={webStyle.bigTitle} className="bold-text">{t(this.handleTitle())}</Typography>
            <Formik
              initialValues={this.state.formValue}
              validationSchema={this.validationSchema}
              onSubmit={(values, { resetForm }) => this.handleFormSubmit(values, resetForm)}
              enableReinitialize
            >
              {({ values, touched, errors, handleBlur, handleChange, setFieldValue, handleSubmit }) => {
                return (
                  <Form onSubmit={handleSubmit} translate="yes">
                    <Box maxWidth="xl" style={webStyle.formContainer}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} style={webStyle.logoGrid}>
                          {values.logo ? (
                            <img
                              src={typeof values.logo === "string" ? values.logo : URL.createObjectURL(values.logo)}
                              style={webStyle.logoHolder}
                            />
                          ) : (
                            <Box style={webStyle.logoHolder}></Box>
                          )}
                          <label htmlFor="logoChange">
                            <span style={webStyle.logoBtn} className="bold-text">
                              {this.handleChangeLabel(values.logo, t)}
                            </span>
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            id="logoChange"
                            data-test-id="logoUpload"
                            onChange={(event: any) => {
                              const files = event.target.files;
                              const file = files[0];
                              setFieldValue("logo", file);
                            }}
                            style={{ display: "none" }}
                          />
                          {ErrorHandler(t, touched.logo, errors.logo)}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <NormalTextField
                            value={values.company}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="company"
                            t={t}
                            language={language}
                            label={t("Company Name")}
                            isLabel
                          />
                          {ErrorHandler(t, touched.company, errors.company)}
                        </Grid>

                        {this.state.accountType === "coo" && (
                          <>
                            <Grid item xs={6} sm={6}>
                              <NormalSelect
                                onBlur={handleBlur}
                                t={t}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = event.target.value;
                                  setFieldValue("salesManager", value);
                                  this.getFilterListForm("GetAccountFilterList", `bx_block_my_team/company_employee/employee_team_members/account_manager_for_sales_manager?id=${value}`)
                                }}
                                value={values.salesManager} 
                                label={t("Sales Manager")}  
                                option={this.state.salesManagerList}
                                name="salesManager"
                                language={language}
                                data-test-id="form-salesManager"
                              />
                              {ErrorHandler(t, touched.salesManager, errors.salesManager)}
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <NormalSelect
                                onBlur={handleBlur}
                                t={t}
                                onChange={handleChange}
                                value={values.accountManager}
                                label={t("Account Manager")}
                                option={this.state.accountManagerList}
                                name="accountManager"
                                language={language}
                              />
                              {ErrorHandler(t, touched.accountManager, errors.accountManager)}
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12} sm={12}>
                          <InputLabel style={webStyle.formLabel}>
                            {t("Point of Contact")} <span className="bold-text">({t("Primary")})</span>
                          </InputLabel>
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                              <NormalTextField
                                value={values.primaryContact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="primaryContact"
                                t={t}
                                language={language}
                                label={t("Contact Name")}
                                isLabel={false}
                              />
                              {ErrorHandler(t, touched.primaryContact, errors.primaryContact)}
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <NormalTextField
                                value={values.primaryEmail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="primaryEmail"
                                t={t}
                                language={language}
                                label={t("Email Address")}
                                isLabel={false}
                              />
                              {ErrorHandler(t, touched.primaryEmail, errors.primaryEmail)}
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Box className="form__phone_number real_estate_phone">
                                <PhoneInput
                                  inputProps={{ name: "primaryPhoneCountry" }}
                                  value={values.primaryPhoneCountry}
                                  enableSearch={true}
                                  onChange={(value: string) => {
                                    setFieldValue("primaryPhoneCountry", `+${value}`);
                                  }}
                                  data-test-id="primary-phone-country"
                                />
                                <NormalTextField
                                  t={t}
                                  language={language}
                                  label="Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isLabel={false}
                                  value={values.primaryPhone}
                                  name="primaryPhone"
                                  type="number"
                                />
                              </Box>
                              {ErrorHandler(t, touched.primaryPhone, errors.primaryPhone)}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <InputLabel style={webStyle.formLabel}>
                            {t("Point of Contact")} <span className="bold-text">({t("Secondary")})</span>
                          </InputLabel>
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                              <NormalTextField
                                value={values.secondaryContact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="secondaryContact"
                                t={t}
                                language={language}
                                label={t("Contact Name")}
                                isLabel={false}
                              />
                              {ErrorHandler(t, touched.secondaryContact, errors.secondaryContact)}
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <NormalTextField
                                value={values.secondaryEmail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="secondaryEmail"
                                t={t}
                                language={language}
                                label={t("Email Address")}
                                isLabel={false}
                              />
                              {ErrorHandler(t, touched.secondaryEmail, errors.secondaryEmail)}
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Box className="form__phone_number real_estate_phone">
                                <PhoneInput
                                  inputProps={{ name: "secondaryPhoneCountry" }}
                                  value={values.secondaryPhoneCountry}
                                  enableSearch={true}
                                  onChange={(value: string) => {
                                    setFieldValue("secondaryPhoneCountry", `+${value}`);
                                  }}
                                  data-test-id="secondary-phone-country"
                                />
                                <NormalTextField
                                  t={t}
                                  language={language}
                                  label="Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isLabel={false}
                                  value={values.secondaryPhone}
                                  name="secondaryPhone"
                                  type="number"
                                />
                              </Box>
                              {ErrorHandler(t, touched.secondaryPhone, errors.secondaryPhone)}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <NormalTextField
                            value={values.manageBy}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="manageBy"
                            t={t}
                            language={language}
                            label={t("Managed By")}
                            isLabel
                          />
                          {ErrorHandler(t, touched.manageBy, errors.manageBy)}
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <NormalSelect
                            onBlur={handleBlur}
                            t={t}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const value = event.target.value;
                              setFieldValue("country", value);
                              this.getFilterListForm(
                                "GetCityFilterList",
                                `bx_block_address/city_list?country=${value}`
                              );
                              this.getFilterListForm(
                                "GetRegionsFilterList",
                                `bx_block_address/region_list?country=${value}`
                              );
                            }}
                            value={values.country}
                            label={t("Country")}
                            option={this.state.countriesList}
                            name="country"
                            language={language}
                            data-test-id="form-country"
                          />
                          {ErrorHandler(t, touched.country, errors.country)}
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <NormalSelect
                            onBlur={handleBlur}
                            t={t}
                            onChange={handleChange}
                            value={values.region}
                            label={t("Region")}
                            option={this.state.regionList}
                            name="region"
                            language={language}
                          />
                          {ErrorHandler(t, touched.region, errors.region)}
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <NormalSelect
                            onBlur={handleBlur}
                            t={t}
                            onChange={handleChange}
                            value={values.city}
                            label={t("City")}
                            option={this.state.cityList}
                            name="city"
                            language={language}
                          />
                          {ErrorHandler(t, touched.city, errors.city)}
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <NormalTextField
                            value={values.zipCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="zipCode"
                            t={t}
                            language={language}
                            label={t("Zip Code")}
                            isLabel
                          />
                          {ErrorHandler(t, touched.zipCode, errors.zipCode)}
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <NormalTextField
                            value={values.mapLocation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="mapLocation"
                            t={t}
                            language={language}
                            label={t("Google Map Location")}
                            isLabel
                          />
                          {ErrorHandler(t, touched.mapLocation, errors.mapLocation)}
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <NormalTextField
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="address"
                            t={t}
                            language={language}
                            label={t("HQ Location Address")}
                            isLabel
                          />
                          {ErrorHandler(t, touched.address, errors.address)}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <NormalTextField
                            value={values.registrationNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="registrationNo"
                            t={t}
                            language={language}
                            label={t("Commercial Registration Number")}
                            isLabel
                          />
                          {ErrorHandler(t, touched.registrationNo, errors.registrationNo)}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <InputLabel style={webStyle.formLabel}>
                            {t("Registration Certificate (Government Proof)")}
                          </InputLabel>
                          <Box style={webStyle.docBox}>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                              <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                                {values.certificate ? (
                                  <Box style={webStyle.docName}>
                                    {values.certificate?.name}
                                    <Box
                                      style={{ paddingLeft: "22px", cursor: "pointer" }}
                                      className="policy-remove-doc"
                                      onClick={() => setFieldValue("certificate", null)}
                                    >
                                      <img src={CrossIcon} />
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box style={webStyle.uploadLabel}>{t("Upload Policy Documents")}</Box>
                                )}
                              </Box>
                              <Box style={{ margin: "8px 0px", cursor: "pointer" }}>
                                <label htmlFor="certificate-upload">
                                  <img src={Upload_Icon} alt="upload" />
                                </label>
                                <input
                                  type="file"
                                  accept=".pdf"
                                  id="certificate-upload"
                                  data-test-id="certificate-upload"
                                  onChange={(event: any) => {
                                    const files = event.target.files;
                                    const file = files[0];
                                    setFieldValue("certificate", file);
                                  }}
                                  onBlur={handleBlur}
                                  style={{ display: "none" }}
                                />
                              </Box>
                            </Box>
                          </Box>
                          {ErrorHandler(t, touched.certificate, errors.certificate)}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box style={webStyle.btnContainer}>
                      {this.state.accountType === "coo" ? (
                        <Button type="submit" style={webStyle.submitBtnCoo}>
                          <span className="bold-text" style={webStyle.btnText}>
                            {this.props.companyId ? t("Edit") : t("Register")}
                          </span>
                        </Button>
                      ) : (
                        <Button type="submit" style={webStyle.submitBtn}>
                          <span className="bold-text" style={webStyle.btnText}>
                            {t("SUBMIT FOR APPROVAL")}
                          </span>
                        </Button>
                      )}
                    </Box>
                  </Form>
                );
              }}
            </Formik>
        </Box>

        <AlertDialog
          IsOpen={this.state.createConfirmModal}
          Image={TickIcon}
          CloseDialog={this.handleCloseCreateModal}
          Header={t("New Company Created")}
          Content={`<p>${
            this.state.accountType === "coo"
              ? t("Company has been successfully created on the platform.")
              : t("Approval request has been sent to the COO once the request get approved. Company will be added.")
          }</p>`}
          DeclineText={t("OKAY")}
          DeclineFun={this.handleCloseCreateModal}
          data-test-id="company-created-modal"
        />

        <AlertDialog
          IsOpen={this.state.editConfirmModal}
          Image={TickIcon}
          CloseDialog={this.handleCloseEditModal}
          Header={t("Company Details Edited")}
          Content={`<p>${
            this.state.accountType === "coo"
              ? t("Company has been successfully edited on the platform.")
              : t("Approval request has been sent to the COO once the request get approved. Company will be edited.")
          }</p>`}
          DeclineText={t("OKAY")}
          DeclineFun={this.handleCloseEditModal}
          data-test-id="company-edited-modal"
        />

        <AlertError
          show={this.state.showError}
          handleClose={this.handleCloseCreateCompanyModal}
          data-test-id="form-alert-error"
          message={t(this.state.error)}
        />

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const webStyle = {
  btnText: { fontSize: "16px" },
  linkColor: { color: "rgb(43, 111, 237)" },
  bigTitle: { fontSize: "32px", fontWeight: 700 },
  formContainer: {
    background: "#fff",
    padding: "15px",
    marginTop: "30px",
    boxShadow: "4px 0px 14px 0px #E9E9E9",
    borderRadius: "8px",
    overflow: "hidden",
  },
  tickIcon: { color: "rgb(252, 132, 52) none repeat scroll 0% 0%", fontSize: "45px" },
  registerContainer: { padding: "0 30px 30px" },
  cancelBtn: {
    borderColor: "#2B6FED",
    color: "#2B6FED",
    width: "180px",
    height: "50px",
    marginRight: "15px",
    borderRadius: "8px",
  },
  submitBtnCoo: {
    background: "#2B6FED",
    borderColor: "#2B6FED",
    color: "#fff",
    width: "180px",
    height: "50px",
    boxShadow: "0px 6px 14px rgba(43,111,237,0.22)",
    borderRadius: "8px",
  },
  submitBtn: {
    background: "#2B6FED",
    borderColor: "#2B6FED",
    color: "#fff",
    width: "250px",
    height: "50px",
    boxShadow: "0px 6px 14px rgba(43,111,237,0.22)",
    borderRadius: "8px",
  },
  btnContainer: { textAlign: "right" as CSSProperties["textAlign"], marginTop: "30px" },
  logoHolder: {
    width: "66px",
    height: "66px",
    borderRadius: "50%",
    background: "#dcdcdc",
  },
  logoBtn: { color: "#FC8434" },
  logoGrid: {
    textAlign: "center" as CSSProperties["textAlign"],
    display: "flex",
    flexDirection: "column" as CSSProperties["flexDirection"],
    justifyContent: "center",
    alignItems: "center",
    marginTop: "24px",
  },
  formLabel: {
    marginBottom: "8px",
    color: "black",
    fontSize: "14px",
  },
  docBox: {
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #f0f0f0",
    marginBottom: "0px",
    padding: "8px 25px 8px 8px",
  },
  docName: {
    borderRadius: "8px",
    border: "1px solid #F0F0F0",
    display: "flex",
    alignItems: "center",
    background: "#FFF",
    marginRight: "15px",
    padding: "8px 15px",
  },
  uploadLabel: { paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 },
  realEstateCompanyTitle: {cursor: 'pointer'},
};

export default withTranslation()(RealEstateCompanyForm);
// Customizable Area End
