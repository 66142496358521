//@ts-ignore
//@ts-nocheck
import React from "react";
//components
// Customizable Area Start
import {
  Box,
  Button,
  Link,
  Typography,
  IconButton,
  Grid, DialogContent, Divider, Dialog,
} from "@material-ui/core";
import AlertErrorWeb from "../../../components/src/AlertError.web";
//images
import {
  Tenant_Logo, Building_Logo, Building1,
  Email_Icon, Lock_User_Icon
} from "../src/assets";
//resources
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withRouter } from 'react-router';
import { Formik, Form, Field } from "formik";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";
import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController.web";
import Loader from "../../../components/src/Loader.web";
import { Dashboard } from "../../dashboard/src/assets";
class EmailAccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        <Box className="login-wrapper ownerResidentLogin" style={{ backgroundColor: "white" }}>
          <Grid container spacing={2} className="auth-container ownerResidentLogin">
            <Grid item xs={12} md={7} className="auth-cols ownerResidentLogin">
              <Box className="content-block ownerResidentLogin">
                <Box display={{ xs: 'flex', md: 'flex' }} className="backIcon ownerResidentLogin" onClick={() => window.history.back()}><KeyboardBackspaceIcon /></Box>
                <Box className="logo-block common-top-padding ownerResidentLogin" display={{ xs: 'none', md: 'flex' }}>
                  <Link href="/EmailAccountLogin">
                    <img src={Building_Logo.default} className="head-logo ownerResidentLogin" alt="" />
                    <h4 className="ownerResidentLogin">Building Name</h4>
                  </Link>
                </Box>
                <Box className="main-content-block desktop-ui inputPlaceholderRegistration ownerResidentLogin">
                  <Box className="header-block ownerResidentLogin" style={{ marginBottom: "30px" }}>
                    <Box className="ownerResidentLogin" display={{ xs: 'flex', md: 'none' }}>
                      <Link href="/EmailAccountLogin">
                        <img src={Tenant_Logo.default} className="tenant-logo" style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.TenantInt.com", '_blank', "noopener")} alt="" />
                      </Link>
                    </Box>
                    <h1 className="login-h1 bold-text ownerResidentLogin" style={{ fontSize: "26px" }}>Welcome Back</h1>
                    <p className="ownerResidentLogin">Login with your account credentials </p>
                  </Box>
                  <Formik
                    initialValues={{ email: "", password: "", userType: ' ', showPassword: false, stayIn: false }}
                    validateOnMount={true}
                    validationSchema={this.LoginSchema()}
                    onSubmit={(values) => { this.doLogIn(values); }}
                  >
                    {({ values, touched, errors, setFieldValue, handleChange }) => (
                      <Form translate="yes" className="commonForm ownerResidentLogin">
                        <Box style={{ marginBottom: "20px" }} className="ownerResidentLogin">
                          <Box
                            className="formGroup ownerResidentLogin"
                            style={{marginBottom: "0px",border: "0.1px solid rgb(209 209 209 / 100%)",borderRadius: "25px",backgroundColor: "#f9f9f9"
                            }}
                          >
                            <Field
                              name="email"
                              type="text"
                              placeholder="Email ID"
                              className="formInput ownerResidentLogin"
                              style={{
                                padding: '18px 50px 18px 50px',
                                color: '#212121',
                                borderRadius: '25px',
                                border: '1px solid #f4f4f4',
                                backgroundColor: '#f9f9f9',
                                fontSize: '16px',
                                outline: '0',
                              }}
                            />
                            <span className="frmLeftIcons ownerResidentLogin">
                              <img
                                src={Email_Icon}
                                className="frm-icons ownerResidentLogin"
                                alt="Email Icon"
                                style={{ marginTop: "-2px" }}
                              />
                            </span>
                          </Box>
                          {errors.email && touched.email ? (
                            <Typography
                              className="text-error ownerResidentLogin"
                              style={{ marginLeft: "5px", fontWeight: 600 }}
                            >
                              {errors.email}
                            </Typography>
                          ) : null}
                        </Box>
                        <Box style={{ marginBottom: "15px" }} className="ownerResidentLogin">
                          <Box
                            className="formGroup ownerResidentLogin"
                            style={{
                              marginBottom: "0px",
                              border: "0.1px solid rgb(209 209 209 / 100%)",
                              borderRadius: "25px",
                              backgroundColor: "#f9f9f9",
                              position: "relative",
                            }}
                          >
                            <Field
                              name="password"
                              type={values.showPassword ? "text" : "password"}
                              placeholder="Password"
                              className="formInput ownerResidentLogin"
                              style={{
                                padding: '18px 50px 18px 50px',
                                color: '#212121',
                                borderRadius: '25px',
                                border: '1px solid #f4f4f4',
                                backgroundColor: '#f9f9f9',
                                fontSize: '16px',
                                outline: '0',
                              }}
                            />
                            <span
                              className="frmLeftIcons ownerResidentLogin"
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <img
                                src={Lock_User_Icon}
                                className="frm-icons ownerResidentLogin"
                                alt="Lock Icon"
                                style={{ marginTop: "-3px",marginLeft: "9px"
                                }}
                              />
                            </span>
                            <span
                              className="frmrightIcons ownerResidentLogin"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <IconButton
                                className="ownerResidentLogin"
                                onClick={() => setFieldValue("showPassword", !values.showPassword)}
                                style={{ backgroundColor: "transparent" }}
                                disableRipple={true}
                              >
                                {values.showPassword ? (
                                  <Visibility
                                    className="ownerResidentLogin"
                                    style={{
                                      width: 30,
                                      height: 30,
                                      color: "#000000",
                                      opacity: 0.54,
                                    }}
                                  />
                                ) : (
                                  <VisibilityOff
                                    className="ownerResidentLogin"
                                    style={{
                                      width: 26,
                                      height: 26,
                                      color: "#000000",
                                      opacity: 0.54,
                                    }}
                                  />
                                )}
                              </IconButton>
                            </span>
                          </Box>
                          {errors.password && touched.password ? (
                            <Typography
                              className="text-error ownerResidentLogin"
                              style={{ marginLeft: "5px", fontWeight: 600 }}
                            >
                              {errors.password}
                            </Typography>
                          ) : null}
                        </Box>
                        <Box className="formGroup formCheckbox ownerResidentLogin">
                          <div>
                            <Checkbox
                              name="stayIn"
                              onChange={handleChange}
                              value={values.stayIn}
                              icon={<CircleUnchecked />}
                              checkedIcon={<CircleCheckedFilled />}
                              id="loginCheckbox"
                              style={{ paddingLeft: "0px" }}
                            />
                            <label htmlFor="loginCheckbox" className="checkboxLabel ownerResidentLogin">Stay logged in</label>
                          </div>
                          <Link href="/ForgotPassword" className="link bold-text ownerResidentLogin">Forgot Password?</Link>
                        </Box>
                        <Box className="customButton ownerResidentLogin">
                          <Button variant="contained" type="submit">Login</Button>
                        </Box>
                        <Box className="bottomBlock link-block ownerResidentLogin" style={{ marginTop: "80px" }}>
                          <Link href="#" className="link ownerResidentLogin">Don't have an account?</Link>
                          <Link href="/selecttype" className="link ownerResidentLogin"><span>Register</span></Link>
                        </Box>
                      </Form>
                    )}
                  </Formik>

                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block ownerResidentLogin" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          onClose={() => this.setState({ isMultiRole: false })}
          open={this.state.isMultiRole}
          className="cancel-meeting-dialog ownerResidentLogin" style={{ borderRadius: "50px" }}
        >
          <DialogContent style={{ margin: "0px 0" }}>
            <Box>
              <Box style={{ position: "absolute", right: "0px", top: "8px" }}>
                <IconButton className="ownerResidentLoginModalCloseButton" onClick={() => this.setState({ isMultiRole: false })}>
                  <Close />
                </IconButton>
              </Box>
              <Box style={{ width: "100%" }}>
                <Typography className="bold-text" variant="h6" style={{ fontSize: "22px", marginBottom: "15px" }}>{"Select Dashboard."}</Typography>
                <Divider />
                <Typography variant="body1" style={{ marginBottom: "0px", color: "#181d25", fontSize: "16px", textAlign: "center", marginTop: "10px" }}>
                  {"You are having both Dashboard access, Please Select Dashboard. You want to access!"}
                </Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15px", marginBottom: "20px" }}>
                <Box className="selectedComplexClick" onClick={this.goToTenantDashboard}
                  style={{ width: "85%", height: "40px", border: "1px solid rgb(217 217 217)", display: "flex", alignItems: 'center', padding: "10px", borderRadius: "90px", cursor: "pointer", marginBottom: "10px" }}>
                  <img src={Dashboard} style={{ width: "30px", height: "30px", marginRight: "10px", marginBottom: "0px", marginLeft: "15px" }} />
                  <Typography variant="body1" className="bold-text" style={{ marginBottom: "0px", fontSize: "16px" }}>Tenant Dashboard</Typography>
                </Box>
                <Box className="selectedComplexClick" onClick={this.goToOwnerDashboard}
                  style={{ width: "85%", height: "40px", border: "1px solid rgb(217 217 217)", display: "flex", alignItems: 'center', padding: "10px", borderRadius: "90px", cursor: "pointer", marginBottom: "10px" }}>
                  <img src={Dashboard} style={{ width: "30px", height: "30px", marginRight: "10px", marginBottom: "0px", marginLeft: "15px" }} />
                  <Typography variant="body1" className="bold-text" style={{ marginBottom: "0px", fontSize: "16px" }}>Owner Dashboard</Typography>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
        <Loader loading={this.state.loading} />
        <AlertErrorWeb show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
      </>
    );
  }
}
export default withRouter(EmailAccountLogin)

// Customizable Area End
