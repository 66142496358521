// Customizable Area Start
import React from "react";
import { Box, Grid, Button, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { Formik, Form } from "formik";
import CEAddNewTeamMebmerModalController, { Props } from "./CEAddNewTeamMemberController.web";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import AlertError from "../../../../components/src/AlertError.web";

class CEAddNewTeamMember extends CEAddNewTeamMebmerModalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n, closeModal, openModal }: any = this.props;
    const language = i18n.language;
    const { userNameList, nationalityList, disableState, reportingPersonList } = this.state;

    return (
      <>
      <Dialog dir={languageCondition(language, "rtl", "ltr")} open={openModal} scroll="paper" fullWidth maxWidth="md">
        <DialogHeader t={t} title={`${t(this.props.isEdit ? "Edit Team Member" : "Add New Team Member")}`} onClose={closeModal} />
        <Formik
          onSubmit={(values, { resetForm }) => {
            this.addTeamMember(values);
            resetForm();
          }}
          initialValues={this.state.userdata}
          enableReinitialize={true}
          validationSchema={this.validationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} translate="yes">
                <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="Employee Name"
                        onChange={(e: any) => {
                          setFieldValue("name", e.target.value);
                          this.handleGetUserDataById(e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.name}
                        data-test-id="add-team-member-name"
                        name="name"
                        disabled={disableState.name}
                        option={userNameList}
                      />
                      {ErrorHandler(t, touched.name, errors.name)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="Role"
                        onChange={(e: any) => {
                          setFieldValue("role", e.target.value);
                          this.getTeamReportingPersonListAPI(e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.role}
                        data-test-id="add-team-member-role"
                        name="role"
                        disabled={disableState.role}
                        option={this.getUpdatedList()}
                      />
                      {ErrorHandler(t, touched.role, errors.role)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label="Email Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        data-test-id="add-team-member-email"
                        name="email"
                        isLabel
                        disabled={disableState.email}
                      />
                      {ErrorHandler(t, touched.email, errors.email)}
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="form__phone_number">
                        <PhoneInput inputProps={{ name: "selectCode" }} country={values.country.toLowerCase() || "us"} enableSearch={true} disabled />
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Phone Number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isLabel
                          value={values.phoneNumber}
                          data-test-id="add-team-member-phone"
                          name="phoneNumber"
                          disabled={disableState.phoneNumber}
                        />
                      </Box>
                      {ErrorHandler(t, touched.phoneNumber, errors.phoneNumber)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="Nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nationality}
                        data-test-id="add-team-member-nationality"
                        name="nationality"
                        disabled={disableState.nationality}
                        option={nationalityList}
                      />
                      {ErrorHandler(t, touched.nationality, errors.nationality)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="Country"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.country}
                        data-test-id="add-team-member-country"
                        name="country"
                        disabled={disableState.country}
                        option={values.country !== "" ? [{ label: this.state.userdata.country, value: this.state.userdata.country }] : []}
                      />
                      {ErrorHandler(t, touched.country, errors.country)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="City"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        data-test-id="add-team-member-city"
                        name="city"
                        disabled={disableState.city}
                        option={values.city !== "" ? [{ label: this.state.userdata.city, value: this.state.userdata.city }] : []}
                      />
                      {ErrorHandler(t, touched.city, errors.city)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="Reporting Person"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reportingPersonId}
                        data-test-id="add-team-member-reportingperson"
                        name="reportingPersonId"
                        disabled={disableState.reportingPerson}
                        option={reportingPersonList}
                      />
                      {ErrorHandler(t, touched.reportingPersonId, errors.reportingPersonId)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label="Employee ID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isLabel
                        value={values.empID}
                        data-test-id="add-team-member-amployeeId"
                        name="empID"
                        disabled={disableState.empID}
                      />
                      {ErrorHandler(t, touched.empID, errors.empID)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label="National/Residency ID"
                        onChange={handleChange}
                        isLabel
                        onBlur={handleBlur}
                        value={values.ResidencyID}
                        data-test-id="add-team-member-nationla-id"
                        name="ResidencyID"
                        disabled={disableState.ResidencyID}
                      />
                      {ErrorHandler(t, touched.ResidencyID, errors.ResidencyID)}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog__button_group">
                  <Button className="cancel-button" onClick={closeModal}>
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button">
                    {t(this.props.isEdit ? "Edit" : "Create")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>

      <AlertError show={this.state.showError} handleClose={()=> this.setState({ showError:false })} message={this.state.error} />
      </>
    );
  }
}

export default withTranslation()(CEAddNewTeamMember);
// Customizable Area End
