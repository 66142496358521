// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import CompEmpBuildingBlockStep4Controller, { Props } from "./CompEmpBuildingBlockStep4Controller.web";
import "../../assets/commonForm.css";
import Loader from "../../../../components/src/Loader.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";

export default class CompEmpBuildingBlockStep4 extends CompEmpBuildingBlockStep4Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Formik
          initialValues={this.state.locationFormValue}
          validationSchema={this.validationSchema}
          enableReinitialize
          data-test-id="location-building-form"
          onSubmit={(values) => {
            this.setState({ loading: true }, () => {
              this.handleIBuildingLocationSubmit(values);
            });
          }}
        >
          {({ values, touched, errors, handleBlur, setFieldValue, handleChange, handleSubmit }) => (
            <Form translate="yes" onSubmit={handleSubmit}>
              <Box className="topBarFormik">
                <Box style={webStyle.heading}>
                  <Typography style={{ fontWeight: "bold", fontSize: "22px" }} className="Location bold-text">
                    {t("Location")}
                  </Typography>
                  <Typography style={{ fontSize: "16px" }} className="steplocation">
                    {t("Step")} {this.props.step + 1} {t("of 7")}
                  </Typography>
                </Box>
                <Box style={{ marginTop: "30px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.link}
                        t={t}
                        onBlur={handleBlur}
                        name="link"
                        required
                        language={language}
                        onChange={handleChange}
                        label={t("Location Link")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.link, errors.link)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.long}
                        onChange={handleChange}
                        required
                        onBlur={handleBlur}
                        name="long"
                        t={t}
                        language={language}
                        label={t("Longitude")}
                        type="number"
                        isLabel
                      />
                      {ErrorHandler(t, touched.long, errors.long)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.lat}
                        onChange={handleChange}
                        required
                        onBlur={handleBlur}
                        name="lat"
                        type="number"
                        t={t}
                        language={language}
                        label={t("Latitude")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.lat, errors.lat)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        required
                        language={language}
                        label="Country"
                        value={values.country}
                        name="country"
                        onChange={(event: any) => {
                          const value = event.target.value;
                          setFieldValue("country", value);
                          this.getIBuildingStateList(value);
                        }}
                        onBlur={handleBlur}
                        option={this.state.countriesList}
                        data-test-id="location-country-select"
                      />
                      {ErrorHandler(t, touched.country, errors.country)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        required
                        language={language}
                        label="State or Area"
                        value={values.state}
                        name="state"
                        onChange={(event: any) => {
                          const value = event.target.value;
                          setFieldValue("state", value);
                          this.getIBuildingCityList(value);
                        }}
                        onBlur={handleBlur}
                        option={this.state.stateList}
                        data-test-id="location-state-select"
                      />
                      {ErrorHandler(t, touched.state, errors.state)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="City"
                        value={values.city}
                        required
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        option={this.state.cityList}
                      />
                      {ErrorHandler(t, touched.city, errors.city)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.zipCode}
                        type="number"
                        onChange={handleChange}
                        required
                        onBlur={handleBlur}
                        name="zipCode"
                        t={t}
                        language={language}
                        label={t("Zip Code")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.zipCode, errors.zipCode)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        value={values.landmark}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="landmark"
                        t={t}
                        required
                        language={language}
                        label={t("Landmark")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.landmark, errors.landmark)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextField
                        value={values.street}
                        onChange={handleChange}
                        required
                        onBlur={handleBlur}
                        name="street"
                        t={t}
                        language={language}
                        label={t("Street Name")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.street, errors.street)}
                    </Grid>
                    <Grid item xs={12}>
                      <NormalTextField
                        value={values.address}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="address"
                        t={t}
                        language={language}
                        label={t("Official Address")}
                        isLabel
                      />
                      {ErrorHandler(t, touched.address, errors.address)}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                style={{ display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "20px" }}
                className="buildingBlockStep4"
              >
                <Grid style={{ marginTop: "30px" }} className="buildingBlockStep4">
                  <Button className="buildingBlockStep4" style={webStyle.backButton} onClick={this.props.handleBack}>
                    <span className="bold-text">{t("PREV")}</span>
                  </Button>
                </Grid>
                <Grid style={{ marginTop: "30px" }} className="buildingBlockStep4">
                  <Button className="buildingBlockStep4" style={webStyle.buttonSave} type="submit">
                    <span className="bold-text">{t("NEXT")}</span>
                  </Button>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const webStyle: any = {
  buttonSave: {
    width: "177px",
    textAlign: "center",
    textTransform: "capitalize",
    height: "56px",
    display: "flex",
    fontWeight: "bold",
    justifyContent: "center",
    color: "#FFFFFF",
    padding: "0px 20.5px",
    borderRadius: "8px",
    backgroundColor: "#2B6FED",
  },
  backButton: {
    padding: "0px 20.5px",
    color: "#2B6FED",
    justifyContent: "center",
    width: "177px",
    display: "flex",
    border: "1px solid #2B6FED",
    height: "56px",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontWeight: "bold",
    textAlign: "center",
  },
  heading: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
};
// Customizable Area End
