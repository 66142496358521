import React from "react";

// Customizable Area Start
import { Grid, Box, Typography, ListItem, Avatar, ListItemText, List, Modal } from "@material-ui/core";
import { withRouter } from "react-router";
import moment from "moment";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import InboxController, { Props } from "./inboxController.web";
import "../assets/css/style.scss";
import { DoubleTick, info, Send } from "./assets";
import { NoProfile_Img } from "../../user-profile-basic/src/assets";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../components/src/ConditionManager.web";
// Customizable Area End

class OwnerChatBox extends InboxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.refs = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  refs: any;
  interval: any;
  handleClick1 = () => {
    this.refs.fileUploader.click();
  }

  handleFile2(file: any) {
    //@ts-ignore
    //@ts-nocheck
    if (file && !["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
      return alert("Only png and jpeg are supported.");
    } else {
      this.setState({
        selectedMedia: { url: URL.createObjectURL(file), mimetype: file.type },
        accept: true,
        file: file,
      });
    }
  }

  async componentDidMount() {
    this.getSingleInbox();
    this.markUnread();
    this.interval = setInterval(() => {
      this.getSingleInbox();
    }, 3000);
  }

  async componentWillUnmount() {
    // @ts-ignore
    // @ts-nocheck
    clearInterval(this.interval);
  }

  messageList: any;
  dateToFromNowDaily(myDate: any) {
    let fromNow = moment.utc(myDate).fromNow();
    return moment(myDate).calendar(null, {
      lastWeek: "[Last] dddd",
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      sameElse: function() {
        return "[" + fromNow + "]";
      },
    });
  }

  displaytime(time: any) {
    let date = new Date(time || Date.now());
    let d = date.getHours();
    let m = date.getMinutes();
    let formattedM = m < 10 ? `0${m}` : m;
    return `${d}:${formattedM}`;
  }

  scrollToBottom = () => {
    const scrollHeight = this.messageList?.scrollHeight;
    if (scrollHeight) {
      const height = this.messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  handleModalHeader = () => {
    if(this.state.selectedMedia?.mimetype !== "application/pdf") {
      return (
        <Box data-test-id="image-modal" style={{ width: "300px", maxHeight: "26rem", margin: '0 auto', display: 'flex', alignItems:'center', justifyContent:'center' }} >
          <img src={this.state.selectedMedia?.url} alt="conversation-image" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        </Box>
      )
    } else {
      return <iframe src={this.state.selectedMedia?.url} style={{ width: "300px", height: "26rem" }} />
    }
  }

  MessageSection = (message: any, currentAccountId: any) => {
    return (
      <>
        {message.message.account_id != currentAccountId && (
          <img
            src={message.message.profile_pic.url || NoProfile_Img}
            alt="profile-pic"
            width="50"
            height="50"
            style={{ borderRadius: "50%", marginRight: 5 }}
          />
        )}
        <Grid
          item
          xs={12}
          // @ts-ignore
          style={
            message.message.account_id == currentAccountId
              ? { display: "flex", justifyContent: "center", alignItems: "flex-end", gap: "0", flexDirection: "column" }
              : { display: "flex", justifyContent: "start", alignItems: "start", gap: "0", flexDirection: "column" }
          }
        >
          <Box style={{ background: "#f6f6f6", borderRadius: "6px", padding: "0.5rem", borderTopRightRadius: 0 }}>
            <Typography
              align={message.message.account_id == currentAccountId ? "right" : "left"}
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#081F32",
                marginLeft: 5,
                fontFamily: "Poppins",
              }}
            ></Typography>
            {message.message.message.length > 45 ? (
              <>
                <Typography
                    align="left"
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#081F32",
                    wordBreak: "break-all",
                  }}
                >
                  {message.message.message}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  style={{
                    display: "flex",
                    fontSize: 14,
                    fontWeight: 500,
                    wordBreak: "break-all",
                    color: "#081F32",
                    alignItems: "center",
                  }}
                >
                  {message.message.account_id == currentAccountId && (
                    <div style={{ position: "relative", margin: "0 0.50rem" }}>
                      <img src={DoubleTick} />
                      <img src={DoubleTick} style={{ position: "absolute", left: "-4px" }} />
                    </div>
                  )}
                  {message.message.message}
                </Typography>
              </>
            )}
            {message?.message?.images.length != 0 && (
              <Grid item xs={12}>
                <img
                  className="owner-chat-box-image"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    //@ts-ignore
                    //@ts-nocheck
                    this.setState({ selectedMedia: message.message.images[0] });
                  }}
                  src={message.message.images[0].url}
                  width="75"
                  height="75"
                />
              </Grid>
            )}
          </Box>
          <ListItemText secondary={this.displaytime(message.message.created_at)} />
        </Grid>
      </>
    );
  };
  
  DisplayMessage = (item: any, t: any) => {
    return (
      <>
        <p>
          {item?.attributes?.chatable?.attributes?.disable_chat ? item?.attributes?.chatable?.attributes?.full_name : item?.attributes?.chat_with_account?.attributes?.full_name}{" "}
          {t("has disabled the chat. You won’t be able to send the message until he/she enables it.")}
        </p>
      </>
    );
  };
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    //@ts-ignore
    //@ts-nocheck
    const item = JSON.parse(localStorage.getItem("selectedChat"));
    const currentAccountId = localStorage.getItem("userId");
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <div
          dir={languageCondition(language, "rtl", "ltr")}
          style={{ padding: "0.3rem", backgroundColor: "#ffff", paddingLeft: "0.3rem", marginTop: "1rem" }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "1rem",
              }}
            >
              <Box className="owner-chat-back-btn" display="flex" alignItems="center" style={{ gap: "5px" }} onClick={() => window.history.back()}>
                <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                <span className="bold-text" style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {item?.attributes?.chat_with_account?.id != localStorage.getItem("userId") ? item?.attributes?.chat_with_account?.attributes?.full_name || "N/A" : item?.attributes?.chatable?.attributes?.full_name || "N/A"}
                </span>
              </Box>
            </Grid>

            <Grid xs={12}>
              <List
                style={{ overflowY: "auto", maxHeight: "75vh", minHeight: "75vh", overflowX: "hidden" }}
                ref={(div: any) => {
                  this.messageList = div;
                }}
              >
                {this.state.allInboxKey?.length != 0 &&
                  this.state.allInboxKey?.map((date, i) => (
                    <>
                      <Box key={i} display="flex" justifyContent="center" position="relative">
                        <p className="oval-shape">
                          {i > 1 ? this.dateToFromNowDaily(date) : moment.utc(date).format("MMM-DD-YYYY")}
                        </p>
                      </Box>
                      {this.state.singleChatRoom[date]?.map((message: any, i: any) => (
                        <>
                          <ListItem key={i}>
                            <Grid container style={{ display: "flex", gap: "2px", flexWrap: "nowrap" }}>
                              {this.MessageSection(message, currentAccountId)}
                            </Grid>
                          </ListItem>
                        </>
                      ))}
                    </>
                  ))}
              </List>

              {item?.attributes?.chatable?.attributes?.disable_chat || item?.attributes?.chat_with_account?.attributes?.disable_chat ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.5rem",
                      background: "#E7E1E1",
                      borderRadius: "6px",
                      boxShadow: "0px 4px 14px #f4f6fb",
                      padding: "0.75rem",
                      position: "fixed",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }}
                  >
                    <img src={info} width="20" height="20" />
                    {this.DisplayMessage(item, t)}
                  </div>
                </>
              ) : (
                <Grid
                  container
                  style={{
                    padding: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "fixed",
                    bottom: "0",
                    background: "white",
                  }}
                >
                  <Grid item style={{ width: 'calc(100% - 50px)', display: "flex", alignItems: "center", gap: "10px" }}>
                    <input
                      type=""
                      onChange={(e) => this.CreateNewMessage(e)}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.createMessages();
                        }
                      }}
                      className="owner-chat-box-input"
                      style={{
                        borderRadius: 15,
                        color: "#726363",
                        padding: 10,
                        width: "100%",
                        border: "1px solid #dcdcdc",
                      }}
                      value={this.state.newMessage}
                      placeholder={t("Type your message")}
                    />
                    {
                      // @ts-ignore
                      // @ts-nocheck
                      <AttachFileIcon onClick={this.handleClick1} for="BtnBrowseHidden" style={{ cursor: "pointer" }} />
                    }
                    <input
                      onChange={(e: any) => this.handleFile2(e.target.files[0])}
                      type="file"
                      id="BtnBrowseHidden"
                      style={{
                        height: "10px",
                        cursor: "pointer",
                        width: "10px",
                        zIndex: 2,
                        outline: "none",
                        opacity: 0,
                        position: "absolute",
                      }}
                      className="owner-chat-upload-input"
                      accept="image/png, image/jpeg, image/jpg,.pdf"
                      ref="fileUploader"
                    />
                  </Grid>
                  <Box
                    style={{ borderRadius: "50%", cursor: "pointer", padding: "10px", background: "#2B6FED" }}
                    className="owner-chat-send-chat"
                    onClick={() => {
                      this.createMessages();
                      this.scrollToBottom();
                    }}
                  >
                    <img src={Send} alt="send" className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Modal
            className="owner-chat-box-modal"
            open={this.state.selectedMedia}
            style={{ display: "flex", alignItems: "center", flexDirection: "column-reverse", justifyContent: "center" }}
            onClose={() => this.setState({ selectedMedia: null, accept: false })}
          >
            <div>
              {this.handleModalHeader()}
              {this.state.accept && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    onClick={() => this.setState({ selectedMedia: null, accept: false })}
                    className="owner-chat-cancel-btn"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "rgb(241, 78, 36)",
                      border: "1px solid rgb(217, 219, 233)",
                      height: 35,
                      boxShadow: "none",
                      marginTop: 10,
                      color: " rgb(247, 247, 252)",
                      fontSize: 13,
                      fontFamily: "Poppins",
                      marginRight: 10,
                      width: 150,
                    }}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    onClick={() =>
                      this.setState({ selectedMedia: null, accept: false }, () =>
                        this.handleSelectFile(this.state.file)
                      )
                    }
                    className="owner-chat-send-btn"
                    style={{
                      backgroundColor: "#ffff",
                      fontFamily: "Poppins",
                      height: 35,
                      border: "1px solid red",
                      borderRadius: "16px",
                      boxShadow: "none",
                      color: " red",
                      marginTop: 10,
                      marginRight: 10,
                      fontSize: 13,
                      width: 150,
                    }}
                  >
                    {t("Send")}
                  </button>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// @ts-ignore
// @ts-nocheck
export default withTranslation()(withRouter(OwnerChatBox));

// Customizable Area Start
// Customizable Area End
