// Customizable Area Start

import React from "react";
import {Box, Button, Grid, Link, Typography} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {withRouter} from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import ChairmanForgotPasswordController, {Props} from "./ChairmanForgotPasswordController.web";
import "@szhsin/react-menu/dist/core.css";
import {Building1, Building_Logo, Email_Icon, Tenant_Logo} from "../src/assets";
import {withTranslation} from "react-i18next";
import AlertErrorWeb from "../../../components/src/AlertError.web"
import {languageCondition} from "../../../components/src/ConditionManager.web";
import ChangeLanguageMenu from "../../../components/src/HeaderComponent/ChangeLanguageMenu.web";

class ChairmanForgotPassword extends ChairmanForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // @ts-ignore
        const {t,i18n} = this.props;
        const language = i18n.language;
        return (
            <>
                <Box className="login-wrapper  auth-wrapper forgotPasswordChairmanMainBox"
                     style={{backgroundColor: "white"}} dir={languageCondition(language,"rtl","ltr")}>
                    <Grid container spacing={2} className="auth-container forgotPasswordChairmanMainBoxGrid">
                        <Grid item xs={12} md={7} className="auth-cols forgotPasswordChairmanMainBoxGridItem">
                            <Box className="content-block forgotPasswordChairmanMainBoxBox">
                                <Box
                                    className="logo-block common-top-padding common-logo-block forgotPasswordChairmanMainBoxBoxImage"
                                    display={{xs: "none", md: "flex"}}>
                                    <Link href="/ManagementLogin" id="forgotPasswordChairmanMainBoxBoxImageLink">
                                        <img src={Building_Logo.default}
                                             className="head-logo forgotPasswordChairmanMainBoxBoxImageLinkImg" alt=""/>
                                        <h4 id="forgotPasswordChairmanMainBoxBuildingName">{t("Building Name")}</h4>
                                    </Link>
                                    <Box className="ForgotPasswordMenu">
                                        <ChangeLanguageMenu />
                                    </Box>
                                </Box>
                                <Box className="main-content-block desktop-ui ChairmanForgotDesktop">
                                    <Box className="header-block ChairmanForgotDesktopBox">
                                        <h1 className="bold-text ChairmanForgotDesktopBoxHeading">{t("Forgot Password")}</h1>
                                        <h6 id="ChairmanForgotPasswordOTP">{t("One Time Password(OTP) will be sent")}<br/>{t("to the registered email.")}</h6>
                                    </Box>
                                    <Formik
                                        initialValues={{email: ""}}
                                        validationSchema={this.EmailSchema()} validateOnMount={true}
                                        onSubmit={(values) => { this.checkUser(values)}}
                                    >
                                        {({touched, errors,setFieldValue}) => (
                                            <Form translate="yes" className="commonForm ForgotPasswordForm">
                                                <Box className="formGroup ForgotPasswordFormGroup">
                                                    <div className="formInputGrp ForgotPasswordFormGroupDiv">
                                                        <Field
                                                            type="email"
                                                            name="email"
                                                            placeholder={t("Email ID")}
                                                            className={languageCondition(language, "formInput_ar ForgotPasswordFormGroupFiled", "formInput ForgotPasswordFormGroupFiled")}
                                                        />
                                                        <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons ForgotPasswordFormGroupIcon")}>
                                                          <img src={Email_Icon} className="frm-icons ForgotPasswordFormGroupIcon" alt="Email Icon" style={languageCondition(language,{marginTop:"5px"},{margin:"0px"})}/>
                                                        </span>
                                                    </div>
                                                    {errors.email && touched.email ? (
                                                        <Typography className="text-error ForgotPasswordFormGroupError"
                                                                    style={{fontWeight: 600}}>{t(errors.email)} </Typography>
                                                    ) : null}
                                                </Box>
                                                <Box className="customButton ForgotPasswordFormGroupBox">
                                                    <Button variant="contained" type="submit" className="ForgotPasswordFormGroupButton">
                                                      {t("next")}
                                                    </Button>
                                                </Box>
                                                <Box className="passwordRow ForgotPasswordFormGroupBox1">
                                                  {t("Back to")}
                                                    <Link href="/ManagementLogin" className="link ForgotPasswordFormGroupButton1">
                                                        <span className="orange-text">{t("Login")}</span>
                                                    </Link>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                                <Box className="footer-main-block bottomBlock ForgotPasswordFormGroupBox2">
                                    <h6 className="bottom-text ForgotPasswordFormGroupBox2H6">{t("POWERED BY")}</h6>
                                    <img src={Tenant_Logo.default} className="tenant-logo ForgotPasswordFormGroupBox2H6Logo" alt=""/>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5} className="auth-cols">
                            <Box className="right-block ForgotPasswordRightSide" display={{xs: "none", md: "flex"}}>
                                <img src={Building1.default} className="building-logo ForgotPasswordRightSideImg" alt=""/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <AlertErrorWeb show={this.state.showError}
                               handleClose={() => this.setState({showError: false, error: null})}
                               message={this.state.error}/>
                <Loader loading={this.state.loading}/>
            </>
        );
    }
}

//@ts-ignore
export default withTranslation()(withRouter(ChairmanForgotPassword));

// Customizable Area End