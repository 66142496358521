// Customizable Area Start
import React from "react";
import { Grid, Button, Dialog, DialogContent, DialogActions } from "@material-ui/core";
export const image_realEstateCompany = require("../../assets/pollsurvey.png");
import CEChangeRPController, { Props } from "./CEChangeRPController.web";
import { Formik, Form } from "formik";
export const emailImg = require("../../assets/email.png");
import * as Yup from "yup";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { withTranslation } from "react-i18next";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";

class CEChangeRP extends CEChangeRPController {
  constructor(props: Props) {
    super(props);
  }

  validationSchema = Yup.object().shape({
    NRP: Yup.string().required("New reporting person is required"),
  });

  render() {
    const { t, i18n, handleModalClose }: any = this.props;
    const language = i18n.language;

    return (
      <Dialog dir={languageCondition(language, "rtl", "ltr")} open={this.state.empformModal} scroll="paper" fullWidth maxWidth="md">
        <DialogHeader t={t} title="Change Reporting Person" onClose={handleModalClose} />
        <Formik
          onSubmit={(values, { resetForm }) => {
            this.changeReportingPerson(values);
            resetForm();
          }}
          initialValues={this.state.initialValues}
          enableReinitialize={true}
          validationSchema={this.validationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }: any) => {
            return (
              <Form onSubmit={handleSubmit} translate="yes">
                <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label="Employee Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.EmpName}
                        data-test-id="assignee-EmpName"
                        name="EmpName"
                        isLabel
                        disabled={true}
                      />
                      {ErrorHandler(t, touched.EmpName, errors.EmpName)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label="Current Reporting Person"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.CRP}
                        data-test-id="assignee-CRP"
                        name="CRP"
                        isLabel
                        disabled={true}
                      />
                      {ErrorHandler(t, touched.CRP, errors.CRP)}
                    </Grid>
                    <Grid item xs={6}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="New Reporting Person"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.NRP}
                        name="NRP"
                        data-test-id="NRP"
                        option={this.state.reportingPersonList}
                      />
                      {ErrorHandler(t, touched.NRP, errors.NRP)}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog__button_group">
                  <Button className="cancel-button" onClick={handleModalClose}>
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" className="add-button">
                    {t("Assign")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    );
  }
}

export default withTranslation()(CEChangeRP);
// Customizable Area End
