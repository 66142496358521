export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const imgGeneralAssemblies = require("../assets/general_assemblies.png");
export const imgOwners = require("../assets/owners.png");
export const imgPropertyDevelopers = require("../assets/property_developers.png");
export const imgPropertyManagers = require("../assets/property_managers.png");
export const imgDeactivateImg = require("../assets/check.png");
// @ts-ignore
import imgDeactivateImgwait from "../assets/image_Bitmap.png"
export const crossImg = require("../assets/cross.png");
export {imgDeactivateImgwait}

