import React from "react";

// Customizable Area Start
import {
  Container,
  Typography,
  Card,
  Dialog,
  IconButton,
  DialogContent,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  DialogActions,
  Button,
  OutlinedInput,
  MenuItem,
  Link,
  Tooltip
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "../../dashboard/src/Dashboard.web.css";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router";
import CommunityUserProfileController, { Props } from "./communityManagementController.web";
import { withTranslation } from "react-i18next";
import { invite, addgroup, newMember, info, user_icon, email_icon, building, unit } from "./assets";
import { CommunityStyle } from "./CommunityStyle.web";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "black",
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
// Customizable Area End

class CRequestManagement extends CommunityUserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserType();
    this.getBuilding();
    this.getCount();
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n?.language
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <GeneralHeaderComponentWeb>
          <Container className={classes.communityDashboard}>
            <Box className="navigation-chairman">
              <Box className="request-header">
                <Typography variant="body1">
                  {t("Community Management")} /{" "}
                  <Box component="span" style={{ color: "blue" }}>
                    {t("Request Management")}
                  </Box>
                </Typography>
              </Box>
              <Box className="sub-heading-box request-heading-box">
                <Typography variant="h5" className="bold-text request-heading">
                  {t("Request Management")}
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={4}>
              <Grid item sm={4}>
                <Card className="dashboard-card-box new-member-card" onClick={() => this.handleOpen()}>
                  <Box className="card-image" style={{ background: "rgb(252, 132, 52)" }}>
                    <img src={newMember} alt="image" />
                  </Box>
                  <Box className="dashboard-card-heading">
                    <h4 className="bold-text">
                      {t("Invite a new Member")}
                      <HtmlTooltip
                        title={
                          <>
                            <Box style={{ padding: "10px" }}>
                              <p>
                                {t("This section will allow you to invite new owners, residents, and team members to join the platform and start engaging with the building community.")}
                              </p>
                            </Box>
                          </>
                        }
                      >
                        <img src={info} id="tooltip-anchor-children" />
                      </HtmlTooltip>
                    </h4>
                  </Box>
                  <Box className="info-box">
                    <p>{t("Invite Member")}</p>
                  </Box>
                </Card>
              </Grid>
              <Grid item sm={4}>
                <Card
                  className="dashboard-card-box pending-request-card"
                  onClick={() => this.props.navigation.navigate("PendingRequest")}
                >
                  <Box className="card-image">
                    <img src={addgroup} alt="image" />
                  </Box>
                  <Box className="dashboard-card-heading">
                    <h4 className="bold-text">
                      {t("Pending Join Requests")}
                      <HtmlTooltip
                        title={
                          <>
                            <Box style={{ padding: "10px" }}>
                              <p>
                                {t("This section will allow you to invite new owners, residents, and team members to join the platform and start engaging with the building community.")}
                              </p>
                            </Box>
                          </>
                        }
                      >
                        <img src={info} id="tooltip-anchor-children-2" />
                      </HtmlTooltip>
                    </h4>
                  </Box>
                  <Box className="info-box">
                    <h4 className="bold-text" style={{ margin: "0" }}>
                      {this.state.invitatonCount?.ragistration_request_pending}
                    </h4>
                  </Box>
                </Card>
              </Grid>
              <Grid item sm={4}>
                <Card
                  className="dashboard-card-box awaiting-card"
                  onClick={() => this.props.navigation.navigate("AwaitingAcceptece")}
                >
                  <Box className="card-image">
                    <img src={invite} alt="image" />
                  </Box>
                  <Box className="dashboard-card-heading">
                    <h4 className="bold-text">
                      {t("Sent invitations awaiting acceptance")}
                      <HtmlTooltip
                        title={
                          <>
                            <Box style={{ padding: "10px" }}>
                              <p>
                                {t("This section will allow you to invite new owners, residents, and team members to join the platform and start engaging with the building community.")}
                              </p>
                            </Box>
                          </>
                        }
                      >
                        <img src={info} id="tooltip-anchor-children-3" />
                      </HtmlTooltip>
                    </h4>
                  </Box>
                  <Box className="info-box request-pending-box">
                    <h4 className="bold-text request-pending-text" style={{ margin: "0" }}>
                      {this.state.invitatonCount?.totle_member_invitation_pending}
                    </h4>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginTop: 15, marginBottom: 30 }}>
              <Grid item sm={12}>
                <Card className="budget-table-content-box request-count-content-card">
                  <Box className="header">
                    <h4 className="bold-text request-count-heading">{t("Invitation Requests")}</h4>
                  </Box>
                  <hr />
                  <Box className="body request-count-content-box">
                    <Box className="table-header request-count-table-header">
                      <span>{t("Title")}</span>
                      <span>{t("Count")}</span>
                    </Box>
                    <Link href="/SentInvitation">
                      <Box className="table-content request-count-table-content">
                        <p>{t("Total Sent Invitations")}</p>
                        <span>{this.state.invitatonCount?.totle_member_invitation_sent}</span>
                      </Box>
                    </Link>
                    <Box className="table-content request-count-table-content">
                      <p>{t("Accepted Invitations by users")}</p>
                      <span>{this.state.invitatonCount?.member_invitation_accepted}</span>
                    </Box>
                    <Box className="table-content request-count-table-content">
                      <p>{t("Rejected Invitation by users")}</p>
                      <span>{this.state.invitatonCount?.member_invitation_rejected}</span>
                    </Box>
                    <Box className="table-content request-count-table-content">
                      <p>{t("Total received join requests")}</p>
                      <span>{this.state.invitatonCount?.totle_ragistration_request}</span>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </GeneralHeaderComponentWeb>

        <Dialog dir={languageCondition(language, "rtl", "ltr")} className="edit-profile edit-unit invite-user-modal" open={this.state.setOpen} scroll="paper" fullWidth maxWidth="md">
          <MuiDialogTitle disableTypography className="dialog-heading request-member-modal-heading">
            <Typography variant="h6" className="bold-text">
              {t("Invite Member")}
            </Typography>
            <IconButton onClick={() => this.handleClose()} className="request-close-btn">
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <Formik
            enableReinitialize={true}
            initialValues={{
              fullname: "",
              usertype: "",
              phoneno: "",
              unit: "",
              building: "",
              email: "",
              countryCode: "+1",
            }}
            validationSchema={this.InvitationSchema()}
            onSubmit={(values, { resetForm }) => {
              if (this.checkUserPhoneValidation(values)) {
                this.handleClose();
                this.createInvitation(values);
                resetForm();
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes">
                  <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                    <Grid container spacing={3} className="edit-building request-member-modal-form">
                      <Grid item md={6}>
                        <InputLabel>{t("Select User Type")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <img src={user_icon} alt="" />
                          <Select
                            displayEmpty
                            variant="filled"
                            className="select-input invite-user-type"
                            onChange={(e: any) => {
                              setFieldValue("usertype", e.target.value);
                              setFieldValue("unit", "");
                              setFieldValue("building", "");
                            }}
                            value={values.usertype}
                            onBlur={handleBlur}
                            name="usertype"
                            input={<OutlinedInput />}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null, 
                            }}
                          >
                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                              {t("Select User Type")}
                            </MenuItem>
                            {this.state.allUserType.map((item: any) => (
                              <MenuItem value={item.id} key={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                {item.attributes.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {ErrorHandler(t, touched.usertype, errors.usertype)}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Member full name")}</InputLabel>
                        <Box className="measurement-modal-box">
                          <Input
                            className="input-with-icon"
                            onBlur={handleBlur}
                            placeholder={t("Member full name")}
                            onChange={handleChange}
                            value={values.fullname}
                            fullWidth
                            name="fullname"
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={user_icon} alt="icon" />
                              </InputAdornment>
                            }
                          />
                        </Box>
                        {ErrorHandler(t, touched.fullname, errors.fullname)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Email Address")}</InputLabel>
                        <Box className="measurement-modal-box">
                          <Input
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={email_icon} alt="icon" />
                              </InputAdornment>
                            }
                            fullWidth
                            placeholder={t("Enter Email Address")}
                            onChange={handleChange}
                            className="input-with-icon"
                            onBlur={handleBlur}
                            name="email"
                            value={values.email}
                          />
                        </Box>
                        {ErrorHandler(t, touched.email, errors.email)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Phone Number")}</InputLabel>
                        <Box className="measurement-modal-box request-phone-number">
                          <PhoneInput
                            value={values.countryCode}
                            onChange={(e: any) => {
                              setFieldValue("countryCode", `+${e}`);
                            }}
                            inputProps={{ name: "selectCode" }}
                            enableSearch={true}
                            country="us"
                          />
                          <Input
                            fullWidth
                            placeholder={t("Phone Number")}
                            name="phoneno"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneno}
                            className="input-with-icon"
                          />
                        </Box>
                        {ErrorHandler(t, touched.phoneno, errors.phoneno)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Select Building")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <img src={building} alt="" />
                          <Select
                            onBlur={handleBlur}
                            value={values.building}
                            name="building"
                            variant="filled"
                            onChange={(e: any) => {
                              setFieldValue("building", e.target.value);
                              this.getUnit2(this.handleUserType(values.usertype), e.target.value);
                            }}
                            className="select-input invite-building"
                            disabled={this.handleDisable(this.handleUserType(values.usertype))}
                            input={<OutlinedInput />}
                            displayEmpty
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom', 
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top', 
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                              {t("Select Building")}
                            </MenuItem>
                            {this.state.allBuilding.map((item: any) => (
                              <MenuItem value={item.id} key={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {ErrorHandler(t, touched.building, errors.building)}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Select Unit")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <img src={unit} alt="" />
                          <Select
                            disabled={this.handleDisable(this.handleUserType(values.usertype))}
                            displayEmpty
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="unit"
                            value={values.unit}
                            className="select-input"
                            input={<OutlinedInput />}
                            variant="filled"
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              PaperProps: {
                                style: {
                                  maxHeight: 200, 
                                  marginTop: 8,
                                },
                              },
                            }}
                            
                          >
                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                              {t("Select Unit")}
                            </MenuItem>
                            {this.state.allUnit.map((item: any) => (
                              <MenuItem value={item.id} key={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                {item.value}
                              </MenuItem>
                            ))}
                          </Select>
                          {ErrorHandler(t, touched.unit, errors.unit)}
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button className="cancel-button" onClick={() => this.handleClose()} style={{ height: "50px" }}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button" style={{ height: "50px", margin: "0" }}>
                      {t("Send Invitation")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <AlertErrorWeb
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={this.state.error}
        />
      </>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withTranslation()(withStyles(CommunityStyle)(withRouter(CRequestManagement)));
