// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";
import {tokenManagerP1P2,pathRedirectManager} from "../../../components/src/ConditionManager.web";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history:any;
  match:any;
  location:any;
}

interface S {
  anchorEl :any ;
  anchorEl_1 :any ;
  loading: boolean;
  sortBy:any;
  status:any;
  SurveyData: any;
  audienceModal:any;
  surveyQuestions:any;
  audienceType:any;
  isSubmitted:any;
  PreViewSurveyData:any;
  SurveyTitleError:any;
  textEditor:any;
  initialtextEditorVal:any;
  pollTitleError:string;
  pollDateError:string;
  pollEndDateError:string;
  pollDescriptionError:string;
  PreViewPollData:any;
  isAudienceEdit:any;
  deleteModal:boolean;
  isDataLoading:boolean;
  audienceList:any;
  selectedAudience:any[];
  selectedAudienceId:any[];
  selectedAudienceName:any[],
  audienceValidationError:any;
  deleteAudienceId:any;
  inputType1:any;
  inputType2:any;
  error:any;
  showError:boolean;
  audienceIds:any;
  residentAudience:Array<any>;
  ownerAudience:Array<any>
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {

  apiEmailLoginCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  createSurvey:string = "";
  getAudienceListId:string = "";
  deleteAudienceId:string = "";
  getOwnerAudienceId:string = "";
  getResidentAudienceId:string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      anchorEl:null,
      anchorEl_1:null,
      loading:false,
      sortBy : "" ,
      status:"",
      SurveyData: {
        title:'',
        startDate:'',
        endDate:'',
        description:'',
        question:'',
      },
      audienceModal:false,
      surveyQuestions: [
        {
          question_type: "",
          question_typeError:"",
          title:"",
          questionError:"",
          survey_options_attributes: [
            {text: "",_destroy: "false",error:""},
            {text: "",_destroy: "false",error:""}
          ],
          error:false,
          _destroy: "false",
        }
      ],
      audienceType:"",
      isSubmitted:false,
      SurveyTitleError:"",
      PreViewSurveyData: {},
      textEditor: '',
      initialtextEditorVal: '',
      pollDateError:"",
      pollEndDateError:"",
      pollDescriptionError:"",
      pollTitleError:"",
      PreViewPollData:"",
      isAudienceEdit:false,
      deleteModal:false,
      isDataLoading:false,
      audienceList:[],
      selectedAudience:[],
      selectedAudienceId:[],
      selectedAudienceName:[],
      deleteAudienceId:"",
      audienceValidationError:"",
      inputType1:"Text",
      inputType2:"Text",
      error:"",
      showError:false,
      audienceIds:[],
      residentAudience:[],
      ownerAudience:[],
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.handlePollDataChange = this.handlePollDataChange.bind(this)
    this.handleSurveyDataSubmit = this.handleSurveyDataSubmit.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
    this.handlePriviewData = this.handlePriviewData.bind(this)
    this.handleOpenAudienceModal = this.handleOpenAudienceModal.bind(this)
    this.handleCloseAudienceModal = this.handleCloseAudienceModal.bind(this)
    this.handleOpenAudienceModalEditMode = this.handleOpenAudienceModalEditMode.bind(this)
    this.handleDeleteModal = this.handleDeleteModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)

  }

  async componentDidMount() {
    this.getResidentAudience()
    this.getOwnerAudience()
    this.getAudienceList()
    if(localStorage.getItem("Survey_Data")){
      const surveyPreview:any = JSON.parse(localStorage.getItem("Survey_Data") || "")
      if(surveyPreview){
        this.setState({
          textEditor:surveyPreview.PollDescription,
          SurveyData:surveyPreview.PollFormData,
          surveyQuestions:surveyPreview.PollOptions,
          selectedAudience:surveyPreview.selectedAudience,
          selectedAudienceId:surveyPreview.selectedAudienceId,
          selectedAudienceName:surveyPreview.selectedAudienceName,
          audienceIds:surveyPreview.audienceIds,
        })
      }
      await localStorage.removeItem("Survey_Data")
    }
  }

  getAudienceList = async () => {
    const societyID = localStorage.getItem("society_id")
    this.setState({
      isDataLoading:true,
    })
    this.getAudienceListId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `/society_managements/${societyID}/bx_block_survey/survey_audiences`,
    });
  }
  createSurveyResponse = (responseJson:any) => {
    if(responseJson.code === 200){
      this.setState({
        loading:false
      })
      pathRedirectManager(this.props.history, "/polling", "/CompanyEmployee/CEPollSurvey")
    }else{
      console.log("SOMETHING WENT WRONG")
    }
  }

  getAudienceResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty('data')){
      this.setState({
        audienceList:responseJson.data
      })
    }
  }

  deleteAudianceResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty('data')){
      this.setState({
        deleteModal:false,
        deleteAudienceId:"",
      })
      this.getAudienceList()
    }
  }

  getOwnerIdsResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty("owner_account")){
      this.setState({
        ownerAudience:responseJson.owner_account
      })
    }
  }

  getResidentIdsResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty("resident_account")){
      this.setState({
        residentAudience:responseJson.resident_account
      })
    }
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.PostDetailDataMessage) === message.id){
      if(message.properties.text === "UPDATE_AUDIENCE_LIST"){
        this.getAudienceList()
      }
    }
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if(this.createSurvey === apiRequestCallId){
        this.createSurveyResponse(responseJson)
      }
      if(this.getAudienceListId === apiRequestCallId){
        this.getAudienceResponse(responseJson)
      }
      if(this.deleteAudienceId === apiRequestCallId){
        this.deleteAudianceResponse(responseJson)
      }
      if(this.getOwnerAudienceId === apiRequestCallId){
        this.getOwnerIdsResponse(responseJson)
      }
      if(this.getResidentAudienceId === apiRequestCallId){
        this.getResidentIdsResponse(responseJson)
      }
    }
  }

  getOwnerAudience = async () => {
    const societyID = localStorage.getItem("society_id")
    this.getOwnerAudienceId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `/society_managements/${societyID}/bx_block_meeting/meeting_groups/owner`,
    });
  }

  getResidentAudience = async () => {
    const societyID = localStorage.getItem("society_id")
    this.getResidentAudienceId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `/society_managements/${societyID}/bx_block_meeting/meeting_groups/resident`,
    });
  }

  removeItemFromArray(arr:any, value:any) {
    let index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  selectAudience(value:any,name:any){
    if(this.state.selectedAudienceName.find((item:any)=> item === name)){
      let updatedArray = this.removeItemFromArray(this.state.selectedAudienceName,name)
      this.setState({
        selectedAudienceName:updatedArray,
      })
    }else{
      this.setState({
        selectedAudienceName:[
          ...this.state.selectedAudienceName,
          name
        ]
      })
    }
    if(value === "Owner" || value === "Resident"){
      if(this.state.selectedAudience.find((item:any)=> item === value)){
        let updatedArray = this.removeItemFromArray(this.state.selectedAudience,value)
        this.setState({
          selectedAudience:updatedArray,
        })
      }else{
        this.setState({selectedAudience:[
            ...this.state.selectedAudience,
            value
          ]
        })
      }
      this.setState({
        audienceValidationError:"",
      })
    }else{
      if(this.state.selectedAudienceId.find((item:any)=> item === value)){
        let updatedArray = this.removeItemFromArray(this.state.selectedAudienceId,value)
        this.setState({
          selectedAudienceId:updatedArray
        })
      }else{
        this.setState({selectedAudienceId:[
            ...this.state.selectedAudienceId,
            value
          ]})
      }
      this.setState({
        audienceValidationError:"",
      })
    }
  }

  handlePollDataChange = (event:any) => {
    this.setState({ SurveyData: {...this.state.SurveyData, [event.target.name] : event.target.value}})
    if(this.state.isSubmitted){
      this.handleValidation()
    }
  }

  onChangeTextEditor = (value: any) => {
    this.setState(
      prevState => ({
        textEditor: value,
        SurveyData: {
          ...prevState.SurveyData,
          description: value // Update description in SurveyData
        }
      }),
      () => {
        if (this.state.isSubmitted) {
          this.descriptionValidation(); // Re-validate the description after updating
        }
      }
    );
  };

  dateIsValid(dateStr:any) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateStr.match(regex) === null) {
      return false;
    }

    const date = new Date(dateStr);

    const timestamp = date.getTime();

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false;
    }

    return date.toISOString().startsWith(dateStr);
  }

  checkTitleValidation = () => {
    if(this.state.SurveyData.title && this.state.SurveyData.title.trim() !== ""){
      if(this.state.SurveyData.title.length >=5){
        this.setState({
          pollTitleError:""
        })
        return true
      }else{
        this.setState({
          pollTitleError:"Title not match the minimum requirements."
        })
        return false
      }
    }else{
      this.setState({
        pollTitleError:"Title can't be empty."
      })
      return false
    }
  }

  startDateValidation = () => {
    if(this.state.SurveyData.startDate && this.state.SurveyData.startDate !== ""){
      if(this.dateIsValid(this.state.SurveyData.startDate)){
        let today = new Date();
        today.setHours(0,0,0,0);
        let startDate = new Date(this.state.SurveyData.startDate)
        if (startDate <= today) {
          this.setState({pollDateError: "You can not use previous date."})
          return false
        }else{
          this.setState({
            pollDateError:""
          })
          return true
        }
      }else{
        this.setState({pollDateError: "Invalid start date"})
        return false
      }
    }else{
      this.setState({pollDateError: "Start Date can't be empty."})
      return false
    }
  }
  endDateValidation = () => {
    if(this.state.SurveyData.endDate){
      if(this.dateIsValid(this.state.SurveyData.endDate)){
        let today = new Date();
        today.setHours(0,0,0,0);
        let endDate = new Date(this.state.SurveyData.endDate)
        let startDate = new Date(this.state.SurveyData.startDate)
        if (endDate <= today || endDate < startDate) {
          if(endDate <= today){
            this.setState({pollEndDateError: "You can not use previous date."})
            return false
          }else{
            this.setState({pollEndDateError: "You can not use previous date then start date"})
            return false
          }
        }else{
          this.setState({
            pollEndDateError:""
          })
          return true
        }
      }else{
        this.setState({pollEndDateError: "Invalid end date"})
        return false
      }
    }else{
      this.setState({pollEndDateError: "End Date can't be empty."})
      return false
    }
  }

  descriptionValidation = () => {
    const description = this.state.SurveyData.description?.trim(); 
    //@ts-nocheck
    //@ts-ignore
    const regexDescription = /<([a-zA-Z0-9]+)(?:\s+[^>]*|\s*)>/g;
    let plainText = description.replace(regexDescription, '').replace(/&nbsp;/g, ' ');
    let hasLeadingSpaceOrLineBreak = plainText.length > 0 && (plainText[0] === ' ' || plainText[0] === '\n');
let isValid  = plainText.trim().length > 0 && !hasLeadingSpaceOrLineBreak;
  
    if (!isValid) {
      this.setState({
        pollDescriptionError: "Description can't be empty or spaces only."
      });
      return false;
    } else if (plainText.length < 5) {
      this.setState({
        pollDescriptionError: "Description must contain characters (excluding spaces)."
      });
      return false;
    } else {
      this.setState({
        pollDescriptionError: ""
      });
      return true;
    }
  };

  optionValidation1 = (item:any) => {
    let updatedArry = item.survey_options_attributes.map((item: any) => {
      if (item?.text === "" || item?.text.trim() === "") {
        return {
          ...item,
          error: "Option can't be blank"
        }
      } else {
        return {
          ...item,
          error: ""
        }
      }
    })
    return updatedArry
  }

  checkQuestionTypeValidation = (item:any) => {
    if(item.question_type !== ""){
      return true
    }else{
      return false
    }
  }

  checkQuestionValidation = (item:any) => {
    if(item.title !== "" && item.title.trim() !== ""){
      return true
    }else{
      return false
    }
  }

  questionValidation = (updatedObject:any,questionValidation:boolean,question_typeValidation:boolean) => {
    if(!questionValidation){
      updatedObject = {
        ...updatedObject,
        questionError:"Question Can't be empty",
        error:true
      }
    }else{
      updatedObject =  {
        ...updatedObject,
        questionError:""
      }
    }
    if(!question_typeValidation){
      updatedObject =  {
        ...updatedObject,
        question_typeError:"Please select Question Type",
        error:true
      }
    }else{
      updatedObject =  {
        ...updatedObject,
        question_typeError:"",
      }
    }
    return updatedObject
  }
  surveyQuestionValidation = () => {
    let optionValidation = false
    let updatedArray = this.state.surveyQuestions.map((item:any) => {
      let question_typeValidation = false
      let questionValidation = false
      question_typeValidation = this.checkQuestionTypeValidation(item)
      questionValidation = this.checkQuestionValidation(item)
      let updatedArry = this.optionValidation1(item)
      let optionsValidation = updatedArry.every((item:any)=>{{return item.error === ""}})
      if(!questionValidation || !question_typeValidation) {
        let updatedObject = item
         updatedObject = this.questionValidation(updatedObject,questionValidation,question_typeValidation)
        return {
          ...updatedObject,
          survey_options_attributes:updatedArry
        }
      }else if (question_typeValidation && questionValidation && optionsValidation) {
        return {
          ...item,
          survey_options_attributes: updatedArry,
          questionError: "",
          question_typeError: "",
          error: false
        }
      } else {
        return {
          ...item,
          survey_options_attributes: updatedArry,
          questionError: "",
          question_typeError: "",
          error: true
        }
      }
    })
    this.setState({
      surveyQuestions:updatedArray
    })
    updatedArray.forEach((item:any)=>{
      if(item.error){
        return
      }else{
        optionValidation =  true
      }
    })
    return optionValidation
  }

  validateTargetAudience = () => {
    if(this.state.selectedAudience.length === 0 && this.state.selectedAudienceId.length === 0){
      this.setState({
        audienceValidationError:"Please select audience"
      })
      return false
    }else{
      return true
    }
  }

  handleValidation(){
    let titleValidation = false
    let startDateValidation = false
    let endDateValidation = false
    let DescriptionValidation = false
    let optionValidation = false
    let targetAudienceValidation = false
    titleValidation = this.checkTitleValidation()
    startDateValidation = this.startDateValidation()
    endDateValidation = this.endDateValidation()
    DescriptionValidation = this.descriptionValidation()
    optionValidation = this.surveyQuestionValidation()
    targetAudienceValidation = this.validateTargetAudience()
    return titleValidation && startDateValidation && endDateValidation && DescriptionValidation && optionValidation && targetAudienceValidation;
  }

  addOptionsFields = (index:any) => {
    const updatedArray = this.state.surveyQuestions.map((item:any,key:any)=>{
      if(key === index){
        return{
          ...item,
          survey_options_attributes:[
            // @ts-ignore
            ...item.survey_options_attributes,
            {text: "",_destroy: "false",error:""}
          ]
        }
      }else{
        return item
      }
    })
    this.setState({surveyQuestions :updatedArray})
  }

  deleteOption = (mainKey:any,subKey:any) =>{
    let updatedArray = this.state.surveyQuestions
    if (subKey > -1) { // only splice array when item is found
      updatedArray[mainKey].survey_options_attributes.splice(subKey, 1); // 2nd parameter means remove one item only
    }
    this.setState({
      surveyQuestions:updatedArray
    })
  }

  deleteQuestion = (mainKey:any) => {

    let updatedArray = this.state.surveyQuestions
    if (mainKey > -1) {
      updatedArray.splice(mainKey, 1)
    }
    console.log("UpdatedArray",updatedArray)
    this.setState({
      surveyQuestions:updatedArray
    })
  }

  handleDeleteModal (value:any) {
    this.setState({
      deleteModal:true,
      deleteAudienceId:value
    })
  }

  closeDeleteModal () {
    this.setState({
      deleteModal:false,
      deleteAudienceId:""
    })
  }


  handleQuestionType(index:any,event:any) {
    const updatedArray = this.state.surveyQuestions.map((item:any,key:any)=>{
      if(key === index){
        return{
          ...item,
          question_type:event.target.value,
        }
      }else{
        return item
      }
    })
    this.setState({surveyQuestions :updatedArray})
  }

  handleQuestion(index:any,event:any) {
    const updatedArray = this.state.surveyQuestions.map((item:any,key:any)=>{
      if(key === index){
        return{
          ...item,
          title:event.target.value,
        }
      }else{
        return item
      }
    })
    this.setState({surveyQuestions :updatedArray})
  }

  handleOptionsChange = (mainIndex:any,subIndex:any, event:any) => {
    const updatedArray = this.state.surveyQuestions.map((item:any,key:any)=>{
      if(key === mainIndex){
        // @ts-ignore
        const updateOptions = item.survey_options_attributes.map((item,key)=>{
          if(key === subIndex){
            return{
              ...item,
              text:event.target.value,
            }
          }else{
            return item
          }
        })
        return{
          ...item,
          survey_options_attributes:updateOptions
        }
      }else{
        return item
      }
    })
    this.setState({surveyQuestions :updatedArray})
  }

  addQuestionFields = () => {
    this.setState({surveyQuestions : [...this.state.surveyQuestions, {
        question_type: "",
        question_typeError:"",
        title:"",
        questionError:"",
        survey_options_attributes: [
          {text: "",_destroy: "false",error:""},
          {text: "",_destroy: "false",error:""}
        ],
        _destroy: "false",
      }
      ]})
  }

  removeDuplicateAudienceArray = (arr:any) => {
    let unique_array = []
    for(const element of arr) {
      if (unique_array.indexOf(element) === -1) {
        unique_array.push(element)
      }
    }
    return unique_array
  }

  handleAudienceIDs = () => {
    let audienceIds:any = []
    if(this.state.selectedAudience.length > 0){
        this.state.selectedAudience.forEach((item:any)=>{
            if(item === "Owner"){
            this.state.ownerAudience.forEach((item:any)=>{
                audienceIds.push(item)
            })
            }else{
            this.state.residentAudience.forEach((item:any)=>{
                audienceIds.push(item)
            })
            }
        })
    }
    return this.removeDuplicateAudienceArray(audienceIds)
  }

  handlePriviewData = () => {
    this.setState({
      isSubmitted:true
    })
    if(this.state.surveyQuestions.length > 0){
      if(this.handleValidation()){
        let audienceIds  = this.handleAudienceIDs()
        localStorage.setItem('Survey_Data', JSON.stringify({
          PollFormData:this.state.SurveyData,
          PollOptions:this.state.surveyQuestions,
          PollDescription:this.state.textEditor,
          selectedAudience:this.state.selectedAudience,
          selectedAudienceId:this.state.selectedAudienceId,
          selectedAudienceName:this.state.selectedAudienceName,
          audienceIds:audienceIds,
        }))
        pathRedirectManager(this.props.history, "/SurveyPreview", "/CompanyEmployee/CESurveyPreview")
      }
    }else{
      this.setState({
        showError:true,
        error:"No Question added! Please add minimum one question."
      })
    }
  }

  handleSurveyDataSubmit =  async (event:any,preview?:boolean) => {
    event.preventDefault()
    let societyID = localStorage.getItem("society_id")
    this.setState({
      isSubmitted: true,
    })
    if(this.state.surveyQuestions.length > 0){
      if (this.handleValidation() || preview) {
        let audienceIds  = this.handleAudienceIDs()
        this.setState({
          loading:true
        })
        let reqPayload = {
          "society_id": societyID,
          "survey":
              {
                "type_name": "survey",
                "title": this.state.SurveyData.title,
                "description": this.state.SurveyData.description,
                "target_audience_type":this.state.selectedAudience,
                "survey_audience_ids":this.state.selectedAudienceId,
                "target_audience_ids":audienceIds,
                "schedule": "1",
                "start_date": this.state.SurveyData.startDate,
                "end_date": this.state.SurveyData.endDate,
                "survey_questions_attributes": this.state.surveyQuestions,
              }
        }
        await this.addSurveyData(reqPayload);
        localStorage.removeItem("Survey_Data")
      }
    }else{
      this.setState({
        showError:true,
        error:"No Question added! Please add minimum one question."
      })
    }
  }

  addSurveyData = async (data:any) => {
    const societyID = localStorage.getItem("society_id")
    this.createSurvey = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: `/society_managements/${societyID}/bx_block_survey/surveys`,
      body:JSON.stringify(data)
    });
  }

  deleteAudience = async () => {
    const societyID = localStorage.getItem("society_id")
    this.deleteAudienceId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpDeleteMethod,
      endPoint: `/society_managements/${societyID}/bx_block_survey/survey_audiences/${this.state.deleteAudienceId}`,
    });
  }

  handleCloseAudienceModal () {
    this.setState({
      audienceModal:false,
      isAudienceEdit:false,
    })
  }

  handleOpenAudienceModal () {
    this.setState({
      audienceModal:true
    })
  }

  handleOpenAudienceModalEditMode (value:any) {
    this.setState({
      audienceModal:true,
      isAudienceEdit:value,
    })
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    // console.log("Called 1",data);
    const token = tokenManagerP1P2() ;

    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    body && requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("Called",requestMessage);
    return requestMessage.messageId;
  };
  handleClick = (event:any) => {
    this.setState({anchorEl:event.currentTarget })
  };

  handleClose = (e?:any, v?:any) => {
    let sortBy : any ;
    if(v === undefined || v === null){
      sortBy =this.state.sortBy
    }
    else {
      sortBy =v;
    }
    this.setState({anchorEl:null,sortBy : sortBy})
  };

  handleClick_1 = (event:any) => {
    this.setState({anchorEl_1:event.currentTarget})
  };

  handleClose_1 = (e?:any, v?:any) => {
    let status : any ;
    if(v === undefined || v === null){
      status =this.state.status;
    }
    else {
      status =v;
    }
    this.setState({anchorEl_1:null ,status :status})
  };
}

// Customizable Area End
