import React from 'react';
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import MenuItem from "@material-ui/core/MenuItem";
import {Menu} from "@szhsin/react-menu";
import {globalIcon} from "../../../blocks/forgot-password/src/assets";
const ChangeLanguageMenuWeb = () => {
    const {  i18n } = useTranslation()
    const handleEngLngChange = () => {
        localStorage.setItem("language", "en");
        // @ts-ignore
        i18n.changeLanguage("en");
    };

    const handleAreLngChange = () => {
        localStorage.setItem("language", "ar");
        // @ts-ignore
        i18n.changeLanguage("ar");
    };
    const currentLanguage = localStorage.getItem("language") === "ar" ? "AR" : "ENG";


    return (
        <div  style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Menu
                className="chairman-lang-menu chairman-menu ForgotPasswordMenu1"
                arrow={true}
                align="center"
                menuButton={<img src={globalIcon} alt="GlobalIcon"/>}
            >
                <MenuItem
                    className={localStorage.getItem("language") === "en" ? "active" : ""}
                    id="ForgotPasswordMenu2"
                    onClick={() => handleEngLngChange()}
                >
                    English
                </MenuItem>
                <MenuItem
                    className={localStorage.getItem("language") === "ar" ? "active" : ""}
                    id="ForgotPasswordMenu3"
                    onClick={() => handleAreLngChange()}
                >
                    Arabic
                </MenuItem>
            </Menu>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>{currentLanguage}</span>
        </div>
    );
};

export default ChangeLanguageMenuWeb;