// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  selectedSpentId: any;
  budgetYear: any;
  selectedSpent: any;
  getMySpentList: any;
  complexId: any;
  isShareModalOpen: boolean;
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  downloadIDPdf: any;
  getMySpentDetails = null;
  getSpendingDetailsListId = "";
  getMyInvoicesCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedSpentId: null,
      budgetYear: new Date().getFullYear(),
      getMySpentList: null,
      selectedSpent: null,
      complexId: "",
      isShareModalOpen: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const spend_id = this.props.navigation.getParam("id");
    const complex_id = this.props.navigation.getParam("cid");
    this.setState(
      {
        selectedSpentId: spend_id,
        complexId: complex_id,
      },
      () => {
        this.getSpendingDetailsList(this.state.selectedSpentId);
      }
    );
  }

  getSpendingDetailsList = async (id: any) => {
    const { complexId } = this.state;
    this.getMyInvoicesCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_fees_payment/building_budgets/${id}`,
    });
  };

  getMyInvoicesResponse = (responseJson: any) => {
    if (responseJson && responseJson.expence_report) {
      this.setState({
        getMySpentList: responseJson?.expence_report?.data,
      });
    }
  };

  handleSpentShareModal = () => {
    this.setState({
      ...this.state,
      isShareModalOpen: !this.state.isShareModalOpen,
    })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getMyInvoicesCallId) {
        this.getMyInvoicesResponse(responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  buildingBudgetDownload = async (selectedId: any) => {
    const { complexId } = this.state;
    if(complexId && selectedId) {
      await this.downloadPdf(`/society_managements/${complexId}/bx_block_fees_payment/building_budgets/${selectedId}/download_report.pdf`, `SpentDetails.pdf`);
    }
  };
}

// Customizable Area End
