// Customizable Area Start

import React from "react";
import { withRouter } from "react-router-dom";
import { Box, Button, Link, Grid } from "@material-ui/core";
import ChairmanForgotPasswordController, { Props } from "./ChairmanForgotPasswordController.web";
import OtpInput from "react-otp-input";
import Loader from "../../../components/src/Loader.web";
import "@szhsin/react-menu/dist/core.css";
import { Tenant_Logo, Building_Logo, Building1 } from "../src/assets";
import AlertErrorWeb from "../../../components/src/AlertError.web"
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import ChangeLanguageMenu from "../../../components/src/HeaderComponent/ChangeLanguageMenu.web";
class ChairmanForgotPasswordOTP extends ChairmanForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  resendTimerForgotPassChairman = () => {
    if (this.state.ForgotPassOTPTimer <= 30 && this.state.ForgotPassOTPTimer > 0) {
      let intervalIdForgotPassChairman = setInterval(() => {
        if (this.state.ForgotPassOTPTimer === 1) {
          clearInterval(intervalIdForgotPassChairman); // Clear interval when timer value reaches 0
        }
        this.setState(({ ForgotPassOTPTimer }) => ({
          ForgotPassOTPTimer: ForgotPassOTPTimer - 1,
          ForgotPassIsTimer: true
        }))
      }, 1000)
      setTimeout(() => { this.setState({ ForgotPassOTPTimer: 30, ForgotPassIsTimer: false }); }, (this.state.ForgotPassOTPTimer * 1000) + 1000);
    }
  }

  resendOTP = () => {
    this.checkUser({ email: localStorage.getItem("emailMask"), phone: localStorage.getItem("phoneNumberMask") })
    this.setState({
      resend: true
    })
  }


  render() {
    const emailMask = localStorage.getItem("emailMask");
    const phoneNumber = localStorage.getItem("phoneNumberMask");
    // @ts-ignore
    const { t, i18n } = this.props;
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper forgotPasswordOTP" style={{ backgroundColor: "white" }} dir={languageCondition(language, "rtl", "ltr")}>
          <Grid container spacing={2} className="auth-container forgotPasswordOTP">
            <Grid item xs={12} md={7} className="auth-cols forgotPasswordOTP">
              <Box className="content-block">
                <Box className="logo-block common-top-padding common-logo-block forgotPasswordOTP" display={{ xs: "none", md: "flex" }}>
                  <Link>
                    <img src={Building_Logo.default} className="head-logo forgotPasswordOTP" alt="" />
                    <h4 className="forgotPasswordOTP">{t("Building Name")}</h4>
                  </Link>
                  <Box>
                    <ChangeLanguageMenu />
                  </Box>
                </Box>
                <Box className="main-content-block desktop-ui forgotPasswordOTP" style={{ marginTop: '80px' }}>
                  <Box className="header-left-block header-block forgotPasswordOTP">
                    <h1 className="login-h1 bold-text forgotPasswordOTP">{t("Enter OTP")}</h1>
                    <h6>
                      {emailMask ? (
                        <>
                          {t("Please enter the code send to the email")}
                          <br />
                          {t("address")} <span className="text forgotPasswordOTP">{emailMask}</span>
                        </>
                      ) : (
                        <>
                          {t("Please enter the code send to the phone")}
                          <br />
                          {t("number")} <span className="text forgotPasswordOTP">{phoneNumber}</span>
                        </>
                      )}
                    </h6>
                  </Box>
                  <Box className="commonForm forgotPasswordOTP" >
                    <Box className="formGroup otpBlock forgotPasswordOTP">
                      <OtpInput
                        className="formOutlineInput"
                        value={this.state.otp}
                        onChange={this.handleChange}
                        numInputs={4}
                      />
                    </Box>
                  </Box>
                  <Box className="customButton row-btn forgotPasswordOTP">
                    <Button
                      variant="contained"
                      className="verifyOTPButton"
                      onClick={() => {
                        //@ts-ignore
                        this.verifyOtp();
                      }}
                    >
                      {t("next")}
                    </Button>
                  </Box>
                  <Box className="passwordRow forgotPasswordOTP">
                    <Button disabled={this.state.ForgotPassIsTimer} onClick={() => {
                      this.resendTimerForgotPassChairman()
                      this.resendOTP();
                    }} className="link forgotPasswordOTPResend forgotPasswordOTP resendOTPButton" style={this.state.ForgotPassIsTimer ? { color: "gray" } : { background: "white" }}
                    >
                      {t("Resend OTP ")}
                      {this.state.ForgotPassIsTimer && (
                        <>
                          {" "}
                          {t("in")}{" "}
                          <span className="orange-text"> 00:{this.state.ForgotPassOTPTimer}</span> 
                          {t("Seconds")}
                        </>
                      )}
                    </Button>
                  </Box>
                </Box>
                <Box className="footer-main-block bottomBlock forgotPasswordOTP" style={{ marginTop: '100px', backgroundColor: "white" }}>
                  <h6 className="bottom-text forgotPasswordOTP">{t("POWERED BY")}</h6>
                  <img src={Tenant_Logo.default} className="tenant-logo" alt="" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols forgotPasswordOTP" >
              <Box className="right-block" display={{ xs: "none", md: "flex" }}>
                <img src={Building1.default} className="building-logo forgotPasswordOTP" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <AlertErrorWeb handleClose={() => this.setState({ showError: false, error: null })} show={this.state.showError} message={this.state.error} />
        <Loader loading={this.state.loading} />
      </>
    );
  }
}
//@ts-ignore
export default withTranslation()(withRouter(ChairmanForgotPasswordOTP));
// Customizable Area End