import React from "react";
//components
import {
  Box,
  Button,
  Link,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardActions,
  Menu,
  MenuItem,
  Avatar,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";

//resources
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { Formik, Form, Field } from "formik";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import { Input } from "react-native-elements";
import * as Yup from "yup";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import ClassifiedController, { Props } from "./ClassifiedController.web";
//Customizable Area Start

//resorces
import { Tenant_Logo, Building1, Close_Icon} from "../src/assets";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class ClassifiedPreview extends ClassifiedController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount(): any {
    this.getCurrencyList();
  }

  render() {
    // @ts-ignore
    const { t,i18n } = this.props;
    const language = i18n.language;
    const classifiedFromData = JSON.parse(localStorage.getItem("classifiedPreview") || '{}')
    const classifiedUserType = localStorage.getItem("classifiedUserType")

    const currency = this.state.getCurrencyList.filter((val: any) => val.id === classifiedFromData.currency);

    if (!classifiedFromData && !classifiedUserType) {
      //@ts-ignore
      this.props.history.replace("/CreateClassified");
      return null;
    }

    return (
      <>
        <Box className="login-wrapper incident-wrapper" dir={languageCondition(language,"rtl","ltr")}>
          <Grid container spacing={2} className="auth-container" style={{backgroundColor:"#f8f9fe"}}>
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="common_content_block content-block">
                <Box className="content-header">
                  <Box className="left-block blocks">
                    <ArrowBackIcon className="back-testcases"
                     onClick={() => window.history.back()} 
                     style={{fontSize:"20px"}} />
                    <UserTypeMain classifiedUserType={classifiedUserType} t={t} />
                  </Box>
                </Box>
                <Box className="content-block-wrapper" style={{padding:"0px 20px"}}>
                  <Box className="incident-content-wrapper" style={{ maxHeight: '60%', overflowY: 'scroll'}}>
                    <Box className="incident-rows">
                      <UserTypeSub classifiedUserType={classifiedUserType} t={t}/>
                    </Box>
                    <Card className="incident-card classificationPre-card card">
                      <CardContent>
                        <Typography component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                          {t("Mobile Number")}:
                        </Typography>
                        <Typography className="h5-title" component="h5">
                          {classifiedFromData.selectCode} {classifiedFromData.phone}
                        </Typography>
                        <Typography component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                          {t("Email Id")}:
                        </Typography>
                        <Typography className="h5-title" component="h5">
                          {classifiedFromData.email}
                        </Typography>
                        <Typography component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                          {t("Title")}:
                        </Typography>
                        <Typography className="h5-title" component="h5">
                          {classifiedFromData.classifiedTitle}
                        </Typography>

                        <Typography component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                          {t("Description")}:
                        </Typography>
                        <Typography className="h5-title" component="h5">
                          {classifiedFromData.description}
                        </Typography>
                        <DetailsCheck data-test-id="detailsCheck" classifiedFromData={classifiedFromData} currency={currency} t={t} language={language}/>
                        {
                          classifiedFromData.media?.length !== 0 &&
                            <>
                              <Typography component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                                {t("Photos")}
                              </Typography>
                              <CardActions className="card-img-row">
                                {
                                  classifiedFromData.media?.map((val: any, index: any) => (
                                    val?.file.type === "video/mp4" || val?.file.type === "video/x-m4v" 
                                    ?
                                      <Box className="video-img"
                                       key={index} data-test-id="showDialogData"
                                      onClick={() => { this.setState({ showDialog: true, file: { url: val.url, type: val?.file.type, name: val?.file?.name } }) }}
                                      >
                                        <Box 
                                        className="img-layer"></Box>
                                        <video 
                                        className="incident-dialog-video"   >
                                          <source src={val.url} type={val.file.type} />
                                        </video>
                                        <FullscreenIcon className="play-icon" 
                                        />
                                      </Box>
                                      :
                                      <Box className="video-img" 
                                      key={index} 
                                      onClick={() => { this.setState({ showDialog: true, file: { url: val.url, type: val?.file.type, name: val?.file?.name } }) }}
                                      >
                                        <Box className="img-layer">

                                        </Box>
                                        <img src={val.url} className="card-img" 
                                        alt="card-img" />
                                        <FullscreenIcon 
                                        className="play-icon" />
                                      </Box>
                                  ))
                                }
                              </CardActions>
                            </>
                        }
                        {/* <hr /> */}
                      </CardContent>
                    </Card>
                  </Box>

                  <Box className="customButton preview-submit" style={{marginTop:"30px"}}>
                    <Button variant="contained" className="previewSubmitButton" style={{fontSize:"18px"}}
                     onClick={() => this.createClassified(classifiedFromData, classifiedUserType)}
                     >{t("Submit")}</Button>
                  </Box>
                </Box>
                <Box className="bottomBlock common-bottom-padding" display={{ xs: 'none', md: 'flex' }}>
                  <h6 className="bottom-text">POWERED BY</h6>
                  <img src={Tenant_Logo.default} className="tenant-logo" alt="" />
                </Box>
              </Box>
            </Grid>
            {/* desktop footer block */}
            <Grid item xs={12} md={5} 
            className="auth-cols">
              <Box className="right-block" 
              display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" 
                alt="" />
              </Box>
            </Grid>
          </Grid>
          <Dialog
            open={
              this.state.showDialog}
            onClose={() => this.setState({ showDialog: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="diloag-wrapper"
            PaperProps={
              {
              style: {
                borderRadius: '15px',
              },
            }}
          >
            <Box 
            className="diloag-body">
              <Box 
              className="diloag-header">
                <DialogTitle className="alert-dialog-title" 
                id="alert-dialog-title">
                  {this.state.file.name}
                </DialogTitle>
                <Button 
                className="showDialog1-testcases"
                onClick={() => { this.setState({ showDialog: false }) }}
                >
                  <img src={Close_Icon} 
                  data-test-id="showDialog2"
                  className="close-icon" 
                  onClick={() => { this.setState({ showDialog: false }) }} 
                  />
                </Button>
              </Box>
              <Box 
              className="diloag-content-body">
                {
                  this.state.file.type === "video/mp4" || this.state?.file?.type === "video/x-m4v" 
                  ?
                    <video className="incident-dialog-video" 
                    controls >
                      <source src={this.state?.file?.url} 
                      type={this.state.file.type} />
                    </video>
                    :
                    <Box>
                      <img src={this.state.file.url} className="incident-dialog-photo" 
                      alt="card-img" />
                      {/* <FullscreenIcon className="play-icon" /> */}
                    </Box>
                }
              </Box>
            </Box>
          </Dialog>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    )
  }
}

export default withTranslation()(withRouter(ClassifiedPreview));

const DetailsCheck = (props:any) => {
  const {classifiedFromData,currency,t,language} = props
  return(
      <>
        {
            classifiedFromData.price &&
            <>
              <Typography className="title-span" component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                {t("Price")}:
              </Typography>
              <Typography className="h5-title" component="h5">
                {classifiedFromData?.price} {languageCondition(language,currency[0]?.attributes?.currency_ar,currency[0]?.attributes?.currency)}
              </Typography>
            </>
        }
        {
            classifiedFromData.paymentDetail &&
            <>
              <Typography className="title-span" component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                {t("Payment Detail")}:
              </Typography>
              <Typography className="h5-title" component="h5">
                {classifiedFromData?.paymentDetail} {languageCondition(language,currency[0]?.attributes?.currency_ar,currency[0]?.attributes?.currency)}
              </Typography>
            </>
        }
        {
            classifiedFromData.priceFrom && classifiedFromData.priceTo &&
            <>
              <Typography className="title-span" component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                {t("Price")}:
              </Typography>
              <Typography className="h5-title" component="h5">
                {classifiedFromData?.priceFrom} {languageCondition(language,currency[0]?.attributes?.currency_ar,currency[0]?.attributes?.currency)} - {classifiedFromData?.priceTo} {languageCondition(language,currency[0]?.attributes?.currency_ar,currency[0]?.attributes?.currency)}
              </Typography>
            </>
        }
        {
            classifiedFromData.timeFrom &&
            <Box className="card-rows">
              <Typography className="title-span" component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                {t("From Time")}:
              </Typography>
              <Typography className="h5-title" component="h5">
                {classifiedFromData?.timeFrom}
              </Typography>
            </Box>
        }
        {
            classifiedFromData.timeTo &&
            <Box className="card-rows">
              <Typography className="title-span" component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                {t("To Time")}:
              </Typography>
              <Typography className="h5-title" component="h5">
                {classifiedFromData?.timeTo}
              </Typography>
            </Box>
        }
        {
            classifiedFromData?.startDate &&
            <Box className="card-rows">
              <Typography component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                {t("From Date")}:
              </Typography>
              <Typography className="h5-title" component="h5">
                {classifiedFromData.startDate}
              </Typography>
            </Box>
        }
        {
            classifiedFromData?.endDate &&
            <Box className="card-rows">
              <Typography component="span" style={{color:"#8C8E92",fontSize:"14px"}}>
                {t("To Date")}:
              </Typography>
              <Typography className="h5-title" component="h5">
                {classifiedFromData.endDate}
              </Typography>
            </Box>
        }
      </>
  )
}

const UserTypeMain = (props:any) => {
  const {classifiedUserType,t} = props
  let userType = ""
  if(classifiedUserType === "buyer"){
    userType = "Buyers Request"
  }else{
    if(classifiedUserType === "seller"){
      userType = "Sellers Request"
    }else{
      userType = "Generic Request"
    }
  }
  return(
      <>
        <h4 className="bold-text">{t(userType)}</h4>
      </>
  )
}

const UserTypeSub = (props:any) => {
  const {classifiedUserType,t} = props
  let userType = ""
  if(classifiedUserType === "buyer"){
    userType = "Buyer Request Preview"
  }else{
      userType = "Request Preview"
  }
  return(
      <>
        <h4 className="bold-text" style={{fontSize:"17px",marginTop:"15px"}}>{t(userType)}</h4>
      </>
  )
}
//Customizable Area End