// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CompEmpBuildingBlockStep3Controller, { Props } from "./CompEmpBuildingBlockStep3Controller.web";
import "../../assets/commonForm.css";
import { DeleteIcon, uploadbw } from "../assets";
import Loader from "../../../../components/src/Loader.web";

export default class CompEmpBuildingBlockStep3 extends CompEmpBuildingBlockStep3Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Box className="topBarFormik">
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="Documents bold-text" style={{ fontSize: "22px" }}>
              {t("Documents")}
            </Typography>
            <Typography style={{ fontSize: "16px" }} className="stepComplex">
              {t("Step")} {this.props.step + 1} {t("of 7")}
            </Typography>
          </Box>
          <Box style={{ marginTop: "30px" }}>
            <Box style={{ width: "100%" }} className="policy-complex-box">
              <Typography className="complex-doc-label">{t("Policy")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.iBuildingDocuments.policy.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            onClick={() => this.handleRemoveDocumentFile("policy", index)}
                            style={{ cursor: "pointer" }}
                            data-test-id="ib-policy-remove"
                          >
                            <img src={DeleteIcon} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.iBuildingDocuments.policy.length === 0 && t("Upload Policy Documents")}
                    </Box>
                  </Box>
                  <Box
                    data-test-id="ib-policy-upload"
                    onClick={() => this.handleDocumentFileChanges("policy")}
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                  >
                    <img src={uploadbw} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <Box style={{ width: "100%" }}>
              <Typography className="complex-doc-label">{t("Guidelines")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.iBuildingDocuments.guidelines.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleRemoveDocumentFile("guidelines", index)}
                            data-test-id="ib-guidelines-remove"
                          >
                            <img src={DeleteIcon} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.iBuildingDocuments.guidelines.length === 0 && t("Upload Guidelines Documents")}
                    </Box>
                  </Box>
                  <Box
                    data-test-id="ib-guidelines-upload"
                    onClick={() => this.handleDocumentFileChanges("guidelines")}
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                  >
                    <img src={uploadbw} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <Box style={{ width: "100%" }} className="role-box">
              <Typography className="complex-doc-label">{t("Roles")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.iBuildingDocuments.roles.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleRemoveDocumentFile("roles", index)}
                            data-test-id="ib-roles-remove"
                          >
                            <img src={DeleteIcon} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.iBuildingDocuments.roles.length === 0 && t("Upload Roles Documents")}
                    </Box>
                  </Box>
                  <Box
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                    onClick={() => this.handleDocumentFileChanges("roles")}
                    data-test-id="ib-roles-upload"
                  >
                    <img src={uploadbw} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <Box style={{ width: "100%" }}>
              <Typography className="complex-doc-label">{t("Resolutions")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.iBuildingDocuments.resolutions.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            style={{ cursor: "pointer" }}
                            data-test-id="ib-resolutions-remove"
                            onClick={() => this.handleRemoveDocumentFile("resolutions", index)}
                          >
                            <img src={DeleteIcon} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.iBuildingDocuments.resolutions.length === 0 && t("Upload Resolutions Documents")}
                    </Box>
                  </Box>
                  <Box
                    data-test-id="ib-resolutions-upload"
                    onClick={() => this.handleDocumentFileChanges("resolutions")}
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                  >
                    <img src={uploadbw} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <Box style={{ width: "100%" }}>
              <Typography className="complex-doc-label">{t("Building Plans")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.iBuildingDocuments.buildingPlans.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            data-test-id="ib-buildingPlans-remove"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleRemoveDocumentFile("buildingPlans", index)}
                          >
                            <img src={DeleteIcon} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.iBuildingDocuments.buildingPlans.length === 0 && t("Upload Building Plans Documents")}
                    </Box>
                  </Box>
                  <Box
                    data-test-id="ib-buildingPlans-upload"
                    onClick={() => this.handleDocumentFileChanges("buildingPlans")}
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                  >
                    <img src={uploadbw} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Loader loading={this.state.loading} />

        <Box style={{ display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "20px" }}>
          <Grid style={{ marginTop: "30px" }}>
            <Button style={webStyle.backButton} onClick={this.props.handleBack}>
              <span className="bold-text">{t("PREV")}</span>
            </Button>
          </Grid>
          <Grid style={{ marginTop: "30px" }}>
            <Button data-test-id="save-document" style={webStyle.buttonSave} onClick={this.handleComplexSubmitDocument}>
              <span className="bold-text">{t("NEXT")}</span>
            </Button>
          </Grid>
        </Box>
      </>
    );
  }
}

const webStyle: any = {
  buttonSave: {
    textTransform: "capitalize",
    height: "56px",
    backgroundColor: "#2B6FED",
    display: "flex",
    padding: "0px 20.5px",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    borderRadius: "8px",
    justifyContent: "center",
    width: "177px",
  },
  backButton: {
    borderRadius: "8px",
    height: "56px",
    justifyContent: "center",
    fontWeight: "bold",
    textTransform: "capitalize",
    width: "177px",
    border: "1px solid #2B6FED",
    display: "flex",
    padding: "0px 20.5px",
    color: "#2B6FED",
    textAlign: "center",
  },
  docBox: {
    padding: "8px 25px 8px 8px",
    borderRadius: "8px",
    border: "1px solid #f0f0f0",
    backgroundColor: "#f9f9f9",
    marginBottom: "0px",
  },
  docName: {
    padding: "8px 15px",
    gap: "15px",
    borderRadius: "8px",
    display: "flex",
    background: "#FFF",
    border: "1px solid #F0F0F0",
    alignItems: "center",
  },
  btnContainer: { display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "20px", marginTop: "30px" },
};
// Customizable Area End
