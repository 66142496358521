// Customizable Area Start
import React from "react";
import DeleteRevokeMemberController, { Props } from "./CEDeleteRevokeMemberController.web";
import { withTranslation } from "react-i18next";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
export const tickImg = require("../../assets/tick-circle-icon.png");

class CEDeleteRevokeMember extends DeleteRevokeMemberController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, openModal, modalType, handleModalClose } = this.props;

    return (
      <>
        <AlertDialog
          IsOpen={openModal}
          Image={tickImg}
          CloseDialog={handleModalClose}
          Header={
            modalType == "delete"
              ? `${t("Delete Profile")}`
              : `${t("Revoke")} ${this.props.buildingComplexName} ${t("Permission")}`
          }
          Content={
            modalType == "delete"
              ? `${t("Are you sure you want to delete")} ${this.props.name}' ${t(
                  "Profile? You won't be able to retrieve data once deleted"
                )}`
              : `${t("Are you sure want to revoke")} ${this.getType()} ${this.props.buildingComplexName}'${t(
                  "s permission from"
                )} ${this.props.name}?`
          }
          DeclineText={t("CLOSE")}
          AcceptText={t("CONFIRM")}
          DeclineFun={handleModalClose}
          AcceptFun={this.handleDelete}
        />
      </>
    );
  }
}

export default withTranslation()(CEDeleteRevokeMember);
// Customizable Area End
