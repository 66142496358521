import React, { ChangeEvent } from "react";
//components
import {
    Box,
    Button,
    Grid, Input,
    InputAdornment,
    TextField
} from "@material-ui/core";
//resources
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import ClassifiedController, { Props } from "./ClassifiedController.web";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import AlertError from "../../../components/src/AlertError.web";
//Customizable Area Start

//resorces
import {
    Upload_Icon,
    Building1,
    Checkmark_Icon,
    Error_Icon,
    EmailIcon,
    DescIcon,
    TitleIcon,
    CurrencyIcon,
    PayDetailIcon,
    TimeIcon, Calender_Icon
} from "../src/assets";
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { withTranslation } from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
import {MobileIcon} from "../../ReviewAndApproval/src/assets"
import { PropertyManagerStyleWeb } from "../../ReviewAndApproval/src/PropertyManagerStyle.web";
import {withStyles} from "@material-ui/core/styles";
class CreateClassified extends ClassifiedController {
    handleChangeCCode: ((value: string, data: {} | CountryData, event: ChangeEvent<HTMLInputElement>, formattedValue: string) => void) | undefined;
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): any {
        this.getCurrencyList();
        //@ts-ignore
        const id = this.props.history.location?.state?.id;
        if (id)
            this.getClassifiedDetailsById(id)
    }

    checkSchema = () => {
        const classifiedUserType = localStorage.getItem("classifiedUserType")
        const classified_type = this.state.getClassifiedDetails?.attributes?.classified_type;
        if (classifiedUserType === "generic" || classified_type === "generic") {
            return this.createClassifiedSchemaGerenic();
        } else if (classifiedUserType === "buyer" || classified_type === "buyer") {
            return this.createClassifiedSchemaBuy();
        } else {
            return this.createClassifiedSchemaSell();
        }
    }

    checkUploadMessage = (t:any) => {
        return(
            this.state.upload ?
                <Box className="result-disp-row">
                    <img src={Checkmark_Icon.default} className="successful-icon" alt="card-img" />
                    <span className="text-success">
                      {t("uploaded successfully")}
                    </span>
                </Box>
            :
            this.uploadErrorMessage(t)
        )
    }

    uploadErrorMessage = (t:any) => {
        return(
            this.state.notImageOrVideoError ?
            <Box className="result-disp-row">
                <img src={Error_Icon.default} className="error-icon" alt="card-img" />
                <span className="text-error">
                  {t("Only image are supported.")}
                </span>
            </Box>
            :
            this.state.sizeError &&
            <Box className="result-disp-row">
                <img src={Error_Icon.default} className="error-icon" alt="card-img" />
                <span className="text-error">
                    {t("size is less than 10 mb.")}
                </span>
            </Box>
        )
    }

    checkButtonRender = (classifiedUserType:any,t:any) => {
        return(
            !classifiedUserType ?
                <Box className="customButton">
                    <Button variant="contained" type="submit" >{t("SAVE CHANGES")}</Button>
                </Box>
                :
                <Box className="customButton">
                    <Button variant="contained" type="submit">{t("preview")}</Button>
                </Box>
        )
    }

    placeHolderManagerTimeFrom = (values:any,is_mobile:any) => {
        return(
            !values.timeFrom && is_mobile ? "date placeHolderForTimeClassified" : ""
        )
    }

    placeholderManagerTimeTo = (values:any,is_mobile:any) => {
        return(
            !values.timeTo && is_mobile ? "date placeHolderForTimeClassified" : ""
        )
    }
    render() {
        //@ts-ignore
        const classifiedUserType = localStorage.getItem("classifiedUserType")
        // @ts-ignore
        const { t, i18n,classes} = this.props;
        const language = i18n.language;
        const classified_type = this.state.getClassifiedDetails?.attributes?.classified_type;
        const id = this.state.getClassifiedDetails?.id;
        const attributes = this.state.getClassifiedDetails?.attributes;
        const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/ig) ;
        let validationSchema = this.checkSchema();
        return (
            <>
                <Box className="login-wrapper incident-wrapper" style={{backgroundColor:"white"}}  dir={languageCondition(language,"rtl","ltr")}>
                    <Grid container spacing={2} className="auth-container">
                        <Grid item xs={12} md={7} className="auth-cols">
                            <Box className="common_content_block content-block">
                                <Box className="content-header">
                                    <Box className="left-block blocks">
                                        <ArrowBackIcon 
                                        data-test-id="arrowbackdata" className="CreateClassfiedBackButton" onClick={() => window.history.back()} style={{fontSize:"20px"}} />
                                        <CreateClassifiedHeading classifiedUserType={classifiedUserType} t={t} />
                                    </Box>
                                </Box>
                                <Box className={languageCondition(language, "arabic-grid inputPlaceholderRegistration", "inputPlaceholderRegistration")} style={{padding:"0px 20px",overflowY: "auto",maxHeight: "90vh"}}>
                                    <Formik
                                        initialValues={this.initialValuesClassified(attributes,id)}
                                        enableReinitialize
                                        validationSchema={validationSchema}
                                        validateOnMount={true}
                                        data-test-id="submit"
                                        onSubmit={(values) => this.manageSubmit(values,classifiedUserType)}
                                    >
                                        {({ values,handleBlur, touched, errors, isValid, setFieldError, setFieldValue, handleChange }) => (
                                            <Form className="commonForm CreateClassifiedFrm" translate="yes">
                                                <Box className={classes.registerManager} style={{marginBottom:"15px"}}>
                                                    <FormControl fullWidth>
                                                        <Box className="mobile-box" style={{border:"0.1px solid rgb(209 209 209 / 100%)"}}>
                                                            <Box style={languageCondition(language,{marginRight:"10px"},{})}>
                                                                <FormControl variant="outlined">
                                                                    <PhoneInput inputProps={{ name: "selectCode" }}
                                                                        enableSearch={true} value={this.state.selectCode}
                                                                        onChange={this.handleChangeCCode} country={"us"}
                                                                    />
                                                                </FormControl>
                                                            </Box>
                                                            <Box className="divider" />
                                                            <Input
                                                                value={values.phone} onChange={handleChange}
                                                                onBlur={handleBlur} name="phone"
                                                                className="mobile-input" placeholder={t("Mobile Number")}
                                                                startAdornment={
                                                                    <img src={MobileIcon} alt="" className="mobileIcon" />
                                                                }/>
                                                        </Box>
                                                    </FormControl>
                                                    <ErrorMessage className="text-error" component="Typography" name="phone" />
                                                </Box>
                                                <Box marginBottom="1rem">
                                                    <Box className="formGroup" style={{marginBottom:"0px"}}>
                                                        <Field name="email" type="text" placeholder={t("Email Address")} className={languageCondition(language, "formInput_ar", "formInput enterEmail")} style={{border:"0.1px solid rgb(209 209 209)"}} />
                                                        <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons selectComplex")}>
                                                            <img src={EmailIcon} className="frm-icons" alt="Warning Icon" />
                                                        </span>
                                                    </Box>
                                                    <ErrorMessage className="text-error" component="Typography" name="email" />
                                                </Box>
                                                <Box marginBottom="1rem">
                                                    <Box className="formGroup" style={{marginBottom:"0px"}}>
                                                        <Field name="classifiedTitle" type="text" placeholder={t("Title")} className={languageCondition(language, "formInput_ar", "formInput enterName")} style={{border:"0.1px solid rgb(209 209 209)"}}/>
                                                        <span  className={languageCondition(language, "frmRightIcons", "frmLeftIcons selectComplex")}>
                                                            <img src={TitleIcon} className="frm-icons" alt="Warning Icon" style={{marginTop:'3px'}} />
                                                          </span>
                                                    </Box>
                                                    <ErrorMessage className="text-error" component="Typography" name="classifiedTitle" />
                                                </Box>
                                                <Box marginBottom="1rem">
                                                    <Box className="formGroup" style={{marginBottom:"0px"}}>
                                                        <Field name="description" type="text" placeholder={t("Description")} className={languageCondition(language, "formInput_ar", "formInput enterDescription")} style={{border:"0.1px solid rgb(209 209 209)"}}/>
                                                        <span  className={languageCondition(language, "frmRightIcons", "frmLeftIcons selectComplex")}>
                                                            <img src={DescIcon} className="frm-icons" alt="Warning Icon" style={{marginTop:'4px'}} />
                                                        </span>
                                                    </Box>
                                                    <ErrorMessage className="text-error" component="Typography" name="description" />
                                                </Box>
                                                {(classifiedUserType !== "generic" && classified_type !== "generic") &&
                                                    <Box>
                                                        <Box className="formGroup customFileupload" style={{backgroundColor:"white"}}>
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                            >
                                                                <div className="imgLayer">
                                                                    <img src={values?.media[0]?.url} className="bg-img" alt="" />
                                                                </div>

                                                                <div className="uploadLayer">
                                                                    <div className="content-text">
                                                                        <img src={Upload_Icon} className="upload-icon" alt="upload-icon" />
                                                                        {t("Photos")}
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    name='media'
                                                                    type="file"
                                                                    hidden
                                                                    accept="image/jpg ,image/jpeg,image/gif,image/png"
                                                                    multiple
                                                                    data-test-id="files"
                                                                    onChange={(e: any) =>
                                                                        this.handleSelectMedia(
                                                                            e,
                                                                            values.media,
                                                                            setFieldValue
                                                                        )
                                                                    }
                                                                />
                                                            </Button>
                                                            {this.checkUploadMessage(t)}
                                                        </Box>
                                                    </Box>
                                                }
                                                {
                                                    (classifiedUserType === "generic" || classified_type === "generic") && (
                                                        <>
                                                            <Box  marginBottom="1rem">
                                                                <Box className="formGroup customSelect" style={{ marginTop: 20,marginBottom:"0px" }}>
                                                                    <FormControl variant="outlined" >
                                                                        <Select
                                                                            name="currency"
                                                                            labelId="demo-simple-select-outlined-label"
                                                                            id="demo-simple-select-outlined"
                                                                            style={{ paddingLeft: 0, marginTop: -3,border:"0px",paddingRight:"0px",borderRadius:"25px" }}
                                                                            data-test-id="currency"
                                                                            startAdornment={
                                                                                <img src={CurrencyIcon} className="frm-icons" alt="Warning Icon" style={{marginLeft:"5px",marginRight:"15px"}}/>
                                                                            }
                                                                            onChange={(e) => {
                                                                                setFieldValue("currency", e.target.value)
                                                                            }}
                                                                            value={values.currency}
                                                                        >
                                                                            <MenuItem disabled value=" " className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectComplex")}>
                                                                                {t("Currency")}
                                                                            </MenuItem>
                                                                            {
                                                                                this.state.getCurrencyList.map((val: any, index: any) => (
                                                                                    <MenuItem
                                                                                        className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectComplex")}
                                                                                        key={index}
                                                                                        value={val.id}
                                                                                    >
                                                                                        {languageCondition(language,val.attributes.currency_ar,val.attributes.currency)}
                                                                                    </MenuItem>
                                                                                ))
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                                <ErrorMessage className="text-error" component="Typography" name="currency" />
                                                            </Box>
                                                            <Box marginBottom="1rem">
                                                                <Box className="formGroup"  style={{marginBottom:"0px"}}>
                                                                    <Field name="paymentDetail" type="text" placeholder={t("Payment Detail")} className={languageCondition(language, "formInput_ar", "formInput paymentDetails")} style={{border:"0.1px solid rgb(209 209 209)"}} />
                                                                    <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons selectVisiorName")}>
                                                                      <img src={PayDetailIcon} className="frm-icons" alt="Warning Icon" />
                                                                  </span>
                                                                </Box>
                                                                <ErrorMessage className="text-error" component="Typography" name="paymentDetail" />
                                                            </Box>
                                                            <p style={{marginLeft:"5px"}}>{t("Select timing for which you are willing to provide/receive the service")}</p>
                                                            <Box className="DateSection" style={{marginTop:"10px"}}>
                                                            <Grid container style={{marginTop:"10px"}}>
                                                                <Grid xs={6}>
                                                                    <Box className="formGroup classifiedFormGroup" style={{width:"95%"}}>
                                                                        <TextField
                                                                            label="Time From" variant="outlined"
                                                                            style={{ width: "100%", borderRadius: "25px",border:"0.1px solid rgb(209 209 209)" }}
                                                                            type="time" name="timeFrom" fullWidth
                                                                            id="classifiedTimeBox"
                                                                            format='hh:mm'
                                                                            placeholder={t("From")}
                                                                            className={this.placeHolderManagerTimeFrom(values,is_mobile)}
                                                                            value={values?.timeFrom}
                                                                            data-test-id="timeForm"
                                                                            onChange={(e:any) => setFieldValue("timeFrom",e.target.value)}
                                                                            InputProps={{
                                                                                //@ts-ignore
                                                                                max: "5000-05-31",
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <img src={TimeIcon} className="frm-icons" alt="Warning Icon" />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                        <span className="frmLeftIcons">
                                                                            <img src={TimeIcon} className="frm-icons" alt="Warning Icon"/>
                                                                        </span>
                                                                        <ErrorMessage className="text-error" component="Typography" name="timeFrom" />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Box className="formGroup">
                                                                         <TextField
                                                                            label="Time To" variant="outlined"
                                                                            style={{ width: "100%", borderRadius: "25px",border:"0.1px solid rgb(209 209 209)" }}
                                                                            type="time" name="timeTo" fullWidth
                                                                            id="classifiedTimeBox"
                                                                            data-test-id="timeBoxTest"
                                                                            className={this.placeholderManagerTimeTo(values,is_mobile)}
                                                                            format='hh:mm'
                                                                            placeholder={t("To")}
                                                                            value={values?.timeTo}
                                                                            onChange={(e:any) => setFieldValue("timeTo",e.target.value)}
                                                                            InputProps={{
                                                                                //@ts-ignore
                                                                                max: "5000-05-31",
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <img src={TimeIcon} className="frm-icons" alt="Warning Icon" />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                        <span className="frmLeftIcons" style={{marginTop:"0px"}}>
                                                                            <img src={TimeIcon} className="frm-icons" alt="Warning Icon"/>
                                                                        </span>
                                                                        <ErrorMessage className="text-error" component="Typography" name="timeTo" />
                                                                    </Box>
                                                                </Grid>
                                                                <p>{t("Select duration to let your potential clients know how long your offer stays.")}</p>
                                                            </Grid>
                                                            </Box>
                                                        </>
                                                )}
                                                {
                                                    (classifiedUserType === "buyer" || classified_type === "buyer") &&
                                                        <>
                                                            <Grid container>
                                                                <Grid xs={12} className="classifiedPriceBlock" style={{margin:"0px"}}>
                                                                    <p>{t("Price Range")}</p>
                                                                    <Box className={languageCondition(language,"formGroup priceSelect_ar","formGroup priceSelect")} style={{marginBottom:"0px"}}>
                                                                        <FormControl variant="outlined" >
                                                                                <Select
                                                                                name="currency"
                                                                                labelId="demo-simple-select-outlined-label"
                                                                                style={{ paddingLeft: 50, marginTop: -3 }}
                                                                                onChange={(e) => {
                                                                                   setFieldValue("currency", e.target.value)
                                                                                }}
                                                                                value={values.currency}
                                                                            >
                                                                                <MenuItem disabled value=" ">
                                                                                    {t("Currency")}
                                                                                </MenuItem>
                                                                                {
                                                                                    this.state.getCurrencyList?.map((val: any, index: any) => (
                                                                                        <MenuItem
                                                                                            key={index}
                                                                                            value={val.id}
                                                                                        >
                                                                                            {languageCondition(language,val.attributes.currency_ar,val.attributes.currency)}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                }
                                                                            </Select>
                                                                            <ErrorMessage className="text-error-currency1" component="Typography" name="currency" />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Box marginBottom="1rem">
                                                                        <Box className="formGroup classifiedFormGroup" style={{marginBottom:"0px"}}>
                                                                            <Field name="priceFrom" type="text" placeholder={t("From")} className={languageCondition(language, "formInput_ar", "formInput selectVisiorName")}  style={{border:"0.1px solid rgb(209 209 209)"}} />
                                                                            <span  className={languageCondition(language, "frmRightIcons", "frmLeftIcons selectComplex")} style={{top:"19px"}}>
                                                                              <img src={CurrencyIcon} className="frm-icons" alt="Warning Icon"  />
                                                                          </span>
                                                                        </Box>
                                                                        <ErrorMessage className="text-error" component="Typography" name="priceFrom" />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid xs={6}>
                                                                    <Box marginBottom="1rem">
                                                                        <Box className="formGroup" style={{marginBottom:"0px"}}>
                                                                            <Field name="priceTo" type="text" placeholder={t("To")} className={languageCondition(language, "formInput_ar", "formInput selectVisiorName")} style={{border:"0.1px solid rgb(209 209 209)"}}/>
                                                                            <span  className={languageCondition(language, "frmRightIcons", "frmLeftIcons selectComplex")} style={{top:"19px"}}>
                                                                                  <img src={CurrencyIcon} className="frm-icons" alt="Warning Icon" />
                                                                           </span>
                                                                        </Box>
                                                                        <ErrorMessage className="text-error" component="Typography" name="priceTo" />
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                            <p>{t("Select duration to let buyers know how long your offer stays")}</p>
                                                        </>
                                                }
                                                {(classifiedUserType === "seller" || classified_type === "seller") &&
                                                    <>
                                                        <Box className="sellerPriceBox" style={{ marginTop: 20 }}>
                                                            <Box marginBottom="1rem">
                                                                <Box className="formGroup" style={{marginBottom:"0px"}}>
                                                                    <Field name="price" type="text" placeholder={t("Price")} className={languageCondition(language, "formInput_ar", "formInput selectVisiorName")} style={{border:"0.1px solid rgb(209 209 209)"}}/>
                                                                    <span  className={languageCondition(language, "frmRightIcons", "frmLeftIcons selectComplex")}>
                                                                          <img src={CurrencyIcon} className="frm-icons" alt="Warning Icon" />
                                                                  </span>
                                                                </Box>
                                                                {/*@ts-ignore*/}
                                                                <ErrorMessage className="text-error" component="Typography" name="price" style={{width:"160px"}}/>
                                                            </Box>
                                                            <Box className={languageCondition(language,"formGroup priceSelect_ar","formGroup priceSelect")}>
                                                                <FormControl variant="outlined">
                                                                    <Select
                                                                        name="currency"
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        style={{ paddingLeft: 50, marginTop: -3 }}
                                                                        onChange={(e) => {
                                                                            setFieldValue("currency", e.target.value)
                                                                        }}
                                                                        value={values.currency}
                                                                    >
                                                                        <MenuItem disabled value=" ">
                                                                            {t("Currency")}
                                                                        </MenuItem>
                                                                        {
                                                                            this.state?.getCurrencyList?.map((val: any, index: any) => (
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    value={val?.id}
                                                                                >
                                                                                    {languageCondition(language,val.attributes.currency_ar,val.attributes.currency)}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                                {/*@ts-ignore*/}
                                                                <ErrorMessage className="text-error-currency" component="Typography" name="currency" style={{width:"160px",marginLeft:"-10px"}} />
                                                            </Box>
                                                        </Box>
                                                        <p style={{marginLeft:"5px",marginTop:"15px"}}>{t("Select duration to let buyers know how long your offer stays")}</p>
                                                    </>
                                                }
                                                <Box className="DateSection" style={{marginTop:"10px"}}>
                                                    <Grid container>
                                                        <Grid xs={12} md={6}>
                                                            <Box className="formGroup classifiedFormGroup" style={{width:"95%"}}>
                                                                {/*<Typography> Date</Typography>*/}
                                                                <TextField
                                                                    label="Start Date" variant="outlined"
                                                                    style={{ width: "100%", borderRadius: "25px",border:"0.1px solid rgb(209 209 209)" }}
                                                                    type="date" name="startDate" fullWidth
                                                                    id="classifiedTimeBox"
                                                                    placeholder={t("From")}
                                                                    className={!values.startDate && is_mobile ? "date placeHolderForDate" : ""}
                                                                    format='DD/MM/YYYY'
                                                                    value={values?.startDate}
                                                                    onChange={handleChange}
                                                                    InputProps={{
                                                                        // min: "2019-01-24",
                                                                        //@ts-ignore
                                                                        max: "5000-05-31",
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                 <DateRangeOutlinedIcon />
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                                <span className="frmLeftIcons">
                                                                    <img src={Calender_Icon} className="frm-icons" alt="Calender Icon"/>
                                                                </span>
                                                                <ErrorMessage className="text-error" component="Typography" name="startDate" />
                                                            </Box>
                                                        </Grid>
                                                        <Grid xs={12} md={6}>
                                                            {/*<Typography>To Date</Typography>*/}
                                                            <Box className="formGroup classifiedFormGroup" style={{width:"95%"}} >
                                                                <TextField label="End Date" variant="outlined"
                                                                           type="date" name="endDate" fullWidth
                                                                           style={{ width: "100%", borderRadius: "25px", border:"0.1px solid rgb(209 209 209)" }}
                                                                           id="classifiedTimeBox"
                                                                           format='DD/MM/YYYY'
                                                                           placeholder={t("To")}
                                                                           className={!values.endDate && is_mobile ? "date placeHolderForDate" : ""}
                                                                           value={values?.endDate}
                                                                           onChange={handleChange}
                                                                           InputProps={{
                                                                               // min: "2019-01-24",
                                                                               //@ts-ignore
                                                                               max: "5000-05-31",
                                                                               startAdornment: (
                                                                                   <InputAdornment position="start">
                                                                                       <DateRangeOutlinedIcon />
                                                                                   </InputAdornment>
                                                                               )
                                                                           }}
                                                                />
                                                                <span className="frmLeftIcons">
                                                                    <img src={Calender_Icon} className="frm-icons" alt="Calender Icon"/>
                                                                </span>
                                                                <ErrorMessage className="text-error" component="Typography" name="endDate" />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box >
                                                {this.checkButtonRender(classifiedUserType,t)}
                                            </Form>
                                        )}
                                    </Formik >
                                </Box >
                                {/* desktop footer block */}
                                {/* <Box className="bottomBlock common-bottom-padding" display={{ xs: 'none', md: 'flex' }}>
                  <h6 className="bottom-text">POWERED BY</h6>
                  <img src={Tenant_Logo.default} className="tenant-logo" alt="" />
                </Box> */}
                            </Box >
                        </Grid >
                        <Grid item xs={12} md={5} className="auth-cols">
                            <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                                <img src={Building1.default} className="building-logo" alt="" />
                            </Box>
                        </Grid>
                    </Grid >
                </Box >
                <AlertError  show={this.state.showError} handleClose={()=> this.setState({showError: false})} message={this.state.error} />
                <Loader loading={this.state.loading} />
            </>
        )
    }
}

const CreateClassifiedHeading = (props:any) => {
    const {classifiedUserType,t} = props
    let name = ""
    if(classifiedUserType === "buyer"){
        name = "Buyers Request"
    }else{
        if(classifiedUserType === "seller"){
            name = "Sellers Request"
        }else{
            if(classifiedUserType === "generic"){
                name = "Generic Request"
            }else{
                name = "Edit Classified"
            }
        }
    }
    return(
        <>
            <h4 className={"bold-text createClassifiedHeading"} style={{fontSize:"18px"}}>{t(name)}</h4>
        </>
    )
}
// @ts-ignore
export default withTranslation()(withStyles(PropertyManagerStyleWeb)(withRouter(CreateClassified)));
//Customizable Area End