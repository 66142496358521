// Customizable Area Start
import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import CompEmpComplexBlockStep3Controller, { Props } from "./CompEmpComplexBlockStep3Controller.web";
import "../../assets/commonForm.css";
import { cross, upload } from "../assets";
import Loader from "../../../../components/src/Loader.web";

export default class CompEmpComplexBlockStep3 extends CompEmpComplexBlockStep3Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box className="topBarFormik">
          <Box id="checknow" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="Documents bold-text" style={{ fontSize: "22px", fontWeight: "bold" }}>
              {t("Documents")}
            </Typography>
            <Typography className="stepComplex" style={{ fontSize: "16px" }}>
              {t("Step")} {this.props.step + 1} {t("of 7")}
            </Typography>
          </Box>
          <Box style={{ marginTop: "30px" }}>
            <Box style={{ width: "100%" }} className="policy-complex-box">
              <Typography className="complex-doc-label">{t("Policy")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.documents.policy.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            onClick={() => this.handleRemoveFile("policy", index)}
                            style={{ cursor: "pointer" }}
                            className="complex-policy-remove"
                          >
                            <img src={cross} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.documents.policy.length === 0 && t("Upload Policy Documents")}
                    </Box>
                  </Box>
                  <Box
                    className="complex-policy-upload upload-button"
                    onClick={() => this.handleFileChanges("policy")}
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                  >
                    <img src={upload} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="steps" style={{ marginTop: "20px" }}>
            <Box style={{ width: "100%" }}>
              <Typography className="complex-doc-label">{t("Guidelines")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.documents.guidelines.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleRemoveFile("guidelines", index)}
                            className="complex-guidelines-remove"
                          >
                            <img src={cross} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.documents.guidelines.length === 0 && t("Upload Guidelines Documents")}
                    </Box>
                  </Box>
                  <Box
                    className="upload-button complex-guidelines-upload"
                    onClick={() => this.handleFileChanges("guidelines")}
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                  >
                    <img src={upload} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <Box style={{ width: "100%" }} className="role-box">
              <Typography className="complex-doc-label">{t("Roles")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.documents.roles.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleRemoveFile("roles", index)}
                            className="complex-roles-remove"
                          >
                            <img src={cross} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.documents.roles.length === 0 && t("Upload Roles Documents")}
                    </Box>
                  </Box>
                  <Box
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                    onClick={() => this.handleFileChanges("roles")}
                    className="complex-roles-upload upload-button"
                  >
                    <img src={upload} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <Box style={{ width: "100%" }}>
              <Typography className="complex-doc-label">{t("Resolutions")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.documents.resolutions.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            style={{ cursor: "pointer" }}
                            className="complex-resolutions-remove"
                            onClick={() => this.handleRemoveFile("resolutions", index)}
                          >
                            <img src={cross} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.documents.resolutions.length === 0 && t("Upload Resolutions Documents")}
                    </Box>
                  </Box>
                  <Box
                    className="upload-button complex-resolutions-upload"
                    onClick={() => this.handleFileChanges("resolutions")}
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                  >
                    <img src={upload} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: "20px" }}>
            <Box style={{ width: "100%" }}>
              <Typography className="complex-doc-label">{t("Building Plans")}</Typography>
              <Box style={webStyle.docBox}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                    {this.state.documents.buildingPlans.map((document: { title: string }, index: number) => {
                      return (
                        <Box style={webStyle.docName} key={index}>
                          {document.title}
                          <Box
                            className="complex-buildingPlans-remove"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleRemoveFile("buildingPlans", index)}
                          >
                            <img src={cross} />
                          </Box>
                        </Box>
                      );
                    })}
                    <Box style={{ paddingLeft: "16px", color: "#8d8d8d", fontSize: "16px", fontWeight: 400 }}>
                      {this.state.documents.buildingPlans.length === 0 && t("Upload Building Plans Documents")}
                    </Box>
                  </Box>
                  <Box
                    className="upload-button complex-buildingPlans-upload"
                    onClick={() => this.handleFileChanges("buildingPlans")}
                    style={{ margin: "8px 0px", cursor: "pointer" }}
                  >
                    <img src={upload} alt="upload" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="step3BtnBox" style={webStyle.btnContainer}>
          <Button className="step3Prev" style={webStyle.backButton} onClick={this.props.handleBack}>
            <span className="bold-text">{t("PREV")}</span>
          </Button>
          <Button className="upload-doc" style={webStyle.buttonSave} onClick={this.handleComplexSubmitDocument}>
            <span className="bold-text">{t("NEXT")}</span>
          </Button>
        </Box>
      </>
    );
  }
}

const webStyle: any = {
  buttonSave: {
    width: "177px",
    justifyContent: "center",
    height: "56px",
    backgroundColor: "#2B6FED",
    display: "flex",
    padding: "0px 20.5px",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    borderRadius: "8px",
    textTransform: "capitalize",
  },
  backButton: {
    color: "#2B6FED",
    height: "56px",
    fontWeight: "bold",
    textTransform: "capitalize",
    width: "177px",
    justifyContent: "center",
    border: "1px solid #2B6FED",
    display: "flex",
    padding: "0px 20.5px",
    textAlign: "center",
    borderRadius: "8px",
  },
  docBox: {
    borderRadius: "8px",
    marginBottom: "0px",
    border: "1px solid #f0f0f0",
    backgroundColor: "#f9f9f9",
    padding: "8px 25px 8px 8px",
  },
  docName: {
    border: "1px solid #F0F0F0",
    borderRadius: "8px",
    gap: "15px",
    display: "flex",
    background: "#FFF",
    alignItems: "center",
    padding: "8px 15px",
  },
  btnContainer: { display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "20px", marginTop: "30px" },
};

// Customizable Area End
