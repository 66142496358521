// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  Button,
  IconButton,
  Select,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Input,
  InputBase,
  Box,
  Grid,
  MenuItem,
} from "@material-ui/core";
import ScheduledMeetingController, { Props } from "./ScheduledMeetingController.web";
import { Link } from "react-router-dom";
import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import { MeetingsStyleWeb } from "./MeetingsStyle.web";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
//@ts-ignore
import Pagination from "@material-ui/lab/Pagination";
import { SearchIconImage } from "./assets";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { ROLE } from "../../../framework/src/Enum";
import { withRouter } from "react-router";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import Loader from "../../../components/src/Loader.web";

class ScheduledMeeting extends ScheduledMeetingController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    await this.getAllMeetings();
    await this.getBuildingsList();
    await this.getUserList();
    await this.getGroupList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (
      prevState.filter.title !== this.state.filter.title ||
      prevState.filter.status !== this.state.filter.status ||
      prevState.filter.date !== this.state.filter.date ||
      prevState.filter.place !== this.state.filter.place ||
      prevState.filter.page !== this.state.filter.page ||
      prevState.filter.building !== this.state.filter.building
    ) {
      await this.getAllMeetings();
    }

    if (
      prevState.userFilter.buildingName !== this.state.userFilter.buildingName ||
      prevState.userFilter.floorId !== this.state.userFilter.floorId ||
      prevState.userFilter.userType !== this.state.userFilter.userType
    ) {
      await this.getUserList();
    }
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <GeneralHeaderComponentWeb>
              <Container className={classes.scheduledMeeting}>
                <Box className="navigation schedule-meeting-navigation">
                  <Box className="schedule-meeting-heading-box">
                    <Typography variant="body1">
                      {t("Meetings")} /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Scheduled Meetings")}
                      </Box>
                    </Typography>
                    <Typography variant="h5" className="sub-heading bold-text schedule-meeting-heading">
                      {t("Scheduled Meetings")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="top-bar schedule-meeting-top-bar-box">
                  <Box className="filter schedule-meeting-filter-box">
                    {localStorage.getItem("userType") === ROLE.MANAGER && (
                      <Select
                        displayEmpty
                        className="select-input schedule-meeting-filter-building"
                        value={this.state.building}
                        onChange={(e: any) => {
                          this.setState({
                            ...this.state,
                            building: e.target.value,
                          }, () => {
                            this.removeBuildingAllFilter();
                          });
                        }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 200, 
                              marginTop: 8,  
                            },
                          },
                        }}
                      >
                        <MenuItem value="select building">{t("Select Building")}</MenuItem>
                        <MenuItem value="">{t("All")}</MenuItem>
                        {this.state.buildingsList.map((building: any) => {
                          return <MenuItem value={building.name}>{building.name}</MenuItem>;
                        })}
                      </Select>
                    )}
                    <Input
                      type="text"
                      placeholder={t("Place")}
                      className="input schedule-meeting-filter-place"
                      value={this.state.place}
                      onChange={(e: any) => {
                        this.setState({
                          ...this.state,
                          place: e.target.value,
                        }, () => {
                          this.removePlaceAllFilter();
                        });
                      }}
                    />
                    <Select
                      displayEmpty
                      className="select-input schedule-meeting-filter-status"
                      value={this.state.status}
                      onChange={(e: any) => {
                        this.setState({
                          ...this.state,
                          status: e.target.value,
                        }, () => {
                          this.removeStatusAllFilter();
                        });
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        PaperProps: {
                          style: {
                            maxHeight: 200, 
                            marginTop: 8,  
                          },
                        },
                      }}
                    >
                      <MenuItem value="select status">{t("Select Status")}</MenuItem>
                      <MenuItem value="">{t("All")}</MenuItem>
                      <MenuItem value="scheduled">{t("Scheduled")}</MenuItem>
                      <MenuItem value="completed">{t("Completed")}</MenuItem>
                      <MenuItem value="cancelled">{t("Cancelled")}</MenuItem>
                    </Select>
                    <Input
                      value={this.state.date}
                      onChange={(e: any) => {
                        this.setState({
                          ...this.state,
                          date: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={t("Select Date")}
                      className="input date schedule-meeting-filter-date"
                      onFocus={(e: any) => (e.target.type = "date")}
                    />
                    <Button
                      startIcon={<img src={SearchIconImage} />}
                      onClick={() => {
                        this.setState({
                          filter: {
                            ...this.state.filter,
                            place: this.state.place.trim(),
                            status: this.state.status,
                            date: this.state.date,
                            building: this.state.building,
                            title: "",
                          },
                        });
                      }}
                    >
                      {t("Search")}
                    </Button>
                  </Box>
                  <Box className="create-meeting">
                    <Button onClick={() => this.openCreateMeetingModal()}>{t("Create New Meeting")}</Button>
                  </Box>
                </Box>
                <Grid className="meeting-table">
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="table-top">
                      <h4 className="bold-text">{t("Schedule Meetings")}</h4>
                      <div className="search-box">
                        <SearchIcon />
                        <InputBase
                          placeholder={t("Search by title")}
                          className="search"
                          value={this.state.filter.title}
                          onChange={(e: any) => {
                            this.setState({
                              ...this.state,
                              filter: {
                                ...this.state.filter,
                                title: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </Box>
                    <Divider />
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">#</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Title")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Date & Time")}</TableCell>
                          {localStorage.getItem("userType") === ROLE.MANAGER && (
                            <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Building")}</TableCell>
                          )}
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Place")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Agenda")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Status")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.scheduleMeetingList.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={6}>{t("No Schedule Meeting Available!!")}</TableCell>
                          </TableRow>
                        )}
                        {this.state.scheduleMeetingList.map((meeting: any, index: number) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align={languageCondition(language,"right","left")}>{index + 1}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}
                                className={meeting.attributes.meeting_type === "ga_meeting" ? "ellipse" : "ellipse-one"}
                              >
                                <span className="ellipse-one" title={meeting.attributes.title}>
                                  {meeting.attributes.title}
                                </span>
                                {meeting.attributes.meeting_type === "ga_meeting" && (
                                  <span className="ga-meeting">{t("GA Meeting")}</span>
                                )}
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>
                                {moment(meeting.attributes.meeting_date_time, "DD-MM-YYYY HH:mm", true).format(
                                  "MMMM DD, YYYY HH:mm"
                                )}
                              </TableCell>
                              {localStorage.getItem("userType") === ROLE.MANAGER && (
                                <TableCell align={languageCondition(language,"right","left")}>{meeting.attributes?.building?.name || "All"}</TableCell>
                              )}
                              <TableCell align={languageCondition(language,"right","left")} className="ellipse-one">{meeting.attributes.place}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className="ellipse-one">{meeting.attributes.agenda}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>
                                <span className={meeting.attributes.status}>{t(meeting.attributes.status)}</span>
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className={LanguageAttributeHandler(language, "s-meeting-menu", "")}>
                                <Menu
                                  menuButton={
                                    <IconButton>
                                      <MoreVertIcon />
                                    </IconButton>
                                  }
                                >
                                  <MenuItem>
                                    <Link to={`ScheduledMeeting/${meeting.id}`}>{t("View")}</Link>
                                  </MenuItem>
                                  {meeting.attributes.status !== "completed" &&
                                    <MenuItem className="sm-edit-menu" onClick={() => this.openEditMeetingModal(meeting)}>{t("Edit")}</MenuItem>
                                  }
                                  <MenuItem
                                    className="sm-cancel-menu"
                                    onClick={() => {
                                      this.setState(
                                        { scheduleMeetingId: meeting.id, scheduleMeetingDetails: meeting },
                                        () => {
                                          this.handleCancelMeetingModal();
                                        }
                                      );
                                    }}
                                  >
                                    {t("Cancel")}
                                  </MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <Divider />
                    <Box className="table-bottom">
                      <p>
                        Showing <span className="current-page">{this.state.scheduleMeetingList.length}</span> of{" "}
                        <span className="total-page">
                          {this.state.pagination ? this.state.pagination.total_count : 0}
                        </span>{" "}
                        results
                      </p>
                      {this.state.pagination && (
                        <Pagination
                          variant="outlined"
                          onChange={(event: any, value: any) => {
                            this.setState({
                              ...this.state,
                              filter: {
                                ...this.state.filter,
                                page: Number(value),
                              },
                            });
                          }}
                          siblingCount={2}
                          count={this.state.pagination.total_pages}
                          shape="rounded"
                          page={this.state.pagination.current_page}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
        </GeneralHeaderComponentWeb>

        {this.scheduleMeetingModal(language, t)}

        {this.editMeetingModal(language, t)}

        {this.cancelMeetingModal(language, t)}

        {this.createMeetingGroupModal(language, t)}
      </>
    );
  }
}

export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(ScheduledMeeting)));
// Customizable Area End
