import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { apiCall } from "../../../components/src/APICallComponent/index.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface RentHistoryForm {
  startDate: string;
  endDate: string;
  rentAmount: string;
  receivedAmount: string;
  tenantName: string;
}

interface UnitRegisterForm {
  country: string;
  region: string;
  city: string;
  complex: string;
  buildingId: string;
  floorId: string;
  unitId: string;
  size: string;
  config: string;
  price: string;
  date: string;
  type: string;
  income: string;
  valuation: string;
  images: any[];
}

interface S {
  loading: boolean;
  isRentHistoryModalOpen: boolean;

  countryList: any[];
  regionList: any[];
  cityList: any[];
  complexList: any[];
  buildingList: any[];
  floorList: any[];
  unitList: any[];
  rentHistoryList: any[];
  configList: any[];
  sizes:string;
  unitRegisterForm: UnitRegisterForm;
  configuration:any;
  rentHistoryForm: RentHistoryForm;
  unitId: string;
  purchasePrice:string;
  currency: string;
  measurement: string;
  date:string;
  lat: string;
  long: string;

  newRentIds: any[];
  deleteRentIds: any;

  isBuilding: boolean;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RegisterUnitController extends BlockComponent<Props, S, SS> {
  GetBuildingListCallId: any;
  GetComplexDetailsCallId: any;
  GetRentHistoryListCallId: any;
  DeleteRentHistoriesCallId: any;
  GetFloorListCallId: any;
  GetUnitListCallId: any;
  CreateRentHistoryCallId: any;
  RegisterUnitCallId: any;
  GetEditUnitDetailsCallId: any;
  EditRegisterUnitCallId: any;
  GetConfigurationListCallId: any;
  PublishRentHistoryCallId: any;
  GetCountryListCallId: any;
  GetRegionListCallId: any;
  GetCityListCallId: any;
  GetComplexListCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    // Customizable Area End

    this.state = {
      loading: false,
      isRentHistoryModalOpen: false,

      countryList: [],
      regionList: [],
      cityList: [],
      complexList: [],
      buildingList: [],
      floorList: [],
      unitList: [],
      rentHistoryList: [],
      configList: [],
      sizes:"",
      configuration:'',
      purchasePrice:"",
      date:"",
      unitRegisterForm: {
        country: "",
        region: "",
        city: "",
        complex: "",
        buildingId: "",
        floorId: "",
        unitId: "",
        size: "",
        config: "",
        price: "",
        date: "",
        type: "Rented",
        income: "",
        valuation: "",
        images: [],
      },

      rentHistoryForm: {
        startDate: "",
        endDate: "",
        rentAmount: "",
        receivedAmount: "",
        tenantName: "",
      },
      unitId: "",

      currency: "",
      measurement: "",

      lat: "",
      long: "",

      newRentIds: [],
      deleteRentIds: [],

      isBuilding: true,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetBuildingListCallId:
          this.handleBuildingListResponse(responseJson);
          break;
        case this.GetFloorListCallId:
          this.handleFloorListResponse(responseJson);
          break;
        case this.GetUnitListCallId:
          this.handleUnitListResponse(responseJson);
          break;
        case this.GetRentHistoryListCallId:
          this.handleRentHistoryResponse(responseJson);
          break;
        case this.CreateRentHistoryCallId:
          this.handleAddRentHistoryResponse(responseJson);
          break;
        case this.DeleteRentHistoriesCallId:
          this.handleDeleteRentHistoriesResponse(responseJson);
          break;
        case this.RegisterUnitCallId:
          this.handleRegisterUnitToOwnerResponse(responseJson);
          break;
        case this.EditRegisterUnitCallId:
          this.handleEditRegisterUnitResponse(responseJson);
          break;
        case this.GetConfigurationListCallId:
          this.handleConfigurationListResponse(responseJson);
          break;
        case this.GetEditUnitDetailsCallId:
          this.handleGetEditUnitDetailsResponse(responseJson);
          break;
        case this.GetComplexDetailsCallId:
          this.handleComplexDetailsResponse(responseJson);
          break;
        case this.GetCountryListCallId:
          this.handleGetCountryListResponse(responseJson);
          break;
        case this.GetRegionListCallId:
          this.handleGetRegionListResponse(responseJson);
          break;
        case this.GetCityListCallId:
          this.handleGetCityListResponse(responseJson);
          break;
        case this.GetComplexListCallId:
          this.handleGetComplexListResponse(responseJson);
          break;
        default:
          break;
      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  uploadUnitImages: any;

  getCountryList = async () => {
    this.GetCountryListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/country_list`,
    });
  };

  handleGetCountryListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.countries) {
      this.setState({ countryList: responseJson.data.countries });
    }
  };

  getRegionList = async (country: any) => {
    this.GetRegionListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/region_list?country=${country}`,
    });
  };

  handleGetRegionListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.regions) {
      this.setState({ regionList: responseJson.data.regions });
    }
  };

  getCityList = async (region: any) => {
    this.GetCityListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/city_list?region=${region}`,
    });
  };

  handleGetCityListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.cities) {
      this.setState({ cityList: responseJson.data.cities });
    }
  };

  getComplexList = async (city: any) => {
    this.GetComplexListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/housing_complex_list?city=${city}`,
    });
  };

  handleGetComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ complexList: responseJson.data });
    }
  };

  getBuildingList = (society_id: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetBuildingListCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_society_management/society_managements/building_list?society_management_id=${society_id}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ buildingList: responseJson.data.buildings });
    }
  };

  getConfigurationList = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetConfigurationListCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/apartment_managements/unit_configuration`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleConfigurationListResponse = (responseJson: any) => {
    if (responseJson && responseJson.configuration) {
      this.setState({ configList: responseJson.configuration });
    }
  };

  getFloorList = (building: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetFloorListCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_meeting/meeting_groups/floor_listing?building_id=${building}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleFloorListResponse = (responseJson: any) => {
    if (responseJson && responseJson.floors) {
      this.setState({ floorList: responseJson.floors });
    }
  };

  getUnitList = (buildingId: any, floor: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetUnitListCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/unit_list?status=${"No-Own"}&building_management_id=${buildingId}&floor_number=${floor}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleUnitListResponse = (responseJson: any) => {
    if (responseJson && responseJson.apartment_managements) {
      this.setState({ unitList: responseJson.apartment_managements
       });
    }
  };
  handleSaveData = () => {
      if (this.state.unitList.length > 0) {
        const { size, configuration, purchase_price, purchase_date } = this.state.unitList[0];
        const configureData = this.state.configList.find((config)=>config.id === Number(configuration))
        this.setState({
          sizes: size || "",         
          configuration: configureData || "", 
          purchasePrice: purchase_price || "",  
          date: purchase_date || "",           
        });
      }
  };

  getComplexDetails = (society_id: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetComplexDetailsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/find_complex?society_management_id=${society_id}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleComplexDetailsResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        measurement: responseJson.complex?.measurement_unit,
        lat: responseJson.complex_address?.latitude,
        long: responseJson.complex_address?.longitude,
        currency: responseJson.currency?.currency,
      });
      if (this.state.unitId) {
        this.setState({
          unitRegisterForm: {
            ...this.state.unitRegisterForm,
            complex: responseJson.complex.name,
            country: responseJson.complex_address.country,
            region: responseJson.complex_address.region,
            city: responseJson.complex_address.city,
          },
        });
      }
    }
  };

  getRentHistory = (unitId: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetRentHistoryListCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/rent_histories?apartment_management_id=${unitId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleRentHistoryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ rentHistoryList: responseJson.data });
    }
  };

  publishRentHistory = () => {
    const dataValue = {
      rent_history: {
        ids: this.state.newRentIds,
      },
    };

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.PublishRentHistoryCallId = apiRequest.messageId;

    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_settings/rent_histories/publish`);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(dataValue));

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  addRentHistory = (values: any) => {
    let data = new FormData();
    data.append("rent_history[apartment_management_id]", this.state.unitId);
    data.append("rent_history[start_date]", values.startDate);
    data.append("rent_history[end_date]", values.endDate);
    data.append("rent_history[rent_amount]", values.rentAmount);
    data.append("rent_history[tenant_name]", values.tenantName);
    data.append("rent_history[received_amount]", values.receivedAmount);
    data.append("rent_history[publish]", "false");

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateRentHistoryCallId = apiRequest.messageId;

    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_settings/rent_histories`);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleAddRentHistoryResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState(
          {
            rentHistoryList: [...this.state.rentHistoryList, responseJson.data],
            newRentIds: [...this.state.newRentIds, responseJson.data.id],
          },
          () => {
            toast.success("Rent History Created Successfully");
          }
        );
      } else if (responseJson && responseJson.message) {
        toast.error(responseJson.message);
      }
    });
  };

  deleteRentHistories = (id: any) => {
    const tempRentData = this.state.rentHistoryList.filter((item: any) => item.id !== id);
    this.setState({ rentHistoryList: tempRentData, deleteRentIds: [...this.state.deleteRentIds, id] });
  };

  deleteRentHistoriesOnSave = () => {
    const body = {
      ids: this.state.deleteRentIds,
    };

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.DeleteRentHistoriesCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/rent_histories/delete_all`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleDeleteRentHistoriesResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.message) {
        toast.success(responseJson.message);
        this.getRentHistory(this.state.unitId);
      }
    });
  };

  dataURLtoFile = (dataUrl: any) => {
    let arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "image-name.jpg", { type: mime });
  };

  toDataURL = (url: any) =>
    fetch(url)
      .then((response: any) => response.blob())
      .then(
        (blob: any) =>
          new Promise((resolve: any, reject: any) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  registerUnitToOwner = (values: any) => {
    const society_id = localStorage.getItem("society_id");
    const role = localStorage.getItem("userType") || "";

    let formData = new FormData();
    formData.append("data[role]", role);
    formData.append("data[country]", values.country);
    formData.append("data[city]", values.city);
    formData.append("data[building_management_id]", values.buildingId);
    formData.append("data[apartment_management_id]", values.unitId);
    formData.append("data[floor_number]", values.floorId);
    formData.append("data[size]", values.size);
    formData.append("data[purchase_price]", values.price);
    formData.append("data[configuration]", values.config);
    formData.append("data[purchase_date]", values.date);
    formData.append("data[current_valuation]", values.valuation);
    formData.append("data[monthly_renting_income]", values.income);
    formData.append("data[unit_type]", values.type);
    formData.append("data[society_management_id]", society_id || "");
    values.images.forEach((image: any) => {
      formData.append("data[photos][]", this.dataURLtoFile(image));
    });

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.RegisterUnitCallId = apiRequest.messageId;

    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_request_management/requests`);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleRegisterUnitToOwnerResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.props.navigation.navigate("RegisterMyUnitSuccess");
      }
    });
  };


  getEditUnitDetails = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetEditUnitDetailsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/apartment_managements/${this.state.unitId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleGetEditUnitDetailsResponse = async (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const unit = responseJson.data;

      const imageUrlPromise: any[] = unit.attributes.photos.map(async (image: any) => {
        return new Promise(async (resolve, reject) => {
          let blobString = await this.toDataURL(image.url);
          resolve(blobString);
        });
      });
      let photos = await Promise.allSettled(imageUrlPromise);

      this.setState({
        unitRegisterForm: {
          ...this.state.unitRegisterForm,
          buildingId: unit.attributes.building_management.name,
          floorId: unit.attributes.floor_number,
          unitId: unit.attributes.apartment_name,
          size: unit.attributes.size || "",
          config: unit.attributes.configuration || "",
          price: unit.attributes.purchase_price || "",
          date: unit.attributes.purchase_date || "",
          type: unit.attributes.unit_type,
          income: unit.attributes.monthly_renting_income || "",
          valuation: unit.attributes.current_valuation || "",
          images: photos.map((image: any) => image.value),
        },
        lat: unit.attributes.lat,
        long: unit.attributes.long,
      });
    }
  };

  editRegisterUnit = (values: any) => {
    var data = new FormData();
    data.append("apartment_management[purchase_price]", values.price);
    data.append("apartment_management[purchase_date]", values.date);
    data.append("apartment_management[current_valuation]", values.valuation);
    data.append("apartment_management[unit_type]", values.type);
    data.append("apartment_management[monthly_renting_income]", values.income);
    values.images.forEach((image: any) => {
      data.append("apartment_management[photos][]", this.dataURLtoFile(image));
    });

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.EditRegisterUnitCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/apartment_managements/${this.state.unitId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePatch);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  handleEditRegisterUnitResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.props.navigation.navigate("MyUnitDetails", { id: this.state.unitId });
      }
    });
  };

  validationRentHistoryFormSchema: any = Yup.object().shape({
    startDate: Yup.string().required("Required").matches(/\S/, "Required"),
    endDate: Yup.string().required("Required").matches(/\S/, "Required"),
    rentAmount: Yup.string().required("Required").matches(/\S/, "Required").matches(/^\d+$/, "Only digit allowed"),
    receivedAmount: Yup.string().required("Required").matches(/\S/, "Required").matches(/^\d+$/, "Only digit allowed"),
    tenantName: Yup.string()
      .required("Required")
      .max(100, "Maximum length of title should be 100 character")
      .trim().matches(/^[A-Za-z\s\u0600-\u06FF]*$/,"Only characters are allowed in name"),
  });

  validationRegisterUnitFormSchema: any = Yup.object().shape({
    country: Yup.string().required("Required").matches(/\S/, "Required"),
    region: Yup.string().required("Required").matches(/\S/, "Required"),
    city: Yup.string().required("Required").matches(/\S/, "Required"),
    complex: Yup.string().required("Required").matches(/\S/, "Required"),
    buildingId: Yup.string().required("Required").matches(/\S/, "Required"),
    floorId: Yup.string().required("Required").matches(/\S/, "Required"),
    unitId: Yup.string().required("Required").matches(/\S/, "Required"),
    price: Yup.string().matches(/^\d+$/, "Only digit allowed"),
    type: Yup.string(),
    income: Yup.string().matches(/^\d+$/, "Only digit allowed"),
    valuation: Yup.string().matches(/^\d+$/, "Only digit allowed"),
    size: Yup.string().matches(/^\d+$/, "Only digit allowed"),
    // images: Yup.array(),
  });

  validationEditUnitFormSchema: any = Yup.object().shape({
    price: Yup.string().matches(/^\d+$/, "Only digit allowed"),
    valuation: Yup.string().matches(/^\d+$/, "Only digit allowed"),
    type: Yup.string().required("Required").nullable(),
    income: Yup.string().matches(/^\d+$/, "Only digit allowed"),
    // images: Yup.array().min(1, "Required"),
  });

  handleOpenRentHistoryModal = (unitId: any) => {
    this.setState({
      isRentHistoryModalOpen: !this.state.isRentHistoryModalOpen,
      unitId: unitId,
    });
  };

  handleCloseRentHistoryModal = () => {
    this.setState({
      isRentHistoryModalOpen: !this.state.isRentHistoryModalOpen,
    });
  };

  handleRegisterComplex = (complex: any, setFieldValue: any) => {
    const complexId = complex.id;
    this.getComplexDetails(complexId);
    setFieldValue("complex", complexId);
    if (complex.attributes.is_building) {
      this.setState({ isBuilding: false }, () => {
        const buildingId = complex.attributes.is_building_list[0].id;
        setFieldValue("buildingId", buildingId);
        this.getFloorList(buildingId);
      });
    } else {
      this.setState({ isBuilding: true }, () => {
        this.getBuildingList(complexId);
        setFieldValue("buildingId", "");
      });
    }
  };
  // Customizable Area End
}
