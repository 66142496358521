import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { getStorageData } from "../../../framework/src/Utilities";
import { CEApiCall } from "../../../components/src/APICallComponent/index.web";
export const configJSON = require("./config.js");
import toast from "react-hot-toast";

export interface Props {
  id: string;
  t: (value: string) => string;
  history: any;
  match: any;
  location: any;
  i18n: {
    language: string;
  };
}

interface ISubscriptionList {
  name:string,
  country:string,
  city:string,
  approval_type:string,
  requested_by:string,
  item: any
}
interface IPendingunitList{
  complex:string,
  builing:string,
  pending_unit_request:string,
  operation_type:string,
  account_manager:string,
  country:string,
  city:string,
  item:any
}
interface IComplexunitList{
  unit_number:number,
  floor_number:string,
  resident_name:string,
  owner_name:string,
}

interface Selectoption {
  label: string;
  value: string;
}

// Customizable Area End

export interface S {
    // Customizable Area Start
  loading: boolean;

  subscriptionList: ISubscriptionList[];
  pendingunitlist:IPendingunitList[];
  page: number;
  dialogSucessstate:boolean;
  pendingUnitsstate:boolean;
  approveAllunitsState:boolean;
  rejectAllunitsState:boolean;
  request_id:string
  isRejectModalOpen: boolean;
  approveComplexAllunitsState:boolean,
  rejectComplexAllunitsState:boolean,
  isRejectReasonModalOpen: boolean;
  isAcceptModalOpen: boolean;
  isSubscriptionViewOpen: boolean;
  isCompanyViewOpen: boolean;
  activeTab: {
    id: number;
    tab: string;
    title: string;
    value:string
  };
  complexunitlist:IComplexunitList[]
  subscriptionListData:{[key:string]:string}
  filter: {
    country: string;
    city: string;
    complex:string,
    building:string,
    realCompany:string,
    complex_type:string,
    requester_id:string,
   
  };

  userRole:string|null
  pagination: any;
  buildingId:number;
  acceptInfo:{
    id:string,
    complexName:string,
    buildingName:string
  },
  countryList: Selectoption[];
  cityList: Selectoption[];
  companyList: Selectoption[];
  managerList:Selectoption[]
  ComplexDataList:Selectoption[],
  buildingList:Selectoption[],
  ErrorMessage:string
  searchState:string,
  complexlist:{
    label:string,
    value:string
  }[]
 
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
  id: string;
    // Customizable Area End
}

export default class DashboardApprovalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    GetpendingsubscriptionListApiCallId:string=""
    GetpendingListApiCallId:string="";
    GetListofunitsApiCallId:string="";
    AcceptRejectunitsApiCallId:string="";
    AcceptRejectunitApiCallId:string="";
    AcceptRejectSubscriptionApiCallId:string="";
    GetCountryApiCallId:string="";
    GetCityApiCallId:string="";
    GetComplexApiCallId:string="";
    GetbuildingApiCallId:string="";
    GetAccountManagerApiCallId:string="";
    GetrealestateApiCallId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      loading: false,
      request_id:"",
      searchState:"",
      acceptInfo:{
        id:"",
        complexName:"",
        buildingName:""
      },
      ErrorMessage:"",
      subscriptionList:[] ,
      countryList: [],
      cityList: [],
      companyList:[],
      managerList:[],
      ComplexDataList:[],
      buildingList:[],
    subscriptionListData:{
      id:"",
      name:"",
      country:"",
      city:"",
      subscription_plan:"",
      upgratde_to:"",
      payment_reference_number:"",
      approval_type:""
  },
  complexlist:[
    {
    label:"complex",
    value:"complex"
  },
  {
    label:"indiviual building",
    value:"indiviual building"
  }
],

    dialogSucessstate:false,
    pendingUnitsstate:false,
    approveAllunitsState:false,
    rejectAllunitsState:false,
    approveComplexAllunitsState:false,
    rejectComplexAllunitsState:false,
      isRejectModalOpen: false,
      isAcceptModalOpen: false,
      isRejectReasonModalOpen: false,

      isCompanyViewOpen: false,
      isSubscriptionViewOpen: false,
      activeTab: {
        id: 1,
        tab: "Real Estate Company",
        title: "Real Estate Company",
        value:"real_estate_company"
      },
      filter: {
        complex_type:"",
        country: "",
        city: "",
        complex:"",
        building:"",
        realCompany:"",
        requester_id:"",
      },
      complexunitlist:[],
      page: 1,
      userRole:"",
      pendingunitlist:[],
      pagination: null,
      buildingId:0
    };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (apiRequestCallId) {
      case this.GetpendingListApiCallId:
        return this.handlependingunitsListresponse(responseJson);
      case this.GetListofunitsApiCallId:
        return this.handlelistofunitsresponse(responseJson);
      case this.AcceptRejectunitsApiCallId:
        return this.handleAcceptrejectsunitsResponse(responseJson);
      case this.AcceptRejectunitApiCallId:
        return this.handleAcceptrejectsunitResponse(responseJson) ;
      case this.GetpendingsubscriptionListApiCallId:
        return this.handleSubscriptinlistResponse(responseJson);
      case this.AcceptRejectSubscriptionApiCallId:
        return this.handleAcceptSubscriptinlistResponse(responseJson);
      case this.GetCountryApiCallId:
        return this.handleCountrySuccess(responseJson);
      case this.GetCityApiCallId:
        return this.handleCitySuccess(responseJson);
      case this.GetComplexApiCallId:
        return this.handleComplexSuccess(responseJson);
      case this.GetbuildingApiCallId:
        return this.handlebuildingSuccess(responseJson);
      case this.GetAccountManagerApiCallId:
        return this.handleAccountmanagerSuccess(responseJson);
      case this.GetrealestateApiCallId:
        return this.handleRealestateSuccess(responseJson);
      default:
        break;
    }

    if (apiRequestCallId&&responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      ApiErrorResponse(responseJson);
    }
    ApiCatchErrorResponse(errorResponse);
        // Customizable Area End
    }

    // Customizable Area Start
  async componentDidMount() {
   this.getUserType()
  }
  getUserType = async () => {
    const storedRole = await getStorageData('dashboardPath');
    this.setState({ userRole: storedRole },
    ()=>{
      this.handleMainApicall()
    });
};
handleMainApicall=()=>{
  this.getPendingunitList()
  this.handlependingSubscriptionlist()
  this.getCountryList()
  this.getRealestateList()
  this.getAccountmanagerList()
}

handleCountrySuccess = (responseJson: { data: { countries: string[] } }) => {
  if (responseJson && responseJson.data) {
    this.setState({
      countryList: responseJson.data.countries.map((item: string) => ({ label: item, value: item })),
    });
  }
}

  handleCitySuccess = (responseJson: { city_list: { city: string }[] }) => {
    if (responseJson && responseJson.city_list) {
      this.setState({
        cityList: responseJson.city_list.map((item: { city: string }) => ({ label: item.city, value: item.city })),
      });
    }
  }

  handleComplexSuccess=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({
        ComplexDataList: responseJson.data.map((item:any) => ({ label: item.attributes?.name, value: item.id })),
      });
    }
  }
  handlebuildingSuccess=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({
        buildingList: responseJson.data.building_list.map((item: { name: string,id:string }) => ({ label: item.name, value: item.id })),
      });
    }
  }
  handleAccountmanagerSuccess=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({
        managerList: responseJson.data.map((item:{attributes: { employee_name: string,employee_account_id:string }}) => ({ label: item.attributes?.employee_name, value: item.attributes.employee_account_id })),
      });
    }
  }
  handleRealestateSuccess = (responseJson: any) => {
    const tabResponse = this.state.activeTab.value

    if (responseJson && responseJson.subscribers) {
      switch (tabResponse) {
        case 'real_estate_company':
          this.setState({
            companyList: responseJson.subscribers.map((item: { company_name: string, id: string }) => ({ label: item.company_name, value: item.id })),
          });
          break;
        case 'owner':
        case 'property_manager':
          this.setState({
            companyList: responseJson.subscribers.map((item: { full_name: string, id: string }) => ({ label: item.full_name, value: item.id })),
          });
          break;
        case 'individual_building':
          this.setState({
            companyList: responseJson.subscribers.map((item: { name: string, id: string }) => ({ label: item.name, value: item.id })),
          });
          break;
          default:
            break;
      }
    }
  }


handlependingunitsListresponse=(responseJson: { requests: { data: any[] }, meta: { pagination: any } })=>{
  if (responseJson && responseJson.requests) {
    this.setState({
      pendingunitlist: responseJson.requests.data.map((item: any) => ({
        complex:item.attributes?.complex?.name||"-",
        builing:item.attributes?.builing?.name||"-",
        pending_unit_request:item.attributes?.pending_unit_request||"-",
        operation_type:item.attributes?.operation_type||"-",
        account_manager:item.attributes?.account_manager||"-",
        country:item.attributes?.country||"-",
        city:item.attributes?.city||"-",
        item: item
      })),
      pagination: responseJson.meta.pagination
    });
  }
  if (responseJson && responseJson.requests&& responseJson.requests.data.length<=0) {
   this.setState({
    ErrorMessage:"No Data Found"
   })
   }
}

handlelistofunitsresponse=(responseJson: { requests: { data: any[] }, meta: { pagination: any } })=>{
  if (responseJson && responseJson.requests) {
    this.setState({
      complexunitlist: responseJson.requests.data.map((item: any) => ({
        unit_number:item.attributes?.unit_number?.id||"-",
        floor_number:item.attributes?.unit_number?.floor_number||"-",
        resident_name:item.attributes?.unit_number?.resident_name||"-",
        owner_name:item.attributes?.unit_number?.owner_name||"-",
        item: item
      })),
      pagination: responseJson.meta.pagination
    });
  }
  if (responseJson && responseJson.requests&& responseJson.requests.data.length<=0) {
    this.setState({
      ErrorMessage:"No Data Found"
     })
  }
}

handleAcceptrejectsunitsResponse=(responseJson:{[key:string]:string})=>{
toast.success(responseJson.message)
this.setState({
  dialogSucessstate:true
})
}

handleAcceptrejectsunitResponse=(responseJson:{[key:string]:string})=>{
  toast.success(responseJson.message);
  this.setState({
    dialogSucessstate:true
  })
}
handleSubscriptinlistResponse=(responseJson:any)=>{
  if (responseJson.subscriptions) {
    this.setState({
      subscriptionList: responseJson.subscriptions.data.map((item: any) => ({
        name:item.attributes?.name||"-",
        country:item.attributes?.country||"-",
        city:item.attributes?.city||"-",
        approval_type:item.attributes?.approval_type||"-",
        requested_by:item.attributes?.requested_by||"-",
        item: item
      })),
      pagination: responseJson.meta.pagination
    });
  }if(responseJson&&responseJson.errors&&responseJson.errors==="Subscription request not found"){
    this.setState({
     ErrorMessage:responseJson.errors
    })
  }
}
handleAcceptSubscriptinlistResponse=(responseJson:any)=>{
 if(responseJson){
  this.setState({
    dialogSucessstate:true
  })
 }
}

  handlesalesMangerTag = (tab: { id: number; tab: string; title: string;value:string }) => {
    this.setState({
      activeTab: tab, page: 1, filter: {
        complex_type:"",
        country: "",
        city: "",
        complex:"",
        building:"",
        realCompany:"",
        requester_id:"",
      }
    },()=>{this.handlependingSubscriptionlist()
      this.getRealestateList()
    });
  };

  handlependingSubscriptionlist = async() => {
    this.GetpendingsubscriptionListApiCallId = await CEApiCall({
      method:configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.GetpendingsubscriptionListApiendpoint+`tab=${this.state.activeTab.value}&country=${this.state.filter.country}&city=${this.state.filter.city}&requestor=${this.state.filter.requester_id}&subscriber_id=${this.state.filter.realCompany}`,
    });
  };
  handleAcceptSubscription=(item:any)=>{
    const data=item?.item?.attributes
   this.setState({
    subscriptionListData:{
      id:data.id,
      name:data.name||"(N/A)",
      country:data.country||"(N/A)",
      city:data.city||"(N/A)",
      subscription_plan:data.subscription_plan?.title||"(N/A)",
      upgratde_to:data.upgratde_to||"(N/A)",
      payment_reference_number:data.payment_reference_number||"(N/A)",
      approval_type:data.approval_type||"(N/A)"
    }
   })
  }

  handlesalesViewDetails = () => {
  this.setState({isSubscriptionViewOpen: true,});
  }

  handlesalesAcceptRequest = () => {
    this.setState({
      isAcceptModalOpen: true,
       isSubscriptionViewOpen: false,
    });
  }

  handlesalesRejectRequest = () => {
    this.setState({
      isRejectModalOpen: true,
       isSubscriptionViewOpen: false, 
    });
  }

  handlesalesDialogClose = () => {
    if (this.state.activeTab.id === 1) {
      this.setState({ isSubscriptionViewOpen: false });
    } else {
      this.setState({ isCompanyViewOpen: false });
    }
  }

  handlesalesAcceptModal = () => {
    this.setState({ isAcceptModalOpen: !this.state.isAcceptModalOpen });
  };

  handlesalesRejectModal = () => {
    this.setState({ isRejectModalOpen: !this.state.isRejectModalOpen });
  };

  handlesalesRejectReasonModal = () => {
    this.setState({ isRejectReasonModalOpen: !this.state.isRejectReasonModalOpen,isRejectModalOpen:false });
  };

  handlesalesPagination = (event: any, value: any) => {
    this.setState({ page: value },()=>{
      this.handleMainApicall()
    });
  };

  handleNavigationApprovalDashboard=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'CEDashboardApproval'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  handlesalesRecordActionSuccess = (status:string) => {
    const { userRole, pendingUnitsstate } = this.state;
    this.setState({
      loading: false,
      isAcceptModalOpen: false,
      isRejectModalOpen:false ,
      isRejectReasonModalOpen:false,
      approveAllunitsState:false,
      rejectAllunitsState:false,
      approveComplexAllunitsState:false,
      rejectComplexAllunitsState:false

    },async()=>{
        if (userRole === "Pending Units Requests" && !pendingUnitsstate) {
        this.AcceptRejectunitsApiCallId = await CEApiCall({
        endPoint : `bx_block_dashboard/approval_dashbords/accept_reject_all_request_for_unit?building_id=${this.state.acceptInfo.id}&status=${status}`,
        method: "GET",
        contentType: "application/json",
      });
        }
        else if(userRole === "Pending Units Requests" && pendingUnitsstate){
            this.AcceptRejectunitApiCallId = await CEApiCall({
            endPoint : `bx_block_dashboard/approval_dashbords/accept_reject_all_request_for_unit?request_id=${this.state.acceptInfo.id}&status=${status}`,
            method: "GET",
            contentType: "application/json",
          });
        }
        else if(userRole === "Pending Subscription Requests"  && !pendingUnitsstate){
          const data = {
            "id": Number(this.state.subscriptionListData.id),
            "status":this.state.subscriptionListData.approval_type==="pending"?"active":status,
            "reason": ""
          }
            this.AcceptRejectSubscriptionApiCallId = await CEApiCall({
          endPoint : configJSON.AcceptSubscriptionAPIendpoint,
          method: "PUT",
          contentType: "application/json",
          body: JSON.stringify(data)
        });
      }
    }
  )
  }
  handlesalesDialogSucessstate=()=>{
    this.setState({
      dialogSucessstate:false,
      loading: false
    },()=>{this.handlependinglistAPI()})
  }
  handlependinglistAPI = () => {
    const { userRole, pendingUnitsstate } = this.state;
    if (userRole === "Pending Units Requests" && !pendingUnitsstate) {
      this.getPendingunitList()

    }
    else if (userRole === "Pending Units Requests" && pendingUnitsstate) {
      this.getListofunits()

    }
    else if (userRole === "Pending Subscription Requests" && !pendingUnitsstate) {
      this.handlependingSubscriptionlist()
    }
  }
  handleunitsViewDetails=(item:{[key:string]:any})=>{
   this.setState({
    pendingUnitsstate:!this.state.pendingUnitsstate,
    buildingId:item.item?.id
   },()=>{
    this.getListofunits()
   }) 
  }
  handleAcceptData=(item:{[key:string]:any})=>{
    const data=item?.item?.attributes
    this.setState({
      ErrorMessage:"",
      acceptInfo: {
        id: data?.builing?.id,
        complexName:data?.complex?.name,
        buildingName: data?.builing?.name,
      }
    })
  }
  handleAcceptunitData=(item:{[key:string]:any})=>{
    const data=item?.item?.attributes
    this.setState({
      acceptInfo:{
      id: data?.id,
      complexName:data?.unit_number?.id,
      buildingName: data?.unit_number?.resident_name,
  }})
  }
  handleunitsAcceptRequest=(item:{[key:string]:any})=>{
    this.setState({
      approveAllunitsState:!this.state.approveAllunitsState,
    })
  }
  handleunitsRejectRequest=(item:{[key:string]:any})=>{
 this.setState({
  rejectAllunitsState:!this.state.rejectAllunitsState,
 })
  }

  handleComplexunitsAcceptRequest=()=>{
    this.setState({
      approveComplexAllunitsState:!this.state.approveComplexAllunitsState
    })
  }
  handleComplexunitsRejectRequest=()=>{
 this.setState({
  rejectComplexAllunitsState:!this.state.rejectComplexAllunitsState
 })
  }
  handleSearchfilters=()=>{
    const { userRole } = this.state;
    if (userRole === "Pending Units Requests") {
      this.getPendingunitList()
    }
    else if (userRole === "Pending Subscription Requests") {
      this.handlependingSubscriptionlist()
    }
  }

  handlesearchFilter=(event: React.ChangeEvent<{
    value:
    unknown;
}>)=>{
    this.setState({
      searchState:event?.target.value as string,
    },()=>{this.getListofunits()})
  }

  getPendingunitList= async () => {
    const filter = this.state.filter;
    let status
    if(filter.complex_type==="complex"){
     status=false
    }else if(filter.complex_type==="indiviual building"){
      status=true
    }else{
      status=""
    }
    const extendedFilter = {
      ...filter,
      complex_type: status,
    };
  
    const queryString = Object.entries(extendedFilter)
      .filter(([_, value]) => value !== "" && value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
  
    const endPoint = queryString
      ? `${configJSON.GetpendingListApiendpoint}?${queryString}`
      : configJSON.GetpendingListApiendpoint;
   
    this.GetpendingListApiCallId = await CEApiCall({
      method:configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: endPoint,
    });
  }

  getListofunits= async () => {
    this.GetListofunitsApiCallId = await CEApiCall({
      method:configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint:`bx_block_dashboard/approval_dashbords/complex_unit_request_list?building_id=${this.state.buildingId}&search=${this.state.searchState}`,
    });
  }
  handleFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value as string;
    if (stateParam === "complex/building") {
      this.setState({
        filter: {
          ...this.state.filter,
          complex_type:value,
          country: "",
          city: "",
          complex:"",
          building:"",
          realCompany:"",
          requester_id:"",
        },
    });
    }
    if (stateParam === "country") {
      this.setState({
        filter: {
          ...this.state.filter,
          country: value, 
          city: "",
          complex:"",
          building:"",
          realCompany:"",
          requester_id:"",
        },
      }, () => this.getCityList(value));
    } else if (stateParam === "city") {
      this.setState({
        filter: {
          ...this.state.filter,
          city: value,
          complex:"",
          building:"",
          realCompany:"",
          requester_id:"",
        },
      },()=>{this.getComplexList()});
    } else if (stateParam === "complex") {
      this.setState({
        filter: {
          ...this.state.filter,
          complex: value,
          building:"",
          realCompany:"",
          requester_id:"",
        },
      },()=>{this.getbuildingList()});
    } else if (stateParam === "building") {
      this.setState({
        filter: {
          ...this.state.filter,
          building:value,
          realCompany:"",
          requester_id:"",
        },
      });
    } else if (stateParam === "realCompany") {
      this.setState({
        filter: {
          ...this.state.filter,
          realCompany:value,
          requester_id:"",
        },
      });
    } else if (stateParam === "accountManager") {
      this.setState({
        filter: {
          ...this.state.filter,
          requester_id: value,
        },
      });
    } 
  };
  getCountryList = async () => {
    this.GetCountryApiCallId = await CEApiCall({
      method: "GET",
      endPoint: `bx_block_profile/profiles/country_list`,
      contentType: "application/json",
    });
  }
  getCityList = async (country: any) => {
    this.GetCityApiCallId = await CEApiCall({
      endPoint: `bx_block_custom_form/incident_managements/city_list?country=${country}`,
      method: "GET",
      contentType: "application/json",
    });
  }
  getComplexList = async () => {
    let status
    if(this.state.filter.complex_type==="complex"){
     status=false
    }else if(this.state.filter.complex_type==="indiviual building"){
      status=true
    }else{
      status=""
    }
    this.GetComplexApiCallId = await CEApiCall({
      endPoint: `/bx_block_address/housing_complex_list?city=${this.state.filter.city}&is_building=${status}`,
      method: "GET",
      contentType: "application/json",
    });
  }
  getbuildingList = async () => {
    this.GetbuildingApiCallId = await CEApiCall({
      endPoint: `/bx_block_profile/profiles/building_list?complex_id=${this.state.filter.complex}`,
      method: "GET",
      contentType: "application/json",
    });
  }
  getAccountmanagerList = async () => {
    this.GetAccountManagerApiCallId = await CEApiCall({
      endPoint: `/bx_block_my_team/company_employee/employee_team_members/account_manager_list_for_reassign`,
      method: "GET",
      contentType: "application/json",
    });
  }
  getRealestateList = async () => {
    this.GetrealestateApiCallId = await CEApiCall({
      endPoint: `/bx_block_dashboard/approval_dashbords/subscriber_list?tab=${this.state.activeTab.value}`,
      method: "GET",
      contentType: "application/json",
    });
  }

    // Customizable Area End
}
