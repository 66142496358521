import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  i18n: {
    language: string;
  };
  t: (val: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  currentTab: number;

  taskList: any[];
  notificationList: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChairmansNotificationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  DeleteNotificationCallId: any;
  GetAllTaskListCallId: any;
  UpdateReadStatusCallId: any;
  GetChairmanNotificationListCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      // Customizable Area Start
      loading: false,
      currentTab: 0,

      taskList: [],
      notificationList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetChairmanNotificationListCallId:
          this.handleNotificationResponse(responseJson);
          break;
        case this.DeleteNotificationCallId:
        case this.UpdateReadStatusCallId:
          this.setState({ loading: false }, () => {
            this.getAllChairmanNotification();
          });
          break;
        case this.GetAllTaskListCallId:
          this.handleTaskResponse(responseJson);
          break;
        default:
          break;
      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getAllTaskList();
    this.getAllChairmanNotification();
  }

  getAllTaskList = async () => {
    this.setState({ loading: true });
    const society_id = localStorage.getItem("society_id");
    this.GetAllTaskListCallId = await apiCall({
      method: "GET",
      endPoint: `bx_block_dashboard/assign_actions?society_management_id=${society_id}`,
      contentType: "application/json",
    });
  };

  handleTaskResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ taskList: responseJson.data });
      }
    });
  };

  getAllChairmanNotification = async () => {
    const role =localStorage.getItem("userType")
    const society_id = localStorage.getItem("society_id");
    this.GetChairmanNotificationListCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_notifications/notifications?role=${role}&society_management_id=${society_id}`,
      contentType: "application/json",
    });
  };

  handleNotificationResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ notificationList: responseJson.data });
      }
    });
  };

  handleTabChange = (event: any, newValue: number) => {
    this.setState({ currentTab: newValue });
  };

  updateReadNotificationStatus = async (id: any, isRead: boolean) => {
    const status = {
      notification: {
        is_read: isRead,
      },
    };
    this.setState({ loading: true });
    this.UpdateReadStatusCallId = await apiCall({
      method: "PATCH",
      endPoint: `bx_block_notifications/notifications/${id}`,
      body: JSON.stringify(status),
      contentType: "application/json",
    });
  };

  deleteSingleNotification = async (id: any) => {
    this.setState({ loading: true });
    this.DeleteNotificationCallId = await apiCall({
      method: "DELETE",
      contentType: "application/json",
      endPoint: `bx_block_notifications/notifications/${id}`,
    });
  };
  // Customizable Area End
}
