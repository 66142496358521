import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  Button,
  TableContainer,
  TableHead,
  Table,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Divider,
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Breadcrumbs,
} from "@material-ui/core";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { Menu } from "@szhsin/react-menu";
import { rightIcon, wrongIcon,DashboardInfo,viewCombined, viewunit} from "./assets";
import { navigationFunc,formatDateInIST } from "../../../components/src/helperFunctions.web";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import DialogHeader from "../../../components/src/MuiDialog/DialogHeader.web";
import Loader from "../../../components/src/Loader.web";
import AlertDialog from "../../../components/src/MuiDialog/AlertDialog.web";
import { Formik } from "formik";
import NormalTextArea from "../../../components/src/CommonComponents/NormalTextArea.web";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";

const tabs: { id: number; tab: string; table: string; title: string; }[] = [
  {
    id: 1,
    tab: "Subscription",
    table: "Subscription Approval",
    title: "Subscription"
  },
  {
    id: 2,
    tab: "Company / Complex / Individual Building",
    table: "List of Requests",
    title: "New Company / Complex / Individual Building"
  },
  {
    id: 3,
    tab: "Property Manager / Owner",
    table: "List of Requests",
    title: "Property Manager / Owner"
  },
  {
    id: 4,
    tab: "Misc",
    table: "List of miscellaneous approval requests",
    title: "Misc"
  },
  {
    id: 5,
    tab: "Employee",
    table: "List of company employee requests",
    title: "Employee"
  }
];
// Customizable Area End

import DashboardApprovalController, {
    Props,
} from "./DashboardApprovalController.web";

// Customizable Area Start
export class DashboardApproval extends DashboardApprovalController {
  constructor(props: Props) {
    super(props);
  }

  handleTabs = (t: (value: string) => string) => {
    return (
      <Grid container xs={12} spacing={2}>
        {tabs.map((item: { id: number; tab: string; table: string; title: string; }) => {
          return (
            <Grid item>
              <Typography
                style={this.state.activeTab.id === item.id ? approveCss.selectTag : approveCss.unSelectTag}
                data-test-id="tabId" onClick={() => this.handleTag(item)} className="bold-text"
              >
                {t(item.tab)}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    );
  };

  handleDialogData = (t: (value: string) => string, label: string, value: string, isBlue: boolean = false) => {
    return (
      <Grid item xs={4}>
        <Box>
          <Typography style={approveCss.labelText}>{t(label)}</Typography>
          {value ? <Typography className="bold-text" style={isBlue ? approveCss.blueValue : approveCss.blackValue}>{t(value)}</Typography> :
            <Typography className="bold-text" style={approveCss.noValue}>{t("(N/A)")}</Typography>
          }
        </Box>
      </Grid>
    )
  }
  
  handleSucessDialog = (t: any, language: any) => {
    return (
      <Dialog
        className="delete-document delete-document-dialog"
        fullWidth
        maxWidth="xs"
        onClose={this.handleDialogSucessstate}
        open={this.state.dialogSucessstate}
        dir={languageCondition(language, "rtl", "ltr")}
        style={approveCss.sucessDialog}
      >
        <DialogContent>
          <Box textAlign="center">
            <Typography variant="h6" className="bold-text">
              {t("Request Updated Successfully")}
            </Typography>
            <Typography >
              {this.state.actionInfo.title} {t("for")} {this.state.actionInfo.name} {t("updated successfully")}
            </Typography>
            <DialogActions className="dialog-button-group">
              <Button style={{ height: "50px" }} data-test-id="handleDialogSucessstate" className="add-button" onClick={this.handleDialogSucessstate}>
                {t("Okay")}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  handleSalesManagerdashboard=(t: (value: string) => string,language:any)=>{
    return(
   <Box className="containerBox">
     <Breadcrumbs className="salesHeading">
       <Typography className="myDashboardHeadsales"  data-testId={"dashboardHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>
         {t("My Dashboards")}
       </Typography>
       <Typography color="textPrimary" className="dashboardTextsales" dir={languageCondition(language, "rtl", "ltr")}>{t("Approval Dashboard")}</Typography>
     </Breadcrumbs>
     <Box>
       <Typography className="headingsales" dir={languageCondition(language, "rtl", "ltr")}>{t("Approval Dashboard")}</Typography>
     </Box>
        <Box className="dashboardMaincard">
          <Box className="dashboardCardfirst" data-testId={"navigationUnits"} onClick={()=>{this.handleNavigationDashboard("Pending Units Requests")}}>
            <Box className="dashboardCardIconContainer">
              <img className="dashboardViewunit" src={viewunit} alt="" />
            </Box>
            <Typography className="cardTextmain">
              {t("Pending Units Requests")}<img  height={20} style={{position:"relative",top:"15%",left:"3%"}} src={DashboardInfo} alt=""/>
            </Typography>
            <Typography className="cardText" data-testId={"totalCompaniesTextTestId"}>{this.state.totalPendingunits?.pending_unit_requests}</Typography>
          </Box>
          <Box className="dashboardCard" data-testId={"navigationSunbscription"} onClick={()=>{this.handleNavigationDashboard("Pending Subscription Requests")}} >
            <Box className="dashboardCardIconContainer">
              <img className="dashboardViewunit" src={viewCombined} alt="" />
            </Box>
            <Typography className="cardTextmain">
              {t("Pending Subscription Requests")}<img height={20} src={DashboardInfo} style={{position:"relative",top:"15%",left:"3%"}} alt=""/>
             
            </Typography>
            <Typography className="cardText" data-testId={"totalCompaniesTextTestId"}>{this.state.totalPendingunits?.pending_subscription_requests}</Typography>
          </Box>
        </Box>
   </Box>
    )
  }

// Customizable Area End

    render() {
        // Customizable Area Start
    const { t, i18n }: any = this.props;
    const currentPage=this.state.pagination?.current_page as number
    const language = i18n.language;
        // Customizable Area End
            return (
                // Customizable Area Start
      <CEHeaderComponentWeb>

        <Loader loading={this.state.loading} />

        <DashboardApprovalCss>
          {this.state.userRole==="coo"&&
          <Box className="containerBox">
            <Box className="navigation">
              <Typography variant="body1" className="">
                <span data-test-id="navigation" onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}>{t("My Dashboards")}</span> /{" "}
                <Box component="span">{t("Approval Dashboard")}</Box> /{" "}
                <Box component="span" className="tabColor">
                  {t(this.state.activeTab.title)}
                </Box>
              </Typography>
            </Box>
            <br />
            <Box className="header-title">
              <Typography variant="h4" className="bold-text">
                {t("Approval Dashboard")}
              </Typography>
            </Box>
            <Box>
              <Box style={{ marginTop: "35px" }}>
                {this.handleTabs(t)}
              </Box>
              <Grid container xs={12} spacing={2}>
                <Box className="filter-head-box">
                  <FilterSelect
                    option={[{ label: "All", value: "all" }, ...this.state.countryList]}
                    label="Select Country"
                    language={language}
                    t={t}
                    onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                      this.handleApprovalFilterChange(event, "country")
                    }
                    value={this.state.filter.country}
                    data-test-id="selectCountryId"
                  />
                  <FilterSelect
                    language={language}
                    option={[{ label: "All", value: "all" }, ...this.state.cityList]}
                    t={t}
                    label="Select City"
                    onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                      this.handleApprovalFilterChange(event, "city")
                    }
                    data-test-id="selectCityId"
                    value={this.state.filter.city}
                  />
                  {this.state.activeTab.id !== 2 && (
                    <FilterSelect
                      language={language}
                      option={[{ label: "All", value: "all" }, ...this.state.userTypeList]}
                      label="Select User Type"
                      t={t}
                      onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                        this.handleApprovalFilterChange(event, "userType")
                      }
                      value={this.state.filter.userType}
                      data-test-id="selectUserTypeId"
                    />
                  )}
                   {this.state.activeTab.id !== 1 && (
                    <>
                      <FilterSelect
                        language={language}
                        t={t}
                        option={[{ label: "All", value: "all" }, ...this.state.selectNamelist]}
                        value={this.state.filter.name}
                        label="Select Name"
                        onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                          this.handleApprovalFilterChange(event, "name")
                        }
                        data-test-id="selectNameId"
                      />
                    </>
                  )}
                  {this.state.activeTab.id === 2 && (
                    <>
                      <FilterSelect
                        onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                          this.handleApprovalFilterChange(event, "type")
                        }
                        option={[{ label: "All", value: "all" }, ...this.state.userTypeList]}
                        t={t}
                        language={language}
                        label="Select Type"
                        data-test-id="selectTypeId"
                        value={this.state.filter.type}
                      />
                    </>
                  )}
                  <FilterSelect
                    option={[{ label: "All", value: "all" }, ...this.state.requestorList]}
                    data-test-id="selectRequestorId"
                    language={language}
                    label="Select Requestor"
                    t={t}
                    onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                      this.handleApprovalFilterChange(event, "requestor")
                    }
                    value={this.state.filter.requestor}
                  />
                  <FilterSelect
                    option={[{ label: "All", value: "all" }, ...this.state.approvalTypeList]}
                    label="Select Approval Type"
                    language={language}
                    t={t}
                    onChange={(event: React.ChangeEvent<{ value: unknown | string }>) =>
                      this.handleApprovalFilterChange(event, "approvalType")
                    }
                    data-test-id="selectApprovalId"
                    value={this.state.filter.approvalType}
                  />
                  <Button onClick={this.handleMainAPICall} startIcon={<SearchIcon />}>
                    {t("Search")}
                  </Button>
                </Box>
              </Grid>

              <Grid className="table-box-card">
                <Grid item sm={12} md={12} xs={12}>
                  <h4 className="bold-text table-top">{t(this.state.activeTab.table)}</h4>
                  <Divider />
                  <TableContainer component={Paper} className="table-container">
                    <Table style={{ minWidth: 900 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                          {this.state.activeTab.id === 5 && (
                            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                              {t("Complex")}
                            </TableCell>
                          )}
                          <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                            {t("Name")}
                          </TableCell>
                          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                            {t("Country")}
                          </TableCell>
                          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                            {t("City")}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                            {t("Approval Type")}
                          </TableCell>
                          {this.state.activeTab.id === 2 && (
                            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                              {t("Type")}
                            </TableCell>
                          )}
                          {this.state.activeTab.id !== 2 && (
                            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                              {t("User Type")}
                            </TableCell>
                          )}
                          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                            {t("Requested By")}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.approvalList.map((item: { id: string; complex: string; name: string; country: string; city: string; approvalType: string; type: string; requestedBy: string; },index:number) => {
                          return (
                            <TableRow>
                              <TableCell align={languageCondition(language, "right", "left")}>{(currentPage - 1) * 10 + index + 1}</TableCell>
                              {this.state.activeTab.id === 5 && (
                                <TableCell align={languageCondition(language, "right", "left")}>{item.complex}</TableCell>
                              )}
                              <TableCell align={languageCondition(language, "right", "left")}>{item.name}</TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>{item.country}</TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>{item.city}</TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>{item.approvalType}</TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>{item.type}</TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>{item.requestedBy}</TableCell>
                              <TableCell align={languageCondition(language, "right", "left")} className="ce-pm-report-menu">
                                <Menu menuButton={<MoreVertIcon />}>
                                  <MenuItem data-test-id="view-menu" onClick={() => this.handleViewDetails(item)}>{t("View Details")}</MenuItem>
                                  <MenuItem data-test-id="accept-menu" onClick={() => this.handleAcceptRequest(item)}>{t("Accept Request")}</MenuItem>
                                  <MenuItem data-test-id="reject-menu" onClick={() => this.handleRejectRequest(item)}>{t("Reject Request")}</MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                    <Box className="paginationStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <PaginationModule handlePagination={this.handlePagination} pagination={this.state.pagination} page={this.state.pagination?.current_page} data-testId={"contractListPagintionTestId"} />
                </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>}

          {this.state.userRole==="sales_manager"&&
            this.handleSalesManagerdashboard(t,language)
          }

        </DashboardApprovalCss>

        <AlertDialog
          IsOpen={this.state.isAcceptModalOpen}
          Image={rightIcon}
          CloseDialog={this.handleAcceptModal}
          Header={t("Accept Request?")}
          Content={`<p>${t("Are you sure want to accept")} ${this.state.actionInfo.title} ${t("for")} ${this.state.actionInfo.name}?</p>`}
          DeclineText={t("Close")}
          AcceptText={t("Confirm")}
          DeclineFun={this.handleAcceptModal}
          AcceptFun={() => this.handleRecordAction("approved", { note: "" },this.state.actionInfo.title)}
          language={language}
          data-test-id="accept-dialog"
        />

        <AlertDialog
          IsOpen={this.state.isRejectModalOpen}
          Image={wrongIcon}
          CloseDialog={this.handleRejectModal}
          Header={t("Reject Request?")}
          Content={`<p>${t("Are you sure want to reject")} ${this.state.actionInfo.title} ${t("for")} ${this.state.actionInfo.name}?</p>`}
          DeclineText={t("Close")}
          AcceptText={t("Confirm")}
          DeclineFun={this.handleRejectModal}
          AcceptFun={this.handleRejectReasonModal}
          language={language}
          data-test-id="reject-dialog"
        />
        {this.handleSucessDialog(t,language)}
        <Dialog
          open={this.state.isSubscriptionViewOpen}
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          scroll="paper"
          fullWidth
          className="edit-profile"
          data-test-id="view-subscription-modal"
        >
          <DialogHeader data-test-id="sub-close" t={t} title={this.state.subscriptionView.title} onClose={this.handleDialogClose} />
          <Box style={approveCss.dialogActions}></Box>
          <DialogContent>
            <Grid container spacing={2}>
              {this.handleDialogData(t, "User Type", this.state.subscriptionView.userType)}
              {this.handleDialogData(t, "Country", this.state.subscriptionView.country)}
              {this.handleDialogData(t, "City", this.state.subscriptionView.city)}
              {this.handleDialogData(t, "Name", this.state.subscriptionView.name)}
              {this.handleDialogData(t, "Subscription Plan", this.state.subscriptionView.plan)}
              {this.handleDialogData(t, "Upgrade To", this.state.subscriptionView.upgradeTo)}
              <Grid item xs={4}>
                <Box>
                  <Typography style={approveCss.labelText}>{t("Duration")}</Typography>
                  {this.state.subscriptionView.duration?
                  <Typography className="bold-text" style={approveCss.blackValue}>{t(`${this.state.subscriptionView.duration}days`)}</Typography>
                  :<Typography className="bold-text no-value" style={approveCss.noValue}>{t("(N/A)")}</Typography>} 
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography style={approveCss.labelText}>{t("Complex Name")}</Typography>
                  {this.state.subscriptionView.complex?
                  <Typography className="bold-text" style={approveCss.blackValue}>{t(this.state.subscriptionView.complex)} <span onClick={()=>{this.handleBuildingMap(this.state.subscriptionView.complexMap)}} style={approveCss.blueValue}>{("View on map")}</span></Typography>
                  :<Typography className="bold-text no-value" style={approveCss.noValue}>{t("(N/A)")}</Typography>}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography style={approveCss.labelText}>{t("Building Name")}</Typography>
                 { this.state.subscriptionView.building?
                  <Typography className="bold-text" style={approveCss.blackValue}>{t(this.state.subscriptionView.building)}</Typography>
                  :
                  <Typography className="bold-text" style={approveCss.noValue}>{t("(N/A)")}</Typography>
                  }
                </Box>
              </Grid>
              {this.handleDialogData(t, "Number of buildings in complex", this.state.subscriptionView.noOfBuilding)}
              {this.handleDialogData(t, "Total Units", this.state.subscriptionView.totalUnit)}
              {this.handleDialogData(t, "Payment Reference Number", this.state.subscriptionView.refNumber)}
              {this.handleDialogData(t, "Requested By", this.state.subscriptionView.requestedBy)}
              <Grid item xs={4}>
                <Box>
                  <Typography style={approveCss.labelText}>{t("Requested On")}</Typography>
                  {this.state.subscriptionView.requestedOn?
                  <Typography className="bold-text" style={approveCss.blackValue}>{t(formatDateInIST(this.state.subscriptionView.requestedOn))}</Typography>
                  :<Typography className="bold-text" style={approveCss.noValue}>{t("(N/A)")}</Typography>}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions  className="dialog__button_group">
            <Button style={approveCss.dialogActionreject} className="cancel-button" data-test-id="reject-btn" onClick={() => this.handleRejectRequest(this.state.subscriptionView.selectedItem)}>
              {t("Reject")}
            </Button>
            <Button style={approveCss.dialogActionreject} className="add-button" data-test-id="accept-btn" onClick={() => this.handleAcceptRequest(this.state.subscriptionView.selectedItem)}>
              {t("Accept")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.isCompanyViewOpen}
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          scroll="paper"
          fullWidth
          className="edit-profile"
          data-test-id="view-company-modal"
        >
          <DialogHeader data-test-id="comp-close" t={t} title={this.state.companyView.title} onClose={this.handleDialogClose} />
          <Box style={approveCss.dialogActions}></Box>
          <DialogContent >
            <Grid container spacing={2}>
              {this.handleDialogData(t, "Approval Type", this.state.companyView.approvalType)}
              {this.handleDialogData(t, "Country", this.state.companyView.country)}
              {this.handleDialogData(t, "City", this.state.companyView.city)}
              {this.handleDialogData(t, "User Type", this.state.companyView.type)}
              {this.handleDialogData(t, "Name", this.state.companyView.name, true)}
              {this.handleDialogData(t, "Requested By", this.state.companyView.requestedBy)}
              <Grid item xs={4}>
                <Box>
                  <Typography style={approveCss.labelText}>{t("Requested On")}</Typography>
                  {this.state.companyView.requestedOn ?
                    <Typography className="bold-text" style={approveCss.blackValue}>{t(formatDateInIST(this.state.companyView.requestedOn))}</Typography> :
                    <Typography className="bold-text" style={approveCss.noValue}>{t("(N/A)")}</Typography>
                  }
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="dialog__button_group">
            <Button  style={approveCss.dialogActionreject} className="cancel-button" data-test-id="reject-btn" onClick={() => this.handleRejectRequest(this.state.companyView.selectedItem)}>
              {t("Reject")}
            </Button>
            <Button  style={approveCss.dialogActionreject} className="add-button" data-test-id="accept-btn" onClick={() => this.handleAcceptRequest(this.state.companyView.selectedItem)}>
              {t("Accept")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.isRejectReasonModalOpen}
          scroll="paper"
          fullWidth
          maxWidth="sm"
          dir={languageCondition(language, "rtl", "ltr")}
          data-test-id="reject-reason-modal"
        >
          <DialogHeader t={t} title={`${t("Rejection Reason")}`} onClose={this.handleRejectReasonModal} data-test-id="reason-close" />
          <Formik
            enableReinitialize={true}
            initialValues={{ note: "" }}
            validationSchema={this.rejectValidation}
            onSubmit={(values, { resetForm }) => {
              this.handleRecordAction("rejected", values,this.state.actionInfo.title);
              resetForm();
            }}
            data-test-id="reject-reason-formik"
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    <FormControl fullWidth>
                      <NormalTextArea
                        t={t}
                        language={language}
                        label="Add Notes"
                        value={values.note}
                        name="note"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        maxRows={10}
                        minRows={10}
                      />
                      {ErrorHandler(t, touched.note, errors.note)}
                    </FormControl>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleRejectReasonModal}>
                      {t("Close")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("Confirm")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>
        <MapDialog
            t={this.props.t}
            IsOpen={this.state.openBuildingMap}
            CloseDialog={this.handleCloseBuildingMap}
            lat={this.state.latitude}
            long={this.state.longitude}
            data-testId={"viewBuildingModal"}
        />
      </CEHeaderComponentWeb>
                // Customizable Area End
            );
        }
}

// Customizable Area Start
export const DashboardApprovalCss = styled(Box)({
  "& .dashboardMaincard":{
    display:"flex"
  },
  "& .dashboardCard": {
    backgroundColor: "#fff",
    height: "180px",
    width:"350px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 2px #eee",
    padding: "20px",
    cursor: "pointer"
  },
  "& .dashboardCardfirst": {
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    height: "180px",
    display: "flex",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 2px #eee",
    padding: "20px",
    cursor: "pointer",
    marginRight:"2%",
    width:"350px",
  },

  "& .dashboardCardIconContainer": {
    border:"1px solid #efefef",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems:"center"
  },
  "& .dashboardViewunit":{
  height:"40px",
  width:"40px"
  },
  "& .cardText": {
    color:" #fc8434",
    fontSize: "20px",
    fontWeight: "700"
  },
  "& .cardTextmain": {
    color: "#181d25",
    fontWeight: 800,
    fontSize: "20px",
    margin:"5% 0%"
  },
  "& .containerBox": {
    padding: "0px 40px",
  },
  "& .salesHeading": {
    color: "#181d25"
  },
  "& .myDashboardHeadsales": {
    cursor: 'pointer'
  },
  "& .dashboardTextsales": {
    color: "#537ad2"
  },
  "& .headingsales": {
    fontSize: "26px",
    fontWeight: 900,
    color: "#Dashboard",
    padding: "20px 0px"
  },
  "& .header-title": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .table-container": {
    marginTop: "20px",
    boxShadow: "none",
  },
  "& .tabColor": {
    color: "rgb(43, 111, 237)",
  },
  "& .table-top": {
    margin: "0px 20px 0px auto",
  },
  "& .paginationStyle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    marginLeft: "10px",
  }
});

export const approveCss: any = {
  selectTag: {
    background: "#2B6FED",
    padding: "10px 20px",
    color: "#fff",
    borderRadius: "20px",
    cursor: "pointer",
  },
  unSelectTag: {
    background: "#EEF0F8",
    color: "#8D8F98",
    padding: "10px 20px",
    borderRadius: "20px",
    cursor: "pointer",
  },
  labelText: {
    color: "#fc8434",
    fontSize: "14px",
  },
  blueValue: {
    fontSize: "16px",
    color: "#2b6fed"
  },
  blackValue: {
    fontSize: "16px",
    color: "black"
  },
  noValue: {
    fontSize: "16px",
    color: "lightgray"
  },
  sucessDialog:{
    padding:"1%",
    borderRadius:"10px"
  },
  dialogActions:{
    height:"0px",border:"1px solid lightgrey",margin:"1% 0%"
  },
  dialogActionreject:{
    padding:"3%"
  }
};

export default withTranslation()(withRouter(DashboardApproval));
// Customizable Area End