// Customizable Area Start
import React from "react";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router-dom";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes?: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  companies: any;
  companiesList: any;
  countriesList: any;
  cityList: any;
  regionList: any;
  statusList: any;
  accountManagerList: any;
  salesManagerList: any;
  page: number;
  companyFilter: string;
  countryFilter: string;
  cityFilter: string;
  regionFilter: string;
  statusFilter: string;
  accountManagerFilter: any;
  salesManagerFilter: any;
  openOptions: number;
  paginationData: PaginationData | null;
  searchQuery: string;
  loading: boolean;
  accountType: string;
  anchorEl: any;
  activeId: number;
}

interface SS {
  id: any;
}

export interface PaginationData {
  current_page: null | number;
  next_page: null | number;
  prev_page: null | number;
  total_pages: null | number;
  total_count: null | number;
}

export default class RealEstateCompaniesTableController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetCompaniesList: string = "";
  GetCompaniesFilterList: string = "";
  GetCountriesFilterList: string = "";
  GetRegionsFilterList: string = "";
  GetCityFilterList: string = "";
  GetStatusFilterList: string = "";
  GetFilterApi: string = "";
  GetAccountManagerFilterList: string = "";
  GetSalesManagerFilterList: string = "";
  timeout: any;
  menuRef: any;
  dotRef: any;
  [key: string]: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      loading: false,
      page: 1,
      companies: [],
      companiesList: [],
      countriesList: [],
      cityList: [],
      regionList: [],
      statusList: [],
      accountManagerList: [],
      salesManagerList: [],
      anchorEl: null,
      companyFilter: "",
      countryFilter: "",
      cityFilter: "",
      regionFilter: "",
      statusFilter: "",
      accountManagerFilter: "",
      salesManagerFilter: "",
      openOptions: 0,
      activeId: 0,
      accountType: "",
      searchQuery: "",
      paginationData: {
        current_page: 0,
        next_page: 0,
        prev_page: 0,
        total_pages: 0,
        total_count: 0,
    },
    };

    this.timeout = null;
    this.menuRef = React.createRef();
    this.dotRef = React.createRef();
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetCompaniesList:
          return this.handleCompaniesList(responseJson);
        case this.GetCompaniesFilterList:
          return this.handleCompanyFiltersList(responseJson);
        case this.GetCountriesFilterList:
          return this.handleCountryFiltersList(responseJson);
        case this.GetCityFilterList:
          return this.handleCityFiltersList(responseJson);
        case this.GetRegionsFilterList:
          return this.handleRegionFiltersList(responseJson);
        case this.GetStatusFilterList:
          return this.handleFiltersList(responseJson, "statusList", "status");
        case this.GetAccountManagerFilterList:
          return this.handleFiltersList(responseJson, "accountManagerList", "account_managers");
        case this.GetSalesManagerFilterList:
          return this.handleFiltersList(responseJson, "salesManagerList", "sales_managers");
        case this.GetFilterApi:
          return this.handleCompaniesList(responseJson);
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount() {
    this.getCompaniesList();

    let filterCallTable: any = [
      {
        name: "GetStatusFilterList",
        endPoint: "list_status",
      },
      {
        name: "GetAccountManagerFilterList",
        endPoint: "account_manager_list",
      },
      {
        name: "GetSalesManagerFilterList",
        endPoint: "sales_manager_list",
      },
    ];

    const accountRole = localStorage.getItem("role");

    if (accountRole) {
      const accountRoleName = accountRole.replace(/"/g, "");
      this.setState({ accountType: accountRoleName });
    }

    filterCallTable.forEach((filter: any) => {
      this.getFilterListTable(filter.name, `/bx_block_real_estate_companies/${filter.endPoint}`);
    });
    this.getFilterListTable("GetCompaniesFilterList", `bx_block_dashboard/assign_action_team/real_estate_company_list`);
    this.getFilterListTable("GetCountriesFilterList", `bx_block_address/country_list`);
  }

  handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value }, () => {
      const {companyFilter, countryFilter, regionFilter, cityFilter, statusFilter, salesManagerFilter, accountManagerFilter} = this.state;
      if(companyFilter || countryFilter || regionFilter || cityFilter || statusFilter || salesManagerFilter || accountManagerFilter){
        this.getCompaniesSearchFilter(String(this.state.page));
      }else {
        this.getCompaniesList();
      }
    });
  };

  handleFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value;
    this.setState({ ...this.state, [stateParam]: value }, () => {
      if (stateParam === "countryFilter") {
        this.setState({ regionFilter: "", cityFilter: "" }, () => {
          this.getFilterListTable("GetCityFilterList", `bx_block_address/city_list?country=${value}`);
          this.getFilterListTable("GetRegionsFilterList", `bx_block_address/region_list?country=${value}`);
        });
      }
    });
  };

  handleCompanyFiltersList = (responseJson: { compneys: [] }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.compneys) {
        this.setState({ ...this.state, companiesList: responseJson.compneys });
      }
    });
  };

  handleCountryFiltersList = (responseJson: { data: { countries: [] } }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ ...this.state, countriesList: responseJson.data.countries });
      }
    });
  };

  handleRegionFiltersList = (responseJson: { data: { regions: [] } }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ ...this.state, regionList: responseJson.data.regions });
      }
    });
  };

  handleCityFiltersList = (responseJson: { data: { cities: [] } }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ ...this.state, cityList: responseJson.data.cities });
      }
    });
  };

  handleFilterSubmit = () => {
    this.getCompaniesSearchFilter("1");
  };

  handlePopover = (event: any, activeId: number) => {
    this.setState({ anchorEl: event?.currentTarget, activeId });
  };

  handleClosePopover = () => {
    this.setState({ anchorEl: null });
  };

  handleSearchKeyUp = (e: any) => {
    this.setState({ searchQuery: e.target.value, page: 1 }, () => {
      this.getCompaniesList();
    });
  };

  handleCompaniesList = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.real_estate_companies && responseJson.meta.pagination) {
        this.setState({
          companies: responseJson.real_estate_companies.data,
          paginationData: {
            current_page: responseJson.meta.pagination.current_page,
            next_page: responseJson.meta.pagination.next_page,
            prev_page: responseJson.meta.pagination.prev_page,
            total_pages: responseJson.meta.pagination.total_pages,
            total_count: responseJson.meta.pagination.total_count,
          },
        });
      } else {
        this.setState({ companies: [],
          paginationData: {
            current_page: 0,
              next_page: 0,
              prev_page: 0,
              total_pages: 0,
              total_count: 0,
          },
         });
      }
    });
  };

  handleFiltersList = (responseJson: any, stateParam: string, resParam: string) => {
    this.setState({ loading: false }, () => {
      if (responseJson[resParam]) {
        this.setState({ ...this.state, [stateParam]: responseJson[resParam] });
      }
    });
  };

  getCompaniesSearchFilter = async (page: string) => {
    this.setState({ loading: true });

    const companyFilter = this.state.companyFilter + "".trim();
    const countryFilter = this.state.countryFilter + "".trim();
    const cityFilter = this.state.cityFilter + "".trim();
    const regionFilter = this.state.regionFilter + "".trim();
    const statusFilter = this.state.statusFilter + "".trim();
    const salesManagerFilter = this.state.salesManagerFilter;
    const accountManagerFilter = this.state.accountManagerFilter;

    let url = `bx_block_real_estate_companies/filter?page=${page}&company_name=${
      companyFilter === "all" ? "" : companyFilter
    }&country=${countryFilter === "all" ? "" : countryFilter}&city=${cityFilter === "all" ? "" : cityFilter}&region=${
      regionFilter === "all" ? "" : regionFilter
    }&status=${statusFilter === "all" ? "" : statusFilter}&sales_manager_id=${
      salesManagerFilter === "all" ? "" : salesManagerFilter
    }&account_manager_id=${accountManagerFilter === "all" ? "" : accountManagerFilter}`;

    this.GetFilterApi = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: url,
    });
  };

  getCompaniesList = async () => {
    this.setState({ loading: true });
    this.GetCompaniesList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_real_estate_companies/real_estate_companies?query=${this.state.searchQuery}&page=${this.state.page}&per_page=10`,
    });
  };

  getFilterListTable = (method: any, endPoint: string) => {
    this.setState({ loading: true }, async () => {
      this[method] = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint,
      });
    });
  };

  handleFilterList = () => {
    if (this.state.accountType === "coo") {
      return "coo_real_estate_filter";
    }

    return this.state.accountType === "sales_manager" ? "sales_real_estate_filter" : "account_real_estate_filter";
  };

  handleClearFiltersClick = async () => {
    this.setState({companyFilter:"", countryFilter:"", cityFilter:"", regionFilter:"", statusFilter:"", salesManagerFilter:"", accountManagerFilter:""})
    this.getCompaniesList();
  }
}
// Customizable Area End
