// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography, Dialog, DialogContent, DialogActions, Card, Avatar } from "@material-ui/core";
import { user_icon } from "../assets";
import CompEmpComplexBuildingBlockStep5Controller, {
  MemberDetails,
  Props,
  StyleObject,
} from "./CompEmpComplexBuildingBlockStep5Controller.web";
import { Form, Formik } from "formik";
import CloseIcon from "@material-ui/icons/Close";
import { withTranslation } from "react-i18next";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import AlertError from "../../../../components/src/AlertError.web";
import Loader from "../../../../components/src/Loader.web";

class CompEmpComplexBuildingBlockStep5 extends CompEmpComplexBuildingBlockStep5Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box className="complexBuildingBlock">
          <Box className="complexBuildingBlock">
            <Box className="complex complexBuildingBlock">
              <Typography style={webStyle.heading} className="complexBuildingBlock">
                {t("Complex")}
              </Typography>
            </Box>
            <Box className="complexBuildingBlock" style={webStyle.subHeadingBox}>
              <Box className="complexBuildingBlock">
                <Typography className="complexBuildingBlock" style={{ fontSize: "25px", fontWeight: "bold" }}>
                  {t("Core Members")}
                </Typography>
              </Box>
              <Box className="complexBuildingBlock">
                <Typography className="complexBuildingBlock" style={{ fontSize: "16px" }}>
                  {t("Step")} {this.props.step + 1} {t("of")} 5
                </Typography>
              </Box>
            </Box>
            <Box className="complexBuildingBlock" style={{ marginTop: "30px" }}>
              <Box className="complexBuildingBlock">
                <Grid container spacing={2} alignItems="stretch">
                  {this.state.coreMembersList.map((value: MemberDetails, index: number) => (
                    <Grid item sm={6} md={4} lg={3} key={index} style={{ display: "flex" }}>
                      <Box style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                        <Card
                          style={{
                            flexGrow: 1,
                            flexDirection: "column",
                            display: "flex",
                            justifyContent: "space-between",
                            ...webStyle.coreCard,
                          }}
                        >
                          <Box style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
                            <CloseIcon className="close-core" onClick={() => this.handleDeleteMember(value.id)} />
                          </Box>
                          <Box
                            className="complexBuildingBlock"
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            <Avatar src={value.attributes.user_profile.data.attributes.profile_pic || user_icon} />
                            <Box className="bold-text" style={webStyle.step7}>
                              {value.attributes.role}
                            </Box>
                            <Box style={webStyle.step7}>{value.attributes.name}</Box>
                          </Box>
                          <Box style={webStyle.coreCardTag} className="building-role-box">
                            {value.attributes.role_list.map((role: string, index: number) => {
                              return (
                                <span key={index} className="bold-text building-user-role" style={webStyle.roleTag}>
                                  {role}
                                </span>
                              );
                            })}
                          </Box>
                        </Card>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            <Box className="complexBuildingBlock">
              <Box style={webStyle.addMemberBtnBox}>
                <Box style={{ marginTop: "20px", border: "1px solid #2B6FED", borderRadius: "8px" }}>
                  <Box
                    style={webStyle.addMemberBtn}
                    onClick={this.handleAddMemberDialog}
                    className="bold-text add-role-dialog"
                  >
                    {t("+ ADD NEW MEMBER")}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Dialog
              dir={languageCondition(language, "rtl", "ltr")}
              scroll="paper"
              open={this.state.isCoreMembersOpen}
              fullWidth
              maxWidth="md"
              className="edit-profile building-shared-area-dialog"
            >
              <DialogHeader t={t} title={t("Add New Member")} onClose={this.handleCoreMemberDialog} />
              <Formik
                initialValues={this.state.userValues}
                validationSchema={this.validationSchema}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => {
                  this.setState({ isCoreMembersOpen: false, loading: true }, () => {
                    this.addNewCoreMember(values);
                    resetForm();
                  });
                }}
              >
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                  <Form translate="yes" onSubmit={handleSubmit} className="commonForms complexBuildingBlock">
                    <DialogContent dividers>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <NormalSelect
                            value={values.account_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="account_id"
                            t={t}
                            language={language}
                            label={t("User")}
                            option={this.state.userList}
                            disabled={this.state.userList.length === 0}
                            required
                          />
                          {ErrorHandler(t, touched.account_id, errors.account_id)}
                        </Grid>
                        <Grid item xs={6}>
                          <NormalSelect
                            name="role_id"
                            t={t}
                            onChange={handleChange}
                            value={values.role_id}
                            onBlur={handleBlur}
                            language={language}
                            option={this.state.roleList}
                            label={t("Role")}
                            required
                          />
                          {ErrorHandler(t, touched.role_id, errors.role_id)}
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            className="complexBuildingBlock new-member-dialog"
                            style={webStyle.newMember}
                            onClick={this.handleNewCoreMemberDialog}
                          >
                            {t("+ CREATE NEW MEMBER")}
                          </Box>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions className="dialog__button_group">
                      <Button className="cancel-button" onClick={this.handleCoreMemberDialog}>
                        {t("Cancel")}
                      </Button>
                      <Button type="submit" className="add-button">
                        {t("SAVE")}
                      </Button>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </Dialog>

            <Dialog
              dir={languageCondition(language, "rtl", "ltr")}
              scroll="paper"
              open={this.state.isNewCoreMembersOpen}
              fullWidth
              maxWidth="md"
              className="edit-profile building-core-member-modal"
            >
              <DialogHeader t={t} title={t("Create New Member")} onClose={this.handleNewCoreMemberDialog} />
              <Formik
                initialValues={this.state.memberValues}
                validationSchema={this.validationCreateSchemaBlock}
                enableReinitialize={true}
                data-testid="memberForm"
                onSubmit={(values, { resetForm }) => {
                  this.setState({ isNewCoreMembersOpen: false, loading: true }, () => {
                    this.createCoreMember(values);
                    resetForm();
                  });
                }}
              >
                {({ values, touched, errors, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
                  return (
                    <form onSubmit={handleSubmit} className="commonForms complexBuildingBlock">
                      <DialogContent dividers>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <NormalSelect
                              onBlur={handleBlur}
                              t={t}
                              onChange={handleChange}
                              value={values.userType}
                              label={t("User Type")}
                              option={this.state.allRoleList}
                              name="userType"
                              language={language}
                              required
                            />
                            {ErrorHandler(t, touched.userType, errors.userType)}
                          </Grid>
                          <Grid item xs={6}>
                            <NormalTextField
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="name"
                              t={t}
                              language={language}
                              label={t("Full Name")}
                              isLabel
                              required
                            />
                            {ErrorHandler(t, touched.name, errors.name)}
                          </Grid>
                          <Grid item xs={6}>
                            <NormalTextField
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="email"
                              t={t}
                              language={language}
                              label={t("Email Address")}
                              isLabel
                              required
                            />
                            {ErrorHandler(t, touched.email, errors.email)}
                          </Grid>
                          <Grid item xs={6}>
                            <Box className="form__phone_number">
                              <PhoneInput
                                value={values.countryCode}
                                country="us"
                                enableSearch={true}
                                onChange={(value: any) => {
                                  setFieldValue("countryCode", `+${value}`);
                                }}
                              />
                              <NormalTextField
                                t={t}
                                language={language}
                                label="Phone Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isLabel
                                value={values.phone}
                                name="phone"
                                type="number"
                                required
                              />
                            </Box>
                            {ErrorHandler(t, touched.phone, errors.phone)}
                          </Grid>
                          <Grid item xs={6}>
                            <NormalSelect
                              onBlur={handleBlur}
                              t={t}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("country", event.target.value);
                                setFieldValue("city", "");
                                this.getMemberCityList(event.target.value);
                              }}
                              value={values.country}
                              label={t("Country")}
                              option={this.state.countriesList}
                              name="country"
                              language={language}
                              required
                            />
                            {ErrorHandler(t, touched.country, errors.country)}
                          </Grid>
                          <Grid item xs={6}>
                            <NormalSelect
                              onBlur={handleBlur}
                              t={t}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("city", event.target.value);
                                setFieldValue("complex", "");
                                this.getMemberComplexList(values.country, event.target.value);
                              }}
                              value={values.city}
                              label={t("City")}
                              option={this.state.citiesList}
                              name="city"
                              language={language}
                              required
                            />
                            {ErrorHandler(t, touched.city, errors.city)}
                          </Grid>
                          <Grid item xs={6}>
                            <NormalSelect
                              onBlur={handleBlur}
                              t={t}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("isComplex", Number(event.target.value));
                                setFieldValue("complex", "");
                                this.handleIsComplex(event.target.value);
                              }}
                              value={values.isComplex}
                              label={t("Complex/Individual Building")}
                              option={[
                                { label: "Complex", value: 1 },
                                { label: "Individual Building", value: 2 },
                              ]}
                              name="isComplex"
                              language={language}
                              required
                            />
                            {ErrorHandler(t, touched.isComplex, errors.isComplex)}
                          </Grid>
                          <Grid item xs={6}>
                            <NormalSelect
                              label={values.isComplex === 1 ? t("Complex") : t("Individual Building")}
                              value={values.complex}
                              name="complex"
                              onBlur={handleBlur}
                              t={t}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("complex", event.target.value);
                                setFieldValue("building", "");
                                if (values.isComplex === 1) {
                                  this.getMemberBuildingList(event.target.value);
                                } else {
                                  this.getMemberUnitList(event.target.value, "");
                                }
                              }}
                              language={language}
                              required
                              option={this.state.filterComplexList}
                            />
                            {ErrorHandler(t, touched.complex, errors.complex)}
                          </Grid>
                          {values.isComplex !== 2 && (
                            <Grid item xs={6}>
                              <NormalSelect
                                label={t("Building")}
                                value={values.building}
                                name="building"
                                onBlur={handleBlur}
                                t={t}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue("building", event.target.value);
                                  setFieldValue("unit", "");
                                  this.getMemberUnitList(values.complex, event.target.value);
                                }}
                                language={language}
                                required
                                option={this.state.buildingList}
                              />
                              {ErrorHandler(t, touched.building, errors.building)}
                            </Grid>
                          )}
                          <Grid item xs={6}>
                            <NormalSelect
                              label={t("Unit")}
                              value={values.unit}
                              name="unit"
                              onBlur={handleBlur}
                              t={t}
                              onChange={handleChange}
                              language={language}
                              required
                              option={this.state.unitList}
                            />
                            {ErrorHandler(t, touched.unit, errors.unit)}
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions className="dialog__button_group">
                        <Button className="cancel-button" onClick={this.handleNewCoreMemberDialog}>
                          {t("Cancel")}
                        </Button>
                        <Button type="submit" className="add-button">
                          {t("Send Invitation")}
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </Dialog>
          </Box>
        </Box>

        <Box className="step3BtnBox btnpre" style={webStyle.lastBtnBox}>
          <Grid className="step3Grid btnpre" style={{ marginTop: "30px", marginRight: "20px" }}>
            <Button className="step3Prev btnpre" style={webStyle.prevButton} onClick={this.props.handleBack}>
              <span className="bold-text">{t("Prev")}</span>
            </Button>
          </Grid>
          <Grid className="step3Grid btnpre" style={{ marginTop: "30px" }}>
            <Button className="step3Next btnpre" style={webStyle.saveButton} onClick={this.handleSave}>
              <span className="bold-text">{t("Save")}</span>
            </Button>
          </Grid>
        </Box>

        <AlertError
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
        />
      </>
    );
  }
}

export default withTranslation()(CompEmpComplexBuildingBlockStep5);

const webStyle: StyleObject = {
  prevButton: {
    color: "#2B6FED",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "uppercase",
    width: "177px",
    height: "50px",
    border: "1px solid #2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  saveButton: {
    width: "177px",
    height: "50px",
    backgroundColor: "#2B6FED",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "0px 20.5px",
    textTransform: "uppercase",
  },
  errorMsg: { color: "red", fontSize: "12px", marginTop: "5px" },
  step7: { margin: "11px 0px", color: "black", fontSize: "16px" },
  roleTag: {
    color: "rgb(43, 111, 237)",
    fontSize: "14px",
    background: "rgba(43, 111, 237, 0.08)",
    borderRadius: "14px",
    padding: "6px 22px",
    width: "max-content",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  coreCard: {
    padding: "20px 10px",
    border: "1px solid #e9e9e9",
    boxShadow: "none",
    borderRadius: "8px",
  },
  coreCardTag: { justifyContent: "center", display: "flex", gap: "10px", flexFlow: "wrap" },
  newMember: {
    color: "#FC8434",
    padding: "15px 0",
    fontWeight: "400",
    fontSize: "15px",
    cursor: "pointer",
  },
  heading: {
    paddingBottom: "25px",
    fontWeight: "bold",
    borderBottom: "1px solid #F0F0F0",
    fontSize: "20px",
  },
  subHeadingBox: { display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "25px" },
  addMemberBtn: {
    color: "#2B6FED",
    fontSize: "15px",
    fontWeight: "bold",
    padding: "10px 25px",
  },
  addMemberBtnBox: { display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" },
  lastBtnBox: { display: "flex", justifyContent: "end", flexWrap: "wrap", gap: "10px" },
};
// Customizable Area End
