// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CompEmpBuildingBlockController, { Props } from "./CompEmpBuildingBlockController.web";
import { withTranslation } from "react-i18next";
import "../../assets/commonForm.css";
import CompEmpBuildingBlockStep1 from "./CompEmpBuildingBlockStep1.web";
import CompEmpBuildingBlockStep2 from "./CompEmpBuildingBlockStep2.web";
import CompEmpBuildingBlockStep3 from "./CompEmpBuildingBlockStep3.web";
import CompEmpBuildingBlockStep4 from "./CompEmpBuildingBlockStep4.web";
import CompEmpBuildingBlockStep5 from "./CompEmpBuildingBlockStep5.web";
import CompEmpBuildingBlockStep6 from "./CompEmpBuildingBlockStep6.web";
import CompEmpBuildingBlockStep7 from "./CompEmpBuildingBlockStep7.web";

const multiPleButtonComplex = [
  "Jurisdiction",
  "Basic Details",
  "Documents",
  "Location",
  "Units",
  "Shared Area",
  "Core Members",
];

class CompEmpBuildingBlock extends CompEmpBuildingBlockController {
  constructor(props: Props) {
    super(props);
  }

  getMainBuildingBreadcrumb = () => (this.state.estateId ? "Real Estate Companies" : "Individual Building");

  getMainSubBuildingBreadcrumb = () => (this.state.estateId ? "Company Details" : "Building Details");

  render() {
    const { t } = this.props;

    return (
      <Box style={{ padding: "0 40px 40px", backgroundColor: "rgb(244, 247, 255)" }}>
        <Box style={{ display: "flex" }}>
          <Typography style={{ fontSize: "16px", cursor: "pointer" }} onClick={this.handleCompanyListNavigation}>
            {t(this.getMainBuildingBreadcrumb())} /
          </Typography>
          <Typography style={{ fontSize: "16px", cursor: "pointer" }} onClick={this.handleCompanyDetailNavigation}>
            {t(this.getMainSubBuildingBreadcrumb())} /
          </Typography>
          <Typography style={{ color: "#2B6FED" }}>{t(this.handleHeading(t))}</Typography>
        </Box>
        <Typography style={{ fontSize: "32px", marginTop: "6px" }} className="bold-text">
          {this.handleHeading(t)}
        </Typography>
        <Box style={{ marginTop: "12px", display: "flex", overflowX: "auto", gap: "8px", padding: "4px 0" }}>
          {multiPleButtonComplex.map((val: any, index: number) => {
            return (
              <Box
                key={index}
                data-test-id="building-tab-container"
                style={{
                  ...webBuildingStyle.tabContainer,
                  backgroundColor: this.state.stepBuilding == index ? "#2B6FED" : "#C8C8C81F",
                }}
                onClick={() => this.handleStepClick(index)}
              >
                {this.state.stepBuilding === index ? (
                  <Button className="bold-text" style={{ color: "#FFFFFF", ...webBuildingStyle.tabBtn }}>
                    {t(val)}
                  </Button>
                ) : (
                  <Button
                    style={{ ...webBuildingStyle.tabBtn, color: this.state.stepBuilding < index ? "grey" : "#2B6FED" }}
                  >
                    {t(val)}
                  </Button>
                )}
              </Box>
            );
          })}
        </Box>
        <Grid>
          {this.state.stepBuilding === 0 && (
            <CompEmpBuildingBlockStep1
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              handleChangeJurisdiction={this.handleChangeJurisdiction}
              handleMeasurement={this.handleMeasurement}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 1 && (
            <CompEmpBuildingBlockStep2
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              updateComplexBuildingId={this.handleUpdateComplexBuildingId}
              currency={this.state.currency}
              handleChangeJurisdiction={this.handleChangeJurisdiction}
              measurement={this.state.measurement}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 2 && (
            <CompEmpBuildingBlockStep3
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 3 && (
            <CompEmpBuildingBlockStep4
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              t={this.props.t}
              i18n={this.props.i18n}
              handleCountryCity={this.handleCountryCity}
            />
          )}
          {this.state.stepBuilding === 4 && (
            <CompEmpBuildingBlockStep5
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              buildingId={this.state.buildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 5 && (
            <CompEmpBuildingBlockStep6
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              buildingId={this.state.buildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              currency={this.state.currency}
              measurement={this.state.measurement}
              t={this.props.t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.stepBuilding === 6 && (
            <CompEmpBuildingBlockStep7
              step={this.state.stepBuilding}
              handleNext={this.handleNextBuilding}
              handleBack={this.handleBackBuilding}
              complexBuildingId={this.state.complexBuildingId}
              buildingId={this.state.buildingId}
              jurisdictionSelected={this.state.jurisdictionSelected}
              isEditBuilding={this.state.isEditComplex}
              t={this.props.t}
              i18n={this.props.i18n}
              navigation={this.props.navigation}
              country={this.state.country}
              city={this.state.city}
            />
          )}
        </Grid>
      </Box>
    );
  }
}

const webBuildingStyle: any = {
  tabContainer: {
    display: "flex",
    height: "43px",
    borderRadius: "25px",
    justifyContent: "center",
    marginRight: "8px",
    textAlign: "center",
    marginTop: "8px",
  },
  tabBtn: {
    width: "max-content",
    borderRadius: "25px",
    padding: "0px 20px",
    fontWeight: 800,
    textTransform: "capitalize",
  },
};

export default withTranslation()(CompEmpBuildingBlock);
// Customizable Area End
