// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  MenuItem,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import CEMyTeamTableController, { Props } from "./CEMyTeamTableController.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import Pagination from "@material-ui/lab/Pagination";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { Menu } from "@szhsin/react-menu";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import CEAssignObjectWeb from "./CEAssignObject.web";
import CEAddNewTeamMebmerModal from "./CEAddNewTeamMember.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";

class CEMyTeamTable extends CEMyTeamTableController {
  constructor(props: Props) {
    super(props);
  }

  renderFilter = (t: any, language: any) => {
    const { countryList, memberIdList, memberRoleList, role } = this.state;

    return (
      <Box className="filter-head-box">
        {role === "account_manager" ? (
          <FilterSelect
            t={t}
            language={language}
            label="Department"
            option={[{ label: "All", value: "all" }, ...this.updatedRole(true)]}
            value={this.state.selectedRole}
            onChange={this.handleDepartmentChange}
            data-test-id="department-dropdown-filter"
          />
        ) : (
          <>
            {role === "sales_manager" && (
              <>
                <FilterSelect
                  t={t}
                  language={language}
                  label="Member ID"
                  option={[{ label: "All", value: "all" }, ...memberIdList]}
                  value={this.state.selectedMemberId}
                  onChange={this.handleMemberIdChange}
                  data-test-id="ember-id-dropdown-filter"
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Member Name"
                  option={[{ label: "All", value: "all" }, ...memberRoleList]}
                  value={this.state.selectedMemberRole}
                  onChange={this.handleMemberRoleChange}
                  data-test-id="demo-simple-select-standard-label"
                />
              </>
            )}
            {role !== "sales_manager" && (
              <FilterSelect
                t={t}
                language={language}
                label="Select Country"
                option={[{ label: "All", value: "all" }, ...countryList]}
                value={this.state.selectedCountry}
                onChange={this.handleCountryChange}
                data-test-id="country-dropdown-filter"
              />
            )}
            <FilterSelect
              t={t}
              language={language}
              label="Select Role"
              option={[{ label: "All", value: "all" }, ...this.updatedRole()]}
              value={this.state.selectedRole}
              onChange={this.handleRoleChange}
              data-test-id="role-dropdown-filter"
            />
          </>
        )}
        <Button onClick={() => this.applySearchFilter(true, 1)} startIcon={<SearchIcon />}>
          {t("Search")}
        </Button>
      </Box>
    );
  };

  getRoleValue = (role: string, roleValue: string, language: any) =>
    this.state.role === role && (
      <TableCell align={languageCondition(language, "right", "left")}>{roleValue.replace("_", " ")}</TableCell>
    );

  renderRoleColumn = (role: string, t: any, language: any) =>
    this.state.role === role && (
      <TableCell align={languageCondition(language, "right", "left")} className="text-bold">
        {t("Role")}
      </TableCell>
    );

  getSearchInputPlaceholder = () => (this.state.role !== "coo" ? "Search by name" : "Search by Name or ID");

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    const { data, role } = this.state;

    return (
      <CEHeaderComponentWeb>
        <Box className={classes.MyTeamTable}>
          <Box className="navigation action-header-box">
            <Typography variant="body1">
              <Box>{t("Team Members")}</Box>
            </Typography>
            <Box className="sub-heading-box">
              <Typography variant="h5" className="bold-text">
                {t("Team Members")}
              </Typography>
              <Box className="team-btn-box">
                <CommonButton
                  isVariant={false}
                  data-test-id="add-team-member-btn"
                  t={t}
                  color="blue"
                  label="Add New Team Member"
                  onClick={() => {
                    this.setState({ empformModal: !this.state.empformModal });
                  }}
                />
                <CommonButton isVariant={false} t={t} color="orange" label="Assign Object" onClick={this.handleClick} />
              </Box>
            </Box>
          </Box>

          <Box className="team-filter-box">{this.renderFilter(t, language)}</Box>

          <Grid className="table-box-card">
            <Grid item sm={12} md={12} xs={12}>
              <Box className="table-top">
                <h4 className="bold-text">{t(" List of team members")}</h4>
                <Box className="filter-head-box">
                  <FilterSelect
                    t={t}
                    language={language}
                    label="Sort By"
                    option={[
                      { label: "All", value: "all" },
                      { label: "Companies", value: "individual_company" },
                      { label: "Complexes", value: "individual_society" },
                      { label: "Individual Buildings", value: "individual_building" },
                    ]}
                    value={this.state.selectedSortBy}
                    onChange={this.handleSelectedSortBy}
                    data-test-id="sortby-dropdown-filter"
                  />
                  <SearchInput
                    t={t}
                    language={language}
                    label={this.getSearchInputPlaceholder()}
                    onChange={(e: any) => this.handleMainSearch(e.target.value)}
                  />
                </Box>
              </Box>
              <Divider />
              <Table className="table-box">
                <TableHead>
                  <TableRow>
                    <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Member ID")}
                    </TableCell>
                    <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                      {t("Member Name")}
                    </TableCell>
                    {this.renderRoleColumn("coo", t, language)}
                    {role !== "account_manager" && (
                      <>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Assigned Companies")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Assigned Complexes")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Assigned Individual Buildings")}
                        </TableCell>
                      </>
                    )}
                    {this.renderRoleColumn("sales_manager", t, language)}
                    {role === "account_manager" && (
                      <>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Department")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Title")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Email")}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                          {t("Phone Number")}
                        </TableCell>
                      </>
                    )}
                    {role !== "account_manager" && <TableCell align={languageCondition(language, "right", "left")} />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(data) && data.length > 0 ? (
                    data.map((item: any, index: any) => {
                      const data = item.attributes;
                      return (
                        <TableRow key={item.id}>
                          <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>{item.id}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {data.employee_name}
                          </TableCell>
                          {this.getRoleValue("coo", data.role, language)}
                          {role !== "account_manager" && (
                            <>
                              <TableCell align={languageCondition(language, "right", "left")}>
                                {data.real_estate_company}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>
                                {data.society_management}
                              </TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>
                                {data.building_management}
                              </TableCell>
                            </>
                          )}
                          {this.getRoleValue("sales_manager", data.role, language)}
                          {role === "account_manager" && (
                            <>
                              <TableCell align={languageCondition(language, "right", "left")}>{data.role}</TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>{data.role}</TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>{data.email}</TableCell>
                              <TableCell align={languageCondition(language, "right", "left")}>
                                {data.full_phone_number}
                              </TableCell>
                            </>
                          )}
                          {role !== "account_manager" && (
                            <TableCell
                              align={languageCondition(language, "right", "left")}
                              className={LanguageAttributeHandler(language, "meeting-menu", "")}
                            >
                              <Menu
                                menuButton={
                                  <IconButton>
                                    <MoreVertIcon />
                                  </IconButton>
                                }
                              >
                                <MenuItem onClick={() => this.props.navigation.navigate("CEMyTeamMember", { id: item.id })}>
                                  {t("View Details")}
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={this.getColSpanValue()}>No record found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Divider />
              {this.state.pagination && (
                <Box className="table-bottom">
                  <p>
                    {t("Showing")} <span className="current-page">{data.length}</span> {t("of")}{" "}
                    <span className="total-page">{this.state.pagination.total_count}</span>{" "}
                    {t("results")}
                  </p>
                  <Pagination
                    onChange={this.handleChangePage}
                    count={this.state.pagination.total_pages}
                    page={this.state.pagination.current_page}
                    siblingCount={2}
                    variant="outlined"
                    shape="rounded"
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          {this.state.empformModal && (
            // @ts-ignore
            <CEAddNewTeamMebmerModal
              openModal={this.state.empformModal}
              closeModal={this.handleFormModal}
              isEdit={false}
            />
          )}
          {this.state.AssignObjOpen && (
            // @ts-ignore
            <CEAssignObjectWeb
              dataFromComponent1={this.state.AssignObjOpen}
              handleModalClose={this.handleModalClose}
              isEdit={false}
            />
          )}
        </Box>
      </CEHeaderComponentWeb>
    );
  }
}

export const MyTeamStyle: any = {
  MyTeamTable: {
    padding: "0 40px",
    "& .navigation": {
      flexDirection: "column",
      alignItems: "flex-start",
      display: "flex",
      "& .sub-heading-box": {
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        alignItems: "center",
        marginBottom: "10px",
        marginTop: "10px",
        "& h5": { fontSize: "28px", fontWeight: "600" },
      },
    },
    "& .team-btn-box": { alignItems: "center", display: "flex", gap: "14px" },
    "& .team-filter-box": { margin: "15px 0" },
    "& .filter-head-box": { display: "flex", alignItems: "center", gap: "10px", margin: "0" },
    "& .more-option-btn": {
      height: "40px",
      color: "#2b6fed",
      fontSize: "15px",
      cursor: "pointer",
      "& p": {
        fontSize: "15px",
        fontWeight: "bold",
        color: "#2b6fed",
      },
    },
    "& .team-btn-box .szh-menu-container ul": { position: "absolute", top: "20px", right: "25px" },
    "& .team-content-heading": { fontSize: "22px", fontWeight: "bold", marginBottom: "10px" },
    "& .team-complex-list-header": {
      padding: "20px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .complex-tab": { color: "#2b6fed", fontWeight: "bold", borderRadius: "10px 10px 0 0" },
    "& .activeMenubar": {
      background: "rgb(43, 111, 236)",
      color: "white",
      borderRadius: "10px 10px 0 0",
      textTransform: "none",
    },
    "& .leftInactiveMenubar": {
      background: "white",
      color: "rgb(43, 111, 236)",
      textTransform: "none",
      borderRadius: "10px 0 0 0",
    },
    "& .rightInactiveMenubar": {
      background: "white",
      color: "rgb(43, 111, 236)",
      textTransform: "none",
      borderRadius: "0 10px 0 0",
    },
    "& .menubarButton": { padding: "0px 40px", minWidth: "auto" },
    "& .tablePagination": {
      padding: "30px 0 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .groupButtonContainer": { display: "flex", gap: "12px", alignItems: "center" },
    "& .ce-team-complex-btn": { maxWidth: "95%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" },
    "& .ce-assign-buttonWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      marginRight: "33px",
      height: "50",
      marginBottom: "20px",
    },
    "& .pendingTaskContent": { display: "flex", gap: "20px", alignItems: "center" },
    "& .complex_table_switch_map_switch": { height: "400px", width: "100%", overflow: "hidden" },
    "& .ce-buttonBorderBox": {
      border: "1px solid #f1f1f1",
      borderRadius: "10px",
      display: "flex",
      alignItems: "center",
      "& .ce-activeIcon": { background: "#dbe7fc", color: "#376bd0", borderRadius: "6px", padding: "5px" },
      "& .ce-inactiveIcon": { color: "#afb0b4", padding: "5px", cursor: "pointer" },
    },
    "& .ce-team-search-bar": {
      width: "300px",
      height: "45px",
      borderRadius: "8px",
      border: "1px solid #f0f0f0",
      "& .MuiOutlinedInput-input": { padding: "13px", paddingLeft: "0" },
    },
    "& .team-complex-list-content": {
      padding: "20px",
      marginBottom: "30px",
      "& .complex-btn-box": { paddingLeft: "10px", paddingRight: "10px" },
      "& .complex-btn": {
        border: "1px solid #f1f1f1",
        borderRadius: "8px",
        width: "100% !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white !important",
        padding: "25px 20px",
        fontWeight: "bold !important",
        "& .MuiButton-label": { color: "#fe8335 !important" },
        "& .MuiButton-endIcon": { color: "#ccc !important" },
        "& li": { color: "black !important" },
      },
    },
    "& .team-member-detail": {
      marginBottom: "30px",
      "& .team-member-detail-card": {
        background: "white",
        boxShadow: "4px 0px 14px #e9e9e9",
        borderRadius: "8px",
        display: "flex",
        "& .member-detail-card-left": {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
          width: "20%",
          padding: "20px",
          "& .MuiAvatar-root": { width: "90px", height: "90px" },
          "& .role-chip": {
            padding: "6px 18px",
            borderRadius: "50px",
            background: "#ccdeff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "blue",
            fontWeight: "bold",
            fontSize: "12px",
          },
          "& .contact-image-box": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            "& div": {
              borderRadius: "50px",
              cursor: "pointer",
              background: "#fff6f0",
              padding: "10px",
              "& img": { width: "25px", height: "25px", objectFit: "contain" },
            },
          },
        },
        "& .member-detail-card-right": {
          borderLeft: "1px solid #ccc",
          width: "80%",
          padding: "20px",
          "& .detail-info-box": {
            marginBottom: "14px",
            fontSize: "16px",
            "& .info-title": { marginBottom: "4px", color: "#272B30" },
            "& .info-value": {},
          },
        },
      },
    },
  },
};

export default withTranslation()(withStyles(MyTeamStyle)(withRouter(CEMyTeamTable)));
//Customizable Area End
