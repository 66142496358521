// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import { Share } from "./assets";
import InvoiceDetailsController, { Props } from "./InvoiceDetailsController";
import "./style.css";
import { withTranslation } from "react-i18next";
import moment from "moment";
// @ts-ignore
import { baseURL }  from "../../../framework/src/config";
import { CloseButton } from "./CloseButton";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";

class Visitors extends InvoiceDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid item dir={languageCondition(language, "rtl", "ltr")} xs={12} md={12} className="invoice-details-page auth-cols">
          <Grid container className="invoice-details-nav-bar fee-payment-top-bar">
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}
            >
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} onClick={() => this.props.history.push("/MyInvoices")} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text">
                  {t(this.state.type?.split("_").join(" "))} {t("Invoice")}
                </p>
              </Box>
            </Grid>
          </Grid>

          <Box className="fee-payment-list invoice-details-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              <Grid item xs={12}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px ",
                  }}
                >
                  <Typography variant={"body1"} className="bold-text invoice-details-title">
                    {t("Invoice Details")}
                  </Typography>
                  <Typography variant="subtitle2" className={this.state.status}>
                    {t(this.state.status?.split("_").join(" "))}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="8px"
                  bgcolor="white"
                  padding="15px"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                >
                  <Box style={{ minWidth: "100%" }}>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="invoice-details-building-name">
                        {t("Building Name")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.building}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="invoice-details-unit-name">
                        {t("Unit")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.unit}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Due Amount")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)} {Number(Number(this.state.amount).toFixed(2)).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Month")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {moment(this.state.dueDate, "DD-MM-YYYY").format("MMMM - YYYY")}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Due Date")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {moment(this.state.date, "DD-MM-YYYY").format("MMM DD, YYYY hh:mm A")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px ",
                  }}
                >
                  <Typography variant={"body1"} className="bold-text">
                    {t("Landlord Details")}
                  </Typography>
                </Box>
                <Box
                  borderRadius="8px"
                  bgcolor="white"
                  padding="15px"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                  className="invoice-details-box invoice-detail-card-box"
                >
                  <Box style={{ marginBottom: "10px" }}>

                    <Typography variant="subtitle2" color="textSecondary">
                      {t("Name")}:
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                      {this.state.owner}
                    </Typography>
                    
                  </Box>
                  <Box style={{ marginBottom: "10px" }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t("ID Number")}:
                    </Typography>
                    
                    <Typography variant="subtitle1" color="textPrimary">
                      {this.state.ownerId}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} className="invoice-details-download-share-btn download-share-bar">
                <CloseButton
                  className="invoice-details-download-btn invoice-button"
                  onClick={() => this.invoiceDetailsDownload(this.state.invoiceId)}
                  fullWidth
                >
                  {t("Download Invoice")}
                </CloseButton>
                <Box
                  onClick={this.handleInvoiceShareModal}
                  className="invoice-details-share-btn shareIcon"
                  style={{ marginRight: "5px", padding: "0 16px", width: "unset" }}
                >
                  <img src={Share} alt="share-btn" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <ShareDocumentModal
          documentURL={`${baseURL}/bx_block_fees_payment/invoices/${this.state.invoiceId}/download_invoice.pdf`}
          language={language}
          handleClose={this.handleInvoiceShareModal}
          heading={t("Share")}
          isOpen={this.state.isShareModalOpen}
        />
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));

// Customizable Area End
