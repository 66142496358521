// Customizable Area Start
import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import CEAccountProfileController, { Props, StyleObject } from "./CEAccountProfileController.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { ProfileStyleWeb } from "../../Settings5/src/ProfileStyle.web";
import {
  EmailIcon,
  FacebookIcon,
  FacebookProfileIcon,
  HeartProfileIcon,
  InstagramIcon,
  InstagramProfileIcon,
  ProfileCalendarIcon,
  ProfileEmailIcon,
  ProfileNameIcon,
  ProfilePhoneIcon,
  SettingIcon,
  SnapchatIcon,
  SnapchatProfileIcon,
  TwitterIcon,
  TwitterProfileIcon,
} from "./assets";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import DialogHeader from "../../../components/src/MuiDialog/DialogHeader.web";
import { Formik } from "formik";
import { ErrorHandler, handleLink } from "../../../components/src/UtilFunction.web";
import NormalTextField from "../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ChipInput from "material-ui-chip-input";
import NormalTextArea from "../../../components/src/CommonComponents/NormalTextArea.web";
import PublishCard from "../../../components/src/ProfileCardComponent/PublishCard.web";
import AlertError from "../../../components/src/AlertError.web";
import NormalDateField from "../../../components/src/CommonComponents/NormalDateField.web";
import { formatOnlyDate } from "../../../components/src/helperFunctions.web";

class CEAccountProfile extends CEAccountProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <AlertError
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
        />

        <CEHeaderComponentWeb>
          <Container className={classes.ChairmanProfile}>
            <Box className="navigation">
              <Box>
                <Typography variant="body1">
                  <Box component="span" style={{ color: "#2B6FED" }}>
                    {t("My Profile")}
                  </Box>
                </Typography>
                <Typography variant="h5" className="sub-heading bold-text">
                  {t("My Profile")}
                </Typography>
              </Box>
            </Box>
            <Box className="my-profile-box">
              <Box className="heading">
                <Typography variant="h6" className="sub-heading bold-text">
                  {t("General Details")}
                </Typography>
                {this.state.role !== "coo" && (
                  <Box
                    onClick={this.handleOpenProfilePublish}
                    className="setting bold-text"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={SettingIcon} alt="setting" />
                    <span>{t("Other Can See")}</span>
                  </Box>
                )}
              </Box>
              {this.state.profileDetailObj && (
                <Card className="profile-details-box">
                  <Grid container>
                    <Grid item xs={3} className="left-side" style={{ padding: 20 }}>
                      <Avatar
                        className="profile"
                        alt={this.state.profileDetailObj.attributes.full_name.value}
                        src={this.state.profileDetailObj.attributes.profile_pic?.url}
                      />
                      <Typography variant="h6" className="sub-heading bold-text">
                        {this.state.profileDetailObj.attributes.full_name.value}
                      </Typography>
                      <Box className="icons">
                        <img
                          className="email-link"
                          onClick={() => handleLink(`mailto:${this.state.profileDetailObj.attributes.email.value}`)}
                          src={EmailIcon}
                          alt="email"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={1} className="border" />
                    <Grid item xs={8} className="right-side" style={{ padding: 20 }}>
                      <Grid container className="about">
                        <Grid item xs={12}>
                          <span>{t("About")}</span>
                          <p>{this.state.profileDetailObj.attributes.bio.value}</p>
                        </Grid>
                      </Grid>
                      <Grid container className="info">
                        <Grid item xs={3}>
                          <span>{t("Gender")}</span>
                          <p>{this.state.profileDetailObj.attributes.gender.value === "female" ? "Female" : "Male"}</p>
                        </Grid>
                        <Grid item xs={3}>
                          <span>{t("DOB")}</span>
                          <p> {formatOnlyDate(this.state.profileDetailObj.attributes.date_of_birth.date_of_birth)}</p>
                        </Grid>
                        <Grid item xs={6}>
                          <span>{t("Hobbies")}</span>
                          <Box className="hobbies" style={{ margin: "0px", padding: "2px" }}>
                            {(this.state.profileDetailObj.attributes.hobbies.hobbies ?? []).map((hobbyItem: string) => {
                              return (
                                <span className="hobby-tag" key={hobbyItem} style={{ textTransform: "capitalize" }}>
                                  {hobbyItem}
                                </span>
                              );
                            })}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container className="social">
                        <Grid item xs={12}>
                          <span className="social-header">{t("Social Media")}</span>
                          <Box className="icons">
                            {this.state.link.twitter && (
                              <img
                                style={webStyle.socialIcon}
                                onClick={() => handleLink(this.state.link.twitter)}
                                src={TwitterIcon}
                                className="twitter-link"
                                alt="phone"
                              />
                            )}
                            {this.state.link.instagram && (
                              <img
                                src={InstagramIcon}
                                onClick={() => handleLink(this.state.link.instagram)}
                                alt="phone"
                                style={webStyle.socialIcon}
                                className="instagram-link"
                              />
                            )}
                            {this.state.link.facebook && (
                              <img
                                className="facebook-link"
                                src={FacebookIcon}
                                style={webStyle.socialIcon}
                                alt="phone"
                                onClick={() => handleLink(this.state.link.facebook)}
                              />
                            )}
                            {this.state.link.snapchat && (
                              <img
                                src={SnapchatIcon}
                                className="snap-link"
                                onClick={() => handleLink(this.state.link.snapchat)}
                                alt="phone"
                                style={webStyle.socialIcon}
                              />
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </Box>
            <Box>
              <Button
                className="edit-admin-profile"
                onClick={this.handleEditProfileDetails}
                style={webStyle.editButton}
              >
                {t("Edit Details")}
              </Button>
            </Box>
          </Container>
        </CEHeaderComponentWeb>

        <Dialog
          open={this.state.isProfileOpen}
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          scroll="paper"
          fullWidth
          className="edit-profile edit-admin-profile-modal"
        >
          <DialogHeader t={t} title="Edit My Profile" onClose={this.handleEditProfile} />
          <Formik
            enableReinitialize
            initialValues={this.state.profileForm}
            validationSchema={this.validationSchema}
            onSubmit={(values) => this.handleFormSubmit(values)}
          >
            {({ values, touched, errors, handleBlur, handleSubmit, setFieldValue, handleChange }) => {

              return (
                <form onSubmit={handleSubmit} className="edit-complex-form">
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      <Box className="profile-picture">
                        <Avatar className="picture building" alt="profile-image" src={values.displayLogo || ""} />
                        <p className="logo-text bold-text" onClick={() => this.uploadLogo.click()}>
                          {t("Change Logo")}
                        </p>
                        <input
                          ref={(ref: any) => (this.uploadLogo = ref)}
                          style={{ display: "none" }}
                          className="complex-details-logo"
                          accept="image/*"
                          type="file"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const files = e.target.files;
                            if (files) {
                              const file = files[0];
                              setFieldValue("displayLogo", URL.createObjectURL(file));
                              setFieldValue("logo", file);
                            }
                          }}
                          onBlur={handleBlur}
                          name="logo"
                        />
                        {ErrorHandler(t, touched.logo, errors.logo)}
                      </Box>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Full Name"
                          value={values.name}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isLabel={false}
                          icon={ProfileNameIcon}
                        />
                        {ErrorHandler(t, touched.name, errors.name)}
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="mobile-box">
                          <Box>
                            <PhoneInput
                              value={values.countryCode}
                              enableSearch={true}
                              country="us"
                              onChange={(value: any) => {
                                setFieldValue("countryCode", `+${value}`);
                              }}
                            />
                          </Box>
                          <Box className="divider" />
                          <NormalTextField
                            fullWidth
                            t={t}
                            language={language}
                            label="Mobile Number"
                            value={values.phoneNumber}
                            name="phoneNumber"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isLabel={false}
                            icon={ProfilePhoneIcon}
                            style={{ border: 0 }}
                          />
                        </Box>
                        {ErrorHandler(t, touched.phoneNumber, errors.phoneNumber)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Email"
                          value={values.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isLabel={false}
                          icon={ProfileEmailIcon}
                          disabled
                        />
                        {ErrorHandler(t, touched.email, errors.email)}
                      </Grid>
                      <Grid item xs={12}>
                        <NormalTextArea
                          t={t}
                          language={language}
                          label="About"
                          value={values.about}
                          name="about"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isLabel={false}
                          icon={ProfileEmailIcon}
                        />
                        {ErrorHandler(t, touched.about, errors.about)}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="bold-text" style={{ marginBottom: "10px" }}>
                          {t("Gender")}
                        </Typography>
                        <RadioGroup
                          value={values.gender}
                          style={{ marginLeft: "12px" }}
                          name="gender"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <Box style={webStyle.radioGroup}>
                            <FormControlLabel
                              className={this.handleActiveClass(values.gender, "Female")}
                              value={t("Female")}
                              control={
                                <Radio
                                  icon={<CircleUnchecked style={{ color: "#525252" }} />}
                                  checkedIcon={<RadioButtonCheckedIcon style={{ color: "#FC8434" }} />}
                                />
                              }
                              label="Female"
                              style={webStyle.radioButton}
                            />
                            <FormControlLabel
                              className={this.handleActiveClass(values.gender, "Male")}
                              value={t("Male")}
                              control={
                                <Radio
                                  icon={<CircleUnchecked style={{ color: "#525252" }} />}
                                  checkedIcon={<RadioButtonCheckedIcon style={{ color: "#FC8434" }} />}
                                />
                              }
                              label="Male"
                              style={webStyle.radioButton}
                            />
                          </Box>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={6}>
                        <NormalDateField
                          language={language}
                          t={t}
                          label="Dob"
                          value={values.dob}
                          name="dob"
                          onChange={(val: string) => setFieldValue("dob", val)}
                          onBlur={handleBlur}
                          isLabel={false}
                          icon={ProfileCalendarIcon}
                        />
                        {ErrorHandler(t, touched.dob, errors.dob)}
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="formInputGrp" style={webStyle.hobby}>
                          <ChipInput
                            className="formInput"
                            placeholder={t("Hobbies")}
                            style={{ padding: "10px 0px 7px 40px" }}
                            disableUnderline={true}
                            value={values.hobbies}
                            onAdd={(chip: string) => {
                              setFieldValue("hobbies", [...values.hobbies, chip]);
                            }}
                            onChange={(chip: string[]) => {
                              setFieldValue("hobbies", chip);
                            }}
                            onDelete={(_: string, index: any) => {
                              const hobbyList = values.hobbies.filter((_: string, idx: number) => idx !== index);
                              setFieldValue("hobbies", [...hobbyList]);
                            }}
                          />
                          <span style={webStyle.hobbyImage}>
                            <img src={HeartProfileIcon} style={{ width: "23px" }} />
                          </span>
                        </Box>
                        {ErrorHandler(t, touched.hobbies, errors.hobbies)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Twitter profile link"
                          value={values.twitter}
                          name="twitter"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isLabel={false}
                          icon={TwitterProfileIcon}
                        />
                        {ErrorHandler(t, touched.twitter, errors.twitter)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Facebook profile link"
                          value={values.facebook}
                          name="facebook"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isLabel={false}
                          icon={FacebookProfileIcon}
                        />
                        {ErrorHandler(t, touched.facebook, errors.facebook)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Instagram profile link"
                          value={values.instagram}
                          name="instagram"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isLabel={false}
                          icon={InstagramProfileIcon}
                        />
                        {ErrorHandler(t, touched.instagram, errors.instagram)}
                      </Grid>
                      <Grid item xs={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label="Snapchat profile link"
                          value={values.snapchat}
                          name="snapchat"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isLabel={false}
                          icon={SnapchatProfileIcon}
                        />
                        {ErrorHandler(t, touched.snapchat, errors.snapchat)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleEditProfile}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("SAVE")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>

        <Dialog
          open={this.state.isPublishOpen}
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          scroll="paper"
          fullWidth
          className="edit-profile edit-publish-profile-modal"
        >
          <DialogHeader t={t} title="Publish Details for Others" onClose={this.handleEditPublishProfile} />
          <Formik
            enableReinitialize
            initialValues={this.state.publishDetails}
            onSubmit={async (values) => {
              this.setState({ loading: true, isPublishOpen: false }, () => {
                this.updatePublishProfile(values);
              });
            }}
          >
            {({ values, handleBlur, handleSubmit, handleChange }) => {
              return (
                <form onSubmit={handleSubmit} className="">
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Name"
                          value={this.state.profileDetailObj?.attributes.full_name.value}
                          name="name"
                          checked={true}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Phone No."
                          value={this.state.profileDetailObj?.attributes.full_phone_number.full_phone_number}
                          name="phone"
                          checked={true}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Email Address"
                          value={this.state.profileDetailObj?.attributes.email.value}
                          name="email"
                          checked={true}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Gender"
                          value={this.state.profileDetailObj?.attributes.gender.value === "female" ? "Female" : "Male"}
                          name="gender"
                          onChange={handleChange}
                          checked={values.gender}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Date of Birth"
                          value={this.state.profileDetailObj?.attributes.date_of_birth.date_of_birth}
                          name="dob"
                          onChange={handleChange}
                          checked={values.dob}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Hobbies"
                          value={(this.state.profileDetailObj?.attributes.hobbies.hobbies ?? []).toString()}
                          name="hobby"
                          onChange={handleChange}
                          checked={values.hobby}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Twitter"
                          value={this.state.profileDetailObj?.attributes.website.twitter_link}
                          name="twitter"
                          onChange={handleChange}
                          checked={values.twitter}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Instagram"
                          value={this.state.profileDetailObj?.attributes.website.instagram_link}
                          name="instagram"
                          onChange={handleChange}
                          checked={values.instagram}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Facebook"
                          value={this.state.profileDetailObj?.attributes.website.fb_link}
                          name="facebook"
                          onChange={handleChange}
                          checked={values.facebook}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PublishCard
                          t={t}
                          label="Snapchat"
                          value={this.state.profileDetailObj?.attributes.website.snapchat_link}
                          name="snapchat"
                          onChange={handleChange}
                          checked={values.snapchat}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleEditPublishProfile}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("SAVE")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>
      </>
    );
  }
}

const webStyle: StyleObject = {
  editButton: {
    background: "#2B6FED",
    color: "white",
    borderRadius: "8px",
    padding: "10px 22px",
    marginTop: "50px",
    float: "right",
  },
  radioButton: {
    borderRadius: "8px",
    border: "1px solid #f0f0f0",
    height: "55px",
    width: "100%",
    background: "#F9F9F9",
    backgroundColor: "white",
    marginRight: "0",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "28px",
  },
  hobby: {
    width: "100%",
    border: "1px solid #f0f0f0",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    position: "relative",
  },
  hobbyImage: {
    position: "absolute",
    top: "20px",
    left: "10px",
  },
  socialIcon: {
    cursor: "pointer",
  },
  publishBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    borderRadius: "8px",
    padding: "15px",
    border: "1px solid #e4e4e4",
  },
};

export default withTranslation()(withStyles(ProfileStyleWeb)(withRouter(CEAccountProfile)));
// Customizable Area End
