// Customizable area start
import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import "@szhsin/react-menu/dist/core.css";
import { Menu as MenuNew } from "@szhsin/react-menu";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DashboardHeaderController, { Props } from "./DashboardHeaderController.web";
import { globalIcon, LogoutDialogIcon, notification, TenantLogo } from "../assets";
import "./global.css";
import i18next from "i18next";
import { handleNavigation } from "../../../../components/src/UtilFunction.web";

export default class DashboardHeader extends DashboardHeaderController {
    constructor(props: Props) {
        super(props);
     }
     handleEngLngChange = () => {
      localStorage.setItem("language", "en");
      i18next.changeLanguage("en");
  };

  handleAreLngChange = () => {
      localStorage.setItem("language", "ar");
      i18next.changeLanguage("ar");
  };
     render() {
      
      return (

            <AppBar elevation={0} position="sticky" style={{ display: 'flex', gap: "20px", height: '80px', justifyContent: 'center', backgroundColor: '#FFFFFF' }}>
                <Toolbar style={webStyle.styledtoolbar}>
                        <img src={TenantLogo.default} style={{ width: '126.58px', height: '49px', }} data-test-id='Logo'/>
                        <Box style={{ display: "flex", alignItems: "center", gap: "20px", }}>
                        <Box>
                                        <MenuNew
                                            className="chairman-lang-menu chairman-menu menu-button ManagementLogin"
                                            arrow={true} 
                                            align="center" 
                                            menuButton={<img src={globalIcon} alt="GlobalIcon"/>}
                                            data-test-id="test_lang"
                                            >
                                            <MenuItem
                                                className={localStorage.getItem("language") === "en" ? "active ManagementLogin dashboardHeader_lang" : "ManagementLogin dashboardHeader_lang"}
                                                data-test-id="english"
                                                onClick={() => this.handleEngLngChange()}
                                                id="item1"
                                            >
                                                English
                                            </MenuItem>
                                            <MenuItem
                                                className={localStorage.getItem("language") === "ar" ? "active ManagementLogin dashboardHeader_lang" : "ManagementLogin dashboardHeader_lang"}
                                                onClick={() => this.handleAreLngChange()}
                                                data-test-id="arabic"
                                                id="item2"
                                            >
                                                Arabic
                                            </MenuItem>
                                        </MenuNew>
                                    </Box>
                            <img onClick={() => handleNavigation("/CompanyEmployee/Notification")} src={notification} alt="GlobalIcon" />
                            <Avatar alt="Remy Sharp" src={this.state.profileData?.attributes.profile_pic?.url} style={{width:'50px', height:'50px'}}/>
                            <Box>
                                <Typography style={{ fontSize:"14px", fontWeight:'bold'}}  data-test-id="FullName" onClick={()=>{ }} className="login-h1 bold-text ">{this.state.profileData?.attributes.full_name.value}</Typography>
                                <Typography style={{ fontSize:"13px", fontWeight:'bold'}}>{this.state.actualrole}</Typography>
                            </Box>
                           
                            <ExpandMore style={{color:'black', cursor:"pointer"}} onClick={(e:any) => { this.handleOpen(e) }} id="myExpand" data-test-id='Expand'/>  
                            
                        </Box>
                    <Menu open={this.state.open} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    style={{
                        position: 'absolute',
                        top: '40px'
                      }}
                        onClose={() => { this.setState({ open: false }) }}
                        anchorEl={this.state.field}

                    >
                        <MenuItem><Link to="/CompanyEmployee/AdminProfile">Profile</Link></MenuItem>
                        <MenuItem onClick={()=>{this.setState({isLogoutModalOpen:!this.state.isLogoutModalOpen,open: false})}} data-test-id='Logout' >Logout</MenuItem>
                    </Menu>
                    <Dialog
          className="delete-document personal chairman-logout"
          fullWidth
         
          open={this.state.isLogoutModalOpen}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={LogoutDialogIcon} alt="ExclamationIcon" />
              <Typography variant="h6" className="bold-text">
                Are you sure you want to logout?
              </Typography>
              <Typography variant="body1">You will be returned to the login screen</Typography>
              <DialogActions className="dialog-button-group">
                <Button className="close" onClick={() => this.handleLogoutModal()} data-test-id='ModalClose'>
                  Close
                </Button>
                <Button className="logout" onClick={() => this.logout()} data-test-id='ModalLog'>
                  Logout
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
                </Toolbar>
            </AppBar>

        );
    }
}

const webStyle = {
    login_img: {
        width: '126.58px',
        height: '49px',
    },
    styledtoolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
};
// Customizable Area End































































































































































































































