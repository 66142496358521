// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  IconButton,
  withStyles,
  Box,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  Input,
  InputAdornment,
  Checkbox,
  Drawer,
  FormControl,
  Link,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import LeaseFormController, { Props } from "./LeaseFormController.web";
import { Formik, Form } from "formik";
import { ContractsStyleWeb } from "./ContractsStyle.web";
import { CardIcon, EditIcon, PAmountIcon, PCardIcon, PenaltyAmountIcon } from "./assets";
import { withTranslation } from "react-i18next";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";
import { Close } from "@material-ui/icons";
import DrawerBox from "./components/DrawerBox.web";

class ChangedSelectedTemplate extends LeaseFormController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const latePaymentPenalty = JSON.parse(window.sessionStorage.getItem("isLatePaymentPenalty") as any);
    const sessionCondition = JSON.parse(window.sessionStorage.getItem("condition") as any);
    const template_id: any = this.props.navigation.getParam("templateId");

    this.setState(
      {
        ...this.state,
        templateId: template_id,
        isLatePaymentPenalty: latePaymentPenalty,
        selectedPaymentTermId: sessionCondition.paymentTerm.map((term: any) => Number(term)),
        selectedPersonalConditionId: sessionCondition.personalCondition.map((condition: any) => Number(condition)),
      },
      () => {
        this.getTemplateText();
        this.getPenaltyDetails();
        this.getPaymentTerm();
        this.getPersonalCondition();
      }
    );
  }

  renderConditionsDrawer() {
    const { t, i18n } = this.props;
    const language = i18n.language;
    const { isConditionModalOpen, personalCondition, selectedPersonalConditionId, selectedPaymentTermId } = this.state;
    const copyDisabled = selectedPaymentTermId.length === 0 && this.state.selectedPersonalConditionId.length === 0;
    const addConditionDisabled = selectedPaymentTermId.length === 0 && this.state.selectedPersonalConditionId.length === 0;
    return (
      <Drawer dir={languageCondition(language, "rtl", "ltr")} anchor="bottom" className="condition-modal select-template-condition-modal" open={isConditionModalOpen} onClose={this.handleConditionModal}>
        <DrawerBox data-test-id="drawer-wrapper">
          <Box className="header-wrapper">
            <h2 className="bold-text">{t("Add More Conditions")}</h2>
            <Button size="small" variant="text" data-test-id="condition-drawer-close" className="drawer-button__close" onClick={this.handleConditionModal}>
              <Close />
            </Button>
          </Box>
          <Box className="drawer-content condition-box">
            <Box className="content-box">
              <h4 className="bold-text">{t("Personal Conditions")}</h4>
              {personalCondition.map((condition: any, index: number) => {
                const isChecked = selectedPersonalConditionId.includes(condition.id);
                return (
                  <Box className="condition" key={index}>
                    <p>{LanguageAttributeHandler(language, condition.text, condition.text_ar)}</p>
                    <Checkbox data-test-id="personal-condition" className="condition-check" checked={isChecked} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePersonalConditionChange(e, condition)} icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />
                  </Box>
                );
              })}
            </Box>
            <Box className="content-box">
              <h4 className="bold-text">{t("Payment Terms")}</h4>
              {this.state.paymentTerm.map((term: any, index: number) => {
                const isChecked = selectedPaymentTermId.includes(term.id);
                return (
                  <Box className="condition" key={index}>
                    <p>{LanguageAttributeHandler(language, term.text, term.text_ar)}</p>
                    <Checkbox data-test-id="payment-term" className="condition-check" checked={isChecked} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePaymentTermChange(e, term)} icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box className="button-group">
            <Button data-test-id="copy-condition" className="button-copy__checked" size="large" variant="outlined" color="secondary" disabled={copyDisabled} onClick={this.handleConditionCopy}>
              {t("Copy Checked Condition")}
            </Button>
            <Button data-test-id="add-checked-condition" className="button-add__condition" disabled={addConditionDisabled} onClick={this.handleAddCondition}>
              {t(`Add Checked Condition to a Lease`)}
            </Button>
          </Box>
        </DrawerBox>
      </Drawer>
    )
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    const customConditionText = JSON.parse(window.sessionStorage.getItem("condition") as any);

    return (
      <>
        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.changedTemplate}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className="faq-step">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <IconButton data-test-id="back-button" className="change-template-back-btn" onClick={() => this.gotoLeaseFormPage()}>
                      <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                    </IconButton>
                    <span className="bold-text">{t("Issue a Lease")}</span>
                  </div>
                </Box>
                <Container className="page-container">
                  <div className="template-box" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div className="template-view" style={{ maxHeight: '50vh', paddingBottom: '50px' }}>
                      <div dangerouslySetInnerHTML={{ __html: this.state.changedTemplate }} />
                      {this.state.selectedPaymentTermText.length !== 0 && (
                        <>
                          <br />
                          <h4 className="bold-text">{t("Payment Terms")}</h4>
                        </>
                      )}
                      {this.state.selectedPaymentTermText.map((condition: any) => {
                        return <p key={condition.id}>{condition.text}</p>;
                      })}
                      {this.state.selectedPersonalConditionText.length !== 0 && (
                        <>
                          <br />
                          <h4 className="bold-text">{t("Personal Conditions")}</h4>
                        </>
                      )}
                      {this.state.selectedPersonalConditionText.map((condition: any) => {
                        return <p key={condition.id}>{condition.text}</p>;
                      })}
                      <br />
                      {customConditionText?.isEditorCondition && (
                        <>
                          <h4 className="bold-text">{t("Custom Condition")}</h4>
                          <div dangerouslySetInnerHTML={{ __html: customConditionText.editorCondition }} />
                        </>
                      )}
                      <br />
                    </div>
                    <div className="upload-button">
                      <Box className="condition-select">
                        <Checkbox
                          data-test-id="include-late-payment-checkbox"
                          checked={this.state.isLatePaymentPenalty}
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          onChange={(e) => {
                            this.setState(
                              {
                                isLatePaymentPenalty: e.target.checked,
                              },
                              () => {
                                window.sessionStorage.setItem(
                                  "isLatePaymentPenalty",
                                  `${this.state.isLatePaymentPenalty}`
                                );
                                if (!this.state.penalty && this.state.isLatePaymentPenalty) {
                                  this.handlePenaltyCountModal();
                                }
                              }
                            );
                          }}
                        />
                        <span>{t("Include Penalty for late Payment")}</span>
                      </Box>
                      {this.state.penalty && (
                        <Box className="penalty-detail">
                          <div className="header">
                            <h4 className="bold-text">{t("Penalty Details")}</h4>
                            <img
                              data-test-id="edit-penalty"
                              src={EditIcon}
                              onClick={() => {
                                this.setState(
                                  {
                                    penaltyId: this.state.penalty.id,
                                    penaltyType: this.state.penalty.penanlty_counted,
                                    penaltyAmount: this.state.penalty.amount,
                                  },
                                  () => {
                                    this.handlePenaltyCountModal();
                                  }
                                );
                              }}
                            />
                          </div>
                          <div className="content">
                            <Grid container spacing={2}>
                              <Grid item xs={12} className="content-item" style={{ gap: '10px' }}>
                                <Box>
                                  <img src={CardIcon} style={{ margin: '0' }} />
                                </Box>
                                <Box>
                                  <span>{t("How Penalty will be counted?")}</span>
                                  <p className="bold-text">{t(this.state.penalty.penanlty_counted)}</p>
                                </Box>
                              </Grid>
                              <Grid item xs={12} className="content-item" style={{ gap: '10px' }}>
                                <Box>
                                  <img src={PenaltyAmountIcon} style={{ margin: '0' }} />
                                </Box>
                                <Box>
                                  <span>{t("Penalty Amount")}</span>
                                  <p className="bold-text">
                                    {sessionStorage.getItem("currency")} {this.state.penalty.amount}
                                  </p>
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                        </Box>
                      )}
                      <Box className="button-group">
                        <Button data-test-id="add-more-condition" className="condition-button" onClick={() => this.handleConditionModal()}>
                          {t("Add More Condition")}
                        </Button>
                        <Link href={`${window.location.pathname}/Review`}>
                          <Button>{t("Review A Lease")}</Button>
                        </Link>
                      </Box>
                    </div>
                  </div>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>
        {this.renderConditionsDrawer()}
        <Drawer data-test-id="penalty-drawer" dir={languageCondition(language, "rtl", "ltr")} anchor="bottom" className="condition-modal penalty-dialog" open={this.state.isPenaltyCountModalOpen}>
          <Formik data-test-id="penalty-form" initialValues={{ penaltyType: this.state.penaltyType, penaltyAmount: this.state.penaltyAmount, }}
            validationSchema={this.PenaltyFormValidation}
            onSubmit={(values, { resetForm }) => {
              if (this.state.penaltyId) {
                this.editPenalty(values);
              } else {
                this.createPenalty(values);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes">
                  <Box className={languageCondition(language, "arabic-grid", "")}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h4 className="bold-text">{t("Penalty for late payments")}</h4>
                      <Button data-test-id="penalty-drawer-close" size="small" variant="text" className="drawer-button__close" onClick={this.togglePenaltyModalCount}>
                        <Close />
                      </Button>
                    </Box>
                    <FormControl fullWidth>
                      <Box className="select-penalty-type">
                        <Select
                          displayEmpty
                          value={values.penaltyType}
                          variant="filled"
                          name="penaltyType"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="select-with-icon"
                          input={<OutlinedInput />}
                        >
                          <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                            {t("How penalty will be counted")}
                          </MenuItem>
                          <MenuItem value="Fixed Percentage" className={languageCondition(language, "select-arabic-menu", "")}>{t("Fixed Percentage of Rent")}</MenuItem>
                          <MenuItem value="Fixed Amount" className={languageCondition(language, "select-arabic-menu", "")}>{t("Fixed Amount")}</MenuItem>
                        </Select>
                        <img src={PCardIcon} alt="" />
                      </Box>
                      {errors.penaltyType && touched.penaltyType && <p className="error">{t(errors.penaltyType)}</p>}
                    </FormControl>
                    {values.penaltyType && (
                      <FormControl fullWidth>
                        <Input
                          data-test-id="penalty-amt-input"
                          value={values.penaltyAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="penaltyAmount"
                          className="select-input"
                          placeholder={
                            values.penaltyType === "Fixed Amount"
                              ? `${t("Enter Fixed Amount")}`
                              : `${t("Enter Fixed Percentage of Rent")}`
                          }
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={PAmountIcon} alt="" />
                            </InputAdornment>
                          }
                        />
                        {errors.penaltyAmount && touched.penaltyAmount && (
                          <p className="error">{t(errors.penaltyAmount)}</p>
                        )}
                      </FormControl>
                    )}
                  </Box>
                  <Box className="button-group">
                    <Button className="add-button" type="submit">
                      {this.state.penaltyId ? `${t("Edit")}` : `${t("Add")}`}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Drawer>
      </>
    );
  }
}

export default withTranslation()(withStyles(ContractsStyleWeb)(ChangedSelectedTemplate));
// Customizable Area End
