// Customizable Area Start
import React from "react";
import { Box, Input, InputAdornment, InputLabel } from "@material-ui/core";

const NormalTextField = ({ t, language, label, icon, isLabel, value, required = false, ...props }: any) => {
  return (
    <Box className="input_icon__form_box">
      {isLabel && (
        <InputLabel>
          {t(label)} {required && <span style={{ color: "red" }}>*</span>}
        </InputLabel>
      )}
      <Input
        className="input_box"
        fullWidth
        placeholder={t(label)}
        value={value}
        startAdornment={
          icon && (
            <InputAdornment position="start">
              <img src={icon} alt="icon" />
            </InputAdornment>
          )
        }
        {...props}
      />
    </Box>
  );
};

export default NormalTextField;
// Customizable Area End
